import moment from "moment";
import React from "react";
import { toAbsoluteUrl } from "../../assets/helpers/AssetHelpers";
import { useQuery } from "@apollo/client";
import { DOWNLOADINVOICE } from "../Cart/core/requests";
import toast from "react-hot-toast";
import { FaHourglassHalf } from "react-icons/fa";

const OrderCard = (props: { order: any }) => {
  const order = props.order;
  const date = moment
    .utc(order.createdAt)
    .local()
    .format("YYYY-MM-DD HH:mm:ss");
  const { data: invoiceData, refetch } = useQuery(DOWNLOADINVOICE, {
    variables: {
      order_id: order.id,
    },
    onCompleted: () => {
      refetch();
    },
    // onError: (err:any) => {
    //     toast.error(err.message)
    // }
  });
  const downloadPDF = async (pdf: any) => {
    console.log("pdf", pdf);
    refetch();
    const linkSource = `data:application/pdf;base64,${pdf}`;
    console.log("ss", linkSource);
    const downloadLink = document.createElement("a");
    const orderID = order?.id;
    const utcTimestamp = new Date().toISOString().replace(/:/g, "-");
    const fileName = `${orderID}_${utcTimestamp}.pdf`;
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  return (
    <>
      <div className="card border-color3 mb-lg-3 d-lg-block d-md-block d-none">
        <div className="card-header bg-transparent border-color3">
          <div className="row justify-content-lg-between align-items-l    g-center">
            <div className="col-8">
              <div className="font-subtitle fs-20 font-switzer color5 fw-bold">
                Order Id : {order.id}
              </div>
              <div className="font-subtitle fs-14 font-switzer color2 fw-medium">
                {moment(date).format("DD") +
                  " " +
                  moment(date).format("MMM").substring(0, 3) +
                  ", " +
                  moment(date).format("YYYY") +
                  " " +
                  moment(date).format("HH:mm")}
              </div>
            </div>
            <div className="col-2">
              <div className="badge rounded-pill py-lg-2 bg-color3 fs-16 font-switzer">
                {order.status == "PAID" && (
                  <>
                    <span className="me-2 color6">
                      <i className="fa-solid fa-circle-check"></i>
                    </span>
                    <span className="text-dark fw-medium">Success</span>
                  </>
                )}
                {order.status === "FAILED" && (
                  <>
                    <span className="me-2 color12">
                      <i
                        className="fa-sharp fa-solid fa-circle-xmark"
                        style={{ color: "#e60f0f" }}
                      ></i>
                    </span>
                    <span className="text-dark fw-medium">Failed</span>
                  </>
                )}
                {order.status === "PENDING" && (
                  <>
                    <img src="../media/banner/pro2.png" alt="" />
                    <span className="text-dark fw-medium ms-1">Pending</span>
                  </>
                )}
              </div>
            </div>
            {order.status == "PAID" && (
              <div className="col-2">
                <div className="text-end">
                  <a
                    className="color1 text-decoration-none fs-16 font-switzer fw-bold cursor-pointer"
                    onClick={() => {
                      refetch();
                      downloadPDF(invoiceData?.downloadInvoice);
                    }}
                  >
                    Download Invoice
                  </a>
                </div>
              </div>
            )}
            {order.status == "FAILED" && (
              <div className="col-2">
                <div className="text-end">
                  <a
                    className="color1 text-decoration-none fs-16 font-switzer fw-bold cursor-pointer"
                    href={`${order.paymentLink}`}
                    target="_blank"
                  >
                    Payment Link
                  </a>
                </div>
              </div>
            )}
            {order.status == "PENDING" && <div className="col-2"></div>}
          </div>
        </div>
        {order.orderDetails.length > 0 ? (
          <div className="card-body bg-transparent border-color3">
            {order.orderDetails.map((value: any) => {
              return (
                <>
                  <div className="row justify-content-lg-between mb-lg-2">
                    <div className="col-10">
                      <span className="font-subtitle color5 fs-16 font-switzer fw-medium">
                        {value.course.name}
                        {value.userId}
                      </span>
                      {/* {order.status == "PENDING" && <div>
                                        <div className="alert mt-4 py-2 px-2" style={{ backgroundColor: "#F2F4F7" }} role="alert">
                                            <i className="fa-solid fa-circle-info me-2" style={{ color: "blue" }}></i>If any money was debited from your account, it will be automatically refunded within 5-7 business days.
                                        </div>
                                    </div>} */}
                    </div>
                    <div className="col-2">
                      <div className="text-end">
                        <span className="color2 fs-16 font-switzer fw-medium">
                          ₹ {value.totalPrice}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    {order.status == "PENDING" && (
                      <div>
                        <div
                          className="alert mt-4 py-2 px-2"
                          style={{ backgroundColor: "#F2F4F7" }}
                          role="alert"
                        >
                          <i
                            className="fa-solid fa-circle-info me-2"
                            style={{ color: "blue" }}
                          ></i>
                          If any money was debited from your account, it will be
                          automatically refunded within 5-7 business days.
                        </div>
                      </div>
                    )}
                  </div>
                </>
              );
            })}
          </div>
        ) : (
          <div className="row">
            <div className="col-12">
              <div className="row justify-content-lg-center align-items-lg-center p-4">
                <div className="col-12 ">
                  <div className="pb-lg-5 text-lg-center">
                    <img
                      src={toAbsoluteUrl("../media/logo/wishlistempty.jpg")}
                      alt="celebration"
                      height="100"
                      width="100"
                      className="my-3 img-fluid col-12 col-lg-3"
                    />
                    <div className="d-flex justify-content-center mx-auto">
                      <div
                        className="btn fs-18 font-subtitle my-lg-2"
                        id="start-over"
                      >
                        <p className="fw-bold fs-4 font-title">
                          No Orders Yet!!
                        </p>
                        <p className="fs-6">
                          When you set as a Bookmarks, we will show up here.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="card border-color3 mb-lg-3 d-lg-none d-md-none d-block mt-3">
        <div className="card-header bg-transparent border-color3">
          <div className="row justify-content-lg-between align-items-l g-center">
            <div className="col-12">
              <div className="font-subtitle fs-20 font-switzer color5 fw-bold py-2">
                Order Id : {order.id}
              </div>
              <div className="font-subtitle fs-14 font-switzer color2 fw-medium py-2">
                {moment(date).format("DD") +
                  " " +
                  moment(date).format("MMM").substring(0, 3) +
                  ", " +
                  moment(date).format("YYYY") +
                  " " +
                  moment(date).format("HH:mm")}
              </div>
            </div>
            <div className="col-6 pt-2">
              <div className="badge rounded-pill py-lg-2 bg-color3 fs-16 font-switzer">
                {order.status == "PAID" && (
                  <>
                    <span className="me-2 color6">
                      <i className="fa-solid fa-circle-check"></i>
                    </span>
                    <span className="text-dark fw-medium">Success</span>
                  </>
                )}
                {order.status === "FAILED" && (
                  <>
                    <span className="me-2 color12">
                      <i
                        className="fa-sharp fa-solid fa-circle-xmark"
                        style={{ color: "#e60f0f" }}
                      ></i>
                    </span>
                    <span className="text-dark fw-medium">Failed</span>
                  </>
                )}
                {order.status === "PENDING" && (
                  <>
                    <img src="../media/banner/pro2.png" alt="" />
                    <span className="text-dark fw-medium ms-1">Pending</span>
                  </>
                )}
              </div>
            </div>
            {order.status == "PAID" && (
              <div className="col-6 pt-2">
                <div className="text-end">
                  <a
                    className="color1 text-decoration-none fs-16 font-switzer fw-bold cursor-pointer"
                    onClick={() => {
                      refetch();
                      downloadPDF(invoiceData?.downloadInvoice);
                    }}
                  >
                    Download Invoice
                  </a>
                </div>
              </div>
            )}
            {order.status == "FAILED" && (
              <div className="col-6 pt-2">
                <div className="text-end">
                  <a
                    className="color1 text-decoration-none fs-16 font-switzer fw-bold cursor-pointer"
                    href={`${order.paymentLink}`}
                    target="_blank"
                  >
                    Payment Link
                  </a>
                </div>
              </div>
            )}
            {order.status == "PENDING" && <div className="col-6 pt-2"></div>}
          </div>
        </div>
        {order.orderDetails.length > 0 ? (
          <div className="card-body bg-transparent border-color3">
            {order.orderDetails.map((value: any) => {
              return (
                <>
                  <div className="row justify-content-lg-between mb-lg-2">
                    <div className="col-9">
                      <span className="font-subtitle color5 fs-16 font-switzer fw-medium">
                        {value.course.name}
                        {value.userId}
                      </span>
                    </div>
                    <div className="col-3">
                      <div className="">
                        <span className="color2 fs-16 font-switzer fw-bolder text-dark">
                          ₹ {value.totalPrice}
                        </span>
                      </div>
                    </div>
                    {order.status == "PENDING" && (
                      <div>
                        <div
                          className="alert mt-4 py-2 px-2"
                          style={{ backgroundColor: "#F2F4F7" }}
                          role="alert"
                        >
                          <i
                            className="fa-solid fa-circle-info me-2"
                            style={{ color: "blue" }}
                          ></i>
                          If any money was debited from your account, it will be
                          automatically refunded within 5-7 business days.
                        </div>
                      </div>
                    )}
                  </div>
                </>
              );
            })}
          </div>
        ) : (
          <div className="row">
            <div className="col-12">
              <div className="row justify-content-lg-center align-items-lg-center p-4">
                <div className="col-12 ">
                  <div className="pb-lg-5 text-lg-center">
                    <img
                      src={toAbsoluteUrl("../media/logo/wishlistempty.jpg")}
                      alt="celebration"
                      height="100"
                      width="100"
                      className="my-3 img-fluid col-12 col-lg-3"
                    />
                    <div className="d-flex justify-content-center mx-auto">
                      <div
                        className="btn fs-18 font-subtitle my-lg-2"
                        id="start-over"
                      >
                        <p className="fw-bold fs-4 font-title">
                          No Orders Yet!!
                        </p>
                        <p className="fs-6">
                          When you set as a Bookmarks, we will show up here.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default OrderCard;
