import React, { useState, useCallback, useEffect } from "react";
import AllCourse from "./AllCourse";
import "./../../../assets/sass/css/list-page.css";
import SideFilter from "../../common/SideFilter/SideFilter";
import NewArrivalsSlider from "./NewArrivalsSlider";
import RecommendSlider from "./RecommendSlider";
enum PriceName {
  PAID = "PAID",
  FREE = "FREE",
}
type PriceItem = {
  id: number;
  value: string;
  name: PriceName; // This enforces that name can only be "PAID" or "FREE"
};
function Course() {
  const price: PriceItem[] = [
    { id: 1, value: "Paid", name: PriceName.PAID },
    { id: 2, value: "Free", name: PriceName.FREE },
  ];
  const [selectedPrice, setSelectedPrice] = useState<PriceName | null>(null);
  const [refreshedQueryOptions, setRefreshedQueryOptions] =
    React.useState<any>(null);
  const [whereCondition, setWhereCondition] = React.useState({});
  const [filterCategory, setFilterCategory] = useState<any>([]);
  const [filterLevel, setFilterLevel] = useState<any>([]);
  const [filterMode, setFilterMode] = useState<any>([]);
  const [filterLanguage, setFilterLanguage] = useState<any>([]);
  const [filterSortBy, setFilterSortBy] = useState<any>([]);
  const [checkedCategory, setCheckedCategory] = useState<any>([]);
  const [checkedLevel, setCheckedLevel] = useState<any>([]);
  const [checkedMode, setCheckedMode] = useState<any>([]);
  const [checkedLanguage, setCheckedLanguage] = useState<any>([]);
  const handleModeChange = (event: any, value: any) => {
    setCheckedMode((prevCheckedMode: any) => {
      const updatedList = event.target.checked
        ? [...prevCheckedMode, { name: value.name, id: event.target.value }]
        : prevCheckedMode.filter((item: any) => item.id !== event.target.value);

      const modeValue = updatedList.map((e: any) => e.id);
      setFilterMode(modeValue);

      return updatedList;
    });
  };
  const refresh = useCallback(() => {
    setRefreshedQueryOptions((prev: any) => ({
      fetchKey: (prev?.fetchKey ?? 0) + 1,
      fetchPolicy: "store-and-network",
    }));
  }, []);

  const handleLevelChange = (event: any, value: any) => {
    setCheckedLevel((prevCheckedLevel: any) => {
      const updatedList = event.target.checked
        ? [...prevCheckedLevel, { name: value.name, id: event.target.value }]
        : prevCheckedLevel.filter(
            (item: any) => item.id !== event.target.value,
          );
      const levelValue = updatedList.map((e: any) => e.id);
      setFilterLevel(levelValue);
      return updatedList;
    });
  };

  const handleCategoryChange = (event: any, value: any) => {
    setCheckedCategory((prevCheckedCategory: any) => {
      const updatedList = event.target.checked
        ? [...prevCheckedCategory, { name: value.name, id: event.target.value }]
        : prevCheckedCategory.filter(
            (item: any) => item.id !== event.target.value,
          );
      const categoryValue = updatedList.map((e: any) => e.id);
      setFilterCategory(categoryValue);
      return updatedList;
    });
  };
  const handleLanguageChange = (event: any, value: any) => {
    setCheckedLanguage((prevCheckedLanguage: any) => {
      const updatedList = event.target.checked
        ? [...prevCheckedLanguage, { name: value.name, id: event.target.value }]
        : prevCheckedLanguage.filter(
            (item: any) => item.id !== event.target.value,
          );

      const languageValue = updatedList.map((e: any) => e.id);
      setFilterLanguage(languageValue);

      return updatedList;
    });
  };
  const handlePriceChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    value: PriceItem,
  ) => {
    const { checked } = event.target;
    if (checked) {
      setSelectedPrice(value.name);
    } else {
      setSelectedPrice(null);
    }
  };
  const filterData = () => {
    const conditions: any = {};
    conditions["and"] = {};
    conditions["and"]["course_type_id"] = {
      in: ["37a300f7-4b53-4ec3-a4ca-c413b8458f29"],
    };
    if (filterCategory.length > 0) {
      conditions["course_category_id"] = { in: filterCategory };
    }
    if (filterLevel.length > 0) {
      conditions["course_level_id"] = { in: filterLevel };
    }
    if (filterMode.length > 0) {
      conditions["course_mode_id"] = { in: filterMode };
    }
    if (filterLanguage.length > 0) {
      conditions["course_language_id"] = { in: filterLanguage };
    }
    if (selectedPrice === "FREE") {
      conditions["cost_type"] = { eq: PriceName.FREE };
    }
    if (selectedPrice === "PAID") {
      conditions["cost_type"] = { eq: PriceName.PAID };
    }
    setWhereCondition(conditions);
  };

  const resetData = () => {
    setCheckedCategory([]);
    setFilterCategory([]);
    setCheckedLevel([]);
    setFilterLevel([]);
    setCheckedMode([]);
    setFilterMode([]);
    setCheckedLanguage([]);
    setFilterLanguage([]);
    setSelectedPrice(null);
    const checkboxes = document.querySelectorAll('input[type="checkbox"]');
    checkboxes.forEach(
      (checkbox: Element) => ((checkbox as HTMLInputElement).checked = false),
    );
    setWhereCondition({});
  };
  useEffect(() => {
    filterData();
  }, [filterCategory, filterLevel, filterMode, filterLanguage]);
  const filtercondition =
    filterCategory.length > 0 ||
    filterLevel.length > 0 ||
    filterMode.length > 0 ||
    filterLanguage.length > 0 ||
    selectedPrice === "FREE" ||
    selectedPrice === "PAID";

  return (
    <>
      <section className="section px-xl-4 -x-lg-3 px-2">
        <div className="container">
          <div className="row">
            <SideFilter
              handleModeChange={handleModeChange}
              handleLevelChange={handleLevelChange}
              handleCategoryChange={handleCategoryChange}
              handlePriceChange={handlePriceChange}
              handleLanguageChange={handleLanguageChange}
              selectedPrice={selectedPrice}
            />
            <div className="col-xl-9 col-lg-9 col-12 mt-5">
              {filtercondition === false ? (
                <div className="row gx-lg-0 gy-lg-3  mb-5 mb-lg-5">
                  <div className="col-12">
                    <div className="course-banner">
                      <img
                        src="../media/banner/course.jpg"
                        alt=""
                        srcSet="../media/banner/course.jpg"
                        className="img-fluid rounded-3"
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="list-courses">
                      <ul
                        className="nav list-courses-nav list-courses-nav-tabs nav-tabs mb-3"
                        id="myTab"
                        role="tablist"
                      >
                        <li
                          className="list-courses-nav-item nav-item pe-4 ps-2"
                          role="presentation"
                        >
                          <button
                            className="list-courses-nav-link mx-0 px-0 py-0 nav-link active "
                            id="RecommendSlider-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#RecommendSlider"
                            type="button"
                            role="tab"
                            aria-controls="RecommendSlider"
                            aria-selected="true"
                          >
                            Recommend for you
                          </button>
                        </li>
                        <li
                          className="list-courses-nav-item nav-item"
                          role="presentation"
                        >
                          <button
                            className="list-courses-nav-link mx-0 px-0 py-0 nav-link"
                            id="NewArrivalsSlider-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#NewArrivalsSlider"
                            type="button"
                            role="tab"
                            aria-controls="NewArrivalsSlider"
                            aria-selected="false"
                          >
                            New Arrivals
                          </button>
                        </li>
                      </ul>
                      <div className="tab-content" id="myTabContent">
                        <div
                          className="tab-pane fade show active"
                          id="RecommendSlider"
                          role="tabpanel"
                          aria-labelledby="RecommendSlider-tab"
                        >
                          <div className="row">
                            <div className="col-12">
                              <RecommendSlider
                                whereCondition={whereCondition}
                                refreshedQueryOptions={refreshedQueryOptions}
                              />
                            </div>
                          </div>
                        </div>
                        <div
                          className="tab-pane fade"
                          id="NewArrivalsSlider"
                          role="tabpanel"
                          aria-labelledby="NewArrivalsSlider-tab"
                        >
                          <div className="row">
                            <div className="col-12">
                              <NewArrivalsSlider
                                whereCondition={whereCondition}
                                refreshedQueryOptions={refreshedQueryOptions}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}

              <div className="row justify-content-center mb-3">
                <div className="col-md-12">
                  <div className=" shadow-0  ">
                    <div className="card-body mb-4">
                      <div className="row p-0">
                        <AllCourse
                          resetData={() => resetData()}
                          whereCondition={whereCondition}
                          filtercondition={filtercondition}
                          checkedCategory={checkedCategory}
                          setCheckedCategory={setCheckedCategory}
                          setFilterCategory={setFilterCategory}
                          filterCategory={filterCategory}
                          setCheckedLevel={setCheckedLevel}
                          checkedLevel={checkedLevel}
                          setFilterLevel={setFilterLevel}
                          filterLevel={filterLevel}
                          checkedMode={checkedMode}
                          setCheckedMode={setCheckedMode}
                          filterMode={filterMode}
                          setFilterMode={setFilterMode}
                          checkedLanguage={checkedLanguage}
                          setCheckedLanguage={setCheckedLanguage}
                          filterLanguage={filterLanguage}
                          selectedPrice={selectedPrice}
                          setSelectedPrice={setSelectedPrice}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Course;
function setRefreshedQueryOptions(
  arg0: (prev: any) => { fetchKey: any; fetchPolicy: string },
) {
  throw new Error("Function not implemented.");
}
