import React from "react";
import HeadingSection from "../PreRecordedCourses/HeadingSection";
import CourseSidebar from "./CourseSidebar";
import { useNavigate } from "react-router-dom";

const CourseIsLive = () => {
  const navigate = useNavigate();

  const gotocourseiscompleted = () => {
    navigate("/livecoursecompleted");
  };
  return (
    <>
      <section className="section px-xl-4 -x-lg-3 px-2">
        <div className="">
          <div className="row">
            <HeadingSection course={null} />
          </div>
        </div>
      </section>
      <section className="course-sections">
        <div className="container-fluid px-lg-5">
          <div className="row">
            <CourseSidebar />
            <div className="col-12 col-lg-8">
              <div className="tab-content py-lg-5" id="v-pills-tabContent">
                <div
                  className="tab-pane fade"
                  id="v-pills-start"
                  role="tabpanel"
                  aria-labelledby="v-pills-start-tab"
                >
                  <div className="row justify-content-lg-center align-items-lg-center p-4">
                    <div className="col-12">
                      <div className="border-bottom pb-lg-5 text-lg-center course-counter-section">
                        <div className="course-head fs-20 font-ibm fw-medium mb-lg-4">
                          Course will go live in
                        </div>
                        <div className="uk">
                          <div
                            className="d-lg-flex uk-grid-small uk-child-width-auto justify-content-center"
                            uk-grid
                            uk-countdown="date: 2023-11-21T17:33:42+00:00"
                          >
                            <div>
                              <div className="uk-countdown-number uk-countdown-days">
                                00
                              </div>
                              <div className="uk-countdown-label uk-margin-small uk-text-center uk-visible@s">
                                Days
                              </div>
                            </div>
                            <div className="uk-countdown-separator mt-4">:</div>
                            <div>
                              <div className="uk-countdown-number uk-countdown-hours">
                                12
                              </div>
                              <div className="uk-countdown-label uk-margin-small uk-text-center uk-visible@s">
                                Hours
                              </div>
                            </div>
                            <div className="uk-countdown-separator mt-4">:</div>
                            <div>
                              <div className="uk-countdown-number uk-countdown-minutes">
                                45
                              </div>
                              <div className="uk-countdown-label uk-margin-small uk-text-center uk-visible@s">
                                Minutes
                              </div>
                            </div>
                            <div className="uk-countdown-separator mt-4">:</div>
                            <div>
                              <div className="uk-countdown-number uk-countdown-seconds">
                                34
                              </div>
                              <div className="uk-countdown-label uk-margin-small uk-text-center uk-visible@s">
                                Seconds
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex justify-content-center w-70 mx-auto">
                          <div
                            className="btn fs-18 font-switzer w-30 btn-color1 my-lg-4"
                            id="add-to-calendar"
                          >
                            Add to Calendar
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="pt-lg-4">
                        <div className="course-head fs-4 fw-medium font-ibm mb-lg-3">
                          Course Details
                        </div>
                        <div className="course-head fs-18 font-switzer fw-medium mb-lg-3">
                          <span className="me-2 color9">
                            <i className="fa-solid fa-calendar-day"></i>
                          </span>
                          <span>21 Sept 2023 </span>
                        </div>
                        <div className="course-head fs-18 font-switzer fw-medium mb-lg-3">
                          <span className="me-2 color9">
                            <i className="fa-solid fa-clock"></i>
                          </span>
                          <span>6:00 pm to 8:00 pm </span>
                        </div>
                        <div className="course-head fs-20 fw-medium font-ibm mb-lg-2">
                          Overview
                        </div>
                        <div className="course-head fs-18 font-switzer mb-lg-3">
                          Amet minim mollit non deserunt ullamco est sit aliqua
                          dolor do amet sint. Velit officia consequat duis enim
                          velit mollit. Exercitation veniam consequat sunt
                          nostrud amet. Amet minim mollit non deserunt ullamco
                          est sit aliqua dolor do amet sint. Velit officia
                          consequat duis enim velit mollit.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade show active"
                  id="v-pills-live"
                  role="tabpanel"
                  aria-labelledby="v-pills-live-tab"
                >
                  <div className="row justify-content-lg-center align-items-lg-center p-4">
                    <div className="col-12">
                      <div className="border-bottom pb-lg-5 text-lg-center course-counter-section">
                        <div className="live-dot glow mx-auto mb-lg-3"></div>
                        <div className="course-head fs-20 font-ibm fw-medium mb-lg-2">
                          Course is live
                        </div>
                        <div className="d-flex justify-content-center w-50 mx-auto">
                          <div
                            className="btn fs-18 font-switzer w-30 btn-color1 my-lg-2"
                            id="join-now"
                            onClick={() => gotocourseiscompleted()}
                          >
                            Join Now
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="pt-lg-4">
                        <div className="course-head fs-4 fw-medium font-ibm mb-lg-3">
                          Course Details
                        </div>
                        <div className="course-head fs-18 font-switzer fw-medium mb-lg-3">
                          <span className="me-2 color9">
                            <i className="fa-solid fa-calendar-day"></i>
                          </span>
                          <span>21 Sept 2023 </span>
                        </div>
                        <div className="course-head fs-18 font-switzer fw-medium mb-lg-3">
                          <span className="me-2 color9">
                            <i className="fa-solid fa-clock"></i>
                          </span>
                          <span>6:00 pm to 8:00 pm </span>
                        </div>
                        <div className="course-head fs-20 fw-medium font-ibm mb-lg-2">
                          Overview
                        </div>
                        <div className="course-head fs-18 font-switzer mb-lg-3">
                          Amet minim mollit non deserunt ullamco est sit aliqua
                          dolor do amet sint. Velit officia consequat duis enim
                          velit mollit. Exercitation veniam consequat sunt
                          nostrud amet. Amet minim mollit non deserunt ullamco
                          est sit aliqua dolor do amet sint. Velit officia
                          consequat duis enim velit mollit.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="v-pills-topic"
                  role="tabpanel"
                  aria-labelledby="v-pills-topic-tab"
                >
                  <div className="topic-details-section my-lg-3">
                    <div className="row justify-content-lg-between align-items-lg-center">
                      <div className="col-lg-12">
                        <div className="fs-2 font-ibm fw-medium color5 mb-lg-3">
                          Day 1 . Another good chapter name Amet{" "}
                        </div>
                      </div>
                      <div className="col-lg-12 session-completed-alert">
                        <div className="p-lg-3 fs-18 bg-color11 rounded-1 d-lg-flex justify-content-lg-between">
                          <div className="font-switzer color5">
                            <span className="color6 fw-semibold">
                              <i className="fa-solid fa-circle-check"></i>{" "}
                            </span>
                            <span>This session was completed on </span>
                            <span className="fw-bold">21 Sept 2023 </span>
                            <span>at </span>
                            <span className="fw-bold">6:00 PM.</span>
                          </div>
                          <div className="font-switzer color2">
                            <i className="fa-solid fa-xmark"></i>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="details-topic">
                          <div className="mt-lg-4 ">
                            <span className="fs-18 font-switzer color5">
                              Amet minim mollit non deserunt ullamco est sit
                              aliqua dolor do amet sint. Velit officia consequat
                              duis enim velit mollit. Exercitation veniam
                              consequat sunt nostrud amet. Amet minim mollit non
                              deserunt ullamco est sit aliqua dolor do amet
                              sint. Velit officia consequat duis enim velit
                              mollit. Exercitation veniam consequat sunt nostrud
                              amet Lorem ipsum dolor sit amet consectetur.
                              Condimentum et a interdum pretium viverra sit
                              dictumst arcu tristique. Rutrum sagittis sit
                              aliquam nunc fusce leo ac. Eu felis nisl etiam
                              vestibulum ac egestas enim habitasse. Lectus id
                              semper nunc suscipit elementum. Nisl sollicitudin
                              nisl varius a est.
                            </span>
                          </div>
                          <span className="read-more-button color1 fw-bold cp">
                            Read More
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="v-pills-next"
                  role="tabpanel"
                  aria-labelledby="v-pills-next-tab"
                >
                  <div className="row justify-content-lg-center align-items-lg-center p-4">
                    <div className="col-12">
                      <div className="border-bottom pb-lg-5 text-lg-center course-counter-section">
                        <div className="course-head fs-20 font-ibm fw-medium mb-lg-4">
                          Course will go live in
                        </div>
                        <div className="uk">
                          <div
                            className="d-lg-flex uk-grid-small uk-child-width-auto justify-content-center"
                            uk-grid
                            uk-countdown="date: 2023-11-21T17:33:42+00:00"
                          >
                            <div>
                              <div className="uk-countdown-number uk-countdown-days">
                                00
                              </div>
                              <div className="uk-countdown-label uk-margin-small uk-text-center uk-visible@s">
                                Days
                              </div>
                            </div>
                            <div className="uk-countdown-separator mt-4">:</div>
                            <div>
                              <div className="uk-countdown-number uk-countdown-hours">
                                12
                              </div>
                              <div className="uk-countdown-label uk-margin-small uk-text-center uk-visible@s">
                                Hours
                              </div>
                            </div>
                            <div className="uk-countdown-separator mt-4">:</div>
                            <div>
                              <div className="uk-countdown-number uk-countdown-minutes">
                                45
                              </div>
                              <div className="uk-countdown-label uk-margin-small uk-text-center uk-visible@s">
                                Minutes
                              </div>
                            </div>
                            <div className="uk-countdown-separator mt-4">:</div>
                            <div>
                              <div className="uk-countdown-number uk-countdown-seconds">
                                34
                              </div>
                              <div className="uk-countdown-label uk-margin-small uk-text-center uk-visible@s">
                                Seconds
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex justify-content-center w-70 mx-auto">
                          <div
                            className="btn fs-18 font-switzer w-30 btn-color1 my-lg-4"
                            id="add-to-calendar-2"
                          >
                            Add to Calendar
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="pt-lg-4">
                        <div className="course-head fs-4 fw-medium font-ibm mb-lg-3">
                          Course Details
                        </div>
                        <div className="course-head fs-18 font-switzer fw-medium mb-lg-3">
                          <span className="me-2 color9">
                            <i className="fa-solid fa-calendar-day"></i>
                          </span>
                          <span>21 Sept 2023 </span>
                        </div>
                        <div className="course-head fs-18 font-switzer fw-medium mb-lg-3">
                          <span className="me-2 color9">
                            <i className="fa-solid fa-clock"></i>
                          </span>
                          <span>6:00 pm to 8:00 pm </span>
                        </div>
                        <div className="course-head fs-20 fw-medium font-ibm mb-lg-2">
                          Overview
                        </div>
                        <div className="course-head fs-18 font-switzer mb-lg-3">
                          Amet minim mollit non deserunt ullamco est sit aliqua
                          dolor do amet sint. Velit officia consequat duis enim
                          velit mollit. Exercitation veniam consequat sunt
                          nostrud amet. Amet minim mollit non deserunt ullamco
                          est sit aliqua dolor do amet sint. Velit officia
                          consequat duis enim velit mollit.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CourseIsLive;
