import React, { useState } from 'react';
import './CourseOverView.css';
import { CourseSkills } from 'components/_v2/Course/type';
import useIsSmallScreen from 'hooks/useIsSmallScreen';
import { Badge } from 'react-bootstrap';

const CourseOverView = ({
	description,
	preRequisites,
	objectives,
	skills,
	fullWidth = false,
}: {
	description: string;
	preRequisites?: string;
	objectives: { objective: string }[];
	skills: CourseSkills[];
	fullWidth?: boolean;
}) => {
	const isSmallScreen = useIsSmallScreen();
	const [isExpanded, setIsExpanded] = useState<boolean>(true);
	return (
		<section className={fullWidth ? '' : isSmallScreen ? 'py-3' : 'p-3'}>
			<div className="container">
				<div className={`row ${fullWidth ? '' : 'px-lg-5 px-sm-3 px-2'}`}>
					<div
						className={`${
							fullWidth ? 'col-lg-12' : `col-lg-7 col-12 ${isSmallScreen ? 'w-100' : ''}`
						}`}>
						<div className="row">
							<div className={`${fullWidth ? '' : 'offset-lg-1'} p-lg-0`}>
								<p className="overview-title ibm_med">Course description</p>
								<div>
									<p
										className="course-desc-label p_reg text-justify"
										dangerouslySetInnerHTML={{ __html: description }}></p>
								</div>
								{isExpanded && (
									<>
										{preRequisites && (
											<div>
												<h2 className="pre-title ibm_med">Pre requisites:</h2>
												<p
													className="pre-label p_reg"
													dangerouslySetInnerHTML={{ __html: preRequisites }}></p>
											</div>
										)}
										<div>
											<h2 className="pre-title ibm_med">Learning objectives</h2>
											<ul className="text-decoration-none">
												{objectives.map((objective, index) => (
													<li key={objective.objective + index} className="objective-label p_reg">
														{objective.objective}
													</li>
												))}
											</ul>
										</div>
										<div>
											<h2 className="pre-title ibm_med">Skills covered</h2>
											<div>
												{skills.map((skill) => (
													<Badge
														key={skill.id}
														className="my-md-0 rounded-5 bg-primaryColor me-2 p_med my-2 p-3">
														{skill.name}
													</Badge>
												))}
											</div>
										</div>
									</>
								)}
								<div
									className="primaryColor text-decoration-underline mt-3 mb-2"
									onClick={() => setIsExpanded((prevState) => !prevState)}>
									<span role="button" className="fs-16 g_reg fw-medium">
										{isExpanded ? 'Show Less' : 'Show More'}
									</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default CourseOverView;
