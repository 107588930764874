import React, { useMemo } from 'react';
import './best.css';
import CountUp from 'react-countup';

interface BestProps {
	wrapperClass?: string;
	containerClass?: string;
	headingClass?: string;
	descriptionClass?: string;
	titleClass?: string;
	wrapInRow?: boolean;
}

const Best: React.FC<BestProps> = ({
	wrapperClass = '',
	containerClass = '',
	headingClass = '',
	descriptionClass = '',
	titleClass = ' ',
	wrapInRow = false,
}) => {
	const data = useMemo(
		() =>
			(heading: number, description: string, suffix = '+') => {
				return (
					<div className={`col-lg-3 col-md-6 col-12 my-4 text-center ${containerClass}`}>
						<p className={`number-style p_reg  ${headingClass}`}>
							<CountUp
								className="account-balance"
								start={0}
								end={heading}
								duration={2.75}
								useEasing={true}
								useGrouping={true}
								suffix={suffix}
								enableScrollSpy
								scrollSpyDelay={100}
								scrollSpyOnce>
								{({ countUpRef }) => <span ref={countUpRef} />}
							</CountUp>
						</p>
						<p className={`number-desc ibm_reg ${descriptionClass}`}>{description}</p>
					</div>
				);
			},
		[containerClass, headingClass, descriptionClass, wrapperClass],
	);

	return (
		<section className={`best-wrapper ${wrapperClass} ${wrapInRow ? 'row' : ''}`}>
			<div className="container ">
				<div className="row ">
					<div className="col-lg-10 col-md-10 col-10 col-sm-10 layout-set">
						<h2 className={`ibm_sup best-section-title text-center mt-4 ${titleClass}`}>
							Learn From The Greatest To Be The Best
						</h2>
						<div className="d-flex justify-content-between mt-lg-2 mt-md-3 mt-4 flex-wrap">
							{data(12, 'Years of Experiences')}
							{data(99, 'Accuracy Rate', '%')}
							{data(500, 'Positive Reviews')}
							{data(10, 'Trusted Partners')}
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Best;
