import { ErrorMessage, Field } from 'formik';
import React, { useState } from 'react';
import { toAbsoluteUrl } from 'assets/helpers/AssetHelpers';
import { Image } from 'react-bootstrap';

const CommonPasswordInput = ({
	name,
	disabled,
	placeholder,
	showValidOrInvalidOutlineClass = '',
	label,
	showEyeIcon = true,
	labelColor = 'color16',
	isRequired = true,
	handleSubmit,
}: {
	name: string;
	disabled: boolean;
	placeholder: string;
	showValidOrInvalidOutlineClass?: string;
	label: string;
	showEyeIcon?: boolean;
	labelColor?: string;
	isRequired?: boolean;
	handleSubmit;
}) => {
	const [showPassword, setShowPassword] = useState(false);
	return (
		<form
			onSubmit={handleSubmit}
			className="align-items-baseline d-flex flex-column w-100 gap-0 py-0">
			<label htmlFor={name} className={`fs-14 g_reg mb-2 ${labelColor}`}>
				{label}&nbsp;
				{isRequired && <span className="text-danger">*</span>}
			</label>
			<div className="password-input-wrapper position-relative w-100">
				<Field
					className={`input-box-style form-control g_reg pe-5 ${showValidOrInvalidOutlineClass}`}
					type={showPassword || !showEyeIcon ? 'text' : 'password'}
					name={name}
					autoComplete={name}
					placeholder={placeholder}
					disabled={disabled}
					onKeyDown={(e) => {
						if (e.key === 'Enter') {
							e.preventDefault();
							handleSubmit();
						}
					}}
				/>
				{showEyeIcon && (
					<button
						type="button"
						className="btn eye-icon eye-icon-outline position-absolute"
						style={{
							right: '15px',
							top: '50%',
							transform: 'translateY(-50%)',
						}}
						onClick={() => setShowPassword(!showPassword)}>
						<Image src={toAbsoluteUrl(`/media/icons/view${showPassword ? '' : '-off'}.svg`)} />
					</button>
				)}
			</div>
			<ErrorMessage
				name={name}
				component="div"
				className="error-message fs-7 text-danger g_reg mt-1"
			/>
		</form>
	);
};

export default CommonPasswordInput;
