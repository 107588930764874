import React, { useState } from "react";
import QuizSidebar from "./QuizSidebar";
import HeadingSection from "../PreRecordedCourses/HeadingSection";
import { useNavigate } from "react-router-dom";
import { SUBMIT_QUIZ_MUTATION } from "../Courses-Page/core/requests";
import { useMutation } from "@apollo/client";
import QuizSummary from "./QuizSummary";
import toast from "react-hot-toast";

const Quiz2 = (props: any) => {
  const {
    valLesson,
    value,
    activeTab,
    setActiveTab,
    quizStarted,
    setQuizStarted,
    courseId,
    activeDivId,
    prevNext,
    socket,
    lessonId,
    sectionId,
    currentAuthToken,
    allsectionCompleted,
    lessonCompleted,
    index,
    refetch,
  } = props;

  const [selectedOptions, setSelectedOptions] = useState<Array<string>>([]);
  const [submitQuizMutation] = useMutation(SUBMIT_QUIZ_MUTATION);
  const [quizSubmitted, setQuizSubmitted] = useState(false);
  const length = value?.questions?.length;
  const navigate = useNavigate();
  const quizsummary = () => {
    if (activeTab + 1 < length + 1) {
      setActiveTab(activeTab + 1);
    }
  };

  const quizprevious = () => {
    if (activeTab - 1 >= 0) {
      setActiveTab(activeTab - 1);
    } else if (activeTab === 1) {
      setQuizStarted(true);
    }
  };
  const handleOptionChange = (
    index: number,
    optionIndex: number,
    value: string,
  ) => {
    const updatedSelectedOptions = [...selectedOptions];
    updatedSelectedOptions[index] = value;
    setSelectedOptions(updatedSelectedOptions);
  };

  const isNextButtonEnabled = () => {
    return selectedOptions[activeTab - 1] !== undefined;
  };
  const submitQuiz = () => {
    const isLastLesson = index === valLesson?.lessons?.length - 1;
    try {
      socket?.emit("quiz_update", {
        user_token: currentAuthToken,
        lesson_id: lessonId,
        section_id: sectionId,
        course_id: courseId,
        quiz_id: value.id,
        is_quiz_completed: true,
      });
      socket?.on("quiz_update", (response: any) => {
        console.log("quiz_update Update Response:", response);
      });

      if (lessonCompleted && isLastLesson) {
        socket?.emit("section_update", {
          user_token: currentAuthToken,
          lesson_id: lessonId,
          section_id: sectionId,
          course_id: courseId,
          is_section_completed: true,
        });
        socket.on("section_update", (response: any) => {
          console.log("section_update Update Response:", response);
        });
      }
      if (allsectionCompleted) {
        socket?.emit("course_update", {
          user_token: currentAuthToken,
          lesson_id: lessonId,
          section_id: sectionId,
          course_id: courseId,
          is_section_completed: true,
          is_course_completed: true,
        });
        socket?.on("course_update", (response: any) => {
          console.log("course_update Update Response:", response);
        });
      }
      refetch();
      const userQuizResultInputType = {
        courseId: courseId,
        quizId: value.id,
        questionAnswer: value.questions.map((item: any, index: number) => {
          const selectedOption = selectedOptions[index];
          const answer = selectedOption
            ? item.options.find(
                (option: any) => option.optionText === selectedOption,
              )?.optionText
            : "";
          return {
            questionId: item.id,
            answer: answer,
          };
        }),
      };
      submitQuizMutation({
        variables: { userQuizResultInputType },
        onCompleted: () => {
          toast.success("Quiz submitted Successfully");
          setQuizSubmitted(true);
        },
        onError: (err: any) => {
          toast.error(err.message);
        },
      });
    } catch (error: any) {
      console.log("error", error);
    }
  };

  return (
    <>
      {quizSubmitted === true && (
        <QuizSummary
          quizId={value.id}
          courseId={courseId}
          setQuizStarted={setQuizStarted}
          setActiveTab={setActiveTab}
          questionArray={value}
          setQuizSubmitted={setQuizSubmitted}
          activeDivId={activeDivId}
          prevNext={prevNext}
        />
      )}
      <>
        {quizSubmitted === false && (
          <section className="section px-xl-4 -x-lg-3 px-2 d-lg-block d-md-block d-none">
            <div className="">
              {/* <div className="row">
            <HeadingSection course={null} />
          </div> */}
            </div>
            <section className="section -x-lg-3 px-2">
              <div className="" style={{ marginTop: "-30%" }}>
                <div className="row">
                  {/* <QuizSidebar /> */}
                  <div className="col-lg-12 mt-5">
                    {quizStarted === true && (
                      <ul
                        className="nav border-0 nav-tabs flex-nowrap"
                        id="myTab"
                        role="tablist"
                      >
                        {value?.questions?.map((item: any, index: number) => (
                          <li
                            className="nav-item px-0 border-dark w-100 mx-1 "
                            role="presentation"
                            key={index + 1}
                          >
                            <button
                              className={`m-0 border w-100 border-0 py-1 nav-link${
                                index + 1 === activeTab
                                  ? "active bg-primary"
                                  : "bg-light-blue"
                              }`}
                              id={`question-tab-${index}`}
                              data-bs-toggle="tab"
                              data-bs-target={`#question-${index}`}
                              type="button"
                              onClick={() => setActiveTab(index + 1)}
                              role="tab"
                              aria-controls={`question-${index}`}
                              aria-selected={index + 1 === activeTab}
                            ></button>
                          </li>
                        ))}
                      </ul>
                    )}
                    <div className="tab-content" id="myTabContent">
                      {value?.questions?.map((item: any, index: number) => (
                        <div
                          key={index}
                          className={`tab-pane fade${
                            index + 1 === activeTab ? " show active" : ""
                          }`}
                          id={`question-${index}`}
                          role="tabpanel"
                          aria-labelledby={`question-tab-${index + 1}`}
                        >
                          <div className="mt-4 fs-5">
                            <p className="font-subtitle ">
                              {index + 1}. {item.question}
                            </p>
                          </div>
                          {item.options.map(
                            (option: any, optionIndex: number) => (
                              <div
                                key={optionIndex}
                                className="border p-2 mx-1 my-3"
                              >
                                <div className="form-check">
                                  <input
                                    className="form-check-input cursor-pointer"
                                    type="radio"
                                    name={`question-${index + 1}-options`}
                                    id={`question-${
                                      index + 1
                                    }-option-${optionIndex}`}
                                    value={option}
                                    onChange={() =>
                                      handleOptionChange(
                                        index,
                                        optionIndex,
                                        option.optionText,
                                      )
                                    }
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor={`question-${
                                      index + 1
                                    }-option-${optionIndex}`}
                                  >
                                    {option.optionText}
                                  </label>
                                </div>
                              </div>
                            ),
                          )}
                          <div
                            className="d-flex justify-content-between"
                            style={{ marginTop: "16%" }}
                          >
                            <div>
                              <p className="fs-5 font-subtitletitle">
                                <span className="fw-bold">{index + 1}</span> of{" "}
                                {length} Questions
                              </p>
                            </div>
                            <div className="">
                              {index === length - 1 ? (
                                <>
                                  <button
                                    className="btn btn-primary me-2"
                                    onClick={() => quizprevious()}
                                  >
                                    Previous
                                  </button>
                                  <button
                                    className={`btn btn-primary ${
                                      isNextButtonEnabled()
                                        ? "me-2"
                                        : "disabled"
                                    }`}
                                    onClick={() => submitQuiz()}
                                  >
                                    Finish
                                  </button>
                                </>
                              ) : (
                                <>
                                  <button
                                    className="btn btn-primary me-2"
                                    onClick={() => quizprevious()}
                                  >
                                    Previous
                                  </button>
                                  <button
                                    className={`btn btn-primary ${
                                      isNextButtonEnabled() ? "" : "disabled"
                                    }`}
                                    onClick={() => quizsummary()}
                                  >
                                    Next
                                  </button>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </section>
        )}

        {/*
-------------------------------
 Responsive Mobile View Code 
 ---------------------------
 */}

        <section className="d-lg-none d-md-none d-block">
          <div className="">
            {/* <div className="row">
            <HeadingSection course={null} />
          </div> */}
          </div>
          <section className="mx-4">
            <div className="">
              <div className="row">
                {/* <QuizSidebar /> */}
                <div className="col-lg-12">
                  <ul
                    className="nav border-0 nav-tabs flex-nowrap"
                    id="myTab"
                    role="tablist"
                  >
                    {value?.questions?.map((item: any, index: number) => (
                      <li
                        className="nav-item px-0 border-dark w-100 mx-1"
                        role="presentation"
                        key={index + 1}
                      >
                        <button
                          className={`m-0 border w-100 border-0 py-1 nav-link${
                            index + 1 === activeTab
                              ? "active bg-primary"
                              : "bg-light-blue"
                          }`}
                          id={`question-tab-${index}`}
                          data-bs-toggle="tab"
                          data-bs-target={`#question-${index}`}
                          type="button"
                          onClick={() => setActiveTab(index + 1)}
                          role="tab"
                          aria-controls={`question-${index}`}
                          aria-selected={index + 1 === activeTab}
                        ></button>
                      </li>
                    ))}
                  </ul>
                  <div className="tab-content" id="myTabContent">
                    {value?.questions?.map((item: any, index: number) => (
                      <div
                        key={index}
                        className={`tab-pane fade${
                          index + 1 === activeTab ? " show active" : ""
                        }`}
                        id={`question-${index}`}
                        role="tabpanel"
                        aria-labelledby={`question-tab-${index + 1}`}
                      >
                        <div className="mt-4 fs-5">
                          <p className="font-subtitle">
                            {index + 1}. {item.question}
                          </p>
                        </div>
                        {item.options.map(
                          (option: any, optionIndex: number) => (
                            <div
                              key={optionIndex}
                              className="border p-2 mx-1 my-3"
                            >
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name={`question-${index + 1}-options`}
                                  id={`question-${
                                    index + 1
                                  }-option-${optionIndex}`}
                                  value={option}
                                  onChange={() =>
                                    handleOptionChange(
                                      index,
                                      optionIndex,
                                      option.optionText,
                                    )
                                  }
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor={`question-${
                                    index + 1
                                  }-option-${optionIndex}`}
                                >
                                  {option.optionText}
                                </label>
                              </div>
                            </div>
                          ),
                        )}
                        <div
                          className="d-flex justify-content-between"
                          style={{ marginTop: "16%" }}
                        >
                          <div>
                            <p className="fs-5 font-subtitletitle">
                              <span className="fw-bold">{index + 1}</span> of{" "}
                              {length} Questions
                            </p>
                          </div>
                          <div className="">
                            {index === length - 1 ? (
                              <>
                                <button
                                  className="btn btn-primary me-2"
                                  onClick={() => quizprevious()}
                                >
                                  Previous
                                </button>
                                <button
                                  className={`btn btn-primary ${
                                    isNextButtonEnabled() ? "me-2" : "disabled"
                                  }`}
                                  onClick={() => submitQuiz()}
                                >
                                  Finish
                                </button>
                              </>
                            ) : (
                              <>
                                <button
                                  className="btn btn-primary me-2"
                                  onClick={() => quizprevious()}
                                >
                                  Previous
                                </button>
                                <button
                                  className={`btn btn-primary ${
                                    isNextButtonEnabled() ? "" : "disabled"
                                  }`}
                                  onClick={() => quizsummary()}
                                >
                                  Next
                                </button>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </section>
      </>
    </>
  );
};

export default Quiz2;
