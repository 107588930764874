import React from "react";
import { toAbsoluteUrl } from "../../../assets/helpers/AssetHelpers";
import { Link } from "react-router-dom";

export const Footer = () => {
  return (
    <>
      {/* <div className="footer">
                <footer className="text-lg-start">
                    <section>
                        <div className="footer-py-60 mx-5">
                            <div className="text-md-start">
                                <div className="row mt-3 justify-content-between">
                                    <div className="col-md-12 col-lg-4 col-xl-3  mb-4">
                                        <a href="/" className="px-md-4 px-lg-0 logo-footer">
                                            <img src={toAbsoluteUrl("/media/logo/smart-learn-footer-logo.png")} height={22} />
                                        </a>
                                        <p className='mt-4 px-md-4 px-lg-0 fosoter-text fs-6'>
                                            Small description about SmartLearn goes here like this. Longer
                                            description goes here like this.
                                        </p>
                                    </div>
                                    <div className="col-md-2 col-lg-2 col-xl-2 mb-4">
                                        <h5 className="footer-head">Learning</h5>
                                        <ul className="list-unstyled footer-list mt-3 d-lg-block d-md-block d-flex">
                                            <li className='mt-2'><Link to="/webinars" className="text-foot fs-6 me-lg-0 me-md-0 me-3"> Webinars</Link></li>
                                            <li className='mt-2'><Link to="/courses" className="text-foot fs-6 me-lg-0 me-md-0 me-3"> Courses</Link></li>
                                            <li className='mt-2'><Link to="/seminars" className="text-foot fs-6 me-lg-0 me-md-0 me-3"> Seminars</Link></li>
                                            <li className='mt-2'><Link to="/workshops" className="text-foot fs-6 me-lg-0 me-md-0 me-3"> Workshops</Link></li>
                                        </ul>
                                    </div>
                                    <div className="col-md-3 col-lg-2 col-xl-2  mb-4">
                                        <h5 className="footer-head">Quick Links</h5>
                                        <ul className="list-unstyled footer-list mt-3 d-lg-block d-md-block d-flex">
                                            <li className='mt-2'><a href={`/page/${'about-us'}`} className="text-foot fs-6 me-lg-0 me-md-0 me-3"> About Us</a></li>
                                            <li className='mt-2'><a href={`/page/${'contact-us'}`} className="text-foot fs-6 me-lg-0 me-md-0 me-3"> Contact Us</a></li>
                                        </ul>
                                    </div>

                                    <div className="col-md-4 col-lg-3 col-xl-2 mb-md-0 mb-4 lastsection ps-5">
                                        <h5 className="footer-head">Contact Us</h5>
                                        <ul className="list-unstyled footer-list mt-3">
                                            <li className="d-flex align-items-center mb-2">
                                                <i className="fa-solid fa-envelope footer text-white"></i>
                                                <div className="flex-1 ms-2">
                                                    <a href='mailto:support@smartlearn.com' className="d-block text-foot fs-6 text-decoration-none">support@smartlearn.com </a>
                                                </div>
                                            </li>
                                            <li className="d-flex align-items-center mb-2">
                                                <i className="fa-sharp fa-solid fa-phone footer text-white"></i>
                                                <div className="flex-1 ms-2">
                                                    <a href='tel:+919875543210' className="d-block text-foot fs-6 text-decoration-none">+91 98765 43210</a>
                                                </div>
                                            </li>
                                        </ul>
                                        <div className="social mt-4">
                                            <h5 className="footer-head">Stay Updated With Us</h5>
                                            <ul className="list-unstyled mb-0 mt-3 ul_style">
                                                <li className="list-inline-item"><a href="https://www.facebook.com/smartlearnbyjb?mibextid=LQQJ4d" className="rounded cursor-pointer"><i className="fab fa-facebook fs-3 ms-2 text-white" /></a></li>
                                                <li className="list-inline-item"><a href="https://instagram.com/smartlearnbyjb?igshid=OGQ5ZDc2ODk2ZA==" className="rounded cursor-pointer"><i className="fab fa-instagram fs-3 ms-2 text-white" /></a></li>
                                                <li className="list-inline-item"><a href="https://www.youtube.com/@smartlearnbyjainam/featured" className="rounded cursor-pointer"><i className="fab fa-youtube fs-3 ms-2 text-white" /></a></li>
                                                <li className="list-inline-item"><a href="javascript:void(0)" className="rounded cursor-pointer"><img src="../media/banner/twi.png" className='ms-2 mb-2' alt="Twitter_image" /></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mx-xl-5 mx-lg-3 mx-md-3 mx-1">
                            <div className="row">
                                <div className="col-12">
                                    <div className="py-4 footer-border">
                                        <div className="row align-items-center Footer_last">
                                            <div className="col-sm-6 col-md-12 col-lg-6">
                                                <div className="text-sm-start">
                                                    <p className="mb-0">© Copyright 2023. All rights reserved by <br className='d-block d-md-none' /><a href="" target="_blank" className="text-light text-decoration-none">Jainam Fincap</a></p>
                                                </div>
                                            </div>
                                            <div className="col-sm-6 col-md-12 col-lg-6 mt-4 mt-sm-0 pt-2 pt-md-2 pt-sm-0">
                                                <ul className="list-unstyled footer-list terms-service text-sm-end text-md-start text-lg-end mb-0">
                                                    <li className="list-inline-item mb-0"><a href={`/page/${'terms_condition'}`} className="text-foot fs-6 me-2">Terms & Conditions</a></li>
                                                    <li className="list-inline-item mb-0"><a href={`/page/${'faqs'}`} className="text-foot fs-6 me-2">FAQs</a></li>
                                                    <li className="list-inline-item mb-0"><a href={`/page/${'privacy_policy'}`} className="text-foot fs-6 me-2">Privacy Policy</a></li>
                                                    <li className="list-inline-item mb-0"><a href={`/page/${'help'}`} className="text-foot fs-6">Help</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>                 
                </footer>                
            </div> */}
    </>
  );
};
