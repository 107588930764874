import { gql } from "@apollo/client";
import { graphql } from "babel-plugin-relay/macro";

export const GET_PUBLIC_FAQS = gql`
  query GetPublicFaqsList(
    $where: FaqResolver_GetPublicFaqsList_FilterInputType
    $paginate: PaginatorArgs
  ) {
    getPublicFaqsList(where: $where, paginate: $paginate) {
      records {
        id
        question
        answer
        sortOrder
        type
        status
        courseId
        createdById
        updatedById
      }
      pagination {
        totalRecords
        currentPage
      }
    }
  }
`;

// export const GETFAQS = gql`
// query requests_getFaqsQuery(
//     $where: TestimonialResolver_GetPublicTestimonials_FilterInputType
//     $paginate: PaginatorArgs
//   ) {
//     getPublicFaqs(where: $where, paginate: $paginate) {
//       records {
//         id
//         name
//         designation
//         content
//         image
//       }
//       pagination {
//         totalRecords
//         currentPage
//       }
//     }
//   }
// `;

export const GETTESTIMONIAL = gql`
  query requests_getPublicTestimonialsQuery(
    $where: TestimonialResolver_GetPublicTestimonials_FilterInputType
    $paginate: PaginatorArgs
  ) {
    getPublicTestimonials(where: $where, paginate: $paginate) {
      records {
        id
        name
        designation
        content
        image
      }
      pagination {
        totalRecords
        currentPage
      }
    }
  }
`;
export const GETMENTOR = gql`
  query requests_getPublicInstructorsQuery(
    $where: UsersResolver_GetPublicInstructors_FilterInputType
    $paginate: PaginatorArgs
  ) {
    getPublicInstructors(where: $where, paginate: $paginate) {
      records {
        id
        firstName
        lastName
        email
        userProfile {
          id
          imageUrl
          qualification
          userId
        }
      }
      pagination {
        totalRecords
        currentPage
      }
    }
  }
`;
export const GETUPCOMINGCOURSE = gql`
  query requests_getPublicUpcomingCoursesQuery(
    $where: CoursesPublicResolver_GetPublicUpcomingCourses_FilterInputType
    $paginate: PaginatorArgs
  ) {
    getPublicUpcomingCourses(where: $where, paginate: $paginate) {
      records {
        id
        name
        slug
        images {
          original
        }
        courseType {
          id
          name
          slug
        }
        shortDescription
        description
        sortOrder
        status
        amount
        sellPrice
        startDatetime
        endDatetime
        totalSeat
        costType
        address
        meetingLink
        meetingNumber
        meetingPassword

        courseCategory {
          id
          name
        }
        hasQuiz
        requirements
        courseCategoryId
        courseSubCategoryId
        courseLevelId
        courseLevel {
          id
          name
        }
        courseModeId
        courseTypeId
        mentors {
          mentor {
            id
            firstName
          }
        }
        courseMode {
          id
          name
        }
      }
      pagination {
        totalRecords
        currentPage
      }
    }
  }
`;
export const GETTOPSELLINGCOURSE = gql`
  query requests_getPublicTopSellingCoursesQuery(
    $where: CoursesPublicResolver_GetPublicTopSellingCourses_FilterInputType
    $paginate: PaginatorArgs
  ) {
    getPublicTopSellingCourses(where: $where, paginate: $paginate) {
      records {
        id
        name
        slug
        images {
          original
        }
        courseType {
          id
          name
          slug
        }
        shortDescription
        description
        sortOrder
        status
        amount
        sellPrice
        startDatetime
        endDatetime
        totalSeat
        costType
        address
        meetingLink
        meetingNumber
        meetingPassword
        hasQuiz
        requirements
        courseCategoryId
        mentors {
          mentor {
            id
            firstName
          }
        }
        courseCategory {
          id
          name
        }
        courseMode {
          id
          name
        }
        courseLevel {
          id
          name
        }
        courseSubCategoryId
        courseLevelId
        courseModeId
        courseTypeId
      }
      pagination {
        totalRecords
        currentPage
      }
    }
  }
`;
export const GETBANNERS = gql`
  query requests_getBannersQuery(
    $where: BannerResolver_GetBanners_FilterInputType
    $paginate: PaginatorArgs
  ) {
    getBanners(where: $where, paginate: $paginate) {
      records {
        id
        name
        images {
          original
        }
      }
      pagination {
        totalRecords
        currentPage
      }
    }
  }
`;
export const GETPUBLICCOURSECATEGORIES = gql`
  query requests_getPublicCourseCategoriesQuery(
    $where: CourseCategoriesResolver_GetPublicCourseCategories_FilterInputType
    $paginate: PaginatorArgs
  ) {
    getPublicCourseCategories(where: $where, paginate: $paginate) {
      records {
        id
        name
      }
      pagination {
        totalRecords
        currentPage
      }
    }
  }
`;
export const GETRECOMMENDEDCOURSES = gql`
  query requests_getPublicRecommendedCoursesQuery(
    $where: CoursesPublicResolver_GetPublicRecommendedCourses_FilterInputType
    $paginate: PaginatorArgs
  ) {
    getPublicRecommendedCourses(where: $where, paginate: $paginate) {
      records {
        id
        name
        completed
        slug
        shortDescription
        description
        sortOrder
        status
        images {
          original
        }
        courseCategory {
          id
          name
        }
        courseMode {
          id
          name
        }
        courseLevel {
          id
          name
        }
        amount
        sellPrice
        startDatetime
        endDatetime
        totalSeat
        costType
        address
        meetingLink
        meetingNumber
        meetingPassword
        courseType {
          id
          name
        }
        mentors {
          mentor {
            id
            firstName
          }
        }
        hasQuiz
        requirements
        courseCategoryId
        courseSubCategoryId
        courseLevelId
        courseModeId
        courseTypeId
      }
      pagination {
        totalRecords
        currentPage
      }
    }
  }
`;

export const GETNEWARRIVALCOURSES = gql`
  query requests_getPublicNewArrivalCoursesQuery(
    $where: CoursesPublicResolver_GetPublicNewArrivalCourses_FilterInputType
    $paginate: PaginatorArgs
  ) {
    getPublicNewArrivalCourses(where: $where, paginate: $paginate) {
      records {
        id
        name
        slug
        shortDescription
        description
        sortOrder
        status
        completed
        courseType {
          id
          name
        }
        images {
          original
        }
        amount
        sellPrice
        startDatetime
        endDatetime
        totalSeat
        costType
        address
        meetingLink
        meetingNumber
        meetingPassword
        mentors {
          mentor {
            id
            firstName
          }
        }
        hasQuiz
        requirements
        courseCategoryId
        courseCategory {
          id
          name
        }
        courseSubCategoryId
        courseLevel {
          id
          name
        }
        courseLevelId
        courseMode {
          id
          name
        }
        courseModeId
        courseTypeId
      }
      pagination {
        totalRecords
        currentPage
      }
    }
  }
`;
export const GETCOURSES = gql`
  query requests_getPublicCoursesQuery(
    $where: CoursesPublicResolver_GetPublicCourses_FilterInputType
    $paginate: PaginatorArgs
  ) {
    getPublicCourses(where: $where, paginate: $paginate) {
      records {
        id
        name
        slug
        shortDescription
        description
        sortOrder
        status
        wishlist
        completed
        images {
          original
        }
        courseCategory {
          id
          name
        }
        courseRatings {
          ratings
          starCount
        }
        courseLanguages {
          id
          name
        }
        courseLevel {
          id
          name
        }
        mentors {
          mentor {
            id
            firstName
          }
        }
        amount
        sellPrice
        startDatetime
        endDatetime
        totalSeat
        costType
        address
        meetingLink
        meetingNumber
        meetingPassword
        courseMode {
          id
          name
        }
        hasQuiz
        requirements
        courseCategoryId
        courseCategory {
          id
          name
        }
        courseType {
          name
          id
          slug
        }
        courseSubCategoryId
        courseLevelId
        courseModeId
        courseTypeId
        courseEnrollmentCount
        ratingCounts
      }
      pagination {
        totalRecords
        currentPage
      }
    }
  }
`;
export const GETPUBLICCOURSESLEVELLISTQUERY = gql`
  query requests_getPublicCourseLevelListQuery(
    $where: CourseLevelsResolver_GetPublicCourseLevelList_FilterInputType
    $paginate: PaginatorArgs
  ) {
    getPublicCourseLevelList(where: $where, paginate: $paginate) {
      records {
        id
        name
        slug
      }
      pagination {
        totalRecords
        currentPage
      }
    }
  }
`;
export const GETPUBLICLANGUAGESLISTQUERY = gql`
  query requests_getPublicCourseLanguagesListQuery(
    $where: CourseLanguagesResolver_GetPublicCourseLanguagesList_FilterInputType
    $paginate: PaginatorArgs
  ) {
    getPublicCourseLanguagesList(where: $where, paginate: $paginate) {
      records {
        id
        name
        shortCode
        slug
      }
      pagination {
        totalRecords
        currentPage
      }
    }
  }
`;
export const GETCOURSESMODESQUERY = gql`
  query requests_getPublicCourseModesQuery(
    $where: CourseModeResolver_GetPublicCourseModes_FilterInputType
    $paginate: PaginatorArgs
  ) {
    getPublicCourseModes(where: $where, paginate: $paginate) {
      records {
        id
        name
      }
      pagination {
        totalRecords
        currentPage
      }
    }
  }
`;
export const GETMENTORDETAIL = gql`
  query requests_getPublicInstructorQuery($id: String!) {
    getPublicInstructor(id: $id) {
      id
      firstName
      lastName
      email
      userProfile {
        id
        userId
        countryId
        gender
        mobileNo
        mobileNoStdCode
        imageUrl
        linkedinUrl
        twitterUrl
        instagramUrl
        description
        qualification
        country {
          id
          countryName
          countryStdCode
          countryCode
        }
      }
      courses {
        id
        name
        slug
        shortDescription
        description
        sortOrder
        status
        images {
          original
        }
        amount
        sellPrice
        startDatetime
        endDatetime
        totalSeat
        costType
        address
        meetingLink
        meetingNumber
        meetingPassword
        hasCertificate
        hasQuiz
        requirements
        courseCategoryId
        courseCategory {
          id
          name
          sortNumber
          imageUrl
          slug
          parentId
        }
        courseSubCategoryId
        courseSubCategory {
          id
          name
          sortNumber
          imageUrl
          slug
          parentId
        }
        courseLevelId
        courseLevel {
          id
          name
          slug
        }
        courseModeId
        courseMode {
          id
          name
        }
        courseTypeId
        courseType {
          id
          name
          slug
        }
        courseLanguages {
          id
          name
          shortCode
          slug
          status
        }
        mentors {
          mentor {
            email
            firstName
            id
            lastName
            userProfile {
              gender
              userId
              imageUrl
              description
              country {
                id
                countryName
              }
            }
          }
        }
        objectives {
          objective
        }
        sections {
          id
          name
          sortNumber
          introduction
          courseId
          createdAt
          updatedAt
          createdById
          updatedById
          lessons {
            contentUrl
            description
            type
            quiz {
              totalMarks
              questions {
                duration
                question
              }
            }
            introduction
            name
            duration
          }
        }
        courseSkills {
          id
          name
          slug
          status
        }
        highlights {
          id
          courseId
          highlightIcon
          highlightText
        }
      }
    }
  }
`;
// export const getPublicCourseById = graphql`
//   query requests_getPublicCourseByIdQuery {
//     getPublicCourseById {
//       id
//       name
//       slug
//       shortDescription
//       description
//       sortOrder
//       status
//       images {
//         thumb
//         medium
//         large
//         original
//       }
//       amount
//       sellPrice
//       startDatetime
//       endDatetime
//       totalSeat
//       costType
//       address
//       meetingLink
//       meetingNumber
//       meetingPassword
//       hasCertificate
//       hasQuiz
//       requirements
//       courseCategoryId
//       courseCategory {
//         id
//         name
//         sortNumber
//         imageUrl
//       }
//       courseSubCategoryId
//       courseSubCategory {
//         id
//         name
//         sortNumber
//       }
//       courseLevelId
//       courseLevel {
//         id
//         name
//         slug
//       }
//       courseModeId
//       courseMode {
//         id
//         name
//       }
//       courseTypeId
//       courseType {
//         id
//         name
//         slug
//       }
//       courseLanguages {
//         id
//         name
//       }
//       mentors {
//         mentor {
//           id
//           firstName
//         }
//       }
//       objectives {
//         objective
//       }
//       sections {
//         id
//         name
//         sortNumber
//         introduction
//         courseId
//       }
//       courseSkills {
//         id
//         name
//       }
//       highlights {
//         id
//         courseId
//         highlightIcon
//         highlightText
//       }
//     }
//   }
// `;
