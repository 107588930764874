import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { Profile } from "./Profile/Profile";
import Learning from "./MyLearnings/Learning";
import { useNavigate } from "react-router-dom";
import { GETMYPROFILE } from "./core/requests";
import { useAuth } from "../../pages/auth/core/Auth";
import OrderPaymentDetails from "../order-Payment/OrderPaymentDetails";
import Bookmark from "../Bookmark/Bookmark";
import * as authHelper from "../../pages/auth/core/AuthHelpers";
import { useQuery } from "@apollo/client";
import toast from "react-hot-toast";
import GstDetail from "../Gstdetail/GstDetail";
export const MyProfile = (props: any) => {
  const data: any = useLocation();
  // const navigate = useNavigate()
  const [profile, setProfile] = useState(
    data.state?.page == "mylearning" || data.state?.page == "bookmark"
      ? false
      : true,
  );
  const [learning, setLearning] = useState(
    data.state?.page == "mylearning" ? true : false,
  );
  const [order, setOrder] = useState(false);
  const [bookmark, setBookmark] = useState(
    data.state?.page == "bookmark" ? true : false,
  );
  // const [gst , setGst] = useState(false)
  const navigate = useNavigate();
  // const orderpayment = () => {
  //     navigate('/orderpaymentdetails')
  // }
  const div1Ref = useRef<HTMLDivElement>(null);
  const { logout } = useAuth();
  const handleLearning = () => {
    setLearning(true);
    setProfile(false);
    setOrder(false);
    setBookmark(false);
    // setGst(false)
  };
  const handleProfile = () => {
    setProfile(true);
    setLearning(false);
    setOrder(false);
    setBookmark(false);
    // setGst(false)
  };
  const handleorder = () => {
    setOrder(true);
    setProfile(false);
    setLearning(false);
    setBookmark(false);
    // setGst(false)
  };

  const handleBookmark = () => {
    setBookmark(true);
    setProfile(false);
    setLearning(false);
    setOrder(false);
    // setGst(false)
  };

  // const handlegst = () => {
  //     setGst(true)
  //     setBookmark(false)
  //     setProfile(false)
  //     setLearning(false)
  //     setOrder(false)
  // }
  const {
    data: profileDetail,
    refetch,
    loading,
  } = useQuery(GETMYPROFILE, {
    onCompleted: () => {
      refetch();
    },
  });

  const handleLogout = () => {
    toast.success("SuccessFully Logout from the Platform");
    logout();
    authHelper.removeAuth();
    navigate("/");
  };
  return (
    <>
      <section className="section px-xl-4 px-lg-3 px-2">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 mx-auto mt-5">
              <div className="card border-muted px-2">
                <div className="text-center mt-3 mt-lg-5">
                  <img
                    className="img-fluid avatar-lg rounded-circle"
                    src={
                      profileDetail?.getMyProfile?.userProfile[0]?.imageUrl !=
                        null &&
                      profileDetail?.getMyProfile?.userProfile[0]?.imageUrl !=
                        ""
                        ? profileDetail?.getMyProfile?.userProfile[0]?.imageUrl
                        : "../media/mentor/profile_image.jpg"
                    }
                    alt=""
                  />
                </div>
                <div className="text-center">
                  <div className="mt-2 title fs-3 head1">
                    {profileDetail?.getMyProfile?.firstName +
                      " " +
                      profileDetail?.getMyProfile?.lastName}
                  </div>
                  <p className="pb-0 mb-0 text-muted">
                    {profileDetail?.getMyProfile?.email}
                  </p>
                </div>
                <div className="me-2 mt-4">
                  <div
                    onClick={handleLearning}
                    className={`myprofile-tab align-items-center d-flex justify-content-between d-block text-decoration-none p-2 mt-2 text-hover-primary-dark cursor-pointer ${
                      learning ? "profile-tab-active" : ""
                    }`}
                  >
                    <div className={`${learning ? "color1" : ""} `}>
                      {" "}
                      <i className="fa-solid fa-book-open px-2 "></i>My
                      Learnings
                    </div>
                    <i className="fa-solid fa-chevron-right"></i>
                  </div>
                  <div
                    onClick={handleBookmark}
                    className={`myprofile-tab align-items-center d-flex justify-content-between d-block text-decoration-none p-2 mt-2 text-hover-primary-dark cursor-pointer ${
                      bookmark ? "profile-tab-active" : ""
                    }`}
                  >
                    <div className={`${bookmark ? "color1" : ""} `}>
                      <i className="fa-solid fa-bookmark px-2 me-2"></i>
                      Bookmarks
                    </div>
                    <i className="fa-solid fa-chevron-right"></i>
                  </div>
                  <div
                    onClick={handleProfile}
                    className={`myprofile-tab align-items-center d-flex justify-content-between d-block text-decoration-none p-2 mt-2 text-hover-primary-dark cursor-pointer ${
                      profile ? "profile-tab-active" : ""
                    }`}
                  >
                    <div className={`${profile ? "color1" : ""} `}>
                      <i className="fa-solid fa-user px-2 me-2"></i>My Profile
                    </div>
                    <i className="fa-solid fa-chevron-right"></i>
                  </div>
                  <hr className="mx-2" />
                  <div
                    className={`myprofile-tab align-items-center d-flex justify-content-between d-block text-decoration-none p-2 mt-4 text-hover-primary-dark cursor-pointer ${
                      order ? "profile-tab-active" : ""
                    }`}
                  >
                    <div
                      onClick={handleorder}
                      className={`${order ? "color1" : ""} `}
                    >
                      <i className="fa-solid fa-clock-rotate-left px-2 me-1"></i>
                      Orders & Payments
                    </div>
                    <i className="fa-solid fa-chevron-right"></i>
                  </div>
                  <div className="d-flex justify-content-between d-block text-decoration-none p-2 mt-2 text-hover-primary-dark cursor-pointer align-items-center d-none">
                    <div>
                      <i className="fa-solid fa-database px-2 me-1"></i>GST
                      Details
                    </div>
                    <i className="fa-solid fa-chevron-right"></i>
                  </div>
                  <hr className="mx-2" />
                  <div className="d-flex justify-content-between d-block text-decoration-none p-2 mt-4 text-hover-primary-dark cursor-pointer align-items-center d-none">
                    <div>
                      <i className="fa-solid fa-circle-question px-2 me-1"></i>
                      Help Centre
                    </div>
                    <i className="fa-solid fa-chevron-right"></i>
                  </div>
                  <div
                    onClick={() => {
                      handleLogout();
                    }}
                    className="d-flex justify-content-between d-block text-decoration-none p-2 my-2 text-hover-primary-dark cursor-pointer align-items-center"
                  >
                    <div>
                      <i className="fa-solid fa-right-from-bracket px-2 me-1"></i>
                      Log Out
                    </div>
                    <i className="fa-solid fa-chevron-right"></i>
                  </div>
                </div>
              </div>
            </div>
            {profile === true && (
              <div className="col-lg-9 mt-5">
                <Profile
                  profile={profileDetail}
                  profilerefetch={refetch}
                  loading={loading}
                />
              </div>
            )}
            {learning === true && (
              <div className="col-lg-9 mt-5">
                <Learning />
              </div>
            )}
            {order === true && (
              <div className="col-lg-9 mt-5">
                <OrderPaymentDetails />
              </div>
            )}
            {bookmark === true && (
              <div className="col-lg-9 mt-5">
                <Bookmark />
              </div>
            )}
            {/* {gst === true && (
                            <div className="col-lg-9 mt-5">
                                <GstDetail />
                            </div>)} */}
          </div>
        </div>
      </section>
    </>
  );
};
