import React, { useState, useEffect } from "react";

const Counter = ({ data, ctype }: any) => {
  const [counterValues, setCounterValues] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    const givenDate = new Date(data);
    const intervalId = setInterval(() => {
      const currentDate = new Date();
      const difference = givenDate.getTime() - currentDate.getTime();

      let hours: any;
      let minutes: any;
      const days = Math.floor(difference / (1000 * 60 * 60 * 24));
      hours = Math.floor(
        (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
      );
      minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((difference % (1000 * 60)) / 1000);

      hours = hours < 10 ? `0${hours}` : hours;
      minutes = minutes < 10 ? `0${minutes}` : minutes;

      setCounterValues({ days, hours, minutes, seconds });
    }, 1000);

    return () => clearInterval(intervalId);
  }, [data]);

  if (ctype) {
    return (
      <div className="width-35 text-center">
        <div
          className="py-2"
          style={{
            backgroundColor: "rgba(255, 120, 60, 0.15)",
            borderRadius: "15px",
          }}
          role="alert"
        >
          <>
            Starts in{" "}
            <strong>
              {" "}
              {counterValues.days}d : {counterValues.hours}h :{" "}
              {counterValues.minutes}m : {counterValues.seconds}s
            </strong>
          </>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className="uk d-none d-md-block d-lg-block">
        <div className="uk">
          <div className="uk">
            <div
              className="d-lg-flex uk-grid-small uk-child-width-auto justify-content-center"
              uk-grid
              uk-countdown="date: 2023-11-21T17:33:42+00:00"
            >
              <div>
                <div className="uk-countdown-number uk-countdown-days">
                  {counterValues.days}
                </div>
                <div className="uk-countdown-label uk-margin-small uk-text-center uk-visible@s text-center">
                  Days
                </div>
              </div>
              <div className="uk-countdown-separator mt-4 me-2 ms-2">:</div>
              <div>
                <div className="uk-countdown-number uk-countdown-hours">
                  {counterValues.hours}
                </div>
                <div className="uk-countdown-label uk-margin-small uk-text-center uk-visible@s text-center">
                  Hours
                </div>
              </div>
              <div className="uk-countdown-separator mt-4 me-2 ms-2">:</div>
              <div>
                <div className="uk-countdown-number uk-countdown-minutes">
                  {counterValues.minutes}
                </div>
                <div className="uk-countdown-label uk-margin-small uk-text-center uk-visible@s text-center">
                  Minutes
                </div>
              </div>
              <div className="uk-countdown-separator mt-4 me-2 ms-2">:</div>
              <div>
                <div className="uk-countdown-number uk-countdown-seconds">
                  {counterValues.seconds}
                </div>
                <div className="uk-countdown-label uk-margin-small uk-text-center uk-visible@s text-center">
                  Seconds
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*
-------------------------------
 Responsive Mobile View Code 
 ---------------------------
 */}

      <div className="uk d-block d-md-none d-lg-none">
        <div className="uk d-flex flex-wrap justify-content-center">
          <div className="d-inline-flex uk-grid-small uk-child-width-auto pb-5">
            <div className="me-2">
              <div className="uk-countdown-number uk-countdown-days">
                {counterValues.days}
              </div>
              <div className="uk-countdown-label uk-margin-small uk-text-center uk-visible@s text-center">
                Days
              </div>
            </div>
            <div className="me-2">
              <div className="uk-countdown-separator mt-4">:</div>
            </div>
            <div className="me-2">
              <div className="uk-countdown-number uk-countdown-hours">
                {counterValues.hours}
              </div>
              <div className="uk-countdown-label uk-margin-small uk-text-center uk-visible@s text-center">
                Hours
              </div>
            </div>
            <div className="me-2">
              <div className="uk-countdown-separator mt-4">:</div>
            </div>
            <div className="me-2">
              <div className="uk-countdown-number uk-countdown-minutes">
                {counterValues.minutes}
              </div>
              <div className="uk-countdown-label uk-margin-small uk-text-center uk-visible@s text-center">
                Minutes
              </div>
            </div>
            <div className="me-2">
              <div className="uk-countdown-separator mt-4">:</div>
            </div>
            <div>
              <div className="uk-countdown-number uk-countdown-seconds">
                {counterValues.seconds}
              </div>
              <div className="uk-countdown-label uk-margin-small uk-text-center uk-visible@s text-center">
                Seconds
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Counter;
