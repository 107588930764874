import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Tab, Tabs } from "react-tabs";
import { TabList } from "react-tabs";
import { toAbsoluteUrl } from "../../../assets/helpers/AssetHelpers";
import Slider from "react-slick";
import OnGoing from "../../Profile-page/MyLearnings/OnGoing";
import Ongoing from "./OngoingCard";
import OngoingCard from "./OngoingCard";
import Upcoming from "../../Profile-page/MyLearnings/Upcoming";
import YourUpcoming from "./YourUpcoming";
const settings = {
  // prevArrow: <PrevArrow />,
  // nextArrow: <NextArrow />,
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 2,
  slidesToScroll: 2,
  initialSlide: 0,
  adaptiveHeight: false,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const YourLearning = () => {
  const navigate = useNavigate();
  const [OnGoing, setonGoing] = useState(false);
  const [Upcoming, setUpcoming] = useState(false);
  const OnGoingClick = () => {
    setonGoing(true);
  };
  const upcomingClick = () => {
    setUpcoming(true);
  };
  // const yog = () =>{
  //     navigate('/yourlearning')
  // }
  return (
    <>
      <div className="col-12">
        <div className="list-courses">
          <ul
            className="nav list-courses-nav list-courses-nav-tabs nav-tabs mb-3"
            id="myTab"
            role="tablist"
          >
            <li
              className="list-courses-nav-item nav-item pe-4 ps-2"
              role="presentation"
            >
              <button
                className="list-courses-nav-link mx-0 px-0 py-0 nav-link active "
                id="RecommendSlider-tab"
                data-bs-toggle="tab"
                data-bs-target="#RecommendSlider"
                type="button"
                role="tab"
                aria-controls="RecommendSlider"
                aria-selected="true"
              >
                Recommend for you
              </button>
            </li>
            <li className="list-courses-nav-item nav-item" role="presentation">
              <button
                className="list-courses-nav-link mx-0 px-0 py-0 nav-link"
                id="NewArrivalsSlider-tab"
                data-bs-toggle="tab"
                data-bs-target="#NewArrivalsSlider"
                type="button"
                role="tab"
                aria-controls="NewArrivalsSlider"
                aria-selected="false"
              >
                New Arrivals
              </button>
            </li>
          </ul>
          <div className="tab-content" id="myTabContent">
            <div
              className="tab-pane fade show active"
              id="RecommendSlider"
              role="tabpanel"
              aria-labelledby="RecommendSlider-tab"
            >
              <div className="row">
                <div className="col-12">
                  {/* <RecommendSlider whereCondition={whereCondition}/> */}
                  SASA
                </div>
              </div>
            </div>
            <div
              className="tab-pane fade"
              id="NewArrivalsSlider"
              role="tabpanel"
              aria-labelledby="NewArrivalsSlider-tab"
            >
              <div className="row">
                <div className="col-12">
                  {/* <NewArrivalsSlider whereCondition={whereCondition} /> */}
                  nasa
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="section">
        <div className="container ">
          <div className="row ">
            <div className="col-12 mt-5">
              <div className="flex mb-3">
                <div className="font-title title head1">Your Learnings</div>
              </div>
              <ul
                className="nav list-courses-nav list-courses-nav-tabs nav-tabs mb-3"
                id="myTab"
                role="tablist"
              >
                <li
                  className="list-courses-nav-item nav-item pe-4 ps-2"
                  role="presentation"
                >
                  <button
                    className="list-courses-nav-link mx-0 px-0 py-0 nav-link active "
                    id="RecommendSlider-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#RecommendSlider"
                    type="button"
                    role="tab"
                    aria-controls="RecommendSlider"
                    aria-selected="true"
                  >
                    Ongoing
                  </button>
                </li>
                <li
                  className="list-courses-nav-item nav-item"
                  role="presentation"
                >
                  <button
                    className="list-courses-nav-link mx-0 px-0 py-0 nav-link"
                    id="NewArrivalsSlider-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#NewArrivalsSlider"
                    type="button"
                    role="tab"
                    aria-controls="NewArrivalsSlider"
                    aria-selected="false"
                  >
                    Upcoming
                  </button>
                </li>
              </ul>
              <div className="tab-content" id="myTabContent">
                <div
                  className="tab-pane fade show active"
                  id="RecommendSlider"
                  role="tabpanel"
                  aria-labelledby="RecommendSlider-tab"
                >
                  <div className="row">
                    <div className="col-12">
                      {/* <RecommendSlider whereCondition={whereCondition}/> */}
                      <OngoingCard />
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="NewArrivalsSlider"
                  role="tabpanel"
                  aria-labelledby="NewArrivalsSlider-tab"
                >
                  <div className="row">
                    <div className="col-12">
                      {/* <NewArrivalsSlider whereCondition={whereCondition} /> */}
                      <YourLearning />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <Tabs className="mt-4">
                                <TabList>
                                    <Tab onClick={()=>upcomingClick()}>
                                        Ongoing
                                    </Tab>
                                    <Tab onClick={()=>OnGoingClick()}>
                                        Upcoming
                                    </Tab>
                                </TabList>
                            </Tabs> */}
          </div>
          <div className="col-lg-12 ">
            <Slider {...settings}>
              <OngoingCard />
              <OngoingCard />
              <OngoingCard />
              <OngoingCard />
              <OngoingCard />
              <OngoingCard />
            </Slider>
          </div>
        </div>
      </section>
    </>
  );
};

export default YourLearning;
