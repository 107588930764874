import React from 'react';
import TeamCard from './TeamCard';

const OurTeam = ({ wrapInRow = false }: { wrapInRow?: boolean }) => {
	const teamData = [
		{
			title: 'Sales & Enterprise',
			description: "We'd love to talk about how we can work together.",
			buttonText: 'Contact Sales',
			imageSrc: 'media/contact-us/team1.svg',
			buttonImageSrc: 'media/contact-us/btn-arrow.svg',
		},
		{
			title: 'Help & Support',
			description: 'Get in touch and let us know how we can help.',
			buttonText: 'Get Support',
			imageSrc: 'media/contact-us/team2.svg',
			buttonImageSrc: 'media/contact-us/btn-arrow.svg',
		},
		{
			title: 'Media & Press',
			description: 'Get Deupload news, company info, and media resources.',
			buttonText: 'Visit Newsroom',
			imageSrc: 'media/contact-us/team3.svg',
			buttonImageSrc: 'media/contact-us/btn-arrow.svg',
		},
	];

	return (
		<section className={`${wrapInRow ? 'row' : ''} our-team-wrapper py-lg-4 px-lg-5 p-md-4 p-3`}>
			<div className="row">
				<div className="col-lg-12">
					<div className="row">
						<div className="col-lg-12">
							<div className="mb-5 text-center">
								<h2 className="our-team-title">Talk with Our Team</h2>
								<p className="our-team-subtitle">Get in touch and let us know how we can help.</p>
							</div>

							{/* Adjust the layout for three cards per row */}
							<div className="row">
								{teamData.map((team, index) => (
									<div className="col-lg-4 col-md-12 col-12 mb-4" key={index}>
										<TeamCard
											title={team.title}
											description={team.description}
											buttonText={team.buttonText}
											imageSrc={team.imageSrc}
											buttonImageSrc={team.buttonImageSrc}
										/>
									</div>
								))}
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default OurTeam;
