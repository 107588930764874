import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getCmsDetailBySlug } from "../../pages/auth/core/_requests";
import { Helmet } from "react-helmet";
import createDOMPurify from "dompurify";
interface Contact {
  id: string;
  name: string;
  seoTitle: string;
  seoDescription: string;
  keywords: string;
  slug: string;
  content: string;
}
export const ContactUs = () => {
  const [contactcontent, setcontactcontent] = useState<Contact>();
  const location = useLocation();
  const navigate = useNavigate();
  const cms = location.pathname.split("/").reverse()[0];
  const DOMPurify = createDOMPurify(window);

  function generateHTMLContent(content: any) {
    return { __html: DOMPurify.sanitize(content) };
  }
  if (!cms) {
    navigate("/");
  }
  useEffect(() => {
    const fetchData = async () => {
      const response = await getCmsDetailBySlug(cms);
      setcontactcontent(response.data.data.getCmsDetailBySlug);
    };
    fetchData();
  }, []);
  return (
    <>
      <section className="section">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-8 ">
              <div className="mb-5 mt-5">
                <h2 className="font-title fw-bold">Contact Us</h2>
              </div>
              <div className="row justify-content-between ">
                <div className="col-md-6 border-muted p-3">
                  <div>
                    <h4 className="font-title">Write to Us</h4>
                    <div className="">
                      <label
                        htmlFor="exampleInputEmail1"
                        className="font-subtitle form-label mt-4 text-muted"
                      >
                        Full Name
                      </label>
                      <input
                        type="text"
                        className="form-control border-muted"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        placeholder="John |"
                      />
                    </div>
                    <div className="">
                      <label
                        htmlFor="exampleInputEmail1"
                        className="font-subtitle form-label mt-4 text-muted"
                      >
                        Email
                      </label>
                      <input
                        type="Email"
                        className="form-control border-muted"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        placeholder="Enter Your Email"
                      />
                    </div>
                    <div className="">
                      <label
                        htmlFor="exampleInputEmail1"
                        className="font-subtitle form-label mt-4 text-muted"
                      >
                        Phone Number
                      </label>
                      <input
                        type="mobile"
                        maxLength={10}
                        className="form-control border-muted"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        placeholder="Enter Your Phone Number"
                      />
                    </div>
                    <div className="">
                      <label
                        htmlFor="exampleInputEmail1"
                        className="font-subtitle form-label mt-4 text-muted"
                      >
                        Message
                      </label>
                      <textarea
                        className="form-control border-muted"
                        id="exampleFormControlTextarea1"
                        rows={3}
                        placeholder="Write Here ..."
                      ></textarea>
                    </div>
                    <button className="btn btn-primary mt-3">Submit</button>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="p-5">
                    <img
                      src="../media/contact/contactus.png"
                      alt="contactus"
                      className="float-end"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-color3 ">
        <div className="container-fluid ">
          <div className="row text-center ">
            <div className="col-md-4 border-end border-white border-3 pt-4">
              <img src="../media/contact/map.png" alt="map" />
              <p className="fw-bold mt-3">Head Office</p>
              <p className="text-muted">
                Jainam House, Near Shardayatan School,
                <br /> Piplod, Surat
              </p>
            </div>
            <div className="col-md-4 border-end border-white border-3 pt-4">
              <img src="../media/contact/mail.png" alt="mail" />
              <p className="fw-bold mt-3">Mail us</p>
              <p className="text-muted">
                support@smartlearn. com
                <br />
                info@smartlearn. com
              </p>
            </div>
            <div className="col-md-4 pt-4">
              <img src="../media/contact/call.png" alt="call" />
              <p className="fw-bold mt-3">Call Us</p>
              <p className="text-muted">+91 9876543210</p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
