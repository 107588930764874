import React, { FC, ReactNode } from 'react';
import { Outlet } from 'react-router-dom';
import { useLocation } from 'react-router';
import HGHeader from 'components/_v2/Header/HGHeader';
import HGFooter from 'components/_v2/Footer/HGFooter';
import { BASE_PAGE_URL, DETAIL_URL } from 'pages/routing/routePages';

type WithChildren = {
	children?: ReactNode;
	disableMarginTop: boolean;
};

const Content: FC<WithChildren> = ({ disableMarginTop, children }: any) => {
	return (
		<div
			id="kt_content_container"
			className={`w-100 ${!disableMarginTop ? 'header-layout-set' : ''}`}>
			{children}
		</div>
	);
};

export const MasterLayout = () => {
	const { pathname } = useLocation();

	return (
		<div>
			<HGHeader />
			<Content disableMarginTop={pathname === BASE_PAGE_URL || pathname.includes(DETAIL_URL)}>
				<Outlet />
			</Content>
			<HGFooter />
		</div>
	);
};
