import React, { Suspense, useState } from "react";
import { PiCurrencyInrBold } from "react-icons/pi";
import { MdDateRange } from "react-icons/md";
import { HiMiniComputerDesktop } from "react-icons/hi2";
import { GiNetworkBars } from "react-icons/gi";
import { toAbsoluteUrl } from "../../../assets/helpers/AssetHelpers";
import Slider from "react-slick";
import { slider_three } from "../../../assets/helpers/Slider";
import _ from "lodash";
import moment from "moment";
import { requests_getPublicNewArrivalCoursesQuery } from "../../Home-Page/core/__generated__/requests_getPublicNewArrivalCoursesQuery.graphql";
import { GETNEWARRIVALCOURSES } from "../../Home-Page/core/requests";
import { useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import { getUserData } from "../../../utils/getUserData";
import toast from "react-hot-toast";
import { ADDTOWISHLIST } from "../../Courses-Page/core/requests";
import { Login } from "../../../pages/auth/Login";

function NewArrivalsSlider(props: any) {
  const [currentAuthToken, setCurrentAuthToken] = React.useState(
    getUserData().authToken,
  );
  const { whereCondition } = props;
  const { refreshedQueryOptions } = props;
  const [commit] = useMutation(ADDTOWISHLIST);
  const navigate = useNavigate();
  const { data: newArrivalResult } = useQuery(GETNEWARRIVALCOURSES, {
    variables: {
      where: whereCondition,
    },
  });
  const newArrivalRecord =
    newArrivalResult?.getPublicNewArrivalCourses?.records ?? [];
  // const { authToken: currentAuthToken } = getUserData();
  let wishlistEvent = false;
  const handleOpen = (id: string) => {
    if (wishlistEvent === false) {
      navigate(`/course-view/${id}`);
    }
  };
  const handleWishList = async (id: any) => {
    wishlistEvent = true;
    if (currentAuthToken) {
      const variables = {
        courseId: id,
      };
      commit({
        variables: {
          courseId: id,
        },
        onCompleted: () => {
          toast.success("successfully added to wishlist", {
            style: {
              padding: "16px",
            },
          });
          navigate(`/my-profile`, { state: { page: "bookmark" } });
        },
        onError: (err: any) => {
          toast.error(err.message);
        },
      });
    }
  };

  React.useEffect(() => {
    const handleStorageChange = () => {
      setCurrentAuthToken(getUserData().authToken);
    };

    window.addEventListener("storage", handleStorageChange);
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  React.useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentAuthToken(getUserData().authToken);
    }, 1000);
    return () => clearInterval(intervalId);
  }, []);
  return (
    <>
      <Slider {...slider_three} className=" p-0">
        {newArrivalRecord
          ?.filter((value: any) => value.completed === false)
          .map((value: any) => {
            const date = moment
              .utc(value.startDatetime)
              .local()
              .format("YYYY-MM-DD HH:mm:ss");
            const formattedDate = moment
              .utc(value.startDatetime)
              .format("ddd, DD MMM YYYY • hh:mm A");
            const endDate = new Date(value.endDatetime);
            return (
              <div className="col-xxl-3 col-xl-3 col-lg-3 col-6 col-md-3 p-2 topSelling ">
                <div
                  className="card course-card round overflow-hidden border-muted h-100 cursor-pointer fixed-height-card"
                  onClick={() => handleOpen(value.slug)}
                >
                  <div className="card-body p-4">
                    <div className="card-img image position-relative overflow-hidden">
                      {value?.images?.length > 0 ? (
                        <>
                          {value?.images?.map((value: any) => {
                            return (
                              <>
                                <img
                                  alt=""
                                  src={value.original}
                                  className="card-img-top  image img-fluid round bg-light"
                                />
                              </>
                            );
                          })}
                        </>
                      ) : (
                        <img
                          alt=""
                          src={toAbsoluteUrl("/media/course/1.jpg")}
                          className=" card-img-top  image img-fluid round bg-light"
                        />
                      )}
                      {currentAuthToken !== null && (
                        <div
                          className="course-tag cursor-pointer"
                          onClick={() => {
                            handleWishList(value.id);
                          }}
                        >
                          <div className="course-badge text-dark p-2 py-1 rounded-1">
                            <i className="fa fa-bookmark tag fs-6"></i>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="card-text mt-3">
                      <div className="d-flex justify-content-between mb-2 justify-content-lg-between">
                        <span className="btn btn-outline-success course-btn rounded-pill px-3 py-1 text-dark">
                          {value.courseCategory.name
                            ? value.courseCategory.name
                            : "Technical"}
                        </span>
                        {/* <span className="fs-14 font-subtitle bg-outline-success border border-success rounded-pill px-3 py-1 text-dark me-2" >
                          {value.courseCategory.name ? value.courseCategory.name : "Technical"}
                        </span> */}
                        <div className="align-items-center border border-orange course_difficulty d-flex justify-content-center px-2 rounded-pill text-center">
                          <i
                            className={`mt-0 icon__course-difficulty difficulty-${value.courseLevel.name} position-relative`}
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            data-bs-html="true"
                            title={`Course Defficulty : ${value.courseLevel.name}`}
                          >
                            <span
                              className={` bar-1 ${
                                value.courseLevel.name === "Advanced" ||
                                value.courseLevel.name === "Intermediate" ||
                                value.courseLevel.name === "Beginner"
                                  ? "opacity-100"
                                  : "opacity-25"
                              } `}
                            ></span>
                            <span
                              className={` bar-2 ${
                                value.courseLevel.name === "Advanced" ||
                                value.courseLevel.name === "Intermediate"
                                  ? "opacity-100"
                                  : "opacity-25"
                              } `}
                            ></span>
                            <span
                              className={` bar-3 ${
                                value.courseLevel.name === "Advanced"
                                  ? "opacity-100"
                                  : "opacity-25"
                              } `}
                            ></span>
                          </i>
                        </div>
                      </div>
                      <h6
                        className="mt-3 fw-bold cursor-pointer"
                        onClick={() => handleOpen(value.slug)}
                      >
                        {value.name}
                      </h6>
                      <p className="text-muted mb-0">
                        {value?.mentors?.map((mentor: any, index: any) => (
                          <React.Fragment key={index}>
                            <a
                              className="text-decoration-none text-muted-dark"
                              href={`/mentor-view/${mentor.mentor.id}`}
                            >
                              {index > 0 ? (
                                <>and&nbsp;</>
                              ) : (
                                <span>By&nbsp;</span>
                              )}
                              {mentor.mentor.firstName ?? ""}{" "}
                              {mentor.mentor.lastName ?? ""}
                              {index < value.mentors.length - 1 ? <></> : null}
                            </a>
                          </React.Fragment>
                        ))}
                        {value.startDatetime === null ||
                        value.courseType.name === "Recorded Course" ? (
                          ""
                        ) : (
                          <>
                            <div className="my-2 d-flex text-muted-dark">
                              <img
                                src="../media/icons/calendar-event-fill.svg"
                                alt=""
                                className="fs-5 me-1"
                              />
                              {formattedDate}
                            </div>
                          </>
                        )}

                        <div className="mt-2 text-muted-dark">
                          {value.address && (
                            <>
                              <i className="fa-solid fa-location-dot fs-5 me-1"></i>{" "}
                              {value.address}
                            </>
                          )}
                        </div>
                      </p>
                      <div className="mt-2 fw-bolder align-items-center course-price py-1">
                        <div className="price">
                          {value.amount === 0 && value.sellPrice === 0 && (
                            <>
                              <span className="course-free">Free</span>
                            </>
                          )}
                          {value.sellPrice !== 0 ? (
                            <>
                              <span className="amount">
                                <i className="fa-solid fa-indian-rupee-sign"></i>
                                <span className="">{value.sellPrice}</span>
                              </span>
                              <span className="ms-2 sell text-decoration-line-through">
                                <i className="fa-solid fa-indian-rupee-sign"></i>
                                <span className="">{value.amount}</span>
                              </span>
                            </>
                          ) : value.amount !== 0 ? (
                            <>
                              <span className="amount">
                                <i className="fa-solid fa-indian-rupee-sign"></i>
                                <span className="">{value.amount}</span>
                              </span>
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </Slider>
    </>
  );
}

export default NewArrivalsSlider;
