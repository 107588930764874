import React from "react";
import "./Popup.css"; // Create a CSS file to style the popup

const Popup = ({ onClose }: { onClose: () => void }) => {
  return (
    <div className="popup">
      <div className="popup-content">
        <div className="popup-header">
          <span className="g_mid">This website uses cookies</span>
          <button className="close-button" onClick={onClose}>
            ×
          </button>
        </div>
        <p className="g_reg">
          This website uses cookies and local storage for performance and
          personalization. Only essential cookies are turned on by default.
          <a href="#"> Learn More.</a>
        </p>
        <button className="accept-button g_mid" onClick={onClose}>
          Accept Cookies
        </button>
      </div>
    </div>
  );
};

export default Popup;
