import { NotificationsObjectType } from 'components/_v2/Header/types';
import React, { createContext, useContext, useState, FC, ReactNode, useMemo } from 'react';

type PaginationType = {
  totalRecords: number;
  currentPage: number;
};
type NotificationContextType = {
  studentNotifications: NotificationsObjectType[];
  setStudentNotifications: React.Dispatch<React.SetStateAction<NotificationsObjectType[]>>;
  notificationPagination: PaginationType;
  setNotificationPagination: React.Dispatch<React.SetStateAction<PaginationType>>;
};

type NotificationProviderProps = {
  children: ReactNode;
};
const initialPagination: PaginationType = {
  totalRecords: 0,
  currentPage: 0
};
const NotificationContext = createContext<NotificationContextType>({
  studentNotifications: [],
  setStudentNotifications: (notifications: NotificationsObjectType[]) => {},
  notificationPagination: initialPagination,
  setNotificationPagination: (notifications: PaginationType) => {}
});

export const NotificationProvider: FC<NotificationProviderProps> = ({ children }) => {
  const [studentNotifications, setStudentNotifications] = useState<NotificationsObjectType[]>([]);
  const [notificationPagination, setNotificationPagination] = useState(initialPagination);

  const value = useMemo(() => {
    return {
      studentNotifications,
      setStudentNotifications,
      notificationPagination,
      setNotificationPagination
    };
  }, [studentNotifications, notificationPagination]);

  return <NotificationContext.Provider value={value}>{children}</NotificationContext.Provider>;
};

export const useNotificationContext = () => {
  const context = useContext(NotificationContext);
  if (!context) {
    throw new Error('useNotification must be used within a NotificationProvider');
  }
  return context;
};
