import { gql } from "@apollo/client";
export const GETMYPROFILE = gql`
  query requests_getMyProfileQuery {
    getMyProfile {
      id
      username
      firstName
      lastName
      email
      roleId
      userType
      resetPasswordKey
      createdAt
      updatedAt
      status
      isVerified
      isMobileNumberVerified
      signinType
      userAddress {
        id
        userId
        fullname
        address
        city
        state
        zipCode
        createdAt
        updatedAt
      }
      userProfile {
        id
        userId
        countryId
        gender
        mobileNo
        mobileNoStdCode
        imageUrl
        linkedinUrl
        twitterUrl
        instagramUrl
        description
        goalId
        goal {
          id
          name
          status
        }
        qualification
        createdAt
        updatedAt
        createdById
        updatedById
        ageRange
        occupationId
        occupation {
          id
          name
        }
        platformReferralId
        platformReferral {
          id
          name
          isDefault
        }
        stockMarketAndTradingUnderstandingLevel
        dob
      }
      role {
        id
        name
        slug
        createdAt
        updatedAt
      }
    }
  }
`;

export const GETSTUDENTCOURSES = gql`
  query requests_getStudentCoursesQuery(
    $where: StudentProfileResolver_GetStudentCourses_FilterInputType
    $paginate: PaginatorArgs
  ) {
    getStudentCourses(where: $where, paginate: $paginate) {
      records {
        id
        userId
        courseId
        status

        orderId
        createdAt
        updatedAt
        course {
          id
          name
          slug
          shortDescription
          description
          sortOrder
          status
          images {
            thumb
            medium
            large
            original
          }
          amount
          sellPrice
          startDatetime
          endDatetime
          totalSeat
          costType
          address
          meetingLink
          meetingNumber
          meetingPassword
          hasQuiz
          requirements
          courseCategoryId
          courseCategory {
            id
            name
            sortNumber
            imageUrl
            slug
            parentId
            parent {
              id
              name
            }
          }
          courseSubCategoryId
          courseSubCategory {
            id
            name
            sortNumber
            imageUrl
            slug
            parentId
            parent {
              id
              name
            }
          }
          courseLevelId
          courseLevel {
            id
            name
            slug
          }
          courseModeId
          courseMode {
            id
            name
          }
          courseTypeId
          courseType {
            id
            name
            slug
          }
          courseLanguages {
            id
            name
            shortCode
            slug
            status
          }
          mentors {
            mentor {
              email
              firstName
              id
              lastName
              userProfile {
                gender
                userId
                imageUrl
                description
                country {
                  id
                  countryName
                }
              }
            }
          }
          objectives {
            objective
          }
          sections {
            id
            name
            sortNumber
            introduction
            courseId
            createdAt
            updatedAt
            createdById
            updatedById
            lessons {
              contentUrl
              description
              type
              quiz {
                totalMarks
                questions {
                  duration
                  question
                }
              }
              introduction
              name
              duration
            }
          }
          courseSkills {
            id
            name
            slug
            status
          }
          highlights {
            id
            courseId
            highlightIcon
            highlightText
          }
        }
      }
    }
  }
`;

export const GETSTUDENT = gql`
  query OnGoingCourse_Query(
    $where: StudentProfileResolver_GetStudentCourses_FilterInputType
    $paginate: PaginatorArgs
  ) {
    getStudentCourses(where: $where, paginate: $paginate) {
      records {
        id
        userId
        courseId
        status
        orderId
        createdAt
        updatedAt
        deletedAt
        course {
          sections {
            lessons {
              startDate
            }
          }
          slug
          name
          images {
            original
          }
          mentors {
            mentor {
              firstName
              lastName
            }
          }
          progressPercentage
          id
        }
        completedDatetime
      }
      pagination {
        totalRecords
        currentPage
      }
    }
  }
`;

export const GET_COUNTRIES_WITH_CODE = gql`
  query requests_getCountry {
    getCountriesWithCode {
      id
      countryName
      countryStdCode
      countryCode
    }
  }
`;

export const UPDATE_STUDENT_PROFILE_MUTATION = gql`
  mutation updateStudentProfile(
    $updateStudentProfileInput: UpdateStudentProfileInput!
  ) {
    updateStudentProfile(updateStudentProfileInput: $updateStudentProfileInput)
  }
`;

export const UPDATE_STUDENT_PROFILE_EMAIL_MUTATION = gql`
  mutation updateStudentEmail(
    $updateStudentEmailInput: UpdateStudentEmailInput!
  ) {
    updateStudentEmail(updateStudentEmailInput: $updateStudentEmailInput)
  }
`;
