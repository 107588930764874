import React, { useState } from "react";
import { PiCurrencyInrBold } from "react-icons/pi";
import { MdDateRange } from "react-icons/md";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { GiNetworkBars } from "react-icons/gi";
import { toAbsoluteUrl } from "../../../assets/helpers/AssetHelpers";
import { MdFilterList } from "react-icons/md";
import _ from "lodash";
import moment from "moment";
import { requests_getPublicCoursesQuery } from "../../Home-Page/core/__generated__/requests_getPublicCoursesQuery.graphql";
import { GETCOURSES } from "../../Home-Page/core/requests";
import { useNavigate } from "react-router-dom";
import { HiMiniComputerDesktop } from "react-icons/hi2";
import { toast } from "react-hot-toast";
import { requests_addtoWishListMutation } from "../../Courses-Page/core/__generated__/requests_addtoWishListMutation.graphql";
import { ADDTOWISHLIST } from "../../Courses-Page/core/requests";
import { getUserData } from "../../../utils/getUserData";
import { useMutation, useQuery } from "@apollo/client";
import Loader from "../../common/Spinner/Spinner";
import { Login } from "../../../pages/auth/Login";
const filteroptions = [
  { id: "1", value: "newest", name: "Newest" },
  { id: "2", value: "bestseller", name: "Best Seller" },
  { id: "3", value: "toprated", name: "Top Rated" },
];
function AllWorkshops(props: any) {
  const {
    whereCondition,
    refreshedQueryOptions,
    resetData,
    filtercondition,
    checkedCategory,
    setCheckedCategory,
    setFilterCategory,
    filterCategory,
    checkedLevel,
    setCheckedLevel,
    filterLevel,
    setFilterLevel,
    checkedMode,
    setCheckedMode,
    filterMode,
    setFilterMode,
    checkedLanguage,
    setCheckedLanguage,
    filterLanguage,
    setFilterLanguage,
    selectedPrice,
    setSelectedPrice,
  } = props;
  const [currentAuthToken, setCurrentAuthToken] = React.useState(
    getUserData().authToken,
  );
  const [selectedSort, setSelectedSort] = useState("newest");
  const [open, setOpen] = useState(false);
  const [openRegister, setOpenRegister] = useState(false);
  const [commit] = useMutation(ADDTOWISHLIST);
  // const { authToken: currentAuthToken } = getUserData();
  const handleClick = (value: any) => {
    setCheckedCategory(checkedCategory.filter((e: any) => e.id !== value.id));
    setFilterCategory(filterCategory.filter((e: any) => e !== value.id));
    const checkboxes = document.getElementById(value.id);
    if (checkboxes) {
      (checkboxes as HTMLInputElement).checked = false;
    }
  };
  const handleLevelChange = (value: any) => {
    setCheckedLevel(checkedLevel.filter((e: any) => e.id !== value.id));
    setFilterLevel(filterLevel.filter((e: any) => e !== value.id));
    const checkboxes = document.getElementById(value.id);
    if (checkboxes) {
      (checkboxes as HTMLInputElement).checked = false;
    }
  };
  const handleModeChange = (value: any) => {
    setCheckedMode(checkedMode.filter((e: any) => e.id !== value.id));
    setFilterMode(filterMode.filter((e: any) => e !== value.id));
    const checkboxes = document.getElementById(value.id);
    if (checkboxes) {
      (checkboxes as HTMLInputElement).checked = false;
    }
  };
  const handleLanguageChange = (value: any) => {
    setCheckedLanguage(checkedLanguage.filter((e: any) => e.id !== value.id));
    setFilterLanguage(filterLanguage.filter((e: any) => e !== value.id));
    const checkboxes = document.getElementById(value.id);
    if (checkboxes) {
      (checkboxes as HTMLInputElement).checked = false;
    }
  };
  const handlePriceChange = () => {
    setSelectedPrice(null);
  };

  const navigate = useNavigate();
  const { data: allWorkShopResult, loading } = useQuery(GETCOURSES, {
    variables: { where: whereCondition },
  });

  const allCourseResult = allWorkShopResult?.getPublicCourses?.records ?? [];
  const allWorkShopRecord =
    selectedSort === "bestseller"
      ? [...allCourseResult].sort(
          (a: any, b: any) =>
            b?.courseEnrollmentCount - a?.courseEnrollmentCount,
        )
      : selectedSort === "toprated"
      ? [...allCourseResult].sort(
          (a: any, b: any) => b?.ratingCounts - a?.ratingCounts,
        )
      : selectedSort === "newest"
      ? [...allCourseResult].sort(
          (a: any, b: any) => b?.startDatetime - a?.startDatetime,
        )
      : [...allCourseResult];
  // const allWorkShopRecord = allWorkShopResult?.getPublicCourses?.records ?? [];
  let wishlistEvent = false;
  const handleOpen = (slug: string) => {
    if (wishlistEvent === false) {
      navigate(`/course-view/${slug}`);
    }
  };
  const handleWishList = async (id: any) => {
    wishlistEvent = true;
    if (currentAuthToken) {
      const variables = {
        courseId: id,
      };
      commit({
        variables: {
          courseId: id,
        },
        onCompleted: () => {
          toast.success("successfully added to wishlist", {
            style: {
              padding: "16px",
            },
          });
          navigate(`/my-profile`, { state: { page: "bookmark" } });
        },
        onError: (err: any) => {
          toast.error(err.message);
        },
      });
    }
  };
  React.useEffect(() => {
    const handleStorageChange = () => {
      setCurrentAuthToken(getUserData().authToken);
    };

    window.addEventListener("storage", handleStorageChange);
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  React.useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentAuthToken(getUserData().authToken);
    }, 500);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <section>
      <div className="row gy-4 gx-lg-0 gy-lg-3">
        <div className="col-12">
          <div className="course-heading d-flex align-items-center justify-content-between">
            <h2 className="font-title title py-3 fw-bold head1">
              {filtercondition === false ? "All Workshops" : "Workshops"}
            </h2>
            <div className="filter d-flex d-lg-block">
              <div className="input-group mb-3">
                <div className="input-group-text bg-transparent border-end-0 pe-0">
                  <img src={toAbsoluteUrl("/media/logo/filter.png")} alt="" />
                </div>
                <select
                  className="border border-color3 form-select course-select-filter shadpw-none me-3 me-lg-0 border-start-0"
                  id="inputGroupSelect02"
                  onChange={(e) => setSelectedSort(e.target.value)}
                >
                  <option selected disabled>
                    Sort By
                  </option>
                  {filteroptions.map((value: any) => {
                    return (
                      <>
                        <option value={value.value}>{value.name}</option>
                      </>
                    );
                  })}
                </select>
              </div>
              <div className="mobile-filter d-block d-lg-none">
                <div className="p-2 border border-color2 rounded-3">
                  <i className="fa-solid fa-filter"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {loading ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ minHeight: "300px" }}
        >
          <Loader />
        </div>
      ) : (
        <>
          {filtercondition === true ? (
            <>
              <div className="card border-0 bg-transparent">
                <div className="card-body">
                  <div className="row">
                    <div className="col-12">
                      <div className="d-flex flex-wrap">
                        {filtercondition === true && (
                          <div
                            className="cursor-pointer text-nowrap p-2 m-1 rounded-3 bg-transparent text-primary  "
                            onClick={resetData}
                          >
                            {" "}
                            <span> Clear All</span>
                            <i className="fa fa-close mx-1 text-nowrap" />
                          </div>
                        )}
                        {checkedCategory?.map((value: any) => {
                          return (
                            <>
                              <div
                                className="cursor-pointer text-nowrap p-2 border m-1 rounded-3"
                                onClick={() => handleClick(value)}
                              >
                                {" "}
                                <span> {value.name} </span>
                                <i className="fa fa-close mx-1 text-nowrap" />
                              </div>
                            </>
                          );
                        })}
                        {checkedLevel?.map((value: any) => {
                          return (
                            <>
                              <div
                                className="cursor-pointer text-nowrap p-2 border m-1 rounded-3"
                                onClick={() => handleLevelChange(value)}
                              >
                                {" "}
                                <span> {value.name} </span>
                                <i className="fa fa-close mx-1 text-nowrap" />
                              </div>
                            </>
                          );
                        })}
                        {checkedMode?.map((value: any) => {
                          return (
                            <>
                              <div
                                className="cursor-pointer text-nowrap p-2 border m-1 rounded-3"
                                onClick={() => handleModeChange(value)}
                              >
                                {" "}
                                <span> {value.name} </span>
                                <i className="fa fa-close mx-1 text-nowrap" />
                              </div>
                            </>
                          );
                        })}
                        {checkedLanguage?.map((value: any) => {
                          return (
                            <>
                              <div
                                className="cursor-pointer text-nowrap p-2 border m-1 rounded-3"
                                onClick={() => handleLanguageChange(value)}
                              >
                                {" "}
                                <span> {value.name} </span>
                                <i className="fa fa-close mx-1 text-nowrap" />
                              </div>
                            </>
                          );
                        })}
                        {selectedPrice && (
                          <div
                            className="cursor-pointer text-nowrap p-2 border m-1 rounded-3"
                            onClick={() => handlePriceChange()}
                          >
                            {" "}
                            <span>
                              {" "}
                              {selectedPrice === "FREE" && "Free"}{" "}
                              {selectedPrice === "PAID" && "Paid"}
                            </span>
                            <i className="fa fa-close mx-1 text-nowrap" />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : null}
          {allWorkShopRecord.length === 0 ? (
            <>
              <div className="alert alert-warning mt-4 py-2 px-2" role="alert">
                <i
                  className="fa-sharp fa-solid fa-circle-exclamation mx-2 fs-6 "
                  style={{ color: "#CF4C20" }}
                ></i>
                No upcoming Workshops as of now, we will soon be coming with
                one!
              </div>
            </>
          ) : (
            <div className="row gx-3 gy-3">
              {allWorkShopRecord
                .filter(
                  (value: any) => new Date(value.endDatetime) > new Date(),
                )
                .map((value: any) => {
                  const date = moment
                    .utc(value.startDatetime)
                    .local()
                    .format("YYYY-MM-DD HH:mm:ss");
                  const formattedDate = moment
                    .utc(value.startDatetime)
                    .format("ddd, DD MMM YYYY • hh:mm A");
                  return (
                    <>
                      <div className="col-12 col-md-6 col-lg-4">
                        <div className="recomanded-courses h-100">
                          <div
                            className="card p-3 course-card round overflow-hidden border-muted h-100 cursor-pointer fixed-height-card"
                            onClick={() => handleOpen(value.slug)}
                          >
                            <div className="row d-flex gy-4 gy-lg-3">
                              <div className="col-xl-12 col-lg-12 col-12">
                                <div className="card-img image position-relative overflow-hidden">
                                  {value?.images?.length > 0 ? (
                                    <>
                                      {value?.images?.map((value: any) => {
                                        return (
                                          <>
                                            <img
                                              alt=""
                                              src={value.original}
                                              className="card-img-top  image img-fluid round bg-light"
                                            />
                                          </>
                                        );
                                      })}
                                    </>
                                  ) : (
                                    <img
                                      alt=""
                                      src={toAbsoluteUrl("/media/course/1.jpg")}
                                      className=" card-img-top  image img-fluid round bg-light"
                                    />
                                  )}
                                  {currentAuthToken !== null && (
                                    <div
                                      className="course-tag cursor-pointer"
                                      onClick={() => {
                                        handleWishList(value.id);
                                      }}
                                    >
                                      <div className="course-badge text-dark p-2 py-1 rounded-1">
                                        <i className="fa fa-bookmark tag fs-6"></i>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="col-xl-12 col-lg-12 col-12">
                                <div className="d-flex justify-content-between mb-2 justify-content-lg-between">
                                  <span className="btn btn-outline-success course-btn rounded-pill px-3 py-1 text-dark">
                                    {value.courseCategory.name
                                      ? value.courseCategory.name
                                      : "Technical"}
                                  </span>
                                  <div className="align-items-center border border-orange course_difficulty d-flex justify-content-center px-2 rounded-pill text-center">
                                    <i
                                      className={`mt-0 icon__course-difficulty difficulty-${value.courseLevel.name} position-relative`}
                                      data-bs-toggle="tooltip"
                                      data-bs-placement="top"
                                      data-bs-html="true"
                                      title={`Course Defficulty : ${value.courseLevel.name}`}
                                    >
                                      <span
                                        className={` bar-1 ${
                                          value.courseLevel.name ===
                                            "Advanced" ||
                                          value.courseLevel.name ===
                                            "Intermediate" ||
                                          value.courseLevel.name === "Beginner"
                                            ? "opacity-100"
                                            : "opacity-25"
                                        } `}
                                      ></span>
                                      <span
                                        className={` bar-2 ${
                                          value.courseLevel.name ===
                                            "Advanced" ||
                                          value.courseLevel.name ===
                                            "Intermediate"
                                            ? "opacity-100"
                                            : "opacity-25"
                                        } `}
                                      ></span>
                                      <span
                                        className={` bar-3 ${
                                          value.courseLevel.name === "Advanced"
                                            ? "opacity-100"
                                            : "opacity-25"
                                        } `}
                                      ></span>
                                    </i>
                                  </div>
                                </div>
                                <div
                                  className="card-title mt-2 fw-bold head1 cursor-pointer"
                                  onClick={() => handleOpen(value.slug)}
                                >
                                  {value.name ?? ""}
                                </div>
                                <div className="text-muted">
                                  {value?.mentors?.map(
                                    (mentor: any, index: any) => (
                                      <React.Fragment key={index}>
                                        <a
                                          className="text-decoration-none text-muted-dark"
                                          href={`/mentor-view/${mentor.mentor.id}`}
                                        >
                                          {index > 0 ? (
                                            <>and&nbsp;</>
                                          ) : (
                                            <span>By&nbsp;</span>
                                          )}
                                          {mentor.mentor.firstName ?? ""}{" "}
                                          {mentor.mentor.lastName ?? ""}
                                          {index < value.mentors.length - 1 ? (
                                            <></>
                                          ) : null}
                                        </a>
                                      </React.Fragment>
                                    ),
                                  )}

                                  {value.startDatetime === null ? (
                                    ""
                                  ) : (
                                    <>
                                      <div className="my-2 d-flex text-muted-dark">
                                        <img
                                          src="../media/icons/calendar-event-fill.svg"
                                          alt=""
                                          className="fs-5 me-1"
                                        />
                                        {formattedDate}
                                      </div>
                                    </>
                                  )}
                                  <div className="mt-2  text-muted-dark pb-2">
                                    {value.address && (
                                      <>
                                        <i className="fa-solid fa-location-dot fs-5 me-1"></i>{" "}
                                        {value.address}
                                      </>
                                    )}
                                  </div>
                                  <div className="text-muted-dark">
                                    <HiMiniComputerDesktop className="fs-5 me-1" />
                                    {value.courseMode?.name ?? ""}
                                  </div>
                                </div>
                                <div className="course-price py-1">
                                  <div className="price">
                                    {value.amount === 0 &&
                                      value.sellPrice === 0 && (
                                        <>
                                          <span className="course-free">
                                            Free
                                          </span>
                                        </>
                                      )}
                                    {value.sellPrice !== 0 ? (
                                      <>
                                        <span className="amount">
                                          <i className="fa-solid fa-indian-rupee-sign"></i>
                                          <span className="">
                                            {value.sellPrice}
                                          </span>
                                        </span>
                                        <span className="ms-2 sell text-decoration-line-through">
                                          <i className="fa-solid fa-indian-rupee-sign"></i>
                                          <span className="">
                                            {value.amount}
                                          </span>
                                        </span>
                                      </>
                                    ) : value.amount !== 0 ? (
                                      <>
                                        <span className="amount">
                                          <i className="fa-solid fa-indian-rupee-sign"></i>
                                          <span className="">
                                            {value.amount}
                                          </span>
                                        </span>
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <div className="card  course-card border-1 mx-2 d-lg-none d-md-none d-block h-100 fixed-height-card">
                      <div className="card-body">
                        <div className="row">
                          <div className=" col-xl-4 col-lg-4 col-4 topSelling">
                            <div className="card-img image position-relative overflow-hidden">
                              {value?.images?.length > 0 ? (
                                <>
                                  {value?.images?.map((value: any) => {
                                    return (
                                      <>
                                        <img
                                          alt=""
                                          src={value.original}
                                          className="card-img-top  image img-fluid rounded bg-light"
                                        />
                                      </>
                                    );
                                  })}
                                </>
                              ) : (
                                <img
                                  alt=""
                                  src={toAbsoluteUrl("/media/course/1.jpg")}
                                  className=" card-img-top  image img-fluid rounded bg-light"
                                />
                              )}

                              <div className="course-tag cursor-pointer">
                                <div className="course-badge text-dark p-2 py-1 rounded-1">
                                  <i className="fa fa-bookmark tag fs-6"></i>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-8 col-lg-8 col-8 ">
                            <h6 className="card-title mt-2 fw-bold text-muted cursor-pointer"
                              onClick={() => handleOpen(value.slug)}
                            >
                              {value.name}
                            </h6>
                            <p className="text-muted">
                              {value?.mentors?.length > 0 ? (
                                <>
                                  {value?.mentors?.map((value: any) => {
                                    return (
                                      <>
                                        By <span>{value.mentor.firstName ?? ""}</span>
                                      </>
                                    );
                                  })}
                                </>
                              ) : (
                                null
                              )}
                              <div className="mt-1">
                                <MdDateRange className="fs-5 me-1" />
                                {moment(date)
                                  .format("dddd")
                                  .substring(0, 3) +
                                  ", " +
                                  moment(date)

                                    .format("DD") +
                                  " " +
                                  moment(date)
                                    .format("MMM")
                                    .substring(0, 3) +
                                  " " +
                                  moment(date)

                                    .format("YYYY") +
                                  " • " +
                                  moment(date)

                                    .format("HH:mm")}
                              </div>
                              <br />
                              <span>
                                <HiMiniComputerDesktop />{" "}
                                <span className="ms-2">
                                  {_.capitalize(_.lowerCase(value.costType))}{" "}
                                </span>
                              </span>
                            </p>
                            <div className="mt-2 fw-bolder align-items-center">
                              {value.sellPrice !== 0 ? (<>
                                <i className="fa fa-indian-rupee"></i>
                                <span className="fs-4">{value.sellPrice}</span>
                              </>) : (<div className="mt-2 fw-bolder align-items-center">  <span className="fs-4">Free</span></div>)}
                              {value.amount !== 0 ? (<>
                                <del className="text-muted fs-6 ms-2">
                                  <i className="fa fa-indian-rupee"></i>
                                  <span className="fs-6">{value.amount}</span>
                                </del></>) : (null)}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}
                    </>
                  );
                })}
            </div>
          )}
        </>
      )}
    </section>
  );
}

export default AllWorkshops;
