const AUTH_LOCAL_STORAGE_KEY = "auth-react-v";

export const getUserData = () => {
  const lsValue: string | null = localStorage
    ? localStorage.getItem(`${AUTH_LOCAL_STORAGE_KEY}`)
    : null;
  let user: any;
  let authToken = null;
  if (lsValue) {
    user = JSON.parse(lsValue);
    authToken = user && user.access_token;
  }
  return { user, authToken };
};
