import { toAbsoluteUrl } from 'assets/helpers/AssetHelpers';
import React from 'react';
import { Image } from 'react-bootstrap';

const Loading = ({ height, width }: { height?: string; width?: string | number }) => {
  return (
    <div className="d-flex justify-content-center align-items-center" style={{ height }}>
      <Image src={toAbsoluteUrl('/media/svg/misc/infinite-spinner.svg')} width={width ?? 250} />
    </div>
  );
};

export default Loading;
