import { toAbsoluteUrl } from 'assets/helpers/AssetHelpers';
import React from 'react';
import { ButtonBorderType, ButtonType } from 'components/_v2/CommonComponents/types';
import { COURSE_URL } from 'pages/routing/routePages';
import Button from 'components/_v2/CommonComponents/Button';
import { useNavigate } from 'react-router-dom';

const NoCourses = () => {
	const navigate = useNavigate();
	return (
		<section
			className="d-flex align-items-center justify-content-center"
			style={{ height: '70vh' }}>
			<div className="container">
				<div className="row">
					<div className="col-lg-12">
						<div className="d-flex justify-content-center align-items-center">
							<img
								className="smallImageWidth"
								src={toAbsoluteUrl('/media/nodata/course-nodata.png')}
								alt="Nodata_Found"
							/>
						</div>
						<div className="text-center">
							<h2 className="nodata-title lora">No Courses Here</h2>
							<p className="nodata-label">We will notify you once you have purchased a course.</p>
							<Button
								buttonText="Explore Courses"
								buttonType={ButtonType.PRIMARY_BUTTON}
								classes="g_mid fs-16"
								handleClick={() => {
									navigate(COURSE_URL);
								}}
								buttonBorderType={ButtonBorderType.BLACK_BORDER}
							/>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default NoCourses;
