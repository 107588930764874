import React from "react";

export const Help = () => {
  return (
    <section className="section">
      <div className="container">
        <div className="row">
          <div className="col-12 text-center mt-4 ">Help center</div>
        </div>
      </div>
    </section>
  );
};
