import "./sidebar.css";
import Learning from "../MyLearnings/Learning";
import Bookmark from "../../Bookmark/Bookmark";
import OrderPaymentDetails from "../../order-Payment/OrderPaymentDetails";
import { useAuth } from "../../../pages/auth/core/Auth";
const Sidebar = (props: any) => {
  const {
    profile,
    learning,
    bookmark,
    order,
    handleLearning,
    handleBookmark,
    handleorder,
    handleLogout,
    handleProfile,
  } = props;
  useAuth();
  return (
    <>
      <div className="col-lg-3 mx-auto">
        <div className="card border-muted">
          <div className="text-center mt-3 mt-lg-5">
            <img
              className="img-fluid avatar-lg rounded-circle"
              src={
                profile &&
                profile?.userProfile &&
                profile?.userProfile[0] &&
                profile?.userProfile[0]?.imageUrl
                  ? profile?.userProfile[0]?.imageUrl
                  : "../media/mentor/profile_image.jpg"
              }
              alt=""
            />
          </div>
          <div className="text-center">
            <div className="mt-2 title fs-3">
              {profile?.firstName} {profile?.lastName}
            </div>
            <p className="pb-0 mb-0 text-muted">{profile?.email}</p>
          </div>
          <div className="me-2 mt-4">
            <div
              className="d-flex justify-content-between d-block text-decoration-none p-2 mt-2 text-hover-primary-dark cursor-pointer"
              onClick={handleLearning}
            >
              <div>
                {" "}
                <i className="fa-solid fa-book-open px-2"></i> My Learnings
              </div>
              <i className="fa-solid fa-chevron-right mt-2"></i>
            </div>
            <div
              className="d-flex justify-content-between d-block text-decoration-none p-2 mt-2 text-hover-primary-dark cursor-pointer"
              onClick={handleBookmark}
            >
              <div>
                <i className="fa-solid fa-bookmark px-2"></i>Bookmarks
              </div>
              <i className="fa-solid fa-chevron-right mt-2"></i>
            </div>
            <div
              className="d-flex justify-content-between d-block text-decoration-none p-2 mt-2 text-hover-primary-dark cursor-pointer"
              onClick={handleProfile}
            >
              <div>
                <i className="fa-solid fa-user px-2"></i>My Profile
              </div>
              <i className="fa-solid fa-chevron-right mt-2"></i>
            </div>
            <hr className="mx-2" />
            <div
              className="d-flex justify-content-between d-block text-decoration-none p-2 mt-4 text-hover-primary-dark cursor-pointer"
              onClick={handleorder}
            >
              <div>
                <i className="fa-solid fa-clock-rotate-left px-2"></i>Orders &
                Payments
              </div>
              <i className="fa-solid fa-chevron-right mt-2"></i>
            </div>
            {/* <div className="d-flex justify-content-between d-block d-none text-decoration-none p-2 mt-2 text-hover-primary-dark cursor-pointer">
                            <div onClick={() => Gst()}><i className="fa-solid fa-database px-2"></i>GST Details</div>
                            <i className="fa-solid fa-chevron-right mt-2"></i>
                        </div> */}

            <hr className="mx-2" />
            {/* <hr/> */}
            {/* <div className="d-flex justify-content-between d-block  d-none text-decoration-none p-2 mt-4 text-hover-primary-dark cursor-pointer">
                            <div onClick={() => Help()} ><i className="fa-solid fa-circle-question px-2"></i>Help Centre</div>
                            <i className="fa-solid fa-chevron-right mt-2"></i>
                        </div> */}
            <div
              onClick={handleLogout}
              className="d-flex justify-content-between d-block text-decoration-none p-2 my-2 text-hover-primary-dark cursor-pointer"
            >
              <div>
                <i className="fa-solid fa-right-from-bracket px-2"></i>Log Out
              </div>
              <i className="fa-solid fa-chevron-right mt-2"></i>
            </div>
          </div>
        </div>
      </div>

      {/* {profilestate === true && (
                <div className="col-lg-9 mt-5">
                    <Profile profile={profileDetail} />
                </div>
            )} */}
      {learning && (
        <div className="col-lg-9 mt-5">
          <Learning />
        </div>
      )}
      {order && (
        <div className="col-lg-9 mt-5">
          <OrderPaymentDetails />
        </div>
      )}
      {bookmark && (
        <div className="col-lg-9 mt-5">
          <Bookmark />
        </div>
      )}
      {/* {(learning || order  || bookmark) ? "": (
                <div className="col-lg-9 mt-5">
                    <PersonalDetailsEdit />
                </div>
            )} */}
    </>
  );
};

export default Sidebar;
