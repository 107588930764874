import React from "react";
import { useNavigate } from "react-router-dom";
// import './course.css'
// import './global.css'

const WebinarLiveHeader = (props: any) => {
  const { course } = props;
  const navigate = useNavigate();
  return (
    <>
      <section className="bg-color3 course-sections d-lg-block d-md-block d-none">
        <div className="container px-lg-0 py-lg-0">
          <div className="row mx-lg-5 align-items-center">
            <div className="col-12 col-lg-10 border-end border-light border-3">
              <div className="py-lg-3">
                <div className="font-title fw-bold fs-4">{course?.name}</div>
              </div>
            </div>
            <div className="col-12 col-lg-2 border-end border-light border-3">
              <div className="px-lg-1 py-lg-3 d-flex align-items-center">
                {course &&
                course?.mentors &&
                course?.mentors[0] &&
                course?.mentors[0]?.mentor &&
                course?.mentors[0]?.mentor?.userProfile &&
                course?.mentors[0]?.mentor?.userProfile[0] &&
                course?.mentors[0]?.mentor?.userProfile[0]?.imageUrl ===
                  null ? (
                  <img
                    src="../media/preRecord/mentor.png"
                    alt="mentor.png"
                    height="60"
                    width="60"
                    className="img-fluid"
                  />
                ) : (
                  <div className="w-30 text-center">
                    <img
                      src={
                        course &&
                        course?.mentors &&
                        course?.mentors[0] &&
                        course?.mentors[0]?.mentor &&
                        course?.mentors[0]?.mentor?.userProfile &&
                        course?.mentors[0]?.mentor?.userProfile[0] &&
                        course?.mentors[0]?.mentor?.userProfile[0]?.imageUrl
                      }
                      alt="mentor.png"
                      height="60"
                      width="60"
                      className="img-fluid rounded-3"
                    />
                  </div>
                )}
                <div
                  className="w-70"
                  onClick={() => {
                    navigate(`/mentor-view/${course?.mentors[0]?.mentor.id}`);
                  }}
                >
                  <div className="color1 font-switzer fs-5 fw-medium">
                    {course &&
                      course?.mentors &&
                      course?.mentors[0] &&
                      course?.mentors[0]?.mentor &&
                      course?.mentors[0]?.mentor?.firstName}{" "}
                    {""}{" "}
                    {course &&
                      course?.mentors &&
                      course?.mentors[0] &&
                      course?.mentors[0]?.mentor &&
                      course?.mentors[0]?.mentor?.lastName}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/*
-------------------------------
 Responsive Mobile View Code 
 ---------------------------
 */}

      <section className="bg-color3 course-sections d-lg-none d-md-none d-block">
        <div className="container">
          <div className="row">
            <div className="col-12 border-end border-light border-3">
              <div className="py-3 text-center">
                <div className="font-title fw-bold fs-4">{course?.name}</div>
              </div>
            </div>
            <div className="col-12 border-end border-light border-3">
              <div className="px-4 py-3 d-flex align-items-center">
                {course &&
                course?.mentors &&
                course?.mentors[0] &&
                course?.mentors[0]?.mentor &&
                course?.mentors[0]?.mentor?.userProfile &&
                course?.mentors[0]?.mentor?.userProfile[0] &&
                course?.mentors[0]?.mentor?.userProfile[0]?.imageUrl ===
                  null ? (
                  <img
                    src="../media/preRecord/mentor.png"
                    alt="mentor.png"
                    height="60"
                    width="60"
                    className="img-fluid"
                  />
                ) : (
                  <div className="me-3">
                    <img
                      src={
                        course &&
                        course?.mentors &&
                        course?.mentors[0] &&
                        course?.mentors[0]?.mentor &&
                        course?.mentors[0]?.mentor?.userProfile &&
                        course?.mentors[0]?.mentor?.userProfile[0] &&
                        course?.mentors[0]?.mentor?.userProfile[0]?.imageUrl
                      }
                      alt="mentor.png"
                      height="60"
                      width="60"
                      className="img-fluid rounded-3"
                    />
                  </div>
                )}
                <div
                  className="w-70"
                  onClick={() => {
                    navigate(`/mentor-view/${course?.mentors[0]?.mentor.id}`);
                  }}
                >
                  <div className="color1 font-switzer fs-5 fw-medium">
                    {course &&
                      course?.mentors &&
                      course?.mentors[0] &&
                      course?.mentors[0]?.mentor &&
                      course?.mentors[0]?.mentor?.firstName}{" "}
                    {""}{" "}
                    {course &&
                      course?.mentors &&
                      course?.mentors[0] &&
                      course?.mentors[0]?.mentor &&
                      course?.mentors[0]?.mentor?.lastName}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default WebinarLiveHeader;
