import React, { createContext, useContext, useState, ReactNode } from 'react';

interface ZoomMeetingContextType {
	isZoomVisited: boolean;
	setIsZoomVisited: (visited: boolean) => void;
}

const ZoomMeetingContext = createContext<ZoomMeetingContextType | undefined>(undefined);

export const ZoomMeetingProvider = ({ children }: { children: ReactNode }) => {
	const [isZoomVisited, setIsZoomVisited] = useState<boolean>(false);

	return (
		<ZoomMeetingContext.Provider value={{ isZoomVisited, setIsZoomVisited }}>
			{children}
		</ZoomMeetingContext.Provider>
	);
};

export const useZoomMeeting = () => {
	const context = useContext(ZoomMeetingContext);
	if (!context) {
		throw new Error('useZoomMeeting must be used within a ZoomMeetingProvider');
	}
	return context;
};
