import { Mentor } from 'components/_v2/Course/type';
import { toAbsoluteUrl } from 'assets/helpers/AssetHelpers';
import React from 'react';
import Stars from 'components/_v2/CommonComponents/SubmitStarRatings/Stars';

export const mentorNamesRenderer = (mentors: { mentor: Mentor }[]) =>
	mentors.map((mentorDetails, index) => {
		let textToRender = mentorDetails.mentor.firstName + ' ' + mentorDetails.mentor.lastName;
		if (index < mentors.length - 1) {
			textToRender += ', ';
		}
		return textToRender;
	});

export const mentorProfileNameRenderer = (
	mentors?: { mentor: Mentor }[],
	isSmallScreen = false,
) => {
	if (!mentors) {
		return;
	}
	return mentors.map((mentor, index) => (
		<div
			key={mentor.mentor.id + index}
			className={isSmallScreen ? 'd-flex d-md-none w-100' : 'd-none d-sm-flex'}>
			<div>
				<img
					src={toAbsoluteUrl(
						mentor.mentor.userProfile?.[0]?.imageUrl ?? '/media/detail-hero-section/detail-dp.png',
					)}
					style={{ borderRadius: '50%' }}
					className="me-2"
					alt="Mentor "
					height="48px"
					width="48px"
				/>
			</div>
			<div className="">
				<span className="profile-mentor">Mentor</span> <br />
				<span className="profile-mentro-name g_mid">{mentor.mentor.firstName}</span>
			</div>
		</div>
	));
};

export const reviewCardRenderer = ({
	key,
	name,
	ratings,
	desc,
	isOnlySingleTestimonial,
}: {
	key: string | number;
	name: string;
	ratings: number;
	desc: string;
	isOnlySingleTestimonial: boolean;
}) => {
	return (
		<div className={`${isOnlySingleTestimonial ? 'col-xxl-12' : 'col-xxl-6'} col-lg-12`} key={key}>
			<div className="card blackBorder">
				<div className="card-body">
					<div className="testimonials-icon d-flex align-items-center">
						<div>
							<div className="testimonial-profile-name g_mid">{name}</div>
							<div className="d-flex">
								{Array.from(Array(5)).map((_, index) => (
									<Stars
										key={index}
										index={index}
										onClickHandler={() => null}
										onMouseEnter={() => null}
										onMouseLeave={() => null}
										ratings={ratings}
									/>
								))}
							</div>
						</div>
					</div>
					<div className="testimonials-main-desc g_reg">{desc}</div>
				</div>
			</div>
		</div>
	);
};
