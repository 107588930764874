import React, { FC, useEffect, useRef, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { FcGoogle } from 'react-icons/fc';
import { Verification } from './Verification';
import * as Yup from 'yup';
import { useGoogleLogin } from '@react-oauth/google';
import { Formik } from 'formik';
import axios from 'axios';
import clsx from 'clsx';
import { STUDENTLOGINWITHSOCIALMEDIA, NEW_STUDENT_REGISTER } from './core/_requests';
import toast from 'react-hot-toast';
import { gql, useMutation, useSubscription } from '@apollo/client';
import './registration.css';
import { useUserContext } from 'context/UserContext';
import { AUTH_LOCAL_STORAGE_KEY } from './core/AuthHelpers';
import Loading from 'components/_v2/CommonComponents/Loading';
import Button from 'components/_v2/CommonComponents/Button';
import { ButtonType } from 'components/_v2/CommonComponents/types';
import { ErrorMessage, Field } from 'formik';
import CommonPasswordInput from 'components/_v2/CommonComponents/CommonPasswordInput';

type Props = {
	openRegister: any;
	handleRegisterClose: () => void;
	setOpenRegister: any;
	toggleLoginOpen: () => void;
	toggleRegisterOpen: () => void;
};

const initialValues = {
	firstName: '',
	lastName: '',
	email: '',
	password: '',
	confirmPassword: '',
	mobileNo: '',
	mobileNoStdCode: '',
};

// eslint-disable-next-line
const phoneRegExp = /^(?:(?:\+|0{0,2})91(\s*|[\-])?|[0]?)?([6789]\d{2}([ -]?)\d{3}([ -]?)\d{4})$/;
// eslint-disable-next-line
const passwordRegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,20}$/;

const registrationSchema = Yup.object().shape({
	firstName: Yup.string()
		.min(3, 'Minimum 3 symbols')
		.max(50, 'Maximum 50 symbols')
		.matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field ')
		.required('Please enter your full name'),
	email: Yup.string()
		.email('Please enter valid email address')
		.min(3, 'Minimum 3 symbols')
		.max(50, 'Maximum 50 symbols')
		.required('Please enter your email address'),
	// lastName: Yup.string()
	//   .min(3, "Minimum 3 symbols")
	//   .max(50, "Maximum 50 symbols")
	//   .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ")
	//   .required("Last name is required"),
	password: Yup.string()
		.required('Please create a password')
		.matches(
			passwordRegExp,
			'Password must include at least 8 character, one special character, one digit, one uppercase and one lowercase letter',
		),
	confirmPassword: Yup.string()
		.required('Please confirm your password')
		.oneOf([Yup.ref('password')], 'Both passwords do not match'),
	// mobileNo: Yup.string()
	//   .required("Mobile number is required")
	//   .matches(phoneRegExp, "Mobile number is not valid"),
});

export const Register: FC<Props> = ({
	openRegister,
	handleRegisterClose,
	setOpenRegister,
	toggleLoginOpen,
	toggleRegisterOpen,
}) => {
	const formikRef = useRef(null);
	const [openVerification, setOpenVerification] = useState(false);
	const { refetchLocalStorageTokenDetails, setUser } = useUserContext();
	const [studentSignInWithSocialAccount] = useMutation(STUDENTLOGINWITHSOCIALMEDIA);
	const handleVerificationClose = () => {
		setOpenVerification(false);
	};
	const [loading, setLoading] = useState(false);
	const [studentRegister] = useMutation(NEW_STUDENT_REGISTER);

	const loginGoogle = useGoogleLogin({
		onSuccess: async (codeResponse: any) => {
			try {
				const userInfoResponse = await axios.get(
					`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${codeResponse.access_token}`,
					{
						headers: {
							Authorization: `Bearer ${codeResponse.access_token}`,
							Accept: 'application/json',
						},
					},
				);
				await studentSignInWithSocialAccount({
					variables: {
						email: userInfoResponse.data.email,
						name: userInfoResponse.data.name,
						signinType: 'GOOGLE',
					},
					onCompleted: (data: any) => {
						localStorage.setItem(
							AUTH_LOCAL_STORAGE_KEY,
							JSON.stringify(data?.studentSignInWithSocialAccount),
						);
						refetchLocalStorageTokenDetails();
						toast.success('Successfully signed in with the correct credentials.');
					},
					onError: (err: any) => {
						toast.error(err.message);
						throw new Error('Sign-in failed');
					},
				});
				setUser(codeResponse);

				handleRegisterClose1();
			} catch (error) {
				toast.error('Error', error);
			}
		},
		onError: (error: any) => {
			toast.error(error.message);
			toast.error('Please select a valid account for Google sign-in.');
		},
	});

	const handleRegisterClose1 = () => {
		setOpenRegister(false);
	};
	// useEffect(() => {
	//   if (user.length !== 0) {
	//     axios
	//       .get(
	//         `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`,
	//         {
	//           headers: {
	//             Authorization: `Bearer ${user.access_token}`,
	//             Accept: "application/json",
	//           },
	//         }
	//       )
	//       .then(async (res: any) => {
	//         const googleLogin = await studentSignInWithSocialAccount({
	//           variables: {
	//             email: res.data.email,
	//             name: res.data.name,
	//             signinType: "GOOGLE"
	//           }
	//         }
	//         );
	//         if (googleLogin) {
	//           if (googleLogin.data.data) {
	//             const loggedInUserData = googleLogin?.data?.data?.studentSignInWithSocialAccount;
	//             if (loggedInUserData?.access_token !== "") {
	//               saveAuth(loggedInUserData);
	//               setCurrentUser(loggedInUserData?.user);
	//               setOpenRegister(false);
	//               toast.success("Google Registraion Successfully. You can explore all courses now....", {
	//                 style: {
	//                   padding: "16px",
	//                 },
	//               });
	//             } else {
	//               setStatus(loggedInUserData?.data?.errors[0].message);
	//               if (loggedInUserData?.access_token === "") {
	//                 //   toast.error(loggedInUserData.message, {
	//                 //     style: {
	//                 //       padding: "16px",
	//                 //     },
	//                 //   });
	//               }
	//             }
	//           } else {
	//             setStatus(googleLogin.data.errors[0].message);
	//           }
	//         }
	//       })
	//       .catch((err: any) => console.log(err));
	//   }
	//   // eslint-disable-next-line
	// }, [user]);

	const handleRegistration = async (values, { setSubmitting }) => {
		setLoading(true);
		try {
			const { data } = await studentRegister({
				variables: {
					email: values.email,
					password: values.password,
					confirmPassword: values.confirmPassword,
					name: values.firstName,
				},
			});
			if (data?.newStudentRegister?.is_success) {
				localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, JSON.stringify(data.newStudentRegister));
				refetchLocalStorageTokenDetails();
				setLoading(false);
				setOpenVerification(true);
			} else {
				toast.error(data?.newStudentRegister?.message);
				setSubmitting(false);
				setLoading(false);
			}
		} catch (error: any) {
			toast.error(error.message);
			setLoading(false);
		}
	};

	const handleResendOTP = async () => {
		try {
			if (formikRef.current) {
				const { values } = formikRef.current;
				const { data } = await studentRegister({
					variables: {
						email: values.email,
						password: values.password,
						confirmPassword: values.confirmPassword,
						name: values.firstName,
					},
					onCompleted: (data) => {
						localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, JSON.stringify(data.newStudentRegister));
					},
				});

				if (data?.newStudentRegister) {
					toast.success('OTP has been resent.');
				}
			}
		} catch (error) {
			toast.error('Failed to resend OTP. Please try again.');
		}
	};

	return (
		<>
			{openRegister === true && (
				<>
					<Modal centered show={openRegister} onHide={handleRegisterClose1}>
						<Modal.Header className="header-top-layout">
							<Modal.Title className="border-0">
								<div className="row">
									<div className="col-md-12 lora login-title">Sign Up</div>
									<div className="login-new-label g_reg">
										Already have an Account? &nbsp;
										<span
											className="login-new-label-span g_reg text-decoration-none cursor-pointer"
											onClick={() => {
												toggleLoginOpen();
											}}>
											Sign In
										</span>
									</div>
								</div>
							</Modal.Title>
							<div
								className="btn btn-icon btn-sm btn-active-icon-primary close-btn-style g_reg"
								onClick={handleRegisterClose1}>
								<i className="fa fa-close"></i>
							</div>
						</Modal.Header>
						<Modal.Body className="body-layout">
							<Formik
								initialValues={initialValues}
								validationSchema={registrationSchema}
								onSubmit={handleRegistration}
								innerRef={formikRef}>
								{({ status, isSubmitting, isValid, touched, errors, handleSubmit }) => (
									<div>
										<div className="row">
											{status && (
												<div className="col-12 mb-3">
													<div className="mb-lg-15 alert alert-danger">
														<div className="alert-text font-weight-bold">{status}</div>
													</div>
												</div>
											)}

											<div className="col-12">
												<label className="label-style g_reg">
													Full Name <span style={{ color: 'red' }}>*</span>
												</label>
												<Field
													name="firstName"
													type="text"
													placeholder="Enter Full Name"
													autoComplete="off"
													className={`form-control input-box-style g_reg w-100 ${
														!touched.firstName
															? ''
															: touched.firstName && errors.firstName
															? 'is-invalid'
															: 'is-valid'
													}`}
												/>
												<ErrorMessage name="firstName">
													{(msg) => (
														<div className="text-danger fs-7 mt-2">
															<i className="fa fa-exclamation-circle me-1"></i> {msg}
														</div>
													)}
												</ErrorMessage>
											</div>

											<div className="col-12 mt-3">
												<label className="label-style g_reg">
													Email <span style={{ color: 'red' }}>*</span>
												</label>
												<Field
													name="email"
													placeholder="Enter Email Address"
													type="email"
													autoComplete="off"
													className={`form-control input-box-style g_reg w-100 ${
														!touched.email
															? ''
															: touched.email && errors.email
															? 'is-invalid'
															: 'is-valid'
													}`}
												/>
												<ErrorMessage name="email">
													{(msg) => (
														<div className="text-danger fs-7 mt-2">
															<i className="fa fa-exclamation-circle me-1"></i> {msg}
														</div>
													)}
												</ErrorMessage>
											</div>

											<div className="col-12 mt-3">
												<CommonPasswordInput
													name="password"
													disabled={false}
													placeholder="Enter Your Password"
													label="Password"
													showValidOrInvalidOutlineClass={
														!touched.password
															? ''
															: touched.password && errors.password
															? 'is-invalid'
															: 'is-valid'
													}
													handleSubmit={handleSubmit}
												/>
											</div>

											<div className="col-12 mt-3">
												<CommonPasswordInput
													name="confirmPassword"
													disabled={false}
													label="Confirm Password"
													placeholder="Confirm Your password"
													showValidOrInvalidOutlineClass={
														!touched.confirmPassword
															? ''
															: touched.confirmPassword && errors.confirmPassword
															? 'is-invalid'
															: 'is-valid'
													}
													handleSubmit={handleSubmit}
												/>
											</div>

											<div className="col-12 btn-registyle">
												<Button
													buttonText={
														<>
															{!loading && <span className="g_mid">Continue</span>}
															{loading && (
																<span className="d-flex w-100 justify-content-center gap-2">
																	<Loading width={50} />
																	Please wait...
																</span>
															)}
														</>
													}
													buttonType={ButtonType.PRIMARY_BUTTON}
													classes="w-100 mb-3 g_mid fs-16"
													disabled={isSubmitting || !isValid}
													handleClick={handleSubmit}
												/>
											</div>

											<div className="divider">Or</div>

											<div className="col-12 mt-2">
												<button
													type="button"
													className="btn cwg-regi-style w-100 g_mid"
													onClick={() => loginGoogle()}>
													<FcGoogle className="me-2 fs-2" />
													Continue with Google
												</button>
											</div>
										</div>
									</div>
								)}
							</Formik>
						</Modal.Body>
					</Modal>
				</>
			)}
			{openVerification && (
				<Verification
					handleRegisterClose1={handleRegisterClose1}
					openVerification={openVerification}
					handleVerificationClose={handleVerificationClose}
					toggleLoginOpen={toggleLoginOpen}
					toggleRegisterOpen={toggleRegisterOpen}
					email={formikRef.current.email}
					handleResendOTP={handleResendOTP}
				/>
			)}
		</>
	);
};
