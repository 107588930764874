import { useLocation, useNavigate } from "react-router-dom";
import DetailBanner from "./DetailBanner";
import { AboutMentor } from "./AboutMentor";
import { CoursesOffered } from "./CoursesOffered";
import Recommendation from "./Recommendation";
import { GETMENTORDETAIL } from "../../core/requests";
import { useQuery } from "@apollo/client";
import Loader from "../../../common/Spinner/Spinner";

export const MentorProfile = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const mentorId = location.pathname.split("/").reverse()[0];

  if (!mentorId) {
    navigate("/apps/mentors");
  }
  const { data: mentorRecord, loading } = useQuery(GETMENTORDETAIL, {
    variables: {
      id: mentorId ?? "",
    },
  });
  const mentor: any = mentorRecord?.getPublicInstructor ?? [];

  return (
    <>
      <div className="px-xl-4 px-lg-3 px-2">
        <DetailBanner profile={mentor} />
        {loading ? (
          <div>
            <Loader />
          </div>
        ) : (
          <>
            <AboutMentor mentor={mentor} />
            <CoursesOffered mentor={mentor} />
            <Recommendation />
          </>
        )}
      </div>
    </>
  );
};

export default MentorProfile;
