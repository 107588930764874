import { gql } from "@apollo/client";
import { graphql } from "babel-plugin-relay/macro";
export const GETWISHLISTS = gql`
  query requests_getWishlistsQuery($paginate: PaginatorArgs) {
    getWishlists(paginate: $paginate) {
      records {
        userId
        courseId
        courseDetails {
          id
          name
          slug
          images {
            original
          }
          mentors {
            mentor {
              id
              firstName
            }
          }
          courseMode {
            id
            name
          }
          courseCategory {
            id
            name
          }
          courseLanguages {
            id
            name
          }
          courseLevel {
            id
            name
          }
          startDatetime
          costType
          sellPrice
          amount
          address
        }
      }
      pagination {
        totalRecords
        currentPage
      }
    }
  }
`;

export const REMOVEFROMWISHLIST = gql`
  mutation requests_removeFromWishlistMutation($courseId: String!) {
    removeFromWishlist(courseId: $courseId)
  }
`;
