import React, { useEffect } from 'react';
import './allcourse.css';
import OurCourse from 'components/_v2/Course/OurCourse';
import { useCourseContext } from 'context/CourseContext';
import useCourses from 'hooks/useCourses';
import TopTitleSection from '../CommonComponents/TopTitleSection';
import { useLocation } from 'react-router-dom';
import { SelectedTabType } from 'components/_v2/CommonComponents/types';

const AllCourse = () => {
	const { hasValueSet } = useCourseContext();
	const { getCourses, courses, error, loadingPublicCourses } = useCourses();
	const { search } = useLocation();
	const selectedTabType = new URLSearchParams(search).get('courseMode') as SelectedTabType;

	useEffect(() => {
		if (!hasValueSet) {
			getCourses();
		}
	}, [hasValueSet]);

	return (
		<section>
			<div className="container-fluid info-layout">
				<div className="row">
					<TopTitleSection
						title="Courses"
						description="Explore the courses that our learners love the most. Tried,
                tested, and highly recommended for your success."
					/>
				</div>
			</div>
			<OurCourse
				loading={loadingPublicCourses}
				error={error}
				courses={courses}
				selectedTabType={selectedTabType}
			/>
		</section>
	);
};

export default AllCourse;
