import React from 'react';
import './CourseCertificate.css';
import { toAbsoluteUrl } from 'assets/helpers/AssetHelpers';
import useIsSmallScreen from 'hooks/useIsSmallScreen';

const CourseCertificate = ({
	courseName,
	fullWidth = false,
}: {
	courseName: string;
	fullWidth?: boolean;
}) => {
	const isSmallScreen = useIsSmallScreen();
	return (
		<section
			className={fullWidth ? '' : isSmallScreen ? 'pt-3' : 'course-certificate-wrapper px-3'}>
			<div className="container">
				<div className="row px-5">
					<div className={`${fullWidth ? 'col-lg-12' : 'col-lg-10'}`}>
						<div className="row">
							<div className={fullWidth ? 'col-lg-12' : 'col-lg-8'}>
								<div>
									<h2 className="certificate-heading lora">Certificate</h2>
									<p className="certificate-label g_reg">
										Students who successfully complete our course will obtain a HoGrowth certificate
										as confirmation of their learning.
									</p>
									<div className="d-lg-flex align-items-center">
										<div style={{ position: 'relative' }}>
											<img
												src={toAbsoluteUrl('/media/course-certificate-images/certificate.svg')}
												alt="Certificate"
											/>
											<div
												style={{
													position: 'absolute',
													top: '53%',
													left: '50%',
													transform: 'translateX(-50%)',
													textAlign: 'center',
													fontSize: 'small',
													width: '80%',
												}}
												className="britania">
												<span>{courseName}</span>
											</div>
										</div>
										<div className="certificate-desc-layout">
											<div className="main-content-layout">
												<img
													src={toAbsoluteUrl('/media/course-certificate-images/Frame.png')}
													alt="Frame"
												/>
												<h2 className="certificate-name lora">Validate your learning</h2>
												<p className="certificate-expr g_reg">
													Get a formal certificate from your mentor attesting to your completion of
													the course, along with shareable evidence to support your learning.
												</p>
											</div>
											<div className="main-content-layout">
												<img
													src={toAbsoluteUrl('/media/course-certificate-images/Frame.png')}
													alt="Frame"
												/>
												<h2 className="certificate-name lora">Validate your learning</h2>
												<p className="certificate-expr g_reg">
													Get a formal certificate from your mentor attesting to your completion of
													the course, along with shareable evidence to support your learning.
												</p>
											</div>
											<div className="main-content-layout">
												<img
													src={toAbsoluteUrl('/media/course-certificate-images/Frame.png')}
													alt="Frame"
												/>
												<h2 className="certificate-name lora">Validate your learning</h2>
												<p className="certificate-expr g_reg">
													Get a formal certificate from your mentor attesting to your completion of
													the course, along with shareable evidence to support your learning.
												</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default CourseCertificate;
