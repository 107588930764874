import React from "react";
import { GiNetworkBars } from "react-icons/gi";
import { toAbsoluteUrl } from "../../assets/helpers/AssetHelpers";

const CourseCompleteCard = () => {
  return (
    <>
      <div className="col-xxl-3 col-xl-3 col-lg-3 col-6 col-md-3 p-2 topSelling ">
        <div className="card  course-card rounded overflow-hidden border-muted  h-100">
          <div className="card-body p-4">
            <div className="card-img image position-relative overflow-hidden">
              <img
                alt=""
                src="/media/course/1.jpg"
                className=" card-img-top  image img-fluid rounded bg-light"
              />
            </div>
            <div className="d-flex mt-3 justify-content-between">
              <button
                className="btn btn-outline-success course-btn rounded-pill px-3 py-1 text-dark"
                type="button"
              >
                Technical Analysis
              </button>
              <div className="course-bar-btn rounded-pill ms-1 ms-lg-2 ms-md-2 px-1 py-1 text-dark float-end">
                <img src={toAbsoluteUrl("../media/logo/rate.jpg")} alt="" />
              </div>
            </div>
            <div>
              <p className="card-text mt-2 fw-bold head1">
                Pro Options Trader Course-Virtual Edition 6.0
                <p className="fw-normal">By Dharmesh Chauhan</p>
              </p>
            </div>
            <div className="course-head fs-18 font-switzer fw-medium mb-lg-3 text-muted-dark">
              <span className="me-2">
                <i className="fa-solid fa-calendar-day"></i>
              </span>
              <span>Mon, 23 Aug 2023 • 2:00 PM </span>
            </div>
            <div className="course-head fs-18 font-switzer fw-medium mb-lg-3 text-muted-dark">
              <span className="me-2">
                <i className="fa-solid fa-desktop"></i>
              </span>
              <span>Online</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CourseCompleteCard;
