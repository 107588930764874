import React from 'react';
import './LiveCourse.css';
import { toAbsoluteUrl } from 'assets/helpers/AssetHelpers';
import { Course } from '../Course/type';
import { useNavigate } from 'react-router-dom';
import CardInformation from 'components/_v2/CommonComponents/CardInformation';
import { CardHeight } from 'components/_v2/CommonComponents/types';
import { useCourseContext } from 'context/CourseContext';
import { useUserContext } from 'context/UserContext';

const LiveCourse = ({
	courses,
	onExploreOfflineCourses,
}: {
	courses: Course[];
	onExploreOfflineCourses: () => void;
}) => {
	const { userCourses } = useCourseContext();
	const { user } = useUserContext();
	const navigate = useNavigate();
	if (courses.length == 0) {
		return (
			<section
				className="d-flex align-items-center justify-content-center"
				style={{ height: '70vh' }}>
				<div className="">
					<div className="row">
						<div className="col-lg-12">
							<div className="d-flex justify-content-center align-items-center">
								<img
									className="smallImageWidth"
									src={toAbsoluteUrl('/media/nodata/course-nodata.png')}
									alt="Nodata_Found"
								/>
							</div>
							<div className="text-center">
								<h2 className="nodata-title lora">No Courses Here</h2>
								<p className="nodata-label">We Will notify you once we’ve added a Course</p>
								<button
									className="btn btn_goto_course g_mid w-auto"
									onClick={onExploreOfflineCourses}>
									Explore Offline Courses
								</button>
							</div>
						</div>
					</div>
				</div>
			</section>
		);
	}

	return (
		<div className="row d-flex course-card-section-spacing gy-lg-4 mt-0">
			{courses.map((course) => (
				<CardInformation
					course={course}
					disableHoverInteraction={false}
					isUserSpecificContent={false}
					userCourses={userCourses}
					user={user}
					cardHeight={CardHeight.LARGE}
					hasCardOffset
				/>
			))}
		</div>
	);
};

export default LiveCourse;
