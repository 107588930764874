import { BreadcrumbDataProps } from 'types/globalTypes';
import { CourseEnrollmentObjectType, LessonType } from '../Course/type';
import { MY_COURSE_URL } from 'pages/routing/routePages';
import { useCourseContext } from 'context/CourseContext';
import { useLocation, useNavigate } from 'react-router-dom';
import Breadcrumbs from '../Header/Breadcrumbs';
import { useEffect, useMemo, useState } from 'react';
import CourseProgramOverview from '../CourseDetail/CourseContent/CourseProgramOverview';
import { Image } from 'react-bootstrap';
import { toAbsoluteUrl } from 'assets/helpers/AssetHelpers';
import CourseLessonData from './CourseLessonData';
import CourseTabs from './CourseTabs';
import FullScreenComponent from './Fullscreen';
import QuizType from './LessonTypesComponents/QuizType';
import { useQuizContext } from 'context/QuizContext';
import './ViewCourseData.css';
import useQuiz from 'hooks/useQuiz';
import { QuizReattemptDetails } from 'components/_v2/ViewCourseData/LessonTypesComponents/Quiz/type';
import { useZoomMeeting } from 'context/ZoomMeetingContext';
import useIsSmallScreen from 'hooks/useIsSmallScreen';

export enum LessonChange {
	NEXT = 'NEXT',
	PREVIOUS = 'PREVIOUS',
}

const ViewCourseData = () => {
	const { userCourses, userCourseData } = useCourseContext();
	const {
		quiz,
		quizResult,
		resetQuizState,
		setQuizResult,
		setCurrentSelectedLessonId,
		setCurrentSelectedSectionId,
		currentSelectedLessonId,
		currentSelectedSectionId,
	} = useQuizContext();
	const { isZoomVisited } = useZoomMeeting();
	const navigate = useNavigate();

	const { handleGetQuizResult, handleQuizReattempt } = useQuiz();
	const { search } = useLocation();
	const searchSlug = new URLSearchParams(search);
	const courseSlug = searchSlug.get('course-slug');
	const isTabletView = useIsSmallScreen(1200);
	const [showCourseModules, setShowCourseModules] = useState<boolean>(true);
	const [quizReattemptList, setQuizReattemptList] = useState<QuizReattemptDetails[]>([]);

	const selectedCourse = useMemo(() => {
		const course = userCourses.find((course) => course.slug === courseSlug);
		const currentChapter = quiz.lessonId || course?.currentChapter?.id;
		setCurrentSelectedLessonId(currentChapter ?? course?.sections?.[0]?.lessons?.[0]?.id);
		return course;
	}, [userCourses, courseSlug]);

	const allUserCourses: CourseEnrollmentObjectType[] = [
		...userCourseData.inProgressCourses,
		...userCourseData.completedCourses,
	];
	const hasCertificate = allUserCourses.find(
		(userCourse) => userCourse.courseId === selectedCourse.id,
	)?.allow_certificate_download;

	const handleSelectLessonId = (newLessonId: string) => {
		if (newLessonId != currentSelectedLessonId) {
			setQuizResult((prevState) => ({ ...prevState, isSubmit: false }));
			resetQuizState();
			setCurrentSelectedLessonId(newLessonId);
		}
	};

	const currentSectionIndex = selectedCourse?.sections?.findIndex(
		(section) => currentSelectedSectionId === section.id,
	);
	const currentSection = selectedCourse?.sections[currentSectionIndex];
	const currentLessonIndex = currentSection?.lessons?.findIndex(
		(lesson) => lesson?.id === currentSelectedLessonId,
	);

	const isFirstLessonOfFirstSection: boolean =
		currentSectionIndex === 0 && currentLessonIndex === 0;

	//If has certificate, second last lesson should be the last lesson, else the last lesson should be the last lesson of the course
	const isLastLessonOfLastSection: boolean =
		currentSectionIndex === selectedCourse?.sections?.length - 1 &&
		currentLessonIndex === currentSection?.lessons?.length - (hasCertificate ? 2 : 1);

	const handleNextOrPreviousLessonChange = (changeType: LessonChange) => {
		if (changeType === LessonChange.NEXT) {
			const hasNextLessonInSection = currentSection.lessons.length - 1 > currentLessonIndex;
			if (hasNextLessonInSection) {
				const nextLessonId = currentSection.lessons[currentLessonIndex + 1].id;
				handleSelectLessonId(nextLessonId);
			} else {
				const nextSection = selectedCourse.sections[currentSectionIndex + 1];

				const nextSectionFirstLesson = nextSection.lessons[0].id;
				handleSelectLessonId(nextSectionFirstLesson);
			}
		} else {
			const hasPreviousLessonInSection = currentLessonIndex > 0;
			if (hasPreviousLessonInSection) {
				const previousLessonId = currentSection.lessons[currentLessonIndex - 1].id;
				handleSelectLessonId(previousLessonId);
			} else {
				const previousSection = selectedCourse.sections[currentSectionIndex - 1];
				const previousSectionLastLesson =
					previousSection.lessons[previousSection.lessons.length - 1].id;
				handleSelectLessonId(previousSectionLastLesson);
			}
		}
	};

	const [currentSelectedLessonData, setCurrentSelectedLessonData] = useState<LessonType>();

	useEffect(() => {
		if (!selectedCourse || !currentSelectedLessonId) return;

		let foundLesson: LessonType | undefined;

		for (const section of selectedCourse.sections) {
			foundLesson = section?.lessons.find((lesson) => lesson?.id === currentSelectedLessonId);
			if (foundLesson) {
				setCurrentSelectedSectionId(section.id);
				break;
			}
		}

		if (foundLesson) {
			setCurrentSelectedLessonData(foundLesson);
			if (foundLesson.quizId) {
				handleGetQuizResult({
					courseId: selectedCourse?.id,
					lessonId: foundLesson?.id,
					quizId: foundLesson?.quizId,
					sectionId: foundLesson?.sectionId,
				});
			}
		}

		//Quiz Reattempt api data fetch call
		if (selectedCourse) {
			handleQuizReattempt(selectedCourse.id, setQuizReattemptList);
		}
	}, [currentSelectedLessonId, selectedCourse]);

	const [transitionClass, setTransitionClass] = useState('');

	useEffect(() => {
		if (showCourseModules) {
			setTransitionClass('transition-enter');
			requestAnimationFrame(() => {
				setTransitionClass('transition-enter-active');
			});
		} else {
			setTransitionClass('transition-exit');
			requestAnimationFrame(() => {
				setTransitionClass('transition-exit-active');
			});
		}
	}, [showCourseModules]);

	useEffect(() => {
		if (isZoomVisited) {
			window.location.reload();
		}
	}, [isZoomVisited]);

	const breadcrumbData: BreadcrumbDataProps[] = [
		{ name: 'My Courses', href: MY_COURSE_URL },
		{ name: selectedCourse?.name },
		{ name: currentSelectedLessonData?.name },
	];

	const showQuizSection =
		!!currentSelectedLessonData?.quiz &&
		quiz.lessonId &&
		!quizResult.isSubmit &&
		quizResult.quizStartTime &&
		!currentSelectedLessonData?.completed;

	useEffect(() => {
		if (!selectedCourse && userCourses.length) {
			navigate(MY_COURSE_URL);
		}
	}, [selectedCourse, userCourses]);

	return (
		<div className="d-lg-flex">
			<div className={`${showCourseModules && !isTabletView ? 'col-lg-8' : 'col-lg-12'}`}>
				<div className="d-flex justify-content-between">
					{!showQuizSection && <Breadcrumbs breadcrumbData={breadcrumbData} />}
					<div>
						{!showCourseModules && (
							<div
								className="d-flex gap-2"
								role="button"
								onClick={() => setShowCourseModules(true)}>
								<Image src={toAbsoluteUrl('/media/course-detail-images/arrow-left.svg')} />
								Course Content
							</div>
						)}
					</div>
				</div>
				<div>
					<div className="position-relative">
						{showQuizSection ? (
							<FullScreenComponent>
								<QuizType
									lessonQuiz={currentSelectedLessonData?.quiz}
									lessonId={currentSelectedLessonId}
									sectionId={currentSelectedSectionId}
									courseId={selectedCourse.id}
								/>
							</FullScreenComponent>
						) : (
							<CourseLessonData
								lessonData={currentSelectedLessonData}
								courseId={selectedCourse?.id}
								handleLessonChange={handleNextOrPreviousLessonChange}
								isFirstLessonOfFirstSection={isFirstLessonOfFirstSection}
								isLastLessonOfLastSection={isLastLessonOfLastSection}
								hasCertificate={hasCertificate}
								quizReattemptList={quizReattemptList}
								setQuizReattemptList={setQuizReattemptList}
								course={selectedCourse}
							/>
						)}
					</div>
				</div>
				<CourseTabs
					course={selectedCourse}
					currentSelectedLessonId={currentSelectedLessonId}
					handleLessonChange={handleSelectLessonId}
					quizReattemptList={quizReattemptList}
				/>
			</div>
			{showCourseModules && !isTabletView && (
				<div className={`col-lg-4 mx-3 ${transitionClass}`}>
					<CourseProgramOverview
						sections={selectedCourse?.sections}
						fullScale
						showLockIcon
						showCloseIcon
						currentSelectedLessonId={currentSelectedLessonId}
						handleLessonChange={handleSelectLessonId}
						quizReattemptList={quizReattemptList}
						onCloseClick={() => {
							setTransitionClass('transition-exit');
							requestAnimationFrame(() => {
								setTransitionClass('transition-exit-active');
							});
							setTimeout(() => {
								setShowCourseModules(false);
							}, 300);
						}}
					/>
				</div>
			)}
		</div>
	);
};

export default ViewCourseData;
