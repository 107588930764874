import React, { Suspense } from 'react';
import './herosection.scss';
import { EXPLORE_OFFLINE_SECTION } from 'utils/consts';
import Button from '../CommonComponents/Button';
import { ButtonBorderType, ButtonType } from '../CommonComponents/types';
import Loading from '../CommonComponents/Loading';
// Lazy load the HeroVideo component
const HeroVideo = React.lazy(() => import('../HeroVideo/HeroVideo'));

const HeroSection = () => {
	const handleCourseRedirect = (sectionid: any) => {
		const contactSection = document.getElementById(sectionid);
		if (contactSection) {
			contactSection.scrollIntoView({ behavior: 'smooth' });
		}
	};

	return (
		<>
			<section className="hero-section-wrapper">
				<Suspense
					fallback={
						<div>
							<Loading />
						</div>
					}>
					<HeroVideo />
				</Suspense>
				<div className="video-overlay"></div>
				<div className="container-fluid content">
					<div className="">
						<div className="col-lg-8 col-md-10 col-11 col-sm-11 mx-auto text-center">
							<p className="hero-title ibm_semi">
								Navigate the Stock Market with Confidence
								<p className="color2 ibm_sup">Learn, Invest, Succeed</p>
							</p>
						</div>
						<div className="col-lg-4 col-md-11 col-11 col-sm-11 mt-lg-3 mt-md-3 mt-sm-3 mb-lg-5 mb-md-5 mb-sm-5 mx-auto mt-3 mb-5 text-center">
							<p className="hero-subtitle p_med">
								Make yourself loss avert by learning the latest trading skills including strategy
								making, algo trading, derivatives and many more
							</p>
						</div>
						<div>
							<Button
								buttonText="Start Your Growth"
								buttonType={ButtonType.PRIMARY_BUTTON}
								handleClick={() => handleCourseRedirect(EXPLORE_OFFLINE_SECTION)}
								classes="g_mid mt-2"
								buttonBorderType={ButtonBorderType.BLACK_BORDER}
							/>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default HeroSection;
