import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  ADDTOCART,
  ADDTOWISHLIST,
  GETCOURSEBYID,
  GETCOURSEBYSLUG,
  GETCOURSEENROLLMENTSTATUS,
} from "../core/requests";
import { DetailBanner } from "./DetailBanner";
import { AboutCourse } from "./AboutCourse";
import { Recommendation } from "./Recommendation";
import { IconPickerItem } from "react-fa-icon-picker";
import "../../../assets/sass/css/list-page.css";
import { Login } from "../../../pages/auth/Login";
import { getUserData } from "../../../utils/getUserData";
import { toast } from "react-hot-toast";
import { GETCART, REMOVEFROMCART } from "../../Cart/core/requests";
import { gql, useMutation, useQuery } from "@apollo/client";
import Counter from "./Counter";
import { Register } from "../../../pages/auth/Register";
import Loader from "../../common/Spinner/Spinner";
export const GETFREEENROLLCOURSES = gql`
  mutation enrollFreeCourse($courseId: String!) {
    enrollFreeCourse(createFreeCourseEnrollmentInput: { courseId: $courseId })
  }
`;
export const CourseDetail = () => {
  const [open, setOpen] = useState(false);
  const [openRegister, setOpenRegister] = useState(false);
  const [error, setError] = useState<any | "">("");
  const [] = React.useState<any>(null);
  const location = useLocation();
  const navigate = useNavigate();
  const [commit] = useMutation(ADDTOWISHLIST, {
    onCompleted: () => {
      toast.success("Successfully Enroll the course");
    },
    onError: (data: any) => {
      toast.error(data);
    },
  });
  const [commitenrollcourses] = useMutation(GETFREEENROLLCOURSES);
  const [commitaddtodata] = useMutation(ADDTOCART);
  const [] = useMutation(REMOVEFROMCART);
  const courseId = location.pathname.split("/").reverse()[0];
  const { refetch } = useQuery(GETCART);
  const [currentAuthToken, setCurrentAuthToken] = React.useState(
    getUserData().authToken,
  );
  const courseSlug = location.pathname.split("/").reverse()[0];
  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError("");
      }, 1000);
    }
  }, [error]);
  if (!courseId) {
    navigate("/apps/courses");
  }

  const { data: courseRecord, loading } = useQuery(GETCOURSEBYSLUG, {
    variables: {
      slug: courseSlug ?? "",
    },
    onError: (err: any) => {
      console.log("error", err);
    },
  });
  const {
    data: enrollmentstatus,
    error: enrollmentstatuserror,
    refetch: enrollmentstatusrefetch,
  } = useQuery(GETCOURSEENROLLMENTSTATUS, {
    variables: {
      courseId: courseRecord?.getPublicCourseBySlug?.id ?? "",
    },
  });
  React.useEffect(() => {
    const handleStorageChange = () => {
      setCurrentAuthToken(getUserData().authToken);
    };

    window.addEventListener("storage", handleStorageChange);
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  React.useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentAuthToken(getUserData().authToken);
    }, 500);
    return () => clearInterval(intervalId);
  }, []);
  useEffect(() => {
    if (currentAuthToken !== null) {
      enrollmentstatusrefetch();
    }
  }, [currentAuthToken, enrollmentstatus]);
  useEffect(() => {
    if (currentAuthToken === null) {
      enrollmentstatusrefetch();
    }
  }, [currentAuthToken, enrollmentstatus]);
  const course: any = courseRecord?.getPublicCourseBySlug ?? [];
  const handleRegisterClose = () => setOpenRegister(false);
  const toggleRegisterOpen = () => {
    setOpenRegister(true);
    setOpen(false);
  };
  const toggleLoginOpen = () => {
    setOpen(true);
    setOpenRegister(false);
  };

  const handleEnrolleNow = async () => {
    if (!currentAuthToken) {
      setOpen(true);
    }
    if (currentAuthToken) {
      if (course?.costType === "FREE") {
        commitenrollcourses({
          variables: {
            courseId: course?.id,
          },
          onCompleted: () => {
            toast.success("successfully Enrolled", {
              style: {
                padding: "16px",
              },
            });
            refetch();
            enrollmentstatusrefetch();
          },
          onError: (err: any) => {
            toast.error(err.message);
          },
        });
      } else if (course.costType === "PAID") {
        commitaddtodata({
          variables: {
            courseId: course?.id,
          },
          onCompleted: () => {
            toast.success("successfully added to Cart", {
              style: {
                padding: "16px",
              },
            });
            refetch();
            navigate("/checkout");
          },
          onError: (err: any) => {
            toast.error(err.message);
          },
        });
      }
    }
  };
  const handleStartLearning = async () => {
    if (
      course?.courseType?.name === "Webinar" ||
      course?.courseType?.name === "Seminar" ||
      course?.courseType?.name === "Workshop"
    ) {
      navigate(`/webinarislive/${course?.slug}`);
    } else if (course?.courseType?.name === "Recorded Course") {
      navigate(`/coursecontentvideo/${course?.slug}`);
    }
  };
  const handleWishList = async (id: any) => {
    commit({
      variables: {
        courseId: id,
      },
      onCompleted: () => {
        toast.success("successfully added to wishlist", {
          style: {
            padding: "16px",
          },
        });
        navigate(`/my-profile`, { state: { page: "bookmark" } });
      },
      onError: (err: any) => {
        toast.error(err.message);
      },
    });
  };
  const handleAddToCart = async (id: any) => {
    commitaddtodata({
      variables: {
        courseId: id,
      },
      onCompleted: () => {
        toast.success("successfully added to Cart", {
          style: {
            padding: "16px",
          },
        });
        refetch();
        // navigate(`/my-profile`, { state: { page: "bookmark" } });
      },
      onError: (err: any) => {
        toast.error(err.message);
      },
    });
  };
  const conditional =
    currentAuthToken === null ||
    (currentAuthToken !== null &&
      enrollmentstatus?.getCourseEnrollmentStatus === false) ||
    (currentAuthToken === null &&
      enrollmentstatuserror?.message === "Unauthorized");
  const courseStartDate = new Date(course.startDatetime);
  let coursTypeConditionArray = ["recorded-course", "live-course"];
  let slugLowerCase = course?.courseType?.slug?.toLowerCase();
  const leftSeats =
    course.totalSeat !== 0
      ? course.totalSeat - course.courseEnrollmentCount
      : course.totalSeat;
  return (
    <>
      <div className="px-xl-4 px-lg-3 px-2 d-lg-block d-md-block d-none">
        <DetailBanner course={course} />
        {loading ? (
          <div className="justify-content-lg-between">
            <Loader />
          </div>
        ) : (
          <>
            <div className="row">
              <div className="col-lg-12 col-11">
                <div className="row">
                  <div className="col-9">
                    <AboutCourse course={course} error={error} />
                  </div>
                  <div className="col-lg-3 col-12" style={{ marginTop: "-5%" }}>
                    <section className="section pb-0">
                      <div className="container">
                        <div className="col-12 p-0">
                          <div className="course-absolute ps-2">
                            <div className="card round shadow  position-relative overflow-hidden border-0">
                              <div className="card-body">
                                {(course?.courseType?.name !==
                                  "Recorded Course" &&
                                  course?.courseType?.name !== "Webinar") ===
                                true ? (
                                  <>
                                    {leftSeats > 0 ? (
                                      <>
                                        <div
                                          className="d-flex align-items-center m-2 gap-2"
                                          style={{
                                            backgroundColor: "#F2F4F7",
                                            padding: "12px",
                                          }}
                                        >
                                          <div className="">
                                            <img
                                              src="../media/banner/seat.png"
                                              alt="seat_image"
                                            />
                                          </div>
                                          <div className="ms-1">
                                            <span className="p_reg">
                                              Only{" "}
                                              <span className="fw-bold me-1 p_reg">
                                                {leftSeats} seats
                                              </span>
                                              left at this moment.
                                            </span>
                                          </div>
                                        </div>
                                      </>
                                    ) : (
                                      <div
                                        className="gap-2 d-flex align-items-center m-2"
                                        style={{
                                          backgroundColor: "#F2F4F7",
                                          padding: "12px",
                                        }}
                                      >
                                        <div className="">
                                          <img
                                            src="../media/banner/seat.png"
                                            alt="seat_image"
                                          />
                                        </div>
                                        <div className=" ms-1">
                                          <span>
                                            <span className="fw-bold me-1 p_reg">
                                              No seats
                                            </span>
                                            left at this moment.
                                          </span>
                                        </div>
                                      </div>
                                    )}
                                  </>
                                ) : (
                                  ""
                                )}
                                {course?.courseType?.name ===
                                  "Recorded Course" &&
                                  course.previewVideo && (
                                    <div className="card course-card round overflow-hidden m-1 h-100 cursor-pointer">
                                      <video width="" height="" controls>
                                        <source
                                          src={course.previewVideo}
                                          type="video/mp4"
                                        />
                                        Your browser does not support the video
                                        tag.
                                      </video>
                                    </div>
                                  )}

                                <div className="text mx-1 mt-4">
                                  {courseStartDate > new Date() &&
                                  course?.courseType.slug
                                    .charAt(0)
                                    .toUpperCase() +
                                    course?.courseType.slug.slice(1) !==
                                    "Recorded-course" ? (
                                    <Counter
                                      data={courseStartDate}
                                      ctype={false}
                                    ></Counter>
                                  ) : (
                                    ""
                                  )}

                                  {enrollmentstatus?.getCourseEnrollmentStatus ===
                                  true ? (
                                    <>
                                      <div className="col-lg-12">
                                        <div
                                          className="font-title fw-bold btn btn-primary py-2 w-100 mt-2"
                                          onClick={() => {
                                            handleStartLearning();
                                          }}
                                        >
                                          Continue Learning
                                        </div>
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      {course?.amount === 0 &&
                                        course?.sellPrice === 0 && (
                                          <div className="mt-1 fw-bolder align-items-center">
                                            <span className="fs-4 orange-font">
                                              FREE
                                            </span>
                                          </div>
                                        )}
                                      {course?.sellPrice !== 0 ? (
                                        <>
                                          <i className="fa fa-indian-rupee fs-5"></i>
                                          <span className="fs-4 fw-bold">
                                            {course?.sellPrice}{" "}
                                          </span>
                                        </>
                                      ) : (
                                        ""
                                      )}

                                      {course?.amount !== 0 ? (
                                        <>
                                          <del className="text-muted fs-6 ms-2">
                                            <i className="fa fa-indian-rupee"></i>
                                            <span className="fs-6">
                                              {course?.amount}{" "}
                                            </span>
                                          </del>
                                        </>
                                      ) : null}
                                      {!currentAuthToken ? (
                                        <div className="row mt-3">
                                          {conditional === true && (
                                            <div className="col-lg-12">
                                              <div
                                                className="font-title btn btn-primary py-1 w-100 mt-2"
                                                onClick={() => {
                                                  handleEnrolleNow();
                                                }}
                                              >
                                                Enroll Now
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                      ) : (
                                        <div className="row mt-3">
                                          {currentAuthToken !== null &&
                                            enrollmentstatus?.getCourseEnrollmentStatus && (
                                              <div className="col-lg-8">
                                                <div
                                                  className="font-title btn btn-primary py-1 w-100 mt-2"
                                                  onClick={() => {
                                                    handleStartLearning();
                                                  }}
                                                >
                                                  Start Learning
                                                </div>
                                              </div>
                                            )}
                                          {conditional === true && (
                                            <div className="col-lg-8">
                                              <div
                                                className="p_reg font-title btn btn-primary py-1 w-100 mt-2"
                                                onClick={() => {
                                                  handleEnrolleNow();
                                                }}
                                              >
                                                Enroll Now
                                              </div>
                                            </div>
                                          )}
                                          {currentAuthToken &&
                                          course?.amount !== 0 ? (
                                            <>
                                              <div
                                                className="col-lg-2"
                                                style={{ marginLeft: "-10px" }}
                                                onClick={() => {
                                                  handleAddToCart(course?.id);
                                                }}
                                              >
                                                <div className="course-tag cursor-pointer">
                                                  <div className="course-badge text-dark pt-2 rounded-3 cart-icon-look">
                                                    <img
                                                      src="../media/icons/coursecart.jpg"
                                                      alt="coursecart"
                                                      className=""
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            </>
                                          ) : null}
                                          {currentAuthToken && (
                                            <div
                                              className="col-lg-2"
                                              style={{ marginLeft: "-10px" }}
                                              onClick={() => {
                                                handleWishList(course?.id);
                                              }}
                                            >
                                              <div className="course-tag cursor-pointer">
                                                <div className="course-badge text-dark pt-2 rounded-3 bookmark-icon-look">
                                                  <img
                                                    src="../media/icons/bookmark.jpg"
                                                    alt="bookmark"
                                                    className=""
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                      )}
                                    </>
                                  )}
                                </div>
                                {!currentAuthToken && (
                                  <Login
                                    open={open}
                                    setOpen={setOpen}
                                    toggleLoginOpen={toggleLoginOpen}
                                    toggleRegisterOpen={toggleRegisterOpen}
                                    openRegister={openRegister}
                                    setOpenRegister={setOpenRegister}
                                  />
                                )}
                                <div className="mx-1">
                                  {course?.highlights?.length > 0 ? (
                                    <>
                                      <div className="font-title fs-5 mt-4 fw-bold font-subtitle">
                                        {slugLowerCase &&
                                        coursTypeConditionArray.includes(
                                          slugLowerCase,
                                        )
                                          ? "Course"
                                          : course?.courseType.slug
                                              .charAt(0)
                                              .toUpperCase() +
                                            course?.courseType.slug.slice(
                                              1,
                                            )}{" "}
                                        Highlights
                                      </div>
                                      {course?.highlights?.map((value: any) => {
                                        return (
                                          <>
                                            <div className="d-flex align-items-center">
                                              <div className="">
                                                <IconPickerItem
                                                  color="#3cc878"
                                                  icon={
                                                    value?.highlightIcon ?? ""
                                                  }
                                                  size={24}
                                                />
                                              </div>
                                              <div className="pt-2 ms-3">
                                                <span>
                                                  {value.highlightText ?? ""}
                                                </span>
                                              </div>
                                            </div>
                                          </>
                                        );
                                      })}
                                    </>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </div>
            <Recommendation />
          </>
        )}
      </div>

      {/*
-------------------------------
 Responsive Mobile View Code 
 ---------------------------
 */}

      <div className="px-xl-4 px-lg-3 px-2 d-lg-none d-md-none d-block">
        <DetailBanner course={course} />
        <div className="row">
          <div className="col-lg-9 col-11">
            <AboutCourse course={course} error={error} />
          </div>
          <div className="col-lg-3 col-12">
            <section className="pb-0">
              <div className="container">
                <div className="col-12 p-0">
                  <div className="ps-2">
                    <div className="card round shadow  position-relative overflow-hidden border-0">
                      <div className="card-body">
                        {course?.courseType?.name !== "Recorded Course" ? (
                          <>
                            {leftSeats > 0 ? (
                              <>
                                <div
                                  className="d-flex align-items-center m-2 gap-2"
                                  style={{
                                    backgroundColor: "#F2F4F7",
                                    padding: "12px",
                                  }}
                                >
                                  <div className="">
                                    <img
                                      src="../media/banner/seat.png"
                                      alt="seat_image"
                                    />
                                  </div>
                                  <div className="ms-1">
                                    <span>
                                      Only{" "}
                                      <span className="fw-bold me-1">
                                        {leftSeats} seats
                                      </span>
                                      left at this moment.
                                    </span>
                                  </div>
                                </div>
                              </>
                            ) : (
                              <div
                                className="gap-2 d-flex align-items-center m-2"
                                style={{
                                  backgroundColor: "#F2F4F7",
                                  padding: "12px",
                                }}
                              >
                                <div className="">
                                  <img
                                    src="../media/banner/seat.png"
                                    alt="seat_image"
                                  />
                                </div>
                                <div className=" ms-1">
                                  <span>
                                    <span className="fw-bold me-1">
                                      No seats
                                    </span>
                                    left at this moment.
                                  </span>
                                </div>
                              </div>
                            )}
                          </>
                        ) : (
                          ""
                        )}
                        {course?.courseType?.name === "Recorded Course" &&
                          course.previewVideo && (
                            <div className="card course-card round overflow-hidden m-1 h-100 cursor-pointer">
                              <video width="" height="" controls>
                                <source
                                  src={course.previewVideo}
                                  type="video/mp4"
                                />
                                Your browser does not support the video tag.
                              </video>
                            </div>
                          )}

                        <div className="text mx-1 mt-4">
                          {courseStartDate > new Date() &&
                          course?.courseType.slug.charAt(0).toUpperCase() +
                            course?.courseType.slug.slice(1) !==
                            "Recorded-course" ? (
                            <Counter
                              data={courseStartDate}
                              ctype={false}
                            ></Counter>
                          ) : (
                            ""
                          )}

                          {enrollmentstatus?.getCourseEnrollmentStatus ===
                          true ? (
                            <>
                              <div className="col-lg-12">
                                <div
                                  className="font-title fw-bold btn btn-primary py-2 w-100 mt-2"
                                  onClick={() => {
                                    handleStartLearning();
                                  }}
                                >
                                  Continue Learning
                                </div>
                              </div>
                            </>
                          ) : (
                            <>
                              {course?.amount === 0 &&
                                course?.sellPrice === 0 && (
                                  <div className="mt-1 fw-bolder align-items-center">
                                    <span className="fs-4 orange-font">
                                      FREE
                                    </span>
                                  </div>
                                )}
                              {course?.sellPrice !== 0 ? (
                                <>
                                  <i className="fa fa-indian-rupee fs-5"></i>
                                  <span className="fs-4 fw-bold">
                                    {course?.sellPrice}{" "}
                                  </span>
                                </>
                              ) : (
                                ""
                              )}

                              {course?.amount !== 0 ? (
                                <>
                                  <del className="text-muted fs-6 ms-2">
                                    <i className="fa fa-indian-rupee"></i>
                                    <span className="fs-6">
                                      {course?.amount}{" "}
                                    </span>
                                  </del>
                                </>
                              ) : null}
                              {!currentAuthToken ? (
                                <div className="row mt-3">
                                  {conditional === true && (
                                    <div className="col-lg-12">
                                      <div
                                        className="font-title btn btn-primary py-1 w-100 mt-2"
                                        onClick={() => {
                                          handleEnrolleNow();
                                        }}
                                      >
                                        Enroll Now
                                      </div>
                                    </div>
                                  )}
                                </div>
                              ) : (
                                <div className="row mt-3">
                                  {currentAuthToken !== null &&
                                    enrollmentstatus?.getCourseEnrollmentStatus && (
                                      <div className="col-lg-8">
                                        <div
                                          className="font-title btn btn-primary py-1 w-100 mt-2"
                                          onClick={() => {
                                            handleStartLearning();
                                          }}
                                        >
                                          Start Learning
                                        </div>
                                      </div>
                                    )}
                                  {conditional === true && (
                                    <div className="col-lg-8">
                                      <div
                                        className="font-title btn btn-primary py-1 w-100 mt-2"
                                        onClick={() => {
                                          handleEnrolleNow();
                                        }}
                                      >
                                        Enroll Now
                                      </div>
                                    </div>
                                  )}
                                  {currentAuthToken && course?.amount !== 0 ? (
                                    <>
                                      <div
                                        className="col-lg-2"
                                        style={{ marginLeft: "-10px" }}
                                        onClick={() => {
                                          handleAddToCart(course?.id);
                                        }}
                                      >
                                        <div className="course-tag cursor-pointer">
                                          <div className="course-badge text-dark pt-2 rounded-3 cart-icon-look">
                                            <img
                                              src="../media/icons/coursecart.jpg"
                                              alt="coursecart"
                                              className=""
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  ) : null}
                                  {currentAuthToken && (
                                    <div
                                      className="col-lg-2"
                                      style={{ marginLeft: "-10px" }}
                                      onClick={() => {
                                        handleWishList(course?.id);
                                      }}
                                    >
                                      <div className="course-tag cursor-pointer">
                                        <div className="course-badge text-dark pt-2 rounded-3 bookmark-icon-look">
                                          <img
                                            src="../media/icons/bookmark.jpg"
                                            alt="bookmark"
                                            className=""
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              )}
                            </>
                          )}
                        </div>
                        {open === true && (
                          <Login
                            open={open}
                            setOpen={setOpen}
                            toggleLoginOpen={toggleLoginOpen}
                            toggleRegisterOpen={toggleRegisterOpen}
                            openRegister={openRegister}
                            setOpenRegister={setOpenRegister}
                          />
                        )}
                        {openRegister === true && (
                          <Register
                            openRegister={openRegister}
                            handleRegisterClose={handleRegisterClose}
                            setOpenRegister={setOpenRegister}
                            toggleRegisterOpen={toggleRegisterOpen}
                            toggleLoginOpen={toggleLoginOpen}
                          />
                        )}
                        <div className="mx-1">
                          {course?.highlights?.length > 0 ? (
                            <>
                              <div className="font-title fs-5 mt-4 fw-bold font-subtitle">
                                {slugLowerCase &&
                                coursTypeConditionArray.includes(slugLowerCase)
                                  ? "Course"
                                  : course?.courseType.slug
                                      .charAt(0)
                                      .toUpperCase() +
                                    course?.courseType.slug.slice(1)}{" "}
                                Highlights
                              </div>
                              {course?.highlights?.map((value: any) => {
                                return (
                                  <>
                                    <div className="d-flex align-items-center">
                                      <div className="">
                                        <IconPickerItem
                                          color="#3cc878"
                                          icon={value?.highlightIcon ?? ""}
                                          size={24}
                                        />
                                      </div>
                                      <div className="pt-2 ms-3">
                                        <span>{value.highlightText ?? ""}</span>
                                      </div>
                                    </div>
                                  </>
                                );
                              })}
                            </>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
        <Recommendation />
      </div>
    </>
  );
};
