import React from 'react';
import TopTitleSection from '../CommonComponents/TopTitleSection';
import './AboutUs.css';
import { toAbsoluteUrl } from 'assets/helpers/AssetHelpers';
import ApproachCard from './ApproachCard';
import AboutCTA from './AboutCTA';
import AboutContentCard from './AboutContentCard';
import { useNavigate } from 'react-router-dom';
import { CONTACT_US, COURSE_URL } from 'pages/routing/routePages';

const AboutUs = () => {
	const navigate = useNavigate();
	const imageData = [
		{
			src: '/media/aboutus/expect1.png',
			alt: 'Expect1_image',
			order: 'order-lg-1 order-sm-2 order-md-1',
			size: 'col-md-6 col-8 mb-3',
		},
		{
			src: '/media/aboutus/expect2.png',
			alt: 'Expect2_image',
			order: 'order-lg-2 order-sm-1 order-md-2',
			size: 'col-md-3 col-4 mb-3',
		},
		{
			src: '/media/aboutus/expect4.png',
			alt: 'Expect4_image',
			order: 'order-lg-4 order-sm-3 order-md-4',
			size: 'col-md-3 col-4 mb-3',
		},
		{
			src: '/media/aboutus/expect5.png',
			alt: 'Expect5_image',
			order: 'order-lg-6 order-sm-4 order-md-5',
			size: 'col-md-6 col-8 mb-3',
		},
		{
			src: '/media/aboutus/expect3.png',
			alt: 'Expect3_image',
			order: 'order-lg-3 order-sm-6 order-md-3',
			size: 'col-md-3 col-4 mb-3',
		},
		{
			src: '/media/aboutus/expect6.png',
			alt: 'Expect6_image',
			order: 'order-lg-5 order-sm-5 order-md-6 d-lg-flex d-md-flex d-none',
			size: 'col-md-3 col-4 mb-3',
		},
		{
			src: '/media/aboutus/expect6res.png',
			alt: 'Expect6_image',
			order: 'order-lg-5 order-sm-5 order-md-6 d-lg-none d-md-none d-flex',
			size: 'col-md-3 col-8 mb-3',
		},
	];

	return (
		<>
			<section className="">
				<div className="container-fluid info-layout">
					<div className="row mt-5">
						<TopTitleSection
							title="About Us"
							description="Welcome to HoGrowth Academy, where we transform finance and capital market education through a hands-on, practical approach."
						/>
					</div>
				</div>
			</section>
			<section className="about-us-wrapper bg-color19">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="row">
								<div className="col-lg-10 col-md-11 col-12 layout-set mx-auto">
									<div className="my-lg-5 my-md-4 my-2">
										<img
											src={toAbsoluteUrl('/media/aboutus/about-intro.svg')}
											alt="Main_Intro_image"
											className="img-fluid cover-image cover-image"
										/>
									</div>

									{/* Grid Layout for the Cards */}
									<div className="row">
										<div className="col-lg-6 col-md-12 col-12 mb-4">
											<AboutContentCard
												title="Empowering Minds through Unique and Inventive Solutions"
												description="At Ho Growth, we believe in crafting creative, real-world solutions that foster intellectual development and self-discovery."
												buttonText="Our Mission"
												reverseOrder={true}
											/>
										</div>
										<div className="col-lg-6 col-md-12 col-12 mb-4">
											<AboutContentCard
												title="Shaping a Future of Inspired Learners and  Future Leaders"
												description="Our vision at Ho Growth is to redefine the boundaries of education, inspiring uniqueness and nurturing creativity."
												buttonText="Our Vision"
												reverseOrder={true}
											/>
										</div>
									</div>

									{/* Approach Cards with alternating layouts */}
									<div className="row">
										<div className="col-lg-12 col-md-12 col-12 mb-4">
											<ApproachCard
												title="Practical Learning"
												description="Our curriculum is designed to bridge the gap between theory and practice. We offer you the tools and knowledge needed to apply financial concepts effectively, ensuring you're prepared to tackle real-world challenges with confidence."
												imageUrl={toAbsoluteUrl('media/aboutus/approch1.svg')}
											/>
											<ApproachCard
												title="Smart Classrooms"
												description="Our curriculum is designed to bridge the gap between theory and practice. We offer you the tools and knowledge needed to apply financial concepts effectively, ensuring you're prepared to tackle real-world challenges with confidence."
												imageUrl={toAbsoluteUrl('media/aboutus/approch2.svg')}
												reverse={true}
											/>
											<ApproachCard
												title="Live Online Classes"
												description="Our curriculum is designed to bridge the gap between theory and practice. We offer you the tools and knowledge needed to apply financial concepts effectively, ensuring you're prepared to tackle real-world challenges with confidence."
												imageUrl={toAbsoluteUrl('media/aboutus/approch3.svg')}
											/>
										</div>
									</div>

									{/* Looking Ahead */}
									<div className="row">
										<div className="col-lg-12 col-md-12 col-12">
											<div className="col-lg-9  col-md-10 col-11 mx-auto text-center">
												<h2 className="expect-title my-lg-3">Looking Ahead</h2>
												<p className="expect-subtitle">
													We're excited to launch recorded courses in regional languages to break
													language barriers and make high-quality finance and capital market
													education accessible to all.
												</p>
											</div>
										</div>
									</div>

									{/* Image Grid */}
									<div className="container my-4">
										<div className="row">
											{imageData.map((image, index) => (
												<div key={index} className={`${image.size} ${image.order}`}>
													<img src={image.src} alt={image.alt} className="img-fluid cover-image" />
												</div>
											))}
										</div>
									</div>

									{/* CTA */}
									<AboutCTA />

									{/* Query Section */}
									<div className="row">
										<div className="col-lg-6 col-md-12 col-12 mb-4">
											<AboutContentCard
												title="Join us at HoGrowth Academy"
												description="and be part of a community that values practical knowledge and real-world application. We're here to support your educational journey and help you grow in your career and beyond.."
												buttonText="Explore All Courses"
												onClickHandler={() => navigate(COURSE_URL)}
												buttonClass="query-course-btn"
												titleClass="query-card-title"
												descriptionClass="query-card-subtitle"
											/>
										</div>
										<div className="col-lg-6 col-md-12 col-12 mb-4">
											<AboutContentCard
												title="Feel Free to Contact Us"
												description="For any inquiries or to learn more about our programs, feel free to contact us. We look forward to helping you achieve your goals and reach new heights!"
												buttonText="Contact Us"
												buttonClass="query-contact-btn"
												titleClass="query-card-title"
												descriptionClass="query-card-subtitle"
												onClickHandler={() => navigate(CONTACT_US)}
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default AboutUs;
