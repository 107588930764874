import React from 'react';
import { useAuthModalContext } from 'context/AuthContext';
import { Login } from 'pages/auth/Login';
import { Register } from 'pages/auth/Register';

const LoginAndRegisterModals: React.FC = () => {
	const {
		open,
		setOpen,
		toggleLoginOpen,
		openRegister,
		setOpenRegister,
		toggleRegisterOpen,
		handleRegisterClose,
	} = useAuthModalContext();

	return (
		<>
			{open && (
				<Login
					open={open}
					setOpen={setOpen}
					toggleLoginOpen={toggleLoginOpen}
					toggleRegisterOpen={() => {
						toggleRegisterOpen();
						setOpen(!open);
					}}
					openRegister={openRegister}
					setOpenRegister={setOpenRegister}
				/>
			)}
			{openRegister && (
				<Register
					openRegister={openRegister}
					handleRegisterClose={handleRegisterClose}
					setOpenRegister={setOpenRegister}
					toggleRegisterOpen={toggleRegisterOpen}
					toggleLoginOpen={() => {
						toggleLoginOpen();
						handleRegisterClose();
					}}
				/>
			)}
		</>
	);
};

export default LoginAndRegisterModals;
