import React from "react";
import { IoBookSharp } from "react-icons/io5";
import { HiUserGroup } from "react-icons/hi2";

export const AboutMentor = (props: { mentor: any }) => {
  const { mentor } = props;
  return (
    <div className="about-mentor">
      <section className="section pb-0" id="features">
        <div className="container">
          <div className="col-lg-6">
            <div className="mentor-absolute ps-4">
              <div className="card features  feature-full-bg round shadow p-3 position-relative overflow-hidden border-0">
                <div className="card-body">
                  <div className="text">
                    <div className="features-icon mb-3">
                      <i className="uil uil-chart-bar" />
                    </div>
                    <div className="row">
                      <div className="col-lg-4 col-md-6 col-12">
                        <div className="d-flex mb-2">
                          <div className="text-center">
                            <img
                              src="../media/course/rating.png"
                              alt="Rating"
                              className="img-fluid"
                              style={{ width: "50px" }}
                            />
                          </div>
                          <div className="flex-1 ms-3">
                            <h5 className="mb-1">
                              <span className="fw-bold">4.5</span> (23,676){" "}
                            </h5>
                            <p className="text-muted fs-6">Ratings</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6 col-12">
                        <div className="d-flex mb-2">
                          <div className="text-center">
                            <img
                              src="../media/course/student.png"
                              alt="student"
                              className="img-fluid"
                              style={{ width: "50px" }}
                            />
                          </div>
                          <div className="flex-1 ms-3">
                            <h5 className="mb-1 fw-bold">122,456</h5>
                            <p className="text-muted fs-6">Total Students</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6 col-12">
                        <div className="d-flex mb-2">
                          <div className="text-center">
                            <img
                              src="../media/course/coursementro.png"
                              alt="coursementro"
                              className="img-fluid"
                              style={{ width: "50px" }}
                            />
                          </div>
                          <div className="flex-1 ms-3">
                            <h5 className="mb-1 fw-bold">38</h5>
                            <p className="text-muted fs-6">Courses</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* end col */}
        </div>
        {/* End container */}
      </section>

      <section className="section d-lg-block d-md-block d-none">
        <div className="card border-0 mx-4">
          <div className="card-body ">
            <h2 className="font-title title mb-3 head1">About</h2>
            <div className="row">
              <div className="col-lg-10 ">
                <p className="font-subtitle sub-title">
                  {mentor &&
                    mentor?.userProfile &&
                    mentor?.userProfile[0] &&
                    mentor?.userProfile[0]?.description}
                </p>
              </div>
              <div className="col-lg-2 col-12  d-lg-block d-md-block d-none">
                <div className="card p-2 ">
                  <li className="list-inline-item ">
                    <a
                      href={
                        mentor &&
                        mentor?.userProfile &&
                        mentor?.userProfile[0] &&
                        mentor?.userProfile[0]?.linkedinUrl
                      }
                      className="rounded cursor-pointer text-dark  text-decoration-none"
                    >
                      <i className="fab fa-linkedin m-2 text-primary" />
                      LinkedIn
                    </a>
                  </li>
                </div>
                <div className="card mt-2 p-2">
                  <li className="list-inline-item ">
                    <a
                      href={
                        mentor &&
                        mentor?.userProfile &&
                        mentor?.userProfile[0] &&
                        mentor?.userProfile[0]?.instagramUrl
                      }
                      className="rounded cursor-pointer text-dark  text-decoration-none"
                    >
                      <i className="fab fa-instagram  text-primary  m-2" />
                      Instagram
                    </a>
                  </li>
                </div>
                <div className="card mt-2 p-2">
                  <li className="list-inline-item   ">
                    <a
                      href={
                        mentor &&
                        mentor?.userProfile &&
                        mentor?.userProfile[0] &&
                        mentor?.userProfile[0]?.twitterUrl
                      }
                      className="rounded cursor-pointer text-dark  text-decoration-none"
                    >
                      <i className="fa fa-times m-2 text-primary" />
                      X(Twitter)
                    </a>
                  </li>
                </div>
              </div>
              <div className="link d-lg-none d-md-none d-block">
                <li className="list-inline-item ">
                  <a
                    href={
                      mentor &&
                      mentor?.userProfile &&
                      mentor?.userProfile[0] &&
                      mentor?.userProfile[0]?.linkedinUrl
                    }
                    className="rounded cursor-pointer text-dark fs-5 text-decoration-none"
                  >
                    <i className="fab fa-linkedin fs-3 m-2 text-primary" />
                  </a>
                </li>

                <li className="list-inline-item ">
                  <a
                    href={
                      mentor &&
                      mentor?.userProfile &&
                      mentor?.userProfile[0] &&
                      mentor?.userProfile[0]?.instagramUrl
                    }
                    className="rounded cursor-pointer text-dark fs-5 text-decoration-none"
                  >
                    <i className="fab fa-instagram  text-primary fs-3  m-2" />
                  </a>
                </li>

                <li className="list-inline-item">
                  <a
                    href={
                      mentor &&
                      mentor?.userProfile &&
                      mentor?.userProfile[0] &&
                      mentor?.userProfile[0]?.twitterUrl
                    }
                    className="rounded cursor-pointer text-dark fs-5 text-decoration-none"
                  >
                    <i className="fa fa-times fs-3 m-2 text-primary" />
                  </a>
                </li>
                <li className="list-inline-item">
                  <a
                    href="http://localhost:3001/"
                    className="rounded cursor-pointer text-dark fs-5 text-decoration-none"
                  >
                    <i className="fa fa-globe fs-3 m-2 text-primary" />
                  </a>
                </li>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*
-------------------------------
 Responsive Mobile View Code 
 ---------------------------
 */}

      <section className="section d-lg-none d-md-none d-block">
        <div className="card border-0 mx-4">
          <div className="card-body ">
            <h2 className="font-title title mb-3 head1 fs-1 fw-bolder">
              About
            </h2>
            <div className="row">
              <div className="col-12">
                <p className="font-subtitle sub-title">
                  {mentor &&
                    mentor?.userProfile &&
                    mentor?.userProfile[0] &&
                    mentor?.userProfile[0]?.description}
                </p>
              </div>
              <div className="col-lg-2 col-12  d-lg-block d-md-block d-none">
                <div className="card p-2 ">
                  <li className="list-inline-item ">
                    <a
                      href={
                        mentor &&
                        mentor?.userProfile &&
                        mentor?.userProfile[0] &&
                        mentor?.userProfile[0]?.linkedinUrl
                      }
                      className="rounded cursor-pointer text-dark  text-decoration-none"
                    >
                      <i className="fab fa-linkedin m-2 text-primary" />
                      LinkedIn
                    </a>
                  </li>
                </div>
                <div className="card mt-2 p-2">
                  <li className="list-inline-item ">
                    <a
                      href={
                        mentor &&
                        mentor?.userProfile &&
                        mentor?.userProfile[0] &&
                        mentor?.userProfile[0]?.instagramUrl
                      }
                      className="rounded cursor-pointer text-dark  text-decoration-none"
                    >
                      <i className="fab fa-instagram  text-primary  m-2" />
                      Instagram
                    </a>
                  </li>
                </div>
                <div className="card mt-2 p-2">
                  <li className="list-inline-item   ">
                    <a
                      href={
                        mentor &&
                        mentor?.userProfile &&
                        mentor?.userProfile[0] &&
                        mentor?.userProfile[0]?.twitterUrl
                      }
                      className="rounded cursor-pointer text-dark  text-decoration-none"
                    >
                      <i className="fa fa-times m-2 text-primary" />
                      X(Twitter)
                    </a>
                  </li>
                </div>
              </div>
              <div className="link d-lg-none d-md-none d-block">
                <li className="list-inline-item ">
                  <a
                    href={
                      mentor &&
                      mentor?.userProfile &&
                      mentor?.userProfile[0] &&
                      mentor?.userProfile[0]?.linkedinUrl
                    }
                    className="rounded cursor-pointer text-dark fs-5 text-decoration-none"
                  >
                    <i className="fab fa-linkedin fs-3 m-2 text-primary" />
                  </a>
                </li>

                <li className="list-inline-item ">
                  <a
                    href={
                      mentor &&
                      mentor?.userProfile &&
                      mentor?.userProfile[0] &&
                      mentor?.userProfile[0]?.instagramUrl
                    }
                    className="rounded cursor-pointer text-dark fs-5 text-decoration-none"
                  >
                    <i className="fab fa-instagram  text-primary fs-3  m-2" />
                  </a>
                </li>

                <li className="list-inline-item">
                  <a
                    href={
                      mentor &&
                      mentor?.userProfile &&
                      mentor?.userProfile[0] &&
                      mentor?.userProfile[0]?.twitterUrl
                    }
                    className="rounded cursor-pointer text-dark fs-5 text-decoration-none"
                  >
                    <i className="fa fa-times fs-3 m-2 text-primary" />
                  </a>
                </li>
                <li className="list-inline-item">
                  <a
                    href="http://localhost:3001/"
                    className="rounded cursor-pointer text-dark fs-5 text-decoration-none"
                  >
                    <i className="fa fa-globe fs-3 m-2 text-primary" />
                  </a>
                </li>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
