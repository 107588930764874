import { toAbsoluteUrl } from "../../../../assets/helpers/AssetHelpers";
import { MdDateRange } from "react-icons/md";
import { HiMiniComputerDesktop } from "react-icons/hi2";

import _ from "lodash";
import moment from "moment";

import { useNavigate } from "react-router-dom";
import { ADDTOWISHLIST } from "../../../Courses-Page/core/requests";
import { useMutation } from "@apollo/client";
import toast from "react-hot-toast";
import React from "react";
import { getUserData } from "../../../../utils/getUserData";

export const CoursesOffered = (props: { mentor: any }) => {
  const { mentor } = props;
  const [currentAuthToken, setCurrentAuthToken] = React.useState(
    getUserData().authToken,
  );
  const [commit] = useMutation(ADDTOWISHLIST);
  const navigate = useNavigate();
  React.useEffect(() => {
    const handleStorageChange = () => {
      setCurrentAuthToken(getUserData().authToken);
    };

    window.addEventListener("storage", handleStorageChange);
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  React.useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentAuthToken(getUserData().authToken);
    }, 500);
    return () => clearInterval(intervalId);
  }, []);

  const handleWishList = async (id: any) => {
    const variables = {
      courseId: id,
    };
    commit({
      variables: {
        courseId: id,
      },
      onCompleted: () => {
        toast.success("successfully added to wishlist", {
          style: {
            padding: "16px",
          },
        });
        navigate(`/my-profile`, { state: { page: "bookmark" } });
      },
      onError: (err: any) => {
        toast.error(err.message);
      },
    });
  };
  return (
    <>
      <section className="section p-0 d-lg-block d-md-block d-none">
        <div className="container px-lg-3">
          {mentor?.courses?.length > 0 && (
            <div className="heading mb-2 mx-1">
              <div className="row">
                <h2 className="title mb-3 head1">
                  Courses offered (
                  {
                    mentor?.courses?.filter(
                      (value: any) => value.status === "PUBLISHED",
                    ).length
                  }
                  )
                </h2>
              </div>
            </div>
          )}

          <div className="row">
            {mentor?.courses?.map((value: any) => {
              const date = moment
                .utc(value.startDatetime)
                .local()
                .format("YYYY-MM-DD HH:mm:ss");
              return (
                <>
                  {value.status === "PUBLISHED" && (
                    <>
                      <div
                        className=" col-lg-3 col-md-6 col-12 mt-2 p-1 d-lg-block d-md-block d-block"
                        onClick={() => {
                          navigate(`/course-view/${value.slug}`);
                        }}
                      >
                        <div className="card border-muted course-card round overflow-hidden mx-xl-2 fixed-height-card">
                          <div className="card-body rounded-md p-4 cursor-pointer">
                            <div className="card-img image position-relative overflow-hidden">
                              {value?.images?.map((item: any) => {
                                return (
                                  <>
                                    <img
                                      alt=""
                                      src={item.original}
                                      className=" card-img-top  image img-fluid rounded bg-light"
                                    />
                                  </>
                                );
                              })}
                              {currentAuthToken !== null && (
                                <div
                                  className="course-tag cursor-pointer"
                                  onClick={() => {
                                    handleWishList(value.id);
                                  }}
                                >
                                  <div className="course-badge text-dark p-2 py-1 rounded-1">
                                    <i className="fa fa-bookmark tag fs-6"></i>
                                  </div>
                                </div>
                              )}
                            </div>

                            <div className="card-text mt-3">
                              <div className="d-flex justify-content-between">
                                <button
                                  className="btn btn-outline-success webinar-btn rounded-pill px-3 py-1 text-dark"
                                  type="button"
                                >
                                  {value?.courseCategory?.name}
                                </button>
                                <div className="align-items-center border border-orange course_difficulty d-flex justify-content-center px-2 rounded-pill text-center">
                                  <i
                                    className={`mt-0 icon__course-difficulty difficulty-${value.courseLevel.name} position-relative`}
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    data-bs-html="true"
                                    title={`Course Defficulty : ${value.courseLevel.name}`}
                                  >
                                    <span
                                      className={` bar-1 ${
                                        value.courseLevel.name === "Advanced" ||
                                        value.courseLevel.name ===
                                          "Intermediate" ||
                                        value.courseLevel.name === "Beginner"
                                          ? "opacity-100"
                                          : "opacity-25"
                                      } `}
                                    ></span>
                                    <span
                                      className={` bar-2 ${
                                        value.courseLevel.name === "Advanced" ||
                                        value.courseLevel.name ===
                                          "Intermediate"
                                          ? "opacity-100"
                                          : "opacity-25"
                                      } `}
                                    ></span>
                                    <span
                                      className={` bar-3 ${
                                        value.courseLevel.name === "Advanced"
                                          ? "opacity-100"
                                          : "opacity-25"
                                      } `}
                                    ></span>
                                  </i>
                                </div>
                              </div>
                              <h6 className="card-title mt-2 fw-bold cursor-pointer">
                                {value.name}
                              </h6>
                              <p className="text-muted">
                                {value?.mentors?.map(
                                  (mentor: any, index: any) => (
                                    <React.Fragment key={index}>
                                      <a
                                        className="text-decoration-none text-muted-dark"
                                        href={`/mentor-view/${mentor.mentor.id}`}
                                      >
                                        {index > 0 ? (
                                          <>&nbsp;and&nbsp;</>
                                        ) : (
                                          <span>By&nbsp;</span>
                                        )}
                                        {mentor.mentor.firstName ?? ""}{" "}
                                        {mentor.mentor.lastName ?? ""}
                                        {index < value.mentors.length - 1 ? (
                                          <></>
                                        ) : null}
                                      </a>
                                    </React.Fragment>
                                  ),
                                )}
                                <div className="mt-2 text-muted-dark">
                                  <img
                                    src="../media/icons/calendar-event-fill.svg"
                                    alt=""
                                    className="fs-5 me-1"
                                  />
                                  {/* <MdDateRange className="fs-5 me-1" /> */}
                                  {value.startDatetime == null
                                    ? ""
                                    : moment(date).format(
                                        "ddd, DD MMM YYYY • hh:mm A ",
                                      )}
                                </div>

                                <div className="mt-1 text-muted-dark">
                                  <HiMiniComputerDesktop className="fs-5 me-1" />{" "}
                                  {value.courseMode?.name}
                                </div>
                              </p>
                              <div className="mt-2 fw-bolder align-items-center">
                                {value.sellPrice !== 0 ? (
                                  <>
                                    <i className="fa fa-indian-rupee"></i>
                                    <span className="fs-4">
                                      {value.sellPrice}
                                    </span>
                                  </>
                                ) : (
                                  ""
                                )}
                                {value.amount === 0 &&
                                  value.sellPrice === 0 && (
                                    <div className="mt-2 fw-bolder align-items-center">
                                      <span className="fs-4">Free</span>
                                    </div>
                                  )}
                                {value.amount !== 0 ? (
                                  <>
                                    <del className="text-muted fs-6 ms-2">
                                      <i className="fa fa-indian-rupee"></i>
                                      <span className="fs-6">
                                        {value.amount}
                                      </span>
                                    </del>
                                  </>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* <div className="card course-card border-1 mx-2 d-lg-none d-md-none d-none h-100 m-2 ">
                        <div className="card-body">
                          <div className="row">
                            <div className=" col-xl-4 col-lg-4 col-12 top selling p-0">
                              <div className="card-img image position-relative overflow-hidden ">
                                <img
                                  alt=""
                                  src={value.image}
                                  className=" card-img-top  image img-fluid rounded "
                                />
                                <div className="course-tag cursor-pointer d-none">
                                  <div className="course-badge text-dark p-2 py-1 rounded-1">
                                    <i className="fa fa-bookmark tag fs-6"></i>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-xl-8 col-lg-8 col-8 p-2">
                              <div className="card-text">
                                <h6 className="card-title fw-bold text-muted cursor-pointer">
                                  {value.title}
                                </h6>
                                <p className="text-muted">
                                  {value?.mentors?.length > 0 ? (
                                    <>
                                      {value?.mentors?.map((value: any) => {
                                        return (
                                          <>
                                            By <span>{value.mentor.firstName ?? ""}</span>
                                          </>
                                        );
                                      })}
                                    </>
                                  ) : (
                                    null
                                  )}
                                  <div className="">
                                    <MdDateRange className="fs-5 me-1" />
                                    {value.date}
                                  </div>

                                  <div className="">
                                    <HiMiniComputerDesktop className="fs-5 me-1" />{" "}
                                    {value.mode}
                                  </div>
                                </p>
                                <div className=" fw-bolder align-items-center">
                                  <i className="fa fa-indian-rupee"></i>
                                  <span className="fs-4">{value.sellPrice}</span>
                                  <del className="text-muted fs-6 ms-2">
                                    <i className="fa fa-indian-rupee"></i>
                                    <span className="fs-6">{value.amount}</span>
                                  </del>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> */}
                    </>
                  )}
                </>
              );
            })}
          </div>
        </div>
      </section>

      {/*
-------------------------------
 Responsive Mobile View Code 
 ---------------------------
 */}

      <section className="section p-0 d-lg-none d-md-none d-block">
        <div className="container px-lg-3">
          {mentor?.courses?.length > 0 && (
            <div className="heading mb-2 mx-1">
              <div className="row">
                <h2 className="title mb-3 head1">
                  Courses offered (
                  {
                    mentor?.courses?.filter(
                      (value: any) => value.status === "PUBLISHED",
                    ).length
                  }
                  )
                </h2>
              </div>
            </div>
          )}

          <div className="row">
            {mentor?.courses?.map((value: any) => {
              const date = moment
                .utc(value.startDatetime)
                .local()
                .format("YYYY-MM-DD HH:mm:ss");
              return (
                <>
                  {value.status === "PUBLISHED" && (
                    <>
                      <div
                        className=" col-lg-3 col-md-6 col-12 mt-2 py-1 d-lg-block d-md-block d-block"
                        onClick={() => {
                          navigate(`/course-view/${value.slug}`);
                        }}
                      >
                        <div className="card course-card round overflow-hidden fixed-height-card">
                          <div className="card-body rounded-md p-4 cursor-pointer">
                            <div className="card-img image position-relative overflow-hidden">
                              {value?.images?.map((item: any) => {
                                return (
                                  <>
                                    <img
                                      alt=""
                                      src={item.original}
                                      className=" card-img-top  image img-fluid rounded bg-light"
                                    />
                                  </>
                                );
                              })}
                              {currentAuthToken !== null && (
                                <div
                                  className="course-tag cursor-pointer"
                                  onClick={() => {
                                    handleWishList(value.id);
                                  }}
                                >
                                  <div className="course-badge text-dark p-2 py-1 rounded-1">
                                    <i className="fa fa-bookmark tag fs-6"></i>
                                  </div>
                                </div>
                              )}
                            </div>

                            <div className="card-text mt-3">
                              <div className="d-flex justify-content-between">
                                <button
                                  className="btn btn-outline-success webinar-btn rounded-pill px-3 py-1 text-dark"
                                  type="button"
                                >
                                  {value?.courseCategory?.name}
                                </button>
                                <div className="align-items-center border border-orange course_difficulty d-flex justify-content-center px-2 rounded-pill text-center">
                                  <i
                                    className={`mt-0 icon__course-difficulty difficulty-${value.courseLevel.name} position-relative`}
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    data-bs-html="true"
                                    title={`Course Defficulty : ${value.courseLevel.name}`}
                                  >
                                    <span
                                      className={` bar-1 ${
                                        value.courseLevel.name === "Advanced" ||
                                        value.courseLevel.name ===
                                          "Intermediate" ||
                                        value.courseLevel.name === "Beginner"
                                          ? "opacity-100"
                                          : "opacity-25"
                                      } `}
                                    ></span>
                                    <span
                                      className={` bar-2 ${
                                        value.courseLevel.name === "Advanced" ||
                                        value.courseLevel.name ===
                                          "Intermediate"
                                          ? "opacity-100"
                                          : "opacity-25"
                                      } `}
                                    ></span>
                                    <span
                                      className={` bar-3 ${
                                        value.courseLevel.name === "Advanced"
                                          ? "opacity-100"
                                          : "opacity-25"
                                      } `}
                                    ></span>
                                  </i>
                                </div>
                              </div>
                              <h6 className="card-title mt-2 fw-bold cursor-pointer">
                                {value.name}
                              </h6>
                              <p className="text-muted">
                                {value?.mentors?.map(
                                  (mentor: any, index: any) => (
                                    <React.Fragment key={index}>
                                      <a
                                        className="text-decoration-none text-muted-dark"
                                        href={`/mentor-view/${mentor.mentor.id}`}
                                      >
                                        {index > 0 ? (
                                          <>&nbsp;and&nbsp;</>
                                        ) : (
                                          <span>By&nbsp;</span>
                                        )}
                                        {mentor.mentor.firstName ?? ""}{" "}
                                        {mentor.mentor.lastName ?? ""}
                                        {index < value.mentors.length - 1 ? (
                                          <></>
                                        ) : null}
                                      </a>
                                    </React.Fragment>
                                  ),
                                )}
                                <div className="mt-2 text-muted-dark">
                                  <img
                                    src="../media/icons/calendar-event-fill.svg"
                                    alt=""
                                    className="fs-5 me-1"
                                  />
                                  {/* <MdDateRange className="fs-5 me-1" /> */}
                                  {value.startDatetime == null
                                    ? ""
                                    : moment(date).format(
                                        "ddd, DD MMM YYYY • hh:mm A ",
                                      )}
                                </div>

                                <div className="mt-1 text-muted-dark">
                                  <HiMiniComputerDesktop className="fs-5 me-1" />{" "}
                                  {value.courseMode?.name}
                                </div>
                              </p>
                              <div className="mt-2 fw-bolder align-items-center">
                                {value.sellPrice !== 0 ? (
                                  <>
                                    <i className="fa fa-indian-rupee"></i>
                                    <span className="fs-4">
                                      {value.sellPrice}
                                    </span>
                                  </>
                                ) : (
                                  ""
                                )}
                                {value.amount === 0 &&
                                  value.sellPrice === 0 && (
                                    <div className="mt-2 fw-bolder align-items-center">
                                      <span className="fs-4">Free</span>
                                    </div>
                                  )}
                                {value.amount !== 0 ? (
                                  <>
                                    <del className="text-muted fs-6 ms-2">
                                      <i className="fa fa-indian-rupee"></i>
                                      <span className="fs-6">
                                        {value.amount}
                                      </span>
                                    </del>
                                  </>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card course-card border-1 mx-2 d-lg-none d-md-none d-none h-100 m-2 ">
                        <div className="card-body">
                          <div className="row">
                            <div className=" col-xl-4 col-lg-4 col-12 top selling p-0">
                              <div className="card-img image position-relative overflow-hidden ">
                                <img
                                  alt=""
                                  src={value.image}
                                  className=" card-img-top  image img-fluid rounded "
                                />
                                <div className="course-tag cursor-pointer d-none">
                                  <div className="course-badge text-dark p-2 py-1 rounded-1">
                                    <i className="fa fa-bookmark tag fs-6"></i>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-xl-8 col-lg-8 col-8 p-2">
                              <div className="card-text">
                                <h6 className="card-title fw-bold text-muted cursor-pointer">
                                  {value.title}
                                </h6>
                                <p className="text-muted">
                                  {value?.mentors?.length > 0 ? (
                                    <>
                                      {value?.mentors?.map((value: any) => {
                                        return (
                                          <>
                                            By{" "}
                                            <span>
                                              {value.mentor.firstName ?? ""}
                                            </span>
                                          </>
                                        );
                                      })}
                                    </>
                                  ) : null}
                                  <div className="">
                                    <MdDateRange className="fs-5 me-1" />
                                    {value.date}
                                  </div>

                                  <div className="">
                                    <HiMiniComputerDesktop className="fs-5 me-1" />{" "}
                                    {value.mode}
                                  </div>
                                </p>
                                <div className=" fw-bolder align-items-center">
                                  <i className="fa fa-indian-rupee"></i>
                                  <span className="fs-4">
                                    {value.sellPrice}
                                  </span>
                                  <del className="text-muted fs-6 ms-2">
                                    <i className="fa fa-indian-rupee"></i>
                                    <span className="fs-6">{value.amount}</span>
                                  </del>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
};
