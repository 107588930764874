import React, { FC, useState } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import { LOGIN_USER, STUDENTLOGINWITHSOCIALMEDIA } from './core/_requests';
import { useAuth } from './core/Auth';
import Modal from 'react-bootstrap/Modal';
import toast from 'react-hot-toast';
import { ForGotPassword } from 'components/common/ForGotPassword/ForGotPassword';
import { useMutation } from '@apollo/client';
import './login.css';
import { toAbsoluteUrl } from 'assets/helpers/AssetHelpers';
import { useUserContext } from 'context/UserContext';
import { UserType } from 'types/globalTypes';
import useCourses from 'hooks/useCourses';
import Loading from 'components/_v2/CommonComponents/Loading';
import Button from 'components/_v2/CommonComponents/Button';
import { ButtonBorderType, ButtonType } from 'components/_v2/CommonComponents/types';
import CommonPasswordInput from 'components/_v2/CommonComponents/CommonPasswordInput';
import { ErrorMessage, Field } from 'formik';

type Props = {
	open: any;
	setOpen: any;
	toggleLoginOpen: () => void;
	toggleRegisterOpen: () => void;
	openRegister: any;
	setOpenRegister: any;
};

const passwordRegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,20}$/;

const loginSchema = Yup.object().shape({
	email: Yup.string()
		.email('Please enter a valid email address')
		.min(3, 'Minimum 3 symbols')
		.max(50, 'Maximum 50 symbols')
		.required('Please enter your email'),
	password: Yup.string()
		.required('Please enter your password')
		.matches(
			passwordRegExp,
			'Password must include at least 8 character, one special character, one digit, one uppercase and one lowercase letter',
		),
});
const initialValues = {
	email: '',
	password: '',
	confirmPassword: '',
};

export const Login: FC<Props> = ({
	open,
	setOpen,
	toggleRegisterOpen,
	openRegister,
	setOpenRegister,
	toggleLoginOpen,
}: any) => {
	const { getUserCourses } = useCourses();
	const { refetchLocalStorageTokenDetails } = useUserContext();

	const handleClose = () => setOpen(false);
	const handleCloseLogin = () => setOpen(false);

	const [login] = useMutation(LOGIN_USER);
	const [studentSignInWithSocialAccount] = useMutation(STUDENTLOGINWITHSOCIALMEDIA);
	const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);
	const [showPassword, setShowPassword] = useState(false);
	const [loading, setLoading] = useState(false);
	const { saveAuth, setCurrentUser } = useAuth();
	const { setUser } = useUserContext();
	const [status, setStatus] = useState<any>('');
	const [showAccountSelection, setShowAccountSelection] = useState(true);

	const loginGoogle = useGoogleLogin({
		onSuccess: async (codeResponse: any) => {
			try {
				if (showAccountSelection) {
					handleCloseLogin();
				}
				const userInfoResponse = await axios.get(
					`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${codeResponse.access_token}`,
					{
						headers: {
							Authorization: `Bearer ${codeResponse.access_token}`,
							Accept: 'application/json',
						},
					},
				);

				const { data } = await studentSignInWithSocialAccount({
					variables: {
						email: userInfoResponse.data.email,
						name: userInfoResponse.data.name,
						signinType: 'GOOGLE',
					},
					onCompleted: async (data: any) => {
						localStorage.setItem(
							'auth-react-v',
							JSON.stringify(data?.studentSignInWithSocialAccount),
						);
						refetchLocalStorageTokenDetails();
						await getUserCourses();
						toast.success('Successfully signed in with the correct credentials.');
					},
					onError: (err: any) => {
						toast.error(err.message);
					},
				});
			} catch (error) {
				console.error(error);
			}
		},
		onError: (error: any) => {
			toast.error(error.message);
			toast.error('Please Select proper account for the google signin');
		},
	});
	const handleForgotPasswordOpen = () => {
		setShowForgotPasswordModal(true);
	};

	const handleForgotPasswordClose = () => {
		setShowForgotPasswordModal(false);
		setOpen(true);
	};

	const handleLogin = async (values, { setStatus, setSubmitting }) => {
		setLoading(true);
		try {
			await login({
				variables: {
					email: values.email,
					password: values.password,
				},
				onCompleted: async (data: { loginUser?: { access_token: string; user: UserType } }) => {
					const result = data?.loginUser;
					if (result && result.user.userType === 'STUDENT') {
						localStorage.setItem('auth-react-v', JSON.stringify(result));
						setUser(result.user);
						refetchLocalStorageTokenDetails();
						await getUserCourses();
						toast.success('Successfully signed in with the correct credentials.');
						handleClose();
					} else {
						setStatus(<div className="g_reg">Only students are allowed to login.</div>);
					}
				},
				onError: (err: any) => {
					toast.error(err.message);
				},
			});
		} catch (error) {
			console.error(error);
			setStatus('The login details are incorrect');
			setSubmitting(false);
			setLoading(false);
		}
		setLoading(false);
	};

	return (
		<>
			{open === true && (
				<>
					<Modal
						centered
						show={open}
						onHide={showAccountSelection ? handleCloseLogin : undefined}
						backdrop={true}>
						<Modal.Header className="header-top-layout">
							<Modal.Title className="border-0">
								<div className="row">
									<div className="col-md-12 lora login-title">Log In</div>
									<div className="login-new-label g_reg">
										You Don’t have an Account? &nbsp;
										<span
											className="login-new-label-span g_reg text-decoration-none cursor-pointer"
											onClick={toggleRegisterOpen}>
											Sign Up
										</span>
									</div>
								</div>
							</Modal.Title>
							<div
								className="btn btn-icon btn-sm btn-active-icon-primary close-btn-style g_reg"
								onClick={handleCloseLogin}>
								<i className="fa fa-close"></i>
							</div>
						</Modal.Header>

						<Modal.Body className="body-layout">
							<Formik
								initialValues={initialValues}
								validationSchema={loginSchema}
								onSubmit={handleLogin}>
								{({ status, isSubmitting, isValid, touched, errors, handleSubmit }) => (
									<>
										<div className="row">
											{status && (
												<div className="col-12 mb-3">
													<div className="mb-lg-15 alert alert-danger">
														<div className="alert-text font-weight-bold">{status}</div>
													</div>
												</div>
											)}

											<div className="fv-row d-flex flex-column">
												<label className="label-style g_reg">
													Email <span style={{ color: 'red' }}>*</span>
												</label>

												<Field
													name="email"
													type="email"
													placeholder="Enter Email Address"
													className={`form-control input-box-style g_reg w-100 ${
														!touched.email
															? ''
															: touched.email && errors.email
															? 'is-invalid'
															: 'is-valid'
													}`}
												/>

												<ErrorMessage name="email">
													{(msg) => (
														<div className="text-danger fs-7 mt-2">
															<i className="fa fa-exclamation-circle me-1"></i>
															{msg}
														</div>
													)}
												</ErrorMessage>
											</div>
										</div>

										<div className="col-12 distance">
											<CommonPasswordInput
												name="password"
												disabled={false}
												label="Password"
												placeholder="Enter your password"
												showValidOrInvalidOutlineClass={
													!touched.password
														? ''
														: touched.password && errors.password
														? 'is-invalid'
														: 'is-valid'
												}
												handleSubmit={handleSubmit}
											/>
										</div>
										<a
											className="float-end fg-style g_reg mb-3 cursor-pointer"
											onClick={handleForgotPasswordOpen}>
											Forgot Password?
										</a>
										<div className="d-flex flex-stack fs-base fw-semibold distance-bottom flex-wrap gap-3" />

										<div className="col-12 distance">
											<Button
												buttonText={
													<>
														{!loading && <span className="g_mid">Continue</span>}
														{loading && (
															<span className="d-flex w-100 justify-content-center gap-2">
																<Loading width={50} />
																Please wait...
															</span>
														)}
													</>
												}
												buttonType={ButtonType.PRIMARY_BUTTON}
												classes="w-100 g_mid fs-16"
												buttonBorderType={ButtonBorderType.NO_BORDER}
												disabled={isSubmitting || !isValid}
												handleClick={handleSubmit}
											/>
											<div className="divider">Or</div>
											<div className="col-12 distance mb-5">
												<button
													type="button"
													className="btn cwg-style w-100 g_mid"
													onClick={() => {
														loginGoogle();
													}}>
													<img
														alt="Logo"
														src={toAbsoluteUrl('/media/svg/brand-logos/google-icon.svg')}
														style={{ width: '25px' }}
														className="h-17px me-3 mb-1"
													/>
													{/* <FcGoogle className="me-2" /> */}
													Continue with Google
												</button>
											</div>
										</div>
									</>
								)}
							</Formik>
						</Modal.Body>
					</Modal>
				</>
			)}
			{showForgotPasswordModal && (
				<ForGotPassword
					showForgotPasswordModal={showForgotPasswordModal}
					handleForgotPasswordClose={handleForgotPasswordClose}
				/>
			)}
		</>
	);
};
