import React from 'react';
import './CourseReviews.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { TestimonialsType } from 'components/_v2/CourseDetail/CourseReviews/type';
import CommonSlider from 'components/_v2/CommonComponents/CommonSlider';
import { SliderComponentType } from 'components/_v2/CommonComponents/types';
import { UserCourseRating } from 'components/_v2/Course/type';

const CourseReviews = ({
	reviewsData,
	fullWidth,
}: {
	reviewsData: UserCourseRating[];
	fullWidth?: boolean;
}) => {
	return (
		<section className="px-3">
			<div className="container">
				<div className={`row ${fullWidth ? '' : 'px-lg-5 px-sm-3 px-2'}`}>
					<div className={`${fullWidth ? 'col-lg-12' : 'col-lg-7 col-12'}`}>
						<div className="row">
							<div className={`${fullWidth ? '' : 'offset-lg-1'} p-lg-0`}>
								<CommonSlider
									headingText="Reviews & Course Ratings"
									headingSubText=""
									showBackdropShadow={false}
									slidesToShow={2}
									sliderComponentType={SliderComponentType.REVIEWS}
									showViewAllButton={false}
									reviewsData={reviewsData}
									disableHoverInteraction
									hasTopRightButtons
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default CourseReviews;
