import { toAbsoluteUrl } from "../../assets/helpers/AssetHelpers";
import moment from "moment";
import React from "react";
import { useNavigate } from "react-router-dom";

const BookmarkCard = (props: any) => {
  const { value, onDeleteWishList } = props;
  const parsedDate = moment(value?.courseDetails?.startDatetime);
  const formattedDate = parsedDate.format("ddd, DD MMM YYYY • h:mm A");
  const navigate = useNavigate();
  let wishlistEvent = false;
  const handleOpen = (id: any) => {
    if (wishlistEvent === false) {
      navigate(`/course-view/${id}`);
    }
  };
  const handleDeleteWishList = async (id: any) => {
    wishlistEvent = true;
    props.onDeleteWishList(id);
  };
  return (
    <>
      <div className="col-xxl-4 col-xl-4 col-lg-4 col-6 col-md-3 p-2 topSelling  d-lg-block d-md-block d-none">
        <div className="card course-card round overflow-hidden border-muted h-100 cursor-pointer">
          <div
            className="card-body p-4"
            onClick={() => {
              handleOpen(value.courseDetails.slug);
            }}
          >
            <div className="card-img image position-relative overflow-hidden">
              {value?.courseDetails.images?.map((item: any) => {
                return (
                  <>
                    <img
                      alt=""
                      src={item.original}
                      className=" card-img-top  image img-fluid rounded bg-light"
                    />
                  </>
                );
              })}
            </div>
            <div className="col-xl-12 col-lg-12 col-12 mt-3">
              <div className="d-flex justify-content-between mb-2">
                <span className="btn btn-outline-success course-btn rounded-pill px-3 py-1 text-dark">
                  {value.courseDetails.courseCategory.name
                    ? value.courseDetails.courseCategory.name
                    : "Technical"}
                </span>
                <div className="align-items-center border border-orange course_difficulty d-flex justify-content-center px-2 rounded-pill text-center">
                  <i
                    className={`mt-0 icon__course-difficulty difficulty-${value.courseDetails.courseLevel.name} position-relative`}
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    data-bs-html="true"
                    title={`Course Defficulty : ${value.courseDetails.courseLevel.name}`}
                  >
                    <span
                      className={` bar-1 ${
                        value.courseDetails.courseLevel.name === "Advanced" ||
                        value.courseDetails.courseLevel.name ===
                          "Intermediate" ||
                        value.courseDetails.courseLevel.name === "Beginner"
                          ? "opacity-100"
                          : "opacity-25"
                      } `}
                    ></span>
                    <span
                      className={` bar-2 ${
                        value.courseDetails.courseLevel.name === "Advanced" ||
                        value.courseDetails.courseLevel.name === "Intermediate"
                          ? "opacity-100"
                          : "opacity-25"
                      } `}
                    ></span>
                    <span
                      className={` bar-3 ${
                        value.courseDetails.courseLevel.name === "Advanced"
                          ? "opacity-100"
                          : "opacity-25"
                      } `}
                    ></span>
                  </i>
                </div>
              </div>

              <div>
                <p className="card-text mt-2 fw-bold">
                  {value.courseDetails.name}
                  {value?.courseDetails?.mentors?.length > 0 ? (
                    <>
                      <p className="fw-normal text-muted-dark mt-1">
                        By{" "}
                        {value.courseDetails.mentors.map(
                          (item: any, index: number) => (
                            <React.Fragment key={index}>
                              {item.mentor.firstName}
                              {index < value.courseDetails.mentors.length - 2
                                ? ", "
                                : index ===
                                  value.courseDetails.mentors.length - 2
                                ? " and "
                                : ""}
                            </React.Fragment>
                          ),
                        )}
                      </p>
                    </>
                  ) : null}
                </p>
              </div>

              {formattedDate !== null ? (
                <div className="course-head text-muted-dark  font-subtitle fw-medium mb-lg-2">
                  <span className="">
                    <img
                      src="../media/icons/calendar-event-fill.svg"
                      alt=""
                      className="fs-5 me-1"
                    />
                  </span>
                  <span>{formattedDate} </span>
                </div>
              ) : null}
              <div className="mt-2 text-muted-dark">
                {value.courseDetails.address && (
                  <>
                    <i className="fa-solid fa-location-dot fs-5 me-1"></i>{" "}
                    {value.courseDetails.address}
                  </>
                )}
              </div>
              <div className="course-head text-muted-dark font-subtitle fw-medium mb-lg-2">
                <span className="me-2">
                  <i className="fa-solid fa-desktop"></i>
                </span>
                <span>
                  {value?.courseDetails.courseMode.name
                    ? value?.courseDetails.courseMode.name
                    : "Online"}
                </span>
              </div>
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  {value.courseDetails.sellPrice !== 0 ? (
                    <>
                      <span className="">
                        <i className="fa-solid fa-indian-rupee-sign fs-5"></i>
                        <span className="fs-4 fw-bolder">
                          {value.courseDetails.sellPrice}
                        </span>
                      </span>
                    </>
                  ) : (
                    ""
                  )}
                  {value.courseDetails.amount === 0 &&
                    value.courseDetails.sellPrice === 0 && (
                      <div className="mt-2 fw-bolder align-items-center">
                        <span className="fs-4">Free</span>
                      </div>
                    )}
                  {value.courseDetails.sellPrice !== 0 ? (
                    <>
                      <span className="ms-2 sell text-decoration-line-through text-muted-dark">
                        <i className="fa-solid fa-indian-rupee-sign fs-6"></i>
                        <span className="">
                          {value.courseDetails.sellPrice}
                        </span>
                      </span>
                    </>
                  ) : (
                    ""
                  )}
                </div>
                <div
                  onClick={() => {
                    handleDeleteWishList(value.courseId);
                  }}
                >
                  <a className="text-decoration-none text-danger fw-bolder fs-5">
                    Remove
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*
--------------------------------------------------------------------
Responsive Code For Mobile 
*/}

      <div className="col-xxl-4 col-xl-4 col-lg-4 col-12 col-md-3 p-2 topSelling  d-lg-none d-md-none d-block">
        <div className="card course-card round overflow-hidden border-muted h-100 cursor-pointer">
          <div
            className="card-body px-4"
            onClick={() => {
              handleOpen(value.courseDetails.id);
            }}
          >
            <div className="card-img image position-relative overflow-hidden">
              {value?.courseDetails.images?.map((item: any) => {
                return (
                  <>
                    <img
                      alt=""
                      src={item.original}
                      className=" card-img-top  image img-fluid rounded bg-light"
                    />
                  </>
                );
              })}
            </div>
            <div className="col-xl-12 col-lg-12 col-12 mt-3">
              <div className="d-flex gap-2 mb-2">
                <span className="btn btn-outline-success course-btn rounded-pill px-3 py-1 text-dark">
                  {value.courseDetails.courseCategory.name
                    ? value.courseDetails.courseCategory.name
                    : "Technical"}
                </span>
                <div className="align-items-center border border-orange course_difficulty d-flex justify-content-center px-2 rounded-pill text-center">
                  <i
                    className={`mt-0 icon__course-difficulty difficulty-${value.courseDetails.courseLevel.name} position-relative`}
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    data-bs-html="true"
                    title={`Course Defficulty : ${value.courseDetails.courseLevel.name}`}
                  >
                    <span
                      className={` bar-1 ${
                        value.courseDetails.courseLevel.name === "Advanced" ||
                        value.courseDetails.courseLevel.name ===
                          "Intermediate" ||
                        value.courseDetails.courseLevel.name === "Beginner"
                          ? "opacity-100"
                          : "opacity-25"
                      } `}
                    ></span>
                    <span
                      className={` bar-2 ${
                        value.courseDetails.courseLevel.name === "Advanced" ||
                        value.courseDetails.courseLevel.name === "Intermediate"
                          ? "opacity-100"
                          : "opacity-25"
                      } `}
                    ></span>
                    <span
                      className={` bar-3 ${
                        value.courseDetails.courseLevel.name === "Advanced"
                          ? "opacity-100"
                          : "opacity-25"
                      } `}
                    ></span>
                  </i>
                </div>
              </div>

              <div>
                <p className="card-text mt-2 fw-bold py-1">
                  {value.courseDetails.name}
                  {value?.courseDetails?.mentors?.length > 0 ? (
                    <>
                      <p className="fw-normal text-muted-dark mt-1">
                        By{" "}
                        {value.courseDetails.mentors.map(
                          (item: any, index: number) => (
                            <React.Fragment key={index}>
                              {item.mentor.firstName}
                              {index < value.courseDetails.mentors.length - 2
                                ? ", "
                                : index ===
                                  value.courseDetails.mentors.length - 2
                                ? " and "
                                : ""}
                            </React.Fragment>
                          ),
                        )}
                      </p>
                    </>
                  ) : null}
                </p>
              </div>

              {formattedDate !== null ? (
                <div className="course-head text-muted-dark  font-subtitle fw-medium mb-lg-5 py-1">
                  <span className="">
                    <img
                      src="../media/icons/calendar-event-fill.svg"
                      alt=""
                      className="fs-5 me-1"
                    />
                  </span>
                  <span>{formattedDate} </span>
                </div>
              ) : null}
              <div className="course-head text-muted-dark font-subtitle fw-medium mb-lg-2">
                <span className="me-2">
                  <i className="fa-solid fa-desktop"></i>
                </span>
                <span>
                  {value?.courseDetails.courseMode.name
                    ? value?.courseDetails.courseMode.name
                    : "Online"}
                </span>
              </div>
              <div className="d-flex justify-content-between align-items-center py-1">
                <div>
                  {value.courseDetails.sellPrice !== 0 ? (
                    <>
                      <span className="">
                        <i className="fa-solid fa-indian-rupee-sign fs-5"></i>
                        <span className="fs-4 fw-bolder">
                          {value.courseDetails.sellPrice}
                        </span>
                      </span>
                    </>
                  ) : (
                    ""
                  )}

                  {value.courseDetails.amount === 0 &&
                    value.courseDetails.sellPrice === 0 && (
                      <div className="mt-2 fw-bolder align-items-center">
                        <span className="fs-4">Free</span>
                      </div>
                    )}
                  {value.courseDetails.sellPrice !== 0 ? (
                    <>
                      <span className="ms-2 sell text-decoration-line-through text-muted-dark">
                        <i className="fa-solid fa-indian-rupee-sign fs-6"></i>
                        <span className="">
                          {value.courseDetails.sellPrice}
                        </span>
                      </span>
                    </>
                  ) : (
                    ""
                  )}
                </div>
                <div
                  onClick={() => {
                    handleDeleteWishList(value.courseId);
                  }}
                >
                  <a className="text-decoration-none text-danger fw-bolder fs-5">
                    Remove
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BookmarkCard;
