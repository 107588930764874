/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import { FC } from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
// import { RelayEnvironmentProvider } from "react-relay";
// import environment from "../../environment";

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
// const { PUBLIC_URL } = process.env;
import { PrivateRoutes } from './PrivateRoutes';
import { Toaster } from 'react-hot-toast';
// import client from "../../appoloclient";
import { ApolloProvider } from '@apollo/client';
import client from '../../client';
import ScrollToElement from '../../components/_v2/ScrollToTop/ScrollToElement';
import { ZoomMeetingProvider } from '../../context/ZoomMeetingContext';
import { AuthModalProvider } from '../../context/AuthContext';
import LoginAndRegisterModals from 'components/_v2/CommonComponents/LoginAndRegisterModals';
const AppRoutes: FC = () => {
	return (
		<>
			{/* <RelayEnvironmentProvider environment={environment}> */}
			<ApolloProvider client={client}>
				<BrowserRouter>
					<Toaster />
					<ScrollToElement />
					<ZoomMeetingProvider>
						<AuthModalProvider>
							<LoginAndRegisterModals />
							{/*Global background color and full page color set*/}
							<div className="bg-backgroundGlobal">
								<Routes>
									<Route path="/*" element={<PrivateRoutes />} />
								</Routes>
							</div>
						</AuthModalProvider>
					</ZoomMeetingProvider>
				</BrowserRouter>
			</ApolloProvider>
			{/* </RelayEnvironmentProvider> */}
		</>
	);
};

export { AppRoutes };
