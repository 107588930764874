import React, { useState } from 'react';
import './Button.css';
import { ButtonBorderType, ButtonType } from './types';

interface Props {
	buttonText: string | React.JSX.Element;
	classes: string;
	handleClick?: (e?: any) => void;
	buttonType: ButtonType;
	buttonBorderType?: ButtonBorderType;
	disabled?: boolean;
	px?: string;
	py?: string;
	type?: 'button' | 'submit' | 'reset';
}

const Button = ({
	buttonText,
	classes = '',
	handleClick,
	buttonType,
	buttonBorderType = ButtonBorderType.NO_BORDER,
	disabled = false,
	px = '26px',
	py = '12px',
	type,
}: Props) => {
	const [hovered, setHovered] = useState(false);
	return (
		<div>
			<button
				disabled={disabled}
				type={type}
				className={`base-btn-style ${
					hovered || disabled ? 'opacity-75' : 'opacity-100'
				} ${classes} ${buttonType} ${buttonBorderType}`}
				onMouseEnter={() => setHovered(true)}
				onMouseLeave={() => setHovered(false)}
				style={{ padding: `${py} ${px}` }}
				onClick={handleClick}>
				{buttonText}
			</button>
		</div>
	);
};

export default Button;
