import React, { useEffect, useState } from 'react';
import { Course, RatingStatusEnum } from '../Course/type';
import './CourseTabs.scss';
import CourseHighLights from '../CourseDetail/CourseHighlights/CourseHighLights';
import CourseOverView from '../CourseDetail/CourseOverview/CourseOverView';
import InstructorProfile from '../CourseDetail/InstructorProfile/InstructorProfile';
import CourseCertificate from '../CourseDetail/CourseCertificate/CourseCertificate';
import CourseReviews from '../CourseDetail/CourseReviews/CourseReviews';
import CourseFAQS from '../CourseDetail/CourseFAQS/CourseFAQS';
import useTestimonialsAndFaq from 'hooks/useTestimonialsAndFaq';
import useIsSmallScreen from 'hooks/useIsSmallScreen';
import CourseProgramOverview from 'components/_v2/CourseDetail/CourseContent/CourseProgramOverview';
import { QuizReattemptDetails } from 'components/_v2/ViewCourseData/LessonTypesComponents/Quiz/type';
import RelatedCourses from 'components/_v2/CourseDetail/RelatedCourses/RelatedCourses';

const CourseTabs = ({
	course,
	currentSelectedLessonId,
	handleLessonChange,
	quizReattemptList,
}: {
	course: Course;
	handleLessonChange?: (lessonId: string) => void;
	currentSelectedLessonId?: string;
	quizReattemptList?: QuizReattemptDetails[];
}) => {
	const [activeTab, setActiveTab] = useState('highlights');
	const { errorFaqs, faqsData, getFaqsList, loadingFaqs } = useTestimonialsAndFaq(course?.id);

	const reviewsData =
		course?.userCourseRatings?.filter((review) => review?.status === RatingStatusEnum.APPROVED) ||
		[];

	useEffect(() => {
		getFaqsList();
	}, []);

	const handleTabChange = (tab: string) => {
		setActiveTab(tab);
	};

	const isTabletView = useIsSmallScreen(1200);
	useEffect(() => {
		if (!isTabletView && activeTab === 'content') {
			setActiveTab('highlights');
		}
		if (isTabletView) {
			setActiveTab('content');
		}
	}, [isTabletView]);

	const hasNoReviewsData = reviewsData?.length === 0;
	const hasNoFaqsData = loadingFaqs || errorFaqs || faqsData.length === 0;

	const tabInfo = [
		{ id: 'highlights', label: 'What You’ll Learn' },
		{ id: 'overview', label: 'About The Course' },
		{ id: 'instructor', label: 'Meet Your Mentor' },
		// { id: 'certificate', label: 'Certificate' },
	];

	if (!hasNoReviewsData) {
		tabInfo.push({ id: 'testimonials', label: 'Testimonials' });
	}
	if (!hasNoFaqsData) {
		tabInfo.push({ id: 'faqs', label: "FAQ's" });
	}

	if (isTabletView) {
		tabInfo.unshift({ id: 'content', label: 'Course Content' });
	}

	return (
		<div className="mt-4">
			<div className="mx-lg-0 mx-2 overflow-x-auto">
				<ul className="tab-list-course-tabs px-0" id="courseTabs" role="tablist">
					{tabInfo.map((tab) => (
						<li className="nav-item nav-items-course-tabs" role="tablist" key={tab.id}>
							<button
								className={`course-tabs ${activeTab === tab.id ? 'g_mid active' : 'g_reg'}`}
								type="button"
								onClick={() => handleTabChange(tab.id)}>
								{tab.label}
							</button>
						</li>
					))}
				</ul>
			</div>
			<div id="courseTabContent" className="px-0">
				{activeTab === 'content' && (
					<CourseProgramOverview
						sections={course?.sections}
						fullScale
						showLockIcon
						currentSelectedLessonId={currentSelectedLessonId}
						handleLessonChange={handleLessonChange}
						quizReattemptList={quizReattemptList}
					/>
				)}
				{activeTab === 'highlights' && (
					<CourseHighLights highlights={course?.highlights || []} fullWidth />
				)}
				{activeTab === 'overview' && (
					<CourseOverView
						description={course?.description || ''}
						objectives={course?.objectives || []}
						skills={course?.courseSkills || []}
						preRequisites={course?.requirements || ''}
						fullWidth
					/>
				)}
				{activeTab === 'instructor' && (
					<InstructorProfile instructors={course?.mentors || []} fullWidth />
				)}
				{activeTab === 'certificate' && <CourseCertificate courseName={course?.name} fullWidth />}
				{activeTab === 'testimonials' && <CourseReviews reviewsData={reviewsData} fullWidth />}
				{activeTab === 'faqs' && <CourseFAQS faqsData={faqsData} fullWidth removeSpacing />}
			</div>
			<RelatedCourses course={course} fullWidth removePadding />
		</div>
	);
};

export default CourseTabs;
