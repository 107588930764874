import {
  GETCOURSESMODESQUERY,
  GETPUBLICCOURSECATEGORIES,
  GETPUBLICCOURSESLEVELLISTQUERY,
  GETPUBLICLANGUAGESLISTQUERY,
} from "../../Home-Page/core/requests";
import { useQuery } from "@apollo/client";
enum PriceName {
  PAID = "PAID",
  FREE = "FREE",
}
type PriceItem = {
  id: number;
  value: string;
  name: PriceName;
};

const SideFilter = (props: any) => {
  const {
    handleModeChange,
    handleLevelChange,
    handleCategoryChange,
    handlePriceChange,
    handleLanguageChange,
    selectedPrice,
  } = props;
  const price: PriceItem[] = [
    { id: 1, value: "Paid", name: PriceName.PAID },
    { id: 2, value: "Free", name: PriceName.FREE },
  ];
  const { data: courseCategoriesResult } = useQuery(GETPUBLICCOURSECATEGORIES);
  const courseCategoriesRecord =
    courseCategoriesResult?.getPublicCourseCategories?.records ?? [];
  const { data: courseLevelResult } = useQuery(GETPUBLICCOURSESLEVELLISTQUERY);
  const courseLevelRecord =
    courseLevelResult?.getPublicCourseLevelList?.records ?? [];
  const { data: courseLanguageResult } = useQuery(GETPUBLICLANGUAGESLISTQUERY);
  const courseLanguageRecord =
    courseLanguageResult?.getPublicCourseLanguagesList?.records ?? [];
  const { data: courseModeResult } = useQuery(GETCOURSESMODESQUERY);
  const courseModeRecord =
    courseModeResult?.getPublicCourseModes?.records ?? [];

  return (
    <>
      <div className="col-lg-3 d-lg-block mb-3">
        <div className="card border-muted">
          <div className="card-header d-lg-flex justify-content-between align-items-center bg-transparent fs-5 fw-medium border-0">
            {/* <div className="text-dark fw-bold"><span>Filter By</span></div> */}
            <div className=" text-align-center  fs-5 fw-bold m-1 text-muted pt-2">
              Filter By
            </div>
            <div className="text-muted-dark d-none ">
              <div className="bg-primary border cursor-pointer fs-14 m-1 p-1 rounded-3 text-nowrap text-white">
                <span> Clear All</span>
                <i className="fa fa-close mx-1 text-nowrap" />
              </div>
            </div>
          </div>
          <div className="card-body p-0">
            <div className="accordion" id="accordionPanelsStayOpenExample">
              <div className="accordion-item border-0">
                <h2
                  className="accordion-header border-bottom"
                  id="panelsStayOpen-headingOne"
                >
                  <button
                    className="accordion-button collapsed text-muted"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#panelsStayOpen-collapseOne"
                    aria-expanded="false"
                    aria-controls="panelsStayOpen-collapseOne"
                  >
                    Categories
                  </button>
                </h2>
                <div
                  id="panelsStayOpen-collapseOne"
                  className="accordion-collapse collapse "
                  aria-labelledby="panelsStayOpen-headingOne"
                >
                  <div className="accordion-body text-muted">
                    {courseCategoriesRecord?.map((value: any, index: any) => {
                      return (
                        <div
                          className="form-check mt-2 d-flex justify-content-start align-items-start"
                          key={index}
                        >
                          <input
                            className="form-check-input fs-5"
                            type="checkbox"
                            id={value.id}
                            value={value.id}
                            onChange={(event: any) => {
                              handleCategoryChange(event, value);
                            }}
                          />
                          <label
                            className="form-check-label fs-6 ms-2 mt-1 "
                            htmlFor={value.id}
                          >
                            {value.name}
                          </label>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className="accordion-item border-0">
                <h2
                  className="accordion-header border-bottom"
                  id="panelsStayOpen-headingTwo"
                >
                  <button
                    className="accordion-button collapsed text-muted"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#panelsStayOpen-collapseTwo"
                    aria-expanded="false"
                    aria-controls="panelsStayOpen-collapseTwo"
                  >
                    Difficulty
                  </button>
                </h2>
                <div
                  id="panelsStayOpen-collapseTwo"
                  className="accordion-collapse collapse"
                  aria-labelledby="panelsStayOpen-headingTwo"
                >
                  <div className="accordion-body text-muted">
                    {/* Checked checkbox */}
                    {courseLevelRecord.map((value: any) => {
                      return (
                        <div className="form-check form-check mt-2 d-flex justify-content-start align-items-start">
                          <input
                            className="form-check-input fs-5"
                            type="checkbox"
                            defaultValue=""
                            id={value.id}
                            value={value.id}
                            onChange={(event: any) => {
                              handleLevelChange(event, value);
                            }}
                          />
                          <label
                            className="form-check-label mt-1 ms-2"
                            htmlFor={value.id}
                          >
                            {value.name}
                          </label>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className="accordion-item border-0">
                <h2
                  className="accordion-header border-bottom"
                  id="panelsStayOpen-headingThree"
                >
                  <button
                    className="accordion-button collapsed text-muted"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#panelsStayOpen-collapseThree"
                    aria-expanded="false"
                    aria-controls="panelsStayOpen-collapseThree"
                  >
                    Mode
                  </button>
                </h2>
                <div
                  id="panelsStayOpen-collapseThree"
                  className="accordion-collapse collapse "
                  aria-labelledby="panelsStayOpen-headingThree"
                >
                  <div className="accordion-body text-muted">
                    <div>
                      {/* Checked checkbox */}
                      {courseModeRecord.map((value: any) => {
                        return (
                          <div className="form-check form-check mt-2 d-flex justify-content-start align-items-start">
                            <input
                              className="form-check-input fs-5"
                              type="checkbox"
                              defaultValue=""
                              id={value.id}
                              value={value.id}
                              onChange={(event: any) => {
                                handleModeChange(event, value);
                              }}
                            />
                            <label
                              className="form-check-label mt-1 ms-2"
                              htmlFor={value.id}
                            >
                              {value.name}
                            </label>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
              <div className="accordion-item border-0">
                <h2
                  className="accordion-header border-bottom"
                  id="panelsStayOpen-headingFive"
                >
                  <button
                    className="accordion-button collapsed text-muted"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#panelsStayOpen-collapseFive"
                    aria-expanded="false"
                    aria-controls="panelsStayOpen-collapseFive"
                  >
                    Price
                  </button>
                </h2>
                <div
                  id="panelsStayOpen-collapseFive"
                  className="accordion-collapse collapse"
                  aria-labelledby="panelsStayOpen-headingFive"
                >
                  <div className="accordion-body text-muted">
                    <div>
                      {price.map((value: PriceItem) => (
                        <div
                          key={value.id}
                          className="form-check form-check mt-2 d-flex justify-content-start align-items-start"
                        >
                          <input
                            className="form-check-input fs-5"
                            type="checkbox"
                            defaultValue=""
                            id={value.id.toString()}
                            value={value.id}
                            checked={selectedPrice === value.name}
                            onChange={(event) => {
                              handlePriceChange(event, value);
                            }}
                          />
                          <label
                            className="form-check-label mt-1 ms-2"
                            htmlFor={value.id.toString()}
                          >
                            {value.value}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="accordion-item border-0">
                <h2
                  className="accordion-header"
                  id="panelsStayOpen-headingFour"
                >
                  <button
                    className="accordion-button collapsed text-muted"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#panelsStayOpen-collapseFour"
                    aria-expanded="false"
                    aria-controls="panelsStayOpen-collapseFour"
                  >
                    Language
                  </button>
                </h2>
                <div
                  id="panelsStayOpen-collapseFour"
                  className="accordion-collapse collapse"
                  aria-labelledby="panelsStayOpen-headingFour"
                >
                  <div className="accordion-body text-muted">
                    <div>
                      {/* Checked checkbox */}
                      {courseLanguageRecord.map((value: any) => {
                        return (
                          <div className="form-check form-check mt-2 d-flex justify-content-start align-items-start">
                            <input
                              className="form-check-input fs-5"
                              type="checkbox"
                              defaultValue=""
                              id={value.id}
                              value={value.id}
                              onChange={(event: any) => {
                                handleLanguageChange(event, value);
                              }}
                            />
                            <label
                              className="form-check-label mt-1 ms-2"
                              htmlFor={value.id}
                            >
                              {value.name}
                            </label>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SideFilter;
