import moment from 'moment';
import React from 'react';
import { NotificationsObjectType } from '../Header/types';
import { toAbsoluteUrl } from 'assets/helpers/AssetHelpers';

const IndividualNotification = ({ notification }: { notification: NotificationsObjectType }) => {
	return (
		<div key={notification.id} className="notification-item p-3">
			<div className="notification-icon">
				<img
					src={toAbsoluteUrl('/media/notification-images/n1.svg')}
					alt={notification.title}
					height="40px"
					width="40px"
				/>
			</div>
			<div className="notification-details w-100">
				<div className="d-flex justify-content-between">
					<div>
						<p className="noti-title g_mid mb-0">{notification.title}</p>
						<p className="noti-content g_reg mb-0">{notification.message}</p>
					</div>
					<div className="noti-date g_reg d-flex flex-column gap-2">
						<span>{moment(notification?.createdAt).format('DD/MM/YYYY')}</span>
						<span>{moment(notification?.createdAt).format('HH:mm:ss A')}</span>
					</div>
				</div>
			</div>
		</div>
	);
};

export default IndividualNotification;
