import React from 'react';

const SliderProgressPagination = ({
	startingOffset,
	individualProgressPercentage,
}: {
	startingOffset: number;
	individualProgressPercentage: number;
}) => {
	return (
		<div
			style={{
				position: 'relative',
				height: '10px',
				background: 'white',
				boxShadow: '0 0 10px rgba(0,0,0, 0.1)',
				borderRadius: '20px',
				marginTop: '20px',
			}}>
			<div
				style={{
					position: 'absolute',
					left: `${startingOffset}%`,
					width: `${individualProgressPercentage}%`,
					height: '100%',
					background: 'var(--color2)',
					borderRadius: '20px',
					transition: 'left 0.5s ease',
				}}
			/>
		</div>
	);
};

export default SliderProgressPagination;
