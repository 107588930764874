import React from "react";
import HeadingSection from "../PreRecordedCourses/HeadingSection";
import CourseCompleteCard from "../PreRecordedCourses/CourseCompleteCard";

const LiveCourseCompleted = () => {
  return (
    <>
      <section className="section px-xl-4 -x-lg-3 px-2">
        <div className="">
          <div className="row">
            <div className="col-12">
              <div className="row justify-content-lg-center align-items-lg-center border p-4">
                <div className="col-12">
                  <div className="border-bottom pb-lg-5 text-lg-center">
                    <div className="font-title course-head fs-2 font-ibm mb-lg-3">
                      Basics of Stock Market Investments
                    </div>
                    <img
                      src="../media/preRecord/celebration.png"
                      alt="celebration"
                      height="140"
                      width="140"
                      className="img-fluid"
                    />
                    <div className="course-head fs-4 fw-medium font-ibm mb-lg-3">
                      Congratulations!
                    </div>
                    <div className="course-head fs-18 fw-medium font-switzer color2">
                      You have successfully completed this course on
                    </div>
                    <div className="course-head fs-18 fw-bold font-switzer mb-lg-3 color2">
                      23rd September, 2023.
                    </div>
                    <div className="d-flex justify-content-center w-60 mx-auto">
                      <div
                        className="btn fs-18 font-switzer w-30 btn-color1 my-lg-2 me-3"
                        id="download-certificate"
                      >
                        Download Certificate
                      </div>
                      {/* <div className="btn fs-18 font-switzer w-30 btn-outline-color1 my-lg-2" id="start-over" >Start Over</div> */}
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="pt-lg-4">
                    <div className="course-head fs-4 fw-bold font-title mb-lg-3">
                      Course Details
                    </div>
                    <div className="course-head fs-18 font-subtitle fw-medium mb-lg-3">
                      <span className="me-2 color9">
                        <i className="fa-solid fa-calendar-day"></i>
                      </span>
                      <span>21 Sept 2023 </span>
                    </div>
                    <div className="course-head fs-18 font-subtitle fw-medium mb-lg-3">
                      <span className="me-2 color9">
                        <i className="fa-solid fa-clock"></i>
                      </span>
                      <span>6:00 pm to 8:00 pm </span>
                    </div>
                    <div className="course-head fs-20 fw-medium font-title mb-lg-2">
                      Overview
                    </div>
                    <div className="course-head fs-18 font-subtitle mb-lg-3">
                      Amet minim mollit non deserunt ullamco est sit aliqua
                      dolor do amet sint. Velit officia consequat duis enim
                      velit mollit. Exercitation veniam consequat sunt nostrud
                      amet. Amet minim mollit non deserunt ullamco est sit
                      aliqua dolor do amet sint. Velit officia consequat duis
                      enim velit mollit.
                    </div>
                  </div>
                  <div
                    className="pt-lg-2 mentor-details"
                    style={{ display: "block" }}
                  >
                    <div className="course-head fs-4 fw-bold font-title mb-lg-2 color5">
                      Mentor
                    </div>
                    <div className="d-flex align-items-center w-15">
                      <div className="w-15 text-center">
                        <img
                          src="../media/preRecord/mentor.png"
                          alt="mentor.png"
                          height="36"
                          width="36"
                          className="img-fluid"
                        />
                      </div>
                      <div className="w-85 px-lg-2">
                        <div className="color1font-subtitle fs-5 fw-medium">
                          Wade Warren
                        </div>
                      </div>
                    </div>
                    <div className="pt-lg-3">
                      <div className="course-head fs-20 fw-medium font-title mb-lg-2">
                        Downloadable Resources
                      </div>
                      <div className="d-lg-flex flex-lg-column w-25">
                        <a
                          className="text-decoration-none color5 fs-16 font-subtitle bg-color3 px-lg-3 py-lg-2 my-lg-2"
                          download="../pdf/sample.pdf"
                          href="../pdf/sample.pdf"
                        >
                          <span className="me-2 color1">
                            <i className="fa-solid fa-file-arrow-down"></i>
                          </span>
                          <span>Cheat sheet to xyz strategy.pdf</span>
                        </a>
                        <a
                          className="text-decoration-none color5 fs-16 font-subtitle bg-color3 px-lg-3 py-lg-2 my-lg-2"
                          download="./.pdf/sample.pdf"
                          href="../pdf/sample.pdf"
                        >
                          <span className="me-2 color1">
                            <i className="fa-solid fa-file-arrow-down"></i>
                          </span>
                          <span>Amet-non-deserunt-aliqua.xlsx</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container mx-3 d-flex justify-content-between">
          <div>
            <h1 className="font-title fw-fw-semibold fs-2 mt-3">
              You May Also Like
            </h1>
            <p className="font-subtitle">
              Some short description/subtext goes here like this
            </p>
          </div>
          <div className="">
            <button className="btn btn-outline-primary mt-5">
              Explore All <i className="fa-solid fa-arrow-right"></i>
            </button>
          </div>
        </div>
        <div className="container">
          <div className="row justify-content-center">
            <CourseCompleteCard />
            <CourseCompleteCard />
            <CourseCompleteCard />
            <CourseCompleteCard />
          </div>
        </div>
      </section>
    </>
  );
};

export default LiveCourseCompleted;
