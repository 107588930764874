import { gql } from "@apollo/client";
import { graphql } from "babel-plugin-relay/macro";

export const GETSTUDENTORDERHISTORY = gql`
  query requests_getStudentOrderHistoryQuery(
    $where: StudentProfileResolver_GetStudentOrderHistory_FilterInputType
    $paginate: PaginatorArgs
  ) {
    getStudentOrderHistory(where: $where, paginate: $paginate) {
      records {
        id
        userId
        subTotal
        total
        tax
        gstNo
        status
        transactionId
        paymentGatewayId
        paymentGatewayMode
        transactionStatus
        transactionDateTime
        totalDiscount
        paymentLink
        type
        createdAt
        orderDetails {
          id
          orderId
          courseId
          tax
          totalPrice
          noOfSeat
          price
          couponDiscountAmount
          discountValue
          createdAt
          subTotal
          course {
            id
            name
            slug
            shortDescription
            description
            sortOrder
            status
            images {
              thumb
              medium
              large
              original
            }
            amount
            sellPrice
            startDatetime
            endDatetime
            totalSeat
            costType
            address
            meetingLink
            meetingNumber
            meetingPassword
            hasQuiz
            requirements
            courseCategoryId
            courseCategory {
              id
              name
              sortNumber
              imageUrl
              slug
              parentId
              parent {
                id
                name
              }
            }
            courseSubCategoryId
            courseSubCategory {
              id
              name
              sortNumber
              imageUrl
              slug
              parentId
              parent {
                id
                name
              }
            }
            courseLevelId
            courseLevel {
              id
              name
              slug
            }
            courseModeId
            courseMode {
              id
              name
            }
            courseTypeId
            courseType {
              id
              name
              slug
            }
            courseLanguages {
              id
              name
              shortCode
              slug
              status
            }
            mentors {
              mentor {
                email
                firstName
                id
                lastName
                userProfile {
                  gender
                  userId
                  imageUrl
                  description
                  country {
                    id
                    countryName
                  }
                }
              }
            }
            objectives {
              objective
            }
            sections {
              id
              name
              sortNumber
              introduction
              courseId
              createdAt
              updatedAt
              createdById
              updatedById
              lessons {
                contentUrl
                description
                type
                quiz {
                  totalMarks
                  questions {
                    duration
                    question
                  }
                }
                introduction
                name
                duration
              }
            }
            courseSkills {
              id
              name
              slug
              status
            }
            highlights {
              id
              courseId
              highlightIcon
              highlightText
            }
          }
        }
      }
      pagination {
        totalRecords
        currentPage
      }
    }
  }
`;
