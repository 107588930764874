import React, { useCallback, useEffect, useMemo, useState } from 'react';
import DetailHeroSection from './DetailHeroSection';
import CourseHighLights from 'components/_v2/CourseDetail/CourseHighlights/CourseHighLights';
import CourseOverView from 'components/_v2/CourseDetail/CourseOverview/CourseOverView';
import CourseProgramOverview from 'components/_v2/CourseDetail/CourseContent/CourseProgramOverview';
import InstructorProfile from 'components/_v2/CourseDetail/InstructorProfile/InstructorProfile';
import CourseReviews from 'components/_v2/CourseDetail/CourseReviews/CourseReviews';
import CourseFAQS from 'components/_v2/CourseDetail/CourseFAQS/CourseFAQS';
import { useCourseContext } from 'context/CourseContext';
import useCourses from 'hooks/useCourses';
import { useNavigate, useParams } from 'react-router-dom';
import { Course, RatingStatusEnum } from 'components/_v2/Course/type';
import './DetailHeroSection.css';
import PopoverCourseDetailCard from './PopoverCourseDetailCard';
import useTestimonialsAndFaq from 'hooks/useTestimonialsAndFaq';
import { RUPEE_SYMBOL } from 'utils/consts';
import { Image } from 'react-bootstrap';
import moment from 'moment';
import { toAbsoluteUrl } from 'assets/helpers/AssetHelpers';
import {
	COURSE_URL,
	getCoursePaymentSubUrl,
	getViewCourseWithSearchSlug,
} from 'pages/routing/routePages';
import { useUserContext } from 'context/UserContext';
import useIsSmallScreen from 'hooks/useIsSmallScreen';
import Button from 'components/_v2/CommonComponents/Button';
import { ButtonBorderType, ButtonType } from 'components/_v2/CommonComponents/types';
import RelatedCourses from 'components/_v2/CourseDetail/RelatedCourses/RelatedCourses';
import { useAuthModalContext } from 'context/AuthContext';

const DetailPage = () => {
	const { hasValueSet, courses, userCourses } = useCourseContext();
	const isSmallScreen = useIsSmallScreen(992);
	const { user } = useUserContext();
	const [isVisible, setIsVisible] = useState(true);
	const [lastScrollY, setLastScrollY] = useState(0);
	const [shouldTranslate, setShouldTranslate] = useState(true);

	const { setOpenRegister } = useAuthModalContext();

	const handleScroll = () => {
		const currentScrollY = window.scrollY;

		if (currentScrollY > lastScrollY) {
			// Scrolling down
			setIsVisible(true);
		} else {
			// Scrolling up
			setIsVisible(false);
		}

		setLastScrollY(currentScrollY);
	};

	const { getCourses } = useCourses();
	const navigate = useNavigate();
	const { courseSlug } = useParams();

	useEffect(() => {
		const loadScript = async () => {
			const script = document.createElement('script');
			script.src = '/assets/commonJs/global.js';
			script.async = true;
			document.body.appendChild(script);

			script.onload = () => {
				console.log('Global.js script loaded successfully.');
			};

			script.onerror = () => {
				console.error('Error loading Global.js script.');
			};
		};

		loadScript();

		return () => {
			const script = document.querySelector(`script[src="/assets/commonJs/global.js"]`);
			if (script) {
				document.body.removeChild(script);
			}
		};
	}, []);

	useEffect(() => {
		if (!hasValueSet) {
			getCourses();
		}
	}, [hasValueSet]);

	useEffect(() => {
		window.addEventListener('scroll', handleScroll);

		// Cleanup the event listener on component unmount
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, [lastScrollY]);
	useEffect(() => {
		const handleScroll = () => {
			const footer = document.querySelector('#footer');

			if (footer) {
				const footerRect = footer.getBoundingClientRect();
				const viewportHeight = window.innerHeight;

				if (footerRect.top < viewportHeight) {
					setShouldTranslate(false);
				} else {
					setShouldTranslate(true);
				}
			}
		};

		window.addEventListener('scroll', handleScroll);
		return () => window.removeEventListener('scroll', handleScroll);
	}, []);

	const currentCourse = useMemo(() => {
		return (
			courses.find((course: Course) => course.slug == courseSlug) ??
			userCourses.find((course: Course) => course.slug == courseSlug)
		);
	}, [courses, courseSlug, userCourses]);

	useEffect(() => {
		if (courses.length !== 0 && !currentCourse) {
			navigate(COURSE_URL);
		}
	}, [currentCourse, courseSlug]);

	const { errorFaqs, faqsData, getFaqsList, loadingFaqs } = useTestimonialsAndFaq(
		currentCourse?.id,
	);

	useEffect(() => {
		if (currentCourse) {
			getFaqsList();
		}
	}, [currentCourse]);

	const reviewsData = currentCourse?.userCourseRatings.filter(
		(review) => review.status === RatingStatusEnum.APPROVED,
	);

	const isCurrentDateBehindCourseStartDate = moment(currentCourse?.startDatetime).isAfter(moment());

	const isCourseSubscribed = userCourses.some((userCourse) => userCourse.id === currentCourse?.id);

	const handleContinueLearning = useCallback(() => {
		navigate(getViewCourseWithSearchSlug(currentCourse?.slug));
	}, [currentCourse]);

	const handleEnrollPageReroute = useCallback(() => {
		if (user) {
			navigate(getCoursePaymentSubUrl(currentCourse?.slug));
		} else {
			setOpenRegister(true);
		}
	}, [currentCourse?.slug, navigate, user]);

	if (!currentCourse) {
		return <p>Loading...</p>;
	}

	return (
		<>
			<div>
				<PopoverCourseDetailCard course={currentCourse} />
				<DetailHeroSection course={currentCourse} />
				<CourseHighLights highlights={currentCourse?.highlights || []} />
				<CourseProgramOverview sections={currentCourse?.sections} noBorderAccordion />
				<CourseOverView
					description={currentCourse?.description || ''}
					preRequisites={currentCourse?.requirements || ''}
					objectives={currentCourse?.objectives || []}
					skills={currentCourse?.courseSkills || []}
				/>
				<InstructorProfile instructors={currentCourse?.mentors} />
				{/*<CourseCertificate courseName={currentCourse?.name} />*/}
				{reviewsData.length === 0 ? <></> : <CourseReviews reviewsData={reviewsData} />}
				<RelatedCourses course={currentCourse} />
				{loadingFaqs || errorFaqs || faqsData.length === 0 ? (
					<></>
				) : (
					<CourseFAQS faqsData={faqsData} />
				)}
			</div>
			{isSmallScreen && (
				<div
					className="w-100 p-lg-5 p-3"
					style={{
						position: 'sticky',
						bottom: 0,
						backgroundColor: 'white',
						transition: 'transform 0.3s',
						...(shouldTranslate && {
							transform: isVisible ? 'translateY(0)' : 'translateY(100%)',
						}),
						height: '100%',
						// display: 'flex',
						alignItems: 'center',
					}}>
					<div className="row">
						<div className="col-4 col-md-8">
							<div className="d-flex align-items-center h-100 flex-wrap">
								<span className="g_semi detail-discount-price me-2">
									{RUPEE_SYMBOL}
									{currentCourse?.sellPrice}/-
								</span>
								<span className="g_reg detail-actual-price mt-1">
									<del>
										{RUPEE_SYMBOL}
										<span className="color17">{currentCourse?.amount}/-</span>
									</del>
									&nbsp; (
									{(
										((currentCourse?.amount - currentCourse?.sellPrice) / currentCourse?.amount) *
										100
									).toFixed(0)}
									% off)
								</span>
							</div>
						</div>

						<div style={{ color: '#B32D0F' }} className="col-8 col-md-4">
							<div className="d-flex flex-column w-100 gap-1">
								{isCurrentDateBehindCourseStartDate && !isCourseSubscribed && (
									<div className="d-flex align-items-center gap-1">
										<Image src={toAbsoluteUrl('/media/course-detail-images/timer-left.svg')} />
										{moment(currentCourse?.startDatetime).diff(moment(), 'days')} &nbsp; Days Left
										to Enroll
									</div>
								)}
								{/* <button
									onClick={isCourseSubscribed ? handleContinueLearning : handleEnrollPageReroute}
									className="button bg-color2 signup-btn-hover g_mid px-1 py-2"
									style={{ borderRadius: '12px' }}></button> */}
								<Button
									buttonText={isCourseSubscribed ? 'Keep Learning' : 'Enroll Now'}
									buttonType={ButtonType.PRIMARY_BUTTON}
									classes="g_mid w-100"
									handleClick={
										isCourseSubscribed ? handleContinueLearning : handleEnrollPageReroute
									}
									buttonBorderType={ButtonBorderType.BLACK_BORDER}
								/>
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default DetailPage;
