import React from 'react';
import TopTitleSection from '../CommonComponents/TopTitleSection';

const TNC = () => {
	// Array holding title and content for each accordion item
	const accordionItems = [
		{
			title: 'About HoGrowth and the Platform',
			content:
				'In India, the Platform is owned, managed, operated and offered by Jainam Fincap Private Limited, a company incorporated under the (Indian) Companies Act, 2013, having its registered office at M-12A, Malhar Complex, Dumas Road, Surat 395007, Gujarat. In any jurisdiction other than India, wherever Services are rendered through the Platform, the same is offered by HoGrowth and / or its affiliates.',
		},
		{
			title: 'Platform Services',
			content:
				'HoGrowth is an platform enabling Content Providers to create and offer content in various formats in written form in a diverse range of categories through offline modes and means and publish and make available such Content Provider Content to the Learners. HoGrowth acts as an intermediary between the Content Providers and Learners as per the applicable Laws, rules and regulations and makes available the Content Provider Content to the Learners, including through subscription offerings. HoGrowth may also offer, either directly, other incidental and ancillary services which may include without limitation question banks, mock tests, doubt-solving solutions, Live Mentorship, live videos, webinars and events (physical and/or online) and facilitating discussion forums. For the purpose of the Platform Terms, usage of the terms “Platform Services”/ “Services” shall mean and include all the services referred to in this section and such other products or services as may be offered by HoGrowth. You agree and acknowledge that HoGrowth shall have the right at any time to change or discontinue any Service and product of the Platform. HoGrowth reserves the right to terminate your Subscription and / or restrict your access to the Platform, if you choose not to provide / partially provide the requested information.',
		},
		{
			title: 'Right to Access and Account Creation',
			content:
				'As a Learner, to access the Platform and use certain Services, you will be required to register and enroll on the Office and on the Platform by providing the requested details. You represent that the information provided by you at the time of the registration are correct, true and accurate, and you agree to update the same as and when there is any change in the said information. Please read our Privacy Policy to understand how we handle your information. Please note that we reserve the right to reject or put on-hold your registration on the Platform as may be required to comply with any legal and regulatory provisions, and also reserve the right to refuse access to the Platform, terminate accounts, remove content at any time without providing any notice to you, and/or reserve the right to disable any account, feature, or identifiers, whether chosen by you or provided by us, at any time if, in our opinion, the said identifiers or you have violated any provision of the Platform Terms.',
		},
		{
			title: 'Subscription Terms',
			content:
				'Accordingly, access to certain Services and features is offered by HoGrowth through a multi- tiered paid subscription plan(s) or purchases; the details of the Services and applicable features along with their corresponding prices can be found on our office and on website. You can walk in to our office for the inquiry and get yourself subscribed for the courses provided by HoGrowth.',
		},
		{
			title: 'Pricing Payments and Refunds',
			content: `
        <h5 class="section-heading">1. Pricing and Payments:</h5>
        <p class="section-paragraph">You can purchase a Course of your choice for any category(ies) of content by following instructions on the Platform and making the payment applicable for the Course you intend to purchase. Please read the below terms applicable for the purchase of your Course Subscription. The below terms are to be read with any other terms communicated to you at the time of purchase of your Course:</p>
        <ul class="section-list">
          <li class="section-list-item"><strong>a.</strong> You agree to pay all Subscription fees and charges that are attributable to your account on the Platform and that you are solely responsible for payment of these fees and charges. The Subscriptions are payable in full and in advance and are valid until the completion of the applicable Subscription Period.</li>
          <li class="section-list-item"><strong>b.</strong> If you have not completed payments for your Courses in the given period, we may restrict you from accessing the Courses until you pay the pending fees in full.</li>
          <li class="section-list-item"><strong>c.</strong> We further reserve the right to offer custom plans and pricing (including discounts and/or special offers) in addition to what is offered on the Platform, which include offering custom billing and payment terms that are different from our standard terms.</li>
          <li class="section-list-item"><strong>d.</strong> We use third-party payment gateways and/or aggregators to process payments applicable to the Services offered by us. Third-party payment gateway(s) made available to you may vary depending on the Subscription you choose. Similarly, we have also enabled integration of third-party payment providers to facilitate better payment options to you. Third-party payment gateways/aggregators and third-party payment providers shall collectively be referred to as “Third-Party Service Providers”.</li>
          <li class="section-list-item"><strong>e.</strong> Further, HoGrowth is solely authorized to offer discounts / offers, if any, on the Course prices. These discounts / offers are communicated on the Platform or via direct communication to you from HoGrowth via email, SMS, phone, or such other means of communication, and can be availed only through the Platform, unless otherwise specifically communicated by HoGrowth. Other than HoGrowth, no person, including without limitation, Content Providers or any third-party platform, are allowed to offer any discounts on the Subscription prices offered on the Platform. HoGrowth shall not be liable for any claims arising from such unauthorized discounts / offers offered by any person (including any third-party platform or Content Provider), other than HoGrowth.</li>
        </ul>
        
        <h5 class="section-heading">2. Cancellation and Refund Policy:</h5>
        <p class="section-paragraph">You may cancel your Subscription through your account on the Platform. However, please note that there is no refund policy. So, please read these terms and conditions carefully before purchasing any Subscription. In case there is a need for a refund, such cases will be treated by the Management and the decision for refund of any fees will be taken by the Management and the same decision will be held final. When you cancel your Subscription, HoGrowth may disable access to features made available to you upon your purchase of Subscription of Courses.</p>
      `,
		},
		{
			title: 'Consulting',
			content:
				'We use third parties to help us provide services to You including the fulfillment of service, processing of payments, monitoring site activity, conducting surveys, maintaining our database, administering emails, and administering contents, and to provide aggregate, comparative information on the performance of our courses.',
		},
		{
			title: 'Content',
			content:
				'HoGrowth and / or its affiliates own all information and materials (in whatever form or media) provided or communicated to you by or on behalf of HoGrowth including but not limited to, the Platform, illustrations, letters, images, ideas, concepts, the layout, design, flow, look and feel of the Platform, logos, marks, graphics, audio files, video files, any software which is owned by or licensed to HoGrowth and / or its affiliates, the underlying source and object code, instructions embedded in any form of digital documents and other data, information, or material made available to you by HoGrowth(“HoGrowth Content”). HoGrowth Content, including its trademarks, will not be used, modified, or altered by you in any way. You acknowledge and agree that you do not acquire any ownership or rights to the HoGrowth Content or the Platform by use of the Platform. You acknowledge and agree that the HoGrowth Content is protected by the copyright, trademark, patent, trade secret and other intellectual property or proprietary rights laws and any unauthorized use, reproduction, modification, distribution, transmission, republication, display or performance of the HoGrowth Content and any component thereof is strictly prohibited.',
		},
		{
			title: 'Applicable Laws and Jurisdictions',
			content: `
        <p class="section-paragraph">The applicable law and jurisdiction will be where the HoGrowth is situated. Any legal dispute related to HoGrowth will be taken into consideration in the jurisdiction of Surat, Gujarat Only.</p>
        
        <h5 class="section-heading">I. GENERAL PROVISIONS:</h5>
        
        <ul class="section-list">
          <li class="section-list-item"><strong>1. Legal Notices:</strong> In the event of any other disputes or claims arising from the use of the HoGrowth Platform, please get in touch with us at <a href="mailto:support@hogrowth.com">support@hogrowth.com</a>.</li>
          
          <li class="section-list-item"><strong>2. Modification, Amendment or Termination:</strong> HoGrowth may, in its sole discretion, modify or revise the Agreement and policies at any time, and you agree to be bound by such modifications or revisions. Your continued use of the Platform post any modification of the Agreement shall be taken as your consent and acceptance to such modifications. Nothing in the Agreement shall be deemed to confer any third-party rights or benefits. You are advised to check our Platform frequently to see recent changes and to keep yourself updated with the most recent updates.</li>
          
          <li class="section-list-item"><strong>3. Force Majeure:</strong> HoGrowth shall not be liable for failure to perform, or the delay in performance of, any of its obligations if, and to the extent that, such failure or delay is caused by events substantially beyond its control, including but not limited to acts of God, acts of the public enemy or governmental body in its sovereign or contractual capacity, war, terrorism, floods, fire, strikes, epidemics, civil unrest or riots, power outage, and/or unusually severe weather.</li>
          
          <li class="section-list-item"><strong>4. Miscellaneous:</strong> If any part of the Agreement is found to be unlawful, void, or unenforceable, that part of the Agreement will be deemed severable and will not affect the validity and enforceability of any remaining provisions. Any notice required to be given in connection with the Platform shall be in writing and sent to the office of HoGrowth. Our failure to act with respect to any breach by you or others does not waive our right to act with respect to subsequent or similar breaches.</li>
        </ul>
      `,
		},
		{
			title: 'Contact for User Support/Queries',
			content:
				'For queries relating to Services offered by HoGrowth, please write to us at <a class="primaryColor g_mid text-decoration-none cursor-pointer" href="mailto:hogrowth@gmail.com">hogrowth@gmail.com</a>',
		},
		{
			title: 'Contact Us',
			content:
				'If you have concerns or queries regarding the Platform Terms, you may write to us by <a class="primaryColor g_mid text-decoration-none cursor-pointer" href="mailto:emailhogrowth@gmail.com">emailhogrowth@gmail.com</a> or by post to: <strong>Jainam Fincap Private Limited, F-7, 3rd Floor, VIP Plaza, VIP Road, Near Shyam Baba Mandir, Vesu, Surat, Gujarat 395007. </strong>',
		},
	];

	return (
		<>
			<section className="privacy-policy-wrapper bg-color19">
				<div className="container-fluid info-layout">
					<div className="row mt-5">
						<TopTitleSection
							title="Terms and Conditions"
							description="Explore the courses that our learners love the most. Tried, tested, and highly recommended for your success."
						/>
					</div>
				</div>
				<div className="col-lg-10 layout-set my-3">
					<div className="terms-container p-4 text-black">
						<p className="g_reg color16">
							These Terms and Conditions (“Terms and Conditions”) set out the terms and conditions
							for use of{' '}
							<a
								className="primaryColor g_mid text-decoration-none cursor-pointer"
								href="https://hogrowth.com/">
								{' '}
								https://hogrowth.com/{' '}
							</a>
							(the “Site”) services provided, media, applications, or solutions offered on or
							through the Site and/or the Application and/or through any modes, medium, platform.
						</p>
						<p className="g_reg color16">
							These Terms and Conditions apply to all users of the Platform. Your access to the
							Platform is subject to your acceptance of the Platform Terms and such acceptance of
							the Platform Terms forms a legally binding agreement between you and HoGrowth.
						</p>
						<p className="g_reg color16">
							From time-to-time, updated versions of the Platform Terms may be made available for
							your reference. By visiting and accessing the Platform, providing your Personal
							Information (as defined in the Privacy Policy), using the Services offered when the
							option is presented to you, you hereby expressly accept and agree to the Platform
							Terms.
						</p>
						<p className="g_reg color16">
							When we speak of “HO Growth”, “we”, “us”, and “our”, we collectively mean Jainam
							Fincap Private Limited - a company incorporated in India and all of its affiliates
							which have license to host the Platform and offer Services.
						</p>

						<div className="accordion accordion-flush my-5" id="accordionFlushExample">
							{accordionItems.map((item, index) => (
								<div className="accordion-item accordion__item" key={index}>
									<h2 className="accordion-header faq-btn">
										<button
											className="accordion-button collapsed custom-class px-0"
											type="button"
											data-bs-toggle="collapse"
											data-bs-target={`#flush-collapse${index}`}
											aria-expanded="false"
											aria-controls={`flush-collapse${index}`}>
											{item.title}
										</button>
									</h2>
									<div
										id={`flush-collapse${index}`}
										className="acc-desc accordion-collapse collapse"
										data-bs-parent="#accordionFlushExample">
										<div
											className="accordion-body px-0"
											dangerouslySetInnerHTML={{ __html: item.content }}
										/>
									</div>
								</div>
							))}
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default TNC;
