import React, { useState, useCallback, useEffect, FC } from "react";
import {
  GETCOURSESMODESQUERY,
  GETPUBLICCOURSECATEGORIES,
  GETPUBLICCOURSESLEVELLISTQUERY,
  GETPUBLICLANGUAGESLISTQUERY,
} from "../../Home-Page/core/requests";
import { useQuery } from "@apollo/client";
enum PriceName {
  PAID = "PAID",
  FREE = "FREE",
}
type PriceItem = {
  id: number;
  value: string;
  name: PriceName;
};

const SideFilter = (props: any) => {
  const {
    handleModeChange,
    handleLevelChange,
    handleCategoryChange,
    handlePriceChange,
    handleLanguageChange,
    selectedPrice,
  } = props;
  const price: PriceItem[] = [
    { id: 1, value: "Paid", name: PriceName.PAID },
    { id: 2, value: "Free", name: PriceName.FREE },
  ];
  const { data: courseCategoriesResult } = useQuery(GETPUBLICCOURSECATEGORIES);
  const courseCategoriesRecord =
    courseCategoriesResult?.getPublicCourseCategories?.records ?? [];
  const { data: courseLevelResult } = useQuery(GETPUBLICCOURSESLEVELLISTQUERY);
  const courseLevelRecord =
    courseLevelResult?.getPublicCourseLevelList?.records ?? [];
  const { data: courseLanguageResult } = useQuery(GETPUBLICLANGUAGESLISTQUERY);
  const courseLanguageRecord =
    courseLanguageResult?.getPublicCourseLanguagesList?.records ?? [];
  const { data: courseModeResult } = useQuery(GETCOURSESMODESQUERY);
  const courseModeRecord =
    courseModeResult?.getPublicCourseModes?.records ?? [];
  return (
    <>
      <div className="col-xl-3 col-lg-3 d-lg-block col-12 mt-5 filter-webinar">
        <button
          className="btn btn-outline-secondary mb-3 d-none w-100 d-lg-none d-none"
          type="button"
          data-mdb-toggle="collapse"
          data-mdb-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span>Show filter</span>
        </button>
        <div
          className="collapse  d-lg-block d-block border-muted rounded "
          id="navbarSupportedContent"
        >
          <div className="accordion" id="accordionPanelsStayOpenExample">
            <div className=" text-align-center ms-3 fs-5 fw-bold m-2 text-muted pt-2">
              Filter By
            </div>
            <div className="accordion-item border-0">
              <h2 className="accordion-header" id="panelsStayOpen-headingOne">
                <button
                  className="accordion-button collapsed text-muted"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseOne"
                  aria-expanded="false"
                  aria-controls="panelsStayOpen-collapseOne"
                >
                  Categories
                </button>
              </h2>
              <div
                id="panelsStayOpen-collapseOne"
                className="accordion-collapse collapse "
                aria-labelledby="panelsStayOpen-headingOne"
              >
                <div className="accordion-body text-muted">
                  {courseCategoriesRecord?.map((value: any, index: any) => {
                    return (
                      <div
                        className="form-check mt-2 d-flex justify-content-start align-items-start"
                        key={value.id}
                      >
                        <input
                          className="form-check-input fs-5"
                          type="checkbox"
                          defaultValue=""
                          id={value.id}
                          value={value.id}
                          onChange={(event: any) => {
                            handleCategoryChange(event, value);
                          }}
                        />
                        <label
                          className="form-check-label fs-6 ms-2 mt-1 "
                          htmlFor={value.id}
                        >
                          {value.name}
                        </label>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="accordion-item border-0">
              <h2 className="accordion-header" id="panelsStayOpen-headingTwo">
                <button
                  className="accordion-button collapsed text-muted"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseTwo"
                  aria-expanded="false"
                  aria-controls="panelsStayOpen-collapseTwo"
                >
                  Difficulty
                </button>
              </h2>
              <div
                id="panelsStayOpen-collapseTwo"
                className="accordion-collapse collapse"
                aria-labelledby="panelsStayOpen-headingTwo"
              >
                <div className="accordion-body text-muted">
                  {courseLevelRecord.map((value: any) => {
                    return (
                      <div
                        className="form-check form-check mt-2 d-flex justify-content-start align-items-start"
                        key={value.id}
                      >
                        <input
                          className="form-check-input fs-5"
                          type="checkbox"
                          defaultValue=""
                          id={value.id}
                          value={value.id}
                          onChange={(event: any) => {
                            handleLevelChange(event, value);
                          }}
                        />
                        <label
                          className="form-check-label mt-1 ms-2"
                          htmlFor={value.id}
                        >
                          {value.name}
                        </label>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="accordion-item border-0">
              <h2 className="accordion-header" id="panelsStayOpen-headingThree">
                <button
                  className="accordion-button collapsed text-muted"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseThree"
                  aria-expanded="false"
                  aria-controls="panelsStayOpen-collapseThree"
                >
                  Mode
                </button>
              </h2>
              <div
                id="panelsStayOpen-collapseThree"
                className="accordion-collapse collapse "
                aria-labelledby="panelsStayOpen-headingThree"
              >
                <div className="accordion-body text-muted">
                  <div>
                    {courseModeRecord.map((value: any) => {
                      return (
                        <div
                          className="form-check form-check mt-2 d-flex justify-content-start align-items-start"
                          key={value.id}
                        >
                          <input
                            className="form-check-input fs-5"
                            type="checkbox"
                            defaultValue=""
                            id={value.id}
                            value={value.id}
                            onChange={(event: any) => {
                              handleModeChange(event, value);
                            }}
                          />
                          <label
                            className="form-check-label mt-1 ms-2"
                            htmlFor={value.id}
                          >
                            {value.name}
                          </label>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
            <div className="accordion-item border-0">
              <h2 className="accordion-header" id="panelsStayOpen-headingFive">
                <button
                  className="accordion-button collapsed text-muted"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseFive"
                  aria-expanded="false"
                  aria-controls="panelsStayOpen-collapseFive"
                >
                  Price
                </button>
              </h2>
              <div
                id="panelsStayOpen-collapseFive"
                className="accordion-collapse collapse"
                aria-labelledby="panelsStayOpen-headingFive"
              >
                <div className="accordion-body text-muted">
                  <div>
                    {price.map((value: PriceItem) => (
                      <div
                        key={value.id}
                        className="form-check form-check mt-2 d-flex justify-content-start align-items-start"
                      >
                        <input
                          className="form-check-input fs-5"
                          type="checkbox"
                          defaultValue=""
                          id={value.id.toString()}
                          value={value.id}
                          checked={selectedPrice === value.name}
                          onChange={(event) => {
                            handlePriceChange(event, value);
                          }}
                        />
                        <label
                          className="form-check-label mt-1 ms-2"
                          htmlFor={value.id.toString()}
                        >
                          {value.value}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="accordion-item border-0">
              <h2 className="accordion-header" id="panelsStayOpen-headingFour">
                <button
                  className="accordion-button collapsed text-muted"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseFour"
                  aria-expanded="false"
                  aria-controls="panelsStayOpen-collapseFour"
                >
                  Language
                </button>
              </h2>
              <div
                id="panelsStayOpen-collapseFour"
                className="accordion-collapse collapse"
                aria-labelledby="panelsStayOpen-headingFour"
              >
                <div className="accordion-body text-muted">
                  <div>
                    {courseLanguageRecord.map((value: any) => {
                      return (
                        <div
                          className="form-check form-check mt-2 d-flex justify-content-start align-items-start"
                          key={value.id}
                        >
                          <input
                            className="form-check-input fs-5"
                            type="checkbox"
                            defaultValue=""
                            id={value.id}
                            value={value.id}
                            onChange={(event: any) => {
                              handleLanguageChange(event, value);
                            }}
                          />
                          <label
                            className="form-check-label mt-1 ms-2"
                            htmlFor={value.id}
                          >
                            {value.name}
                          </label>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SideFilter;
