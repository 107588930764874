import React from "react";
import "./StarRatings.css"; // Ensure to import your CSS file

const StarRating = ({
  rating,
  removeSpacingFromBottom = false,
}: {
  rating: number;
  removeSpacingFromBottom?: boolean;
}) => {
  // Calculate the percentage width of the filled stars
  const percentage = parseFloat((rating / 5).toString()) * 100;

  return (
    <div
      className="fs-5 pe-1"
      style={{
        position: "relative",
        marginBottom: removeSpacingFromBottom ? "2px" : "10px",
      }}
    >
      {/* Unfilled stars */}
      <span>★★★★★</span>
      {/* Filled stars */}
      <span className="filled" style={{ width: `${percentage}%` }}>
        ★★★★★
      </span>
    </div>
  );
};

export default StarRating;
