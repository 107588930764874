import { Tab, Tabs } from "react-tabs";
import { TabList, TabPanel } from "react-tabs";
import { GETSTUDENTCOURSES } from "../core/requests";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { toAbsoluteUrl } from "../../../assets/helpers/AssetHelpers";
import { MdDateRange } from "react-icons/md";
import { useQuery } from "@apollo/client";
import Counter from "../../Courses-Page/Detail-Page/Counter";
import React from "react";
const Learning = (props: any) => {
  const navigate = useNavigate();
  const { whereCondition } = props;
  const { data: studentCourses } = useQuery(GETSTUDENTCOURSES, {
    variables: { where: whereCondition },
  });

  const handleStartCourse = (slug: string) => {
    navigate(`/coursecontentvideo/${slug}`);
  };

  const handleOpen = (id: string) => {
    navigate(`/course-view/${id}`);
  };

  const handleStartLearning = async (course: any) => {
    if (
      course?.courseType?.name === "Webinar" ||
      course?.courseType?.name === "Seminar" ||
      course?.courseType?.name === "Workshop"
    ) {
      navigate(`/webinarislive/${course?.slug}`);
    } else if (course?.courseType?.name === "Recorded Course") {
      navigate(`/coursecontentvideo/${course?.slug}`);
    }
  };

  const inProgressCourses = studentCourses?.getStudentCourses?.records?.filter(
    (x: any) => x.status == "IN_PROGRESS",
  );
  const completedCourses = studentCourses?.getStudentCourses?.records?.filter(
    (x: any) => x.status == "COMPLETED",
  );
  const upcomingCourses = studentCourses?.getStudentCourses?.records?.filter(
    (x: any) => x.status == "ENROLLED",
  );

  return (
    <>
      <section>
        <div className="container mb-5 d-lg-block d-md-block d-none">
          <div className="row">
            {/* <Sidebar /> */}
            <div className="col-lg-12">
              <div className="d-flex justify-content-between">
                <div className="fw-bold fs-2 title mx-3 head1">
                  My Learnings
                </div>
                <div
                  className="col-2 d-none"
                  style={{ marginRight: "3.438rem" }}
                >
                  <select
                    className="form-select border-color3 fs-16 font-switzer shadow-none color2 bg-transparent mt-2"
                    aria-label="Default select example"
                  >
                    <option selected>Sort By</option>
                    <option value="1">OnGoing</option>
                    <option value="2">UpComeing</option>
                    <option value="3">Completed</option>
                  </select>
                </div>
              </div>
              <div className="align-items-center mt-1">
                <Tabs className="mt-1 w-100 learning-tab">
                  <TabList>
                    <Tab>
                      <div className="ongoing col-md-4 w-100 m-2 text-center">
                        <div className="clickable-box p-3">
                          <div className="">
                            <h3 className="fw-bolder">
                              {inProgressCourses?.length}
                            </h3>
                            <p className="mb-0 pb-0 fs-7 fw-normal">On going</p>
                          </div>
                        </div>
                      </div>
                    </Tab>
                    <Tab>
                      <div className="ongoing col-md-4 w-100 m-2 text-center">
                        <div className="clickable-box p-3">
                          <div className="">
                            <h3 className="fw-bolder">
                              {upcomingCourses?.length}
                            </h3>
                            <p className="mb-0 pb-0 fs-7 fw-normal">Upcoming</p>
                          </div>
                        </div>
                      </div>
                    </Tab>
                    <Tab>
                      <div className="ongoing col-md-4 w-100 m-2 text-center">
                        <div className="clickable-box p-3 ">
                          <div className="">
                            <h3 className="fw-bolder">
                              {completedCourses?.length}
                            </h3>
                            <p className="mb-0 pb-0 fs-7 fw-normal">
                              Completed
                            </p>
                          </div>
                        </div>
                      </div>
                    </Tab>
                  </TabList>
                  <TabPanel>
                    <section>
                      <div className="container">
                        <div className="row">
                          {inProgressCourses?.map((value: any) => {
                            // const formattedDate = moment.utc(value.startDatetime)
                            //     .utcOffset('+05:30')
                            //     .format("YYYY-MM-DD HH:mm:ss")
                            const formattedDate = moment
                              .utc(value.course.startDatetime)
                              .format("ddd, DD MMM YYYY • hh:mm A");
                            return (
                              <>
                                <div className="col-lg-12 mx-auto mt-1 upcoming-courses h-100">
                                  <div
                                    className="card px-2 pb-3 round course-card overflow-hidden my-2 h-100 cursor-pointer"
                                    onClick={() =>
                                      handleStartLearning(value.course)
                                    }
                                  >
                                    <div className="row d-flex gy-4 gy-lg-0 ">
                                      <div className="col-md-4">
                                        {value.course.images[0]?.original !=
                                        null ? (
                                          <img
                                            src={
                                              value.course.images[0]?.original
                                            }
                                            alt="Image"
                                            className="img-fluid mt-3 px-2 "
                                            style={{
                                              height: "25vh",
                                              borderRadius: "15px",
                                            }}
                                          />
                                        ) : (
                                          <img
                                            src={toAbsoluteUrl(
                                              "../media/course/mylearning1.jpg",
                                            )}
                                            alt="Image"
                                            className="img-fluid mt-3 px-2"
                                            style={{ height: "25vh" }}
                                          />
                                        )}
                                      </div>
                                      <div
                                        className="col-md-8 d-flex"
                                        style={{ marginLeft: "-5%" }}
                                      >
                                        <div className="card-body">
                                          <div className="d-flex gap-2 mb-2">
                                            <span className="btn btn-outline-success course-btn rounded-pill px-3 py-1 text-dark">
                                              {value.course.courseCategory.name
                                                ? value.course.courseCategory
                                                    .name
                                                : "Technical"}
                                            </span>
                                            {/* <span className="fs-14 font-subtitle bg-outline-success border border-success rounded-pill px-3 py-1 text-dark me-2" >
                                                                                            {value.course.courseCategory.name ? value.course.courseCategory.name : "Technical"}
                                                                                            </span> */}
                                            <div className="align-items-center border border-orange course_difficulty d-flex justify-content-center px-2 rounded-pill text-center">
                                              <i
                                                className={`mt-0 icon__course-difficulty difficulty-${value.course.courseLevel.name} position-relative`}
                                                data-bs-toggle="tooltip"
                                                data-bs-placement="top"
                                                data-bs-html="true"
                                                title={`Course Defficulty : ${value.course.courseLevel.name}`}
                                              >
                                                <span
                                                  className={` bar-1 ${
                                                    value.course.courseLevel
                                                      .name === "Advanced" ||
                                                    value.course.courseLevel
                                                      .name ===
                                                      "Intermediate" ||
                                                    value.course.courseLevel
                                                      .name === "Beginner"
                                                      ? "opacity-100"
                                                      : "opacity-25"
                                                  } `}
                                                ></span>
                                                <span
                                                  className={` bar-2 ${
                                                    value.course.courseLevel
                                                      .name === "Advanced" ||
                                                    value.course.courseLevel
                                                      .name === "Intermediate"
                                                      ? "opacity-100"
                                                      : "opacity-25"
                                                  } `}
                                                ></span>
                                                <span
                                                  className={` bar-3 ${
                                                    value.course.courseLevel
                                                      .name === "Advanced"
                                                      ? "opacity-100"
                                                      : "opacity-25"
                                                  } `}
                                                ></span>
                                              </i>
                                            </div>
                                          </div>
                                          <div>
                                            <p className="card-text mt-2 fw-bold">
                                              <div
                                                className="fs-6 fw-bold cursor-pointer mt-1"
                                                onClick={() =>
                                                  handleOpen(value.course.id)
                                                }
                                              >
                                                {value.course.name ?? ""}
                                              </div>
                                              <p className="fw-normal">
                                                {value?.course?.mentors.map(
                                                  (mentor: any, index: any) => (
                                                    <React.Fragment key={index}>
                                                      <a
                                                        className="text-decoration-none text-muted-dark"
                                                        href={`/mentor-view/${mentor.mentor.id}`}
                                                      >
                                                        {index > 0 ? (
                                                          <>&nbsp;and&nbsp;</>
                                                        ) : (
                                                          <span>By&nbsp;</span>
                                                        )}
                                                        {mentor.mentor
                                                          .firstName ?? ""}{" "}
                                                        {mentor.mentor
                                                          .lastName ?? ""}
                                                        {index <
                                                        value.course.mentors
                                                          .length -
                                                          1 ? (
                                                          <></>
                                                        ) : null}
                                                      </a>
                                                    </React.Fragment>
                                                  ),
                                                )}
                                              </p>
                                            </p>
                                          </div>
                                          <div className="d-flex text-muted gap-2 mb-2">
                                            <img
                                              src="../media/icons/calendar-event-fill.svg"
                                              alt=""
                                            />
                                            {value.course.startDatetime ===
                                            null ? (
                                              ""
                                            ) : (
                                              <span>{formattedDate}</span>
                                            )}
                                          </div>
                                          <div
                                            className="d-flex text-muted-dark"
                                            style={{ marginTop: "-10px" }}
                                          >
                                            <div className="d-flex text-muted-dark gap-2">
                                              <img
                                                src="../media/icons/mac-line.svg"
                                                alt=""
                                              />
                                              <div>
                                                {value.course.courseMode.name}
                                              </div>
                                            </div>
                                          </div>
                                          <button
                                            onClick={() =>
                                              handleStartCourse(
                                                value.course.slug,
                                              )
                                            }
                                            className="btn btn-primary mt-3"
                                          >
                                            Start Learning
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            );
                          })}
                        </div>
                      </div>
                    </section>
                    {/* <OnGoing ipCourse={inProgressCourses} /> */}
                  </TabPanel>
                  <TabPanel>
                    <section>
                      <div className="container">
                        <div className="row">
                          <div className="col-12">
                            {upcomingCourses?.map((value: any) => {
                              const date = moment
                                .utc(value.startDatetime)
                                .utcOffset("+05:30")
                                .format("YYYY-MM-DD HH:mm:ss");
                              const todaydate = moment
                                .utc(new Date())
                                .utcOffset("+05:30")
                                .format("YYYY-MM-DD");
                              const onlydate = moment
                                .utc(value.startDatetime)
                                .utcOffset("+05:30")
                                .format("YYYY-MM-DD");
                              const courseStartDate = new Date(
                                value.course.startDatetime,
                              );
                              const formattedDate = moment
                                .utc(value.course.startDatetime)
                                .format("ddd, DD MMM YYYY • hh:mm A");

                              return (
                                <div>
                                  <div className="col-lg-12 mx-auto mt-1 upcoming-courses h-100">
                                    <div
                                      className="card  px-2 pb-3 round course-card overflow-hidden  my-3 h-100 cursor-pointer"
                                      onClick={() =>
                                        handleStartLearning(value.course)
                                      }
                                    >
                                      <div className="row d-flex gy-4 gy-lg-0">
                                        <div className="col-lg-4 ">
                                          {value.course.images[0]?.original !=
                                          null ? (
                                            <img
                                              src={
                                                value.course.images[0]?.original
                                              }
                                              alt="Image"
                                              className="img-fluid mt-3 px-2"
                                              style={{
                                                height: "25vh",
                                                borderRadius: "15px",
                                              }}
                                            />
                                          ) : (
                                            <img
                                              src={toAbsoluteUrl(
                                                "../media/course/mylearning1.jpg",
                                              )}
                                              alt="Image"
                                              className="img-fluid mt-3 px-2"
                                              style={{ height: "25vh" }}
                                            />
                                          )}
                                        </div>
                                        <div
                                          className="col-lg-8 d-flex"
                                          style={{ marginLeft: "-5%" }}
                                        >
                                          <div className="card-body">
                                            <div className="d-flex gap-2 mb-2">
                                              <span className="btn btn-outline-success course-btn rounded-pill px-3 py-1 text-dark">
                                                {value.course.courseCategory
                                                  .name
                                                  ? value.course.courseCategory
                                                      .name
                                                  : "Technical"}
                                              </span>
                                              <div className="align-items-center border border-orange course_difficulty d-flex justify-content-center px-2 rounded-pill text-center">
                                                <i
                                                  className={`mt-0 icon__course-difficulty difficulty-${value.course.courseLevel.name} position-relative`}
                                                  data-bs-toggle="tooltip"
                                                  data-bs-placement="top"
                                                  data-bs-html="true"
                                                  title={`Course Defficulty : ${value.course.courseLevel.name}`}
                                                >
                                                  <span
                                                    className={` bar-1 ${
                                                      value.course.courseLevel
                                                        .name === "Advanced" ||
                                                      value.course.courseLevel
                                                        .name ===
                                                        "Intermediate" ||
                                                      value.course.courseLevel
                                                        .name === "Beginner"
                                                        ? "opacity-100"
                                                        : "opacity-25"
                                                    } `}
                                                  ></span>
                                                  <span
                                                    className={` bar-2 ${
                                                      value.course.courseLevel
                                                        .name === "Advanced" ||
                                                      value.course.courseLevel
                                                        .name === "Intermediate"
                                                        ? "opacity-100"
                                                        : "opacity-25"
                                                    } `}
                                                  ></span>
                                                  <span
                                                    className={` bar-3 ${
                                                      value.course.courseLevel
                                                        .name === "Advanced"
                                                        ? "opacity-100"
                                                        : "opacity-25"
                                                    } `}
                                                  ></span>
                                                </i>
                                              </div>
                                            </div>
                                            <div>
                                              <p className="card-text mt-2 fw-bold">
                                                <div
                                                  className="fs-6 fw-bold cursor-pointer mt-1"
                                                  onClick={() =>
                                                    handleOpen(value.course.id)
                                                  }
                                                >
                                                  {value.course.name ?? ""}
                                                </div>
                                                <p className="fw-normal">
                                                  {value?.course?.mentors.map(
                                                    (
                                                      mentor: any,
                                                      index: any,
                                                    ) => (
                                                      <React.Fragment
                                                        key={index}
                                                      >
                                                        <a
                                                          className="text-decoration-none text-muted-dark"
                                                          href={`/mentor-view/${mentor.mentor.id}`}
                                                        >
                                                          {index > 0 ? (
                                                            <>&nbsp;and&nbsp;</>
                                                          ) : (
                                                            <span>
                                                              By&nbsp;
                                                            </span>
                                                          )}
                                                          {mentor.mentor
                                                            .firstName ??
                                                            ""}{" "}
                                                          {mentor.mentor
                                                            .lastName ?? ""}
                                                          {index <
                                                          value.course.mentors
                                                            .length -
                                                            1 ? (
                                                            <></>
                                                          ) : null}
                                                        </a>
                                                      </React.Fragment>
                                                    ),
                                                  )}
                                                </p>
                                              </p>
                                            </div>
                                            <div className="d-flex text-muted gap-2 mb-2">
                                              {value.course.startDatetime ===
                                              null ? (
                                                ""
                                              ) : (
                                                <>
                                                  <img
                                                    src="../media/icons/calendar-event-fill.svg"
                                                    alt=""
                                                  />

                                                  <span>{formattedDate}</span>
                                                </>
                                              )}
                                            </div>
                                            <div className="d-flex text-muted-dark gap-2">
                                              <img
                                                src="../media/icons/mac-line.svg"
                                                alt=""
                                              />
                                              <div>
                                                {value.course.courseMode.name}
                                              </div>
                                            </div>

                                            <div className="mt-3"></div>
                                            <>
                                              {onlydate > todaydate ? (
                                                <Counter
                                                  data={courseStartDate}
                                                  ctype={true}
                                                />
                                              ) : (
                                                <button
                                                  onClick={() =>
                                                    handleStartCourse(
                                                      value.course.slug,
                                                    )
                                                  }
                                                  className="btn btn-primary d-none"
                                                  style={{ marginTop: "35px" }}
                                                >
                                                  Start Learning
                                                </button>
                                              )}
                                            </>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </section>
                    {/* <Upcoming courses={upcomingCourses} /> */}
                  </TabPanel>
                  <TabPanel>
                    <section>
                      <div className="container">
                        <div className="row">
                          <div className="col-12">
                            {completedCourses?.map((value: any) => {
                              return (
                                <div>
                                  <div className="col-lg-12 mx-auto mt-1 upcoming-courses h-100">
                                    <div
                                      className="card  px-2 pb-3 round course-card overflow-hidden my-2 h-100 cursor-pointer"
                                      onClick={() =>
                                        handleStartLearning(value.course)
                                      }
                                    >
                                      <div className="row d-flex gy-4 gy-lg-0">
                                        <div className="col-lg-4 ">
                                          {value.course.images[0]?.original !=
                                          null ? (
                                            <img
                                              src={
                                                value.course.images[0]?.original
                                              }
                                              alt="Image"
                                              className="img-fluid mt-3 px-2"
                                              style={{
                                                height: "25vh",
                                                borderRadius: "15px",
                                              }}
                                            />
                                          ) : (
                                            <img
                                              src={toAbsoluteUrl(
                                                "../media/course/mylearning1.jpg",
                                              )}
                                              alt="Image"
                                              className="img-fluid mt-3 px-2"
                                              style={{ height: "25vh" }}
                                            />
                                          )}
                                        </div>
                                        <div
                                          className="col-md-8 d-flex"
                                          style={{ marginLeft: "-5%" }}
                                        >
                                          <div className="card-body">
                                            <div className="d-flex gap-2 mb-2">
                                              <span className="btn btn-outline-success course-btn rounded-pill px-3 py-1 text-dark">
                                                {value.course.courseCategory
                                                  .name
                                                  ? value.course.courseCategory
                                                      .name
                                                  : "Technical"}
                                              </span>
                                              <div className="align-items-center border border-orange course_difficulty d-flex justify-content-center px-2 rounded-pill text-center">
                                                <i
                                                  className={`mt-0 icon__course-difficulty difficulty-${value.course.courseLevel.name} position-relative`}
                                                  data-bs-toggle="tooltip"
                                                  data-bs-placement="top"
                                                  data-bs-html="true"
                                                  title={`Course Defficulty : ${value.course.courseLevel.name}`}
                                                >
                                                  <span
                                                    className={` bar-1 ${
                                                      value.course.courseLevel
                                                        .name === "Advanced" ||
                                                      value.course.courseLevel
                                                        .name ===
                                                        "Intermediate" ||
                                                      value.course.courseLevel
                                                        .name === "Beginner"
                                                        ? "opacity-100"
                                                        : "opacity-25"
                                                    } `}
                                                  ></span>
                                                  <span
                                                    className={` bar-2 ${
                                                      value.course.courseLevel
                                                        .name === "Advanced" ||
                                                      value.course.courseLevel
                                                        .name === "Intermediate"
                                                        ? "opacity-100"
                                                        : "opacity-25"
                                                    } `}
                                                  ></span>
                                                  <span
                                                    className={` bar-3 ${
                                                      value.course.courseLevel
                                                        .name === "Advanced"
                                                        ? "opacity-100"
                                                        : "opacity-25"
                                                    } `}
                                                  ></span>
                                                </i>
                                              </div>
                                            </div>
                                            <div>
                                              <p className="card-text mt-2 fw-bold">
                                                <div
                                                  className="fs-6 fw-bold cursor-pointer mt-1"
                                                  onClick={() =>
                                                    handleOpen(value.course.id)
                                                  }
                                                >
                                                  {value.course.name ?? ""}
                                                </div>
                                                {/* {value.course.name} */}
                                                <p className="fw-normal">
                                                  {value?.course?.mentors.map(
                                                    (
                                                      mentor: any,
                                                      index: any,
                                                    ) => (
                                                      <React.Fragment
                                                        key={index}
                                                      >
                                                        <a
                                                          className="text-decoration-none text-muted-dark"
                                                          href={`/mentor-view/${mentor.mentor.id}`}
                                                        >
                                                          {index > 0 ? (
                                                            <>&nbsp;and&nbsp;</>
                                                          ) : (
                                                            <span>
                                                              By&nbsp;
                                                            </span>
                                                          )}
                                                          {mentor.mentor
                                                            .firstName ??
                                                            ""}{" "}
                                                          {mentor.mentor
                                                            .lastName ?? ""}
                                                          {index <
                                                          value.course.mentors
                                                            .length -
                                                            1 ? (
                                                            <></>
                                                          ) : null}
                                                        </a>
                                                      </React.Fragment>
                                                    ),
                                                  )}
                                                </p>
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </section>
                    {/* <Completed courses={completedCourses} /> */}
                  </TabPanel>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/*
--------------------------------------------------------------------
Responsive Code For Mobile 
*/}
      <section>
        <div className="container mb-5 d-lg-none d-md-none d-block">
          <div className="row">
            {/* <Sidebar /> */}
            <div className="col-lg-12">
              <div className="d-flex justify-content-between">
                <div className="fw-bold fs-2 title mx-3 head1">
                  My Learnings
                </div>
                <div
                  className="col-2 d-none"
                  style={{ marginRight: "3.438rem" }}
                >
                  <select
                    className="form-select border-color3 fs-16 font-switzer shadow-none color2 bg-transparent mt-2"
                    aria-label="Default select example"
                  >
                    <option selected>Sort By</option>
                    <option value="1">OnGoing</option>
                    <option value="2">UpComeing</option>
                    <option value="3">Completed</option>
                  </select>
                </div>
              </div>
              <div className="align-items-center mt-1">
                <Tabs className="mt-1 w-100 learning-tab">
                  <TabList>
                    <Tab>
                      <div className="ongoing col-md-4 w-100 m-2 text-center">
                        <div className="clickable-box p-3">
                          <div className="">
                            <h3 className="fw-bolder">
                              {inProgressCourses?.length}
                            </h3>
                            <p className="mb-0 pb-0 fs-7 fw-normal">On going</p>
                          </div>
                        </div>
                      </div>
                    </Tab>
                    <Tab>
                      <div className="ongoing col-md-4 w-100 m-2 text-center">
                        <div className="clickable-box p-3">
                          <div className="">
                            <h3 className="fw-bolder">
                              {upcomingCourses?.length}
                            </h3>
                            <p className="mb-0 pb-0 fs-7 fw-normal">Upcoming</p>
                          </div>
                        </div>
                      </div>
                    </Tab>
                    <Tab>
                      <div className="ongoing col-md-4 w-100 m-2 text-center">
                        <div className="clickable-box p-3 ">
                          <div className="">
                            <h3 className="fw-bolder">
                              {completedCourses?.length}
                            </h3>
                            <p className="mb-0 pb-0 fs-7 fw-normal">
                              Completed
                            </p>
                          </div>
                        </div>
                      </div>
                    </Tab>
                  </TabList>
                  <TabPanel>
                    <section>
                      <div className="container">
                        <div className="row">
                          {inProgressCourses?.map((value: any) => {
                            // const formattedDate = moment.utc(value.startDatetime)
                            //     .utcOffset('+05:30')
                            //     .format("YYYY-MM-DD HH:mm:ss")
                            const formattedDate = moment
                              .utc(value.course.startDatetime)
                              .format("ddd, DD MMM YYYY • hh:mm A");
                            return (
                              <>
                                <div className="col-lg-12 mx-auto mt-1 upcoming-courses h-100">
                                  <div
                                    className="card px-2 pb-3 round course-card overflow-hidden my-2 h-100 cursor-pointer"
                                    onClick={() =>
                                      handleStartLearning(value.course)
                                    }
                                  >
                                    <div className="row d-flex gy-4 gy-lg-0 ">
                                      <div className="col-md-4">
                                        {value.course.images[0]?.original !=
                                        null ? (
                                          <img
                                            src={
                                              value.course.images[0]?.original
                                            }
                                            alt="Image"
                                            className="img-fluid mt-3 px-2 "
                                            style={{
                                              height: "25vh",
                                              borderRadius: "15px",
                                            }}
                                          />
                                        ) : (
                                          <img
                                            src={toAbsoluteUrl(
                                              "../media/course/mylearning1.jpg",
                                            )}
                                            alt="Image"
                                            className="img-fluid mt-3 px-2"
                                            style={{ height: "25vh" }}
                                          />
                                        )}
                                      </div>
                                      <div
                                        className="col-md-8 d-flex px-4"
                                        style={{ marginLeft: "-5%" }}
                                      >
                                        <div className="card-body">
                                          <div className="d-flex gap-2 mb-2">
                                            <span className="btn btn-outline-success course-btn rounded-pill px-3 py-1 text-dark">
                                              {value.course.courseCategory.name
                                                ? value.course.courseCategory
                                                    .name
                                                : "Technical"}
                                            </span>
                                            {/* <span className="fs-14 font-subtitle bg-outline-success border border-success rounded-pill px-3 py-1 text-dark me-2" >
                                                                                            {value.course.courseCategory.name ? value.course.courseCategory.name : "Technical"}
                                                                                            </span> */}
                                            <div className="align-items-center border border-orange course_difficulty d-flex justify-content-center px-2 rounded-pill text-center">
                                              <i
                                                className={`mt-0 icon__course-difficulty difficulty-${value.course.courseLevel.name} position-relative`}
                                                data-bs-toggle="tooltip"
                                                data-bs-placement="top"
                                                data-bs-html="true"
                                                title={`Course Defficulty : ${value.course.courseLevel.name}`}
                                              >
                                                <span
                                                  className={` bar-1 ${
                                                    value.course.courseLevel
                                                      .name === "Advanced" ||
                                                    value.course.courseLevel
                                                      .name ===
                                                      "Intermediate" ||
                                                    value.course.courseLevel
                                                      .name === "Beginner"
                                                      ? "opacity-100"
                                                      : "opacity-25"
                                                  } `}
                                                ></span>
                                                <span
                                                  className={` bar-2 ${
                                                    value.course.courseLevel
                                                      .name === "Advanced" ||
                                                    value.course.courseLevel
                                                      .name === "Intermediate"
                                                      ? "opacity-100"
                                                      : "opacity-25"
                                                  } `}
                                                ></span>
                                                <span
                                                  className={` bar-3 ${
                                                    value.course.courseLevel
                                                      .name === "Advanced"
                                                      ? "opacity-100"
                                                      : "opacity-25"
                                                  } `}
                                                ></span>
                                              </i>
                                            </div>
                                          </div>
                                          <div>
                                            <p className="card-text mt-2 fw-bold ">
                                              <div
                                                className="fs-6 fw-bold cursor-pointer mt-1 py-1"
                                                onClick={() =>
                                                  handleOpen(value.course.id)
                                                }
                                              >
                                                {value.course.name ?? ""}
                                              </div>
                                              <p className="fw-normal">
                                                {value?.course?.mentors.map(
                                                  (mentor: any, index: any) => (
                                                    <React.Fragment key={index}>
                                                      <a
                                                        className="text-decoration-none text-muted-dark"
                                                        href={`/mentor-view/${mentor.mentor.id}`}
                                                      >
                                                        {index > 0 ? (
                                                          <>&nbsp;and&nbsp;</>
                                                        ) : (
                                                          <span>By&nbsp;</span>
                                                        )}
                                                        {mentor.mentor
                                                          .firstName ?? ""}{" "}
                                                        {mentor.mentor
                                                          .lastName ?? ""}
                                                        {index <
                                                        value.course.mentors
                                                          .length -
                                                          1 ? (
                                                          <></>
                                                        ) : null}
                                                      </a>
                                                    </React.Fragment>
                                                  ),
                                                )}
                                              </p>
                                            </p>
                                          </div>
                                          <div className="d-flex text-muted gap-2 mb-2 py-1">
                                            <img
                                              src="../media/icons/calendar-event-fill.svg"
                                              alt=""
                                            />
                                            {value.course.startDatetime ===
                                            null ? (
                                              ""
                                            ) : (
                                              <span>{formattedDate}</span>
                                            )}
                                          </div>
                                          <div
                                            className="d-flex text-muted-dark py-1"
                                            style={{ marginTop: "-10px" }}
                                          >
                                            <div className="d-flex text-muted-dark gap-2">
                                              <img
                                                src="../media/icons/mac-line.svg"
                                                alt=""
                                              />
                                              <div>
                                                {value.course.courseMode.name}
                                              </div>
                                            </div>
                                          </div>
                                          <button
                                            onClick={() =>
                                              handleStartCourse(
                                                value.course.slug,
                                              )
                                            }
                                            className="btn btn-primary mt-3"
                                          >
                                            Start Learning
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            );
                          })}
                        </div>
                      </div>
                    </section>
                    {/* <OnGoing ipCourse={inProgressCourses} /> */}
                  </TabPanel>
                  <TabPanel>
                    <section>
                      <div className="container">
                        <div className="row">
                          <div className="col-12">
                            {upcomingCourses?.map((value: any) => {
                              const date = moment
                                .utc(value.startDatetime)
                                .utcOffset("+05:30")
                                .format("YYYY-MM-DD HH:mm:ss");
                              const todaydate = moment
                                .utc(new Date())
                                .utcOffset("+05:30")
                                .format("YYYY-MM-DD");
                              const onlydate = moment
                                .utc(value.startDatetime)
                                .utcOffset("+05:30")
                                .format("YYYY-MM-DD");
                              const courseStartDate = new Date(
                                value.course.startDatetime,
                              );
                              const formattedDate = moment
                                .utc(value.course.startDatetime)
                                .format("ddd, DD MMM YYYY • hh:mm A");

                              return (
                                <div>
                                  <div className="col-lg-12 mx-auto mt-1 upcoming-courses h-100">
                                    <div
                                      className="card  px-2 pb-3 round course-card overflow-hidden  my-3 h-100 cursor-pointer"
                                      onClick={() =>
                                        handleStartLearning(value.course)
                                      }
                                    >
                                      <div className="row d-flex gy-4 gy-lg-0">
                                        <div className="col-lg-4 ">
                                          {value.course.images[0]?.original !=
                                          null ? (
                                            <img
                                              src={
                                                value.course.images[0]?.original
                                              }
                                              alt="Image"
                                              className="img-fluid mt-3 px-2"
                                              style={{
                                                height: "25vh",
                                                borderRadius: "15px",
                                              }}
                                            />
                                          ) : (
                                            <img
                                              src={toAbsoluteUrl(
                                                "../media/course/mylearning1.jpg",
                                              )}
                                              alt="Image"
                                              className="img-fluid mt-3 px-2"
                                              style={{ height: "25vh" }}
                                            />
                                          )}
                                        </div>
                                        <div
                                          className="col-lg-8 d-flex px-4"
                                          style={{ marginLeft: "-5%" }}
                                        >
                                          <div className="card-body">
                                            <div className="d-flex gap-2 mb-2">
                                              <span className="btn btn-outline-success course-btn rounded-pill px-3 py-1 text-dark">
                                                {value.course.courseCategory
                                                  .name
                                                  ? value.course.courseCategory
                                                      .name
                                                  : "Technical"}
                                              </span>
                                              <div className="align-items-center border border-orange course_difficulty d-flex justify-content-center px-2 rounded-pill text-center">
                                                <i
                                                  className={`mt-0 icon__course-difficulty difficulty-${value.course.courseLevel.name} position-relative`}
                                                  data-bs-toggle="tooltip"
                                                  data-bs-placement="top"
                                                  data-bs-html="true"
                                                  title={`Course Defficulty : ${value.course.courseLevel.name}`}
                                                >
                                                  <span
                                                    className={` bar-1 ${
                                                      value.course.courseLevel
                                                        .name === "Advanced" ||
                                                      value.course.courseLevel
                                                        .name ===
                                                        "Intermediate" ||
                                                      value.course.courseLevel
                                                        .name === "Beginner"
                                                        ? "opacity-100"
                                                        : "opacity-25"
                                                    } `}
                                                  ></span>
                                                  <span
                                                    className={` bar-2 ${
                                                      value.course.courseLevel
                                                        .name === "Advanced" ||
                                                      value.course.courseLevel
                                                        .name === "Intermediate"
                                                        ? "opacity-100"
                                                        : "opacity-25"
                                                    } `}
                                                  ></span>
                                                  <span
                                                    className={` bar-3 ${
                                                      value.course.courseLevel
                                                        .name === "Advanced"
                                                        ? "opacity-100"
                                                        : "opacity-25"
                                                    } `}
                                                  ></span>
                                                </i>
                                              </div>
                                            </div>
                                            <div>
                                              <p className="card-text mt-2 fw-bold">
                                                <div
                                                  className="fs-6 fw-bold cursor-pointer mt-1 py-1"
                                                  onClick={() =>
                                                    handleOpen(value.course.id)
                                                  }
                                                >
                                                  {value.course.name ?? ""}
                                                </div>
                                                <p className="fw-normal">
                                                  {value?.course?.mentors.map(
                                                    (
                                                      mentor: any,
                                                      index: any,
                                                    ) => (
                                                      <React.Fragment
                                                        key={index}
                                                      >
                                                        <a
                                                          className="text-decoration-none text-muted-dark"
                                                          href={`/mentor-view/${mentor.mentor.id}`}
                                                        >
                                                          {index > 0 ? (
                                                            <>&nbsp;and&nbsp;</>
                                                          ) : (
                                                            <span>
                                                              By&nbsp;
                                                            </span>
                                                          )}
                                                          {mentor.mentor
                                                            .firstName ??
                                                            ""}{" "}
                                                          {mentor.mentor
                                                            .lastName ?? ""}
                                                          {index <
                                                          value.course.mentors
                                                            .length -
                                                            1 ? (
                                                            <></>
                                                          ) : null}
                                                        </a>
                                                      </React.Fragment>
                                                    ),
                                                  )}
                                                </p>
                                              </p>
                                            </div>
                                            <div className="d-flex text-muted gap-2 mb-2">
                                              {value.course.startDatetime ===
                                              null ? (
                                                ""
                                              ) : (
                                                <>
                                                  <img
                                                    src="../media/icons/calendar-event-fill.svg"
                                                    alt=""
                                                  />
                                                  <span>{formattedDate}</span>
                                                </>
                                              )}
                                            </div>
                                            <div className="d-flex text-muted-dark gap-2">
                                              <img
                                                src="../media/icons/mac-line.svg"
                                                alt=""
                                              />
                                              <div>
                                                {value.course.courseMode.name}
                                              </div>
                                            </div>

                                            <div className="mt-3"></div>
                                            <>
                                              {onlydate > todaydate ? (
                                                <Counter
                                                  data={courseStartDate}
                                                  ctype={true}
                                                />
                                              ) : (
                                                <button
                                                  onClick={() =>
                                                    handleStartCourse(
                                                      value.course.slug,
                                                    )
                                                  }
                                                  className="btn btn-primary d-none"
                                                  style={{ marginTop: "35px" }}
                                                >
                                                  Start Learning
                                                </button>
                                              )}
                                            </>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </section>
                    {/* <Upcoming courses={upcomingCourses} /> */}
                  </TabPanel>
                  <TabPanel>
                    <section>
                      <div className="container">
                        <div className="row">
                          <div className="col-12">
                            {completedCourses?.map((value: any) => {
                              return (
                                <div>
                                  <div className="col-lg-12 mx-auto mt-1 upcoming-courses h-100">
                                    <div
                                      className="card  px-2 pb-3 round course-card overflow-hidden my-2 h-100 cursor-pointer"
                                      onClick={() =>
                                        handleStartLearning(value.course)
                                      }
                                    >
                                      <div className="row d-flex gy-4 gy-lg-0">
                                        <div className="col-lg-4 ">
                                          {value.course.images[0]?.original !=
                                          null ? (
                                            <img
                                              src={
                                                value.course.images[0]?.original
                                              }
                                              alt="Image"
                                              className="img-fluid mt-3 px-2"
                                              style={{
                                                height: "25vh",
                                                borderRadius: "15px",
                                              }}
                                            />
                                          ) : (
                                            <img
                                              src={toAbsoluteUrl(
                                                "../media/course/mylearning1.jpg",
                                              )}
                                              alt="Image"
                                              className="img-fluid mt-3 px-2"
                                              style={{ height: "25vh" }}
                                            />
                                          )}
                                        </div>
                                        <div
                                          className="col-md-8 d-flex px-4"
                                          style={{ marginLeft: "-5%" }}
                                        >
                                          <div className="card-body">
                                            <div className="d-flex gap-2 mb-2">
                                              <span className="btn btn-outline-success course-btn rounded-pill px-3 py-1 text-dark">
                                                {value.course.courseCategory
                                                  .name
                                                  ? value.course.courseCategory
                                                      .name
                                                  : "Technical"}
                                              </span>
                                              <div className="align-items-center border border-orange course_difficulty d-flex justify-content-center px-2 rounded-pill text-center">
                                                <i
                                                  className={`mt-0 icon__course-difficulty difficulty-${value.course.courseLevel.name} position-relative`}
                                                  data-bs-toggle="tooltip"
                                                  data-bs-placement="top"
                                                  data-bs-html="true"
                                                  title={`Course Defficulty : ${value.course.courseLevel.name}`}
                                                >
                                                  <span
                                                    className={` bar-1 ${
                                                      value.course.courseLevel
                                                        .name === "Advanced" ||
                                                      value.course.courseLevel
                                                        .name ===
                                                        "Intermediate" ||
                                                      value.course.courseLevel
                                                        .name === "Beginner"
                                                        ? "opacity-100"
                                                        : "opacity-25"
                                                    } `}
                                                  ></span>
                                                  <span
                                                    className={` bar-2 ${
                                                      value.course.courseLevel
                                                        .name === "Advanced" ||
                                                      value.course.courseLevel
                                                        .name === "Intermediate"
                                                        ? "opacity-100"
                                                        : "opacity-25"
                                                    } `}
                                                  ></span>
                                                  <span
                                                    className={` bar-3 ${
                                                      value.course.courseLevel
                                                        .name === "Advanced"
                                                        ? "opacity-100"
                                                        : "opacity-25"
                                                    } `}
                                                  ></span>
                                                </i>
                                              </div>
                                            </div>
                                            <div>
                                              <p className="card-text mt-2 fw-bold py-1">
                                                <div
                                                  className="fs-6 fw-bold cursor-pointer mt-1"
                                                  onClick={() =>
                                                    handleOpen(value.course.id)
                                                  }
                                                >
                                                  {value.course.name ?? ""}
                                                </div>
                                                {/* {value.course.name} */}
                                                <p className="fw-normal py-1">
                                                  {value?.course?.mentors.map(
                                                    (
                                                      mentor: any,
                                                      index: any,
                                                    ) => (
                                                      <React.Fragment
                                                        key={index}
                                                      >
                                                        <a
                                                          className="text-decoration-none text-muted-dark"
                                                          href={`/mentor-view/${mentor.mentor.id}`}
                                                        >
                                                          {index > 0 ? (
                                                            <>&nbsp;and&nbsp;</>
                                                          ) : (
                                                            <span>
                                                              By&nbsp;
                                                            </span>
                                                          )}
                                                          {mentor.mentor
                                                            .firstName ??
                                                            ""}{" "}
                                                          {mentor.mentor
                                                            .lastName ?? ""}
                                                          {index <
                                                          value.course.mentors
                                                            .length -
                                                            1 ? (
                                                            <></>
                                                          ) : null}
                                                        </a>
                                                      </React.Fragment>
                                                    ),
                                                  )}
                                                </p>
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </section>
                    {/* <Completed courses={completedCourses} /> */}
                  </TabPanel>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Learning;
