import React from 'react';
import './NotFound.css';
import { toAbsoluteUrl } from 'assets/helpers/AssetHelpers';
import { useNavigate } from 'react-router-dom';
import { BASE_PAGE_URL } from 'pages/routing/routePages';

const NotFound = () => {
	const navigate = useNavigate();
	const handleHome = () => {
		navigate(BASE_PAGE_URL);
	};
	return (
		<section
			className="d-flex align-items-center justify-content-center"
			style={{ height: '70vh' }}>
			<div className="container">
				<div className="row">
					<div className="col-lg-12">
						<div className="d-flex justify-content-center align-items-center">
							<img src={toAbsoluteUrl('/media/nodata/404.png')} alt="Nodata_Found" />
						</div>
						<div className="text-center">
							<h2 className="nodata-title lora">Page Not Found</h2>
							<p className="nodata-label mt-0">The page you are trying to access does not exist</p>
							<button className="btn btn_goto_course g_mid w-auto" onClick={handleHome}>
								Back to Home
							</button>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default NotFound;
