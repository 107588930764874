import React, { useState, useCallback, useEffect } from "react";
import SideFilter from "./SideFilter";
import "./../../../assets/sass/components/_webinarNew.scss";
import RecommendSlider from "./RecommendSlider";
import NewArrivalsSlider from "./NewArrivalsSlider";
import { toAbsoluteUrl } from "../../../assets/helpers/AssetHelpers";
import { GETCOURSES } from "../../Home-Page/core/requests";
import { MdDateRange } from "react-icons/md";
import { HiMiniComputerDesktop } from "react-icons/hi2";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { requests_addtoWishListMutation } from "../../Courses-Page/core/__generated__/requests_addtoWishListMutation.graphql";
import { ADDTOWISHLIST } from "../../Courses-Page/core/requests";
import { getUserData } from "../../../utils/getUserData";
import { useMutation, useQuery } from "@apollo/client";
import { GETWISHLISTS } from "../../Bookmark/core/requests";
import Loader from "../../common/Spinner/Spinner";
import { Login } from "../../../pages/auth/Login";
enum PriceName {
  PAID = "PAID",
  FREE = "FREE",
}
type PriceItem = {
  id: number;
  value: string;
  name: PriceName;
};

const Webinar = (props: any) => {
  const { data: wishList, refetch } = useQuery(GETWISHLISTS, {
    variables: {
      paginate: null,
    },
  });
  const [selectedPrice, setSelectedPrice] = useState<PriceName | null>(null);
  const [refreshedQueryOptions, setRefreshedQueryOptions] =
    React.useState<any>(null);
  const [filterLevel, setFilterLevel] = useState<any>([]);
  const [filterMode, setFilterMode] = useState<any>([]);
  const [filterLanguage, setFilterLanguage] = useState<any>([]);
  const [filterSortBy, setFilterSortBy] = useState<any>([]);
  const [whereCondition, setWhereCondition] = React.useState({});
  const [checkedCategory, setCheckedCategory] = useState<any>([]);
  const [checkedLevel, setCheckedLevel] = useState<any>([]);
  const [checkedMode, setCheckedMode] = useState<any>([]);
  const [checkedLanguage, setCheckedLanguage] = useState<any>([]);
  // const { authToken: currentAuthToken } = getUserData() ?? { authToken: '' };
  const [filterCategory, setFilterCategory] = useState<any>([]);
  const [selectedSort, setSelectedSort] = useState("newest");
  const [currentAuthToken, setCurrentAuthToken] = React.useState(
    getUserData().authToken,
  );
  const [open, setOpen] = useState(false);
  const [openRegister, setOpenRegister] = useState(false);
  const navigate = useNavigate();

  const filteroptions = [
    { id: "1", value: "newest", name: "Newest" },
    { id: "2", value: "bestseller", name: "Best Seller" },
    { id: "3", value: "toprated", name: "Top Rated" },
  ];
  const [commit] = useMutation(ADDTOWISHLIST);

  const { data: allCourse, loading } = useQuery(GETCOURSES, {
    variables: {
      where: whereCondition,
      // orderBy: {
      //   startDatetime: selectedSort === 'newest' ? 'desc' : null,
      // },
    },
  });
  const allCourseResult = allCourse?.getPublicCourses?.records ?? [];
  const allCourseRecord =
    selectedSort === "bestseller"
      ? [...allCourseResult].sort(
          (a: any, b: any) =>
            b?.courseEnrollmentCount - a?.courseEnrollmentCount,
        )
      : selectedSort === "toprated"
      ? [...allCourseResult].sort(
          (a: any, b: any) => b?.ratingCounts - a?.ratingCounts,
        )
      : selectedSort === "newest"
      ? [...allCourseResult].sort(
          (a: any, b: any) => b?.startDatetime - a?.startDatetime,
        )
      : [...allCourseResult];

  let wishlistEvent = false;
  const handleOpen = (slug: string) => {
    if (wishlistEvent === false) {
      navigate(`/course-view/${slug}`);
    }
  };
  const handleWishList = async (id: any) => {
    wishlistEvent = true;
    if (currentAuthToken) {
      const variables = {
        courseId: id,
      };
      commit({
        variables: {
          courseId: id,
        },
        onCompleted: () => {
          toast.success("successfully added to wishlist", {
            style: {
              padding: "16px",
            },
          });
          navigate(`/my-profile`, { state: { page: "bookmark" } });
        },
        onError: (err: any) => {
          toast.error(err.message);
        },
      });
    }
  };
  const handleClick = (value: any) => {
    setCheckedCategory(checkedCategory.filter((e: any) => e.id !== value.id));
    setFilterCategory(filterCategory.filter((e: any) => e !== value.id));
    const checkboxes = document.getElementById(value.id);
    if (checkboxes) {
      (checkboxes as HTMLInputElement).checked = false;
    }
  };
  const handleLevelClose = (value: any) => {
    setCheckedLevel(checkedLevel.filter((e: any) => e.id !== value.id));
    setFilterLevel(filterLevel.filter((e: any) => e !== value.id));
    const checkboxes = document.getElementById(value.id);
    if (checkboxes) {
      (checkboxes as HTMLInputElement).checked = false;
    }
  };
  const handleModeClose = (value: any) => {
    setCheckedMode(checkedMode.filter((e: any) => e.id !== value.id));
    setFilterMode(filterMode.filter((e: any) => e !== value.id));
    const checkboxes = document.getElementById(value.id);
    if (checkboxes) {
      (checkboxes as HTMLInputElement).checked = false;
    }
  };
  const handleLanguageClose = (value: any) => {
    setCheckedLanguage(checkedLanguage.filter((e: any) => e.id !== value.id));
    setFilterLanguage(filterLanguage.filter((e: any) => e !== value.id));
    const checkboxes = document.getElementById(value.id);
    if (checkboxes) {
      (checkboxes as HTMLInputElement).checked = false;
    }
  };
  const handlePriceClose = () => {
    setSelectedPrice(null);
  };
  const refresh = useCallback(() => {
    setRefreshedQueryOptions((prev: any) => ({
      fetchKey: (prev?.fetchKey ?? 0) + 1,
      fetchPolicy: "store-and-network",
    }));
  }, []);
  // const handleModeChange = (event: any, value: any) => {
  //   let updatedList = [...checkedMode];
  //   if (event.target.checked) {
  //     updatedList = [...checkedMode, { name: value.name, id: event.target.value }];
  //   } else {
  //     updatedList.splice(checkedMode.indexOf(event.target.value), 1);
  //   }
  //   setCheckedMode(updatedList);
  //   const modeValue = updatedList?.map((e: any) => e.id);

  //   setFilterMode(modeValue);
  // };
  const handleModeChange = (event: any, value: any) => {
    setCheckedMode((prevCheckedMode: any) => {
      const updatedList = event.target.checked
        ? [...prevCheckedMode, { name: value.name, id: event.target.value }]
        : prevCheckedMode.filter((item: any) => item.id !== event.target.value);

      const modeValue = updatedList.map((e: any) => e.id);
      setFilterMode(modeValue);
      return updatedList;
    });
  };
  const handleLevelChange = (event: any, value: any) => {
    setCheckedLevel((prevCheckedLevel: any) => {
      const updatedList = event.target.checked
        ? [...prevCheckedLevel, { name: value.name, id: event.target.value }]
        : prevCheckedLevel.filter(
            (item: any) => item.id !== event.target.value,
          );
      const levelValue = updatedList.map((e: any) => e.id);
      setFilterLevel(levelValue);
      return updatedList;
    });
  };
  // const handleLevelChange = (event: any, value: any) => {
  //   let updatedList = [...checkedLevel];
  //   if (event.target.checked) {
  //     updatedList = [...checkedLevel, { name: value.name, id: event.target.value }];
  //   } else {
  //     updatedList.splice(checkedLevel.indexOf(event.target.value), 1);
  //   }
  //   setCheckedLevel(updatedList);
  //   const levelValue = updatedList?.map((e: any) => e.id);
  //   setFilterLevel(levelValue);
  // };
  // const handleCategoryChange = (event: any, value: any) => {
  //   let updatedList = [...checkedCategory];
  //   console.log("updatedList",updatedList);

  //   if (event.target.checked) {
  //     updatedList = [
  //       ...checkedCategory,
  //       { name: value.name, id: event.target.value },
  //     ];
  //   } else {
  //     updatedList.splice(checkedCategory.indexOf(event.target.value), 1);
  //   }
  //   setCheckedCategory(updatedList);
  //   const categoryValue = updatedList?.map((e: any) => e.id);
  //   setFilterCategory(categoryValue);
  // };
  const handleCategoryChange = (event: any, value: any) => {
    setCheckedCategory((prevCheckedCategory: any) => {
      const updatedList = event.target.checked
        ? [...prevCheckedCategory, { name: value.name, id: event.target.value }]
        : prevCheckedCategory.filter(
            (item: any) => item.id !== event.target.value,
          );
      const categoryValue = updatedList.map((e: any) => e.id);
      setFilterCategory(categoryValue);
      return updatedList;
    });
  };
  const handleLanguageChange = (event: any, value: any) => {
    setCheckedLanguage((prevCheckedLanguage: any) => {
      const updatedList = event.target.checked
        ? [...prevCheckedLanguage, { name: value.name, id: event.target.value }]
        : prevCheckedLanguage.filter(
            (item: any) => item.id !== event.target.value,
          );

      const languageValue = updatedList.map((e: any) => e.id);
      setFilterLanguage(languageValue);

      return updatedList;
    });
  };
  const handlePriceChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    value: PriceItem,
  ) => {
    const { checked } = event.target;
    if (checked) {
      setSelectedPrice(value.name);
    } else {
      setSelectedPrice(null);
    }
  };
  const filterData = () => {
    const conditions: any = {};
    conditions["and"] = {};
    conditions["and"]["course_type_id"] = {
      eq: "bffc342c-1e06-4bb8-9f54-81a9dbb45d2b",
    };
    if (filterCategory.length > 0) {
      conditions["course_category_id"] = { in: filterCategory };
    }
    if (filterLevel.length > 0) {
      conditions["course_level_id"] = { in: filterLevel };
    }
    if (filterMode.length > 0) {
      conditions["course_mode_id"] = { in: filterMode };
    }
    if (filterLanguage.length > 0) {
      conditions["course_language_id"] = { in: filterLanguage };
    }
    if (selectedPrice === "FREE") {
      conditions["cost_type"] = { eq: PriceName.FREE };
    }
    if (selectedPrice === "PAID") {
      conditions["cost_type"] = { eq: PriceName.PAID };
    }
    setWhereCondition(conditions);
    refresh();
  };
  useEffect(() => {
    filterData();
  }, [filterCategory, filterLevel, filterMode, filterLanguage, selectedPrice]);
  // const allCourseRecord = allCourse?.getPublicCourses?.records ?? [];
  const resetData = () => {
    setCheckedCategory([]);
    setFilterCategory([]);
    setCheckedLevel([]);
    setFilterLevel([]);
    setCheckedMode([]);
    setFilterMode([]);
    setCheckedLanguage([]);
    setFilterLanguage([]);
    setSelectedPrice(null);
    const checkboxes = document.querySelectorAll('input[type="checkbox"]');
    checkboxes.forEach(
      (checkbox: Element) => ((checkbox as HTMLInputElement).checked = false),
    );
    setWhereCondition({});
  };
  const filtercondition =
    filterCategory?.length > 0 ||
    filterLevel?.length > 0 ||
    filterMode?.length > 0 ||
    filterLanguage?.length > 0 ||
    selectedPrice === "FREE" ||
    selectedPrice === "PAID";
  React.useEffect(() => {
    const handleStorageChange = () => {
      setCurrentAuthToken(getUserData().authToken);
    };

    window.addEventListener("storage", handleStorageChange);
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  React.useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentAuthToken(getUserData().authToken);
    }, 500);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <>
      <section className="hero-section-top-padding"></section>
      <section className="bg-white border-bottom py-5 mt-1">
        <div className="container">
          <div className="row justify-content-lg-center">
            <SideFilter
              handleModeChange={handleModeChange}
              handleLevelChange={handleLevelChange}
              handleCategoryChange={handleCategoryChange}
              handlePriceChange={handlePriceChange}
              handleLanguageChange={handleLanguageChange}
              selectedPrice={selectedPrice}
            />
            <div className="col-lg-9">
              {filtercondition === false ? (
                <div className="row gx-lg-0 gy-lg-3 mb-5 mb-lg-5">
                  <div className="col-12">
                    <div className="course-banner">
                      <img
                        src="../media/banner/webinar.jpg"
                        alt=""
                        srcSet="../media/banner/webinar.jpg"
                        className="img-fluid rounded-3"
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="list-courses">
                      <ul
                        className="nav list-courses-nav list-courses-nav-tabs nav-tabs mb-3"
                        id="myTab"
                        role="tablist"
                      >
                        <li
                          className="list-courses-nav-item nav-item pe-4 ps-2"
                          role="presentation"
                        >
                          <button
                            className="list-courses-nav-link mx-0 px-0 py-0 nav-link active "
                            id="RecommendSlider-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#RecommendSlider"
                            type="button"
                            role="tab"
                            aria-controls="RecommendSlider"
                            aria-selected="true"
                          >
                            Recommend for you
                          </button>
                        </li>
                        <li
                          className="list-courses-nav-item nav-item"
                          role="presentation"
                        >
                          <button
                            className="list-courses-nav-link mx-0 px-0 py-0 nav-link"
                            id="NewArrivalsSlider-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#NewArrivalsSlider"
                            type="button"
                            role="tab"
                            aria-controls="NewArrivalsSlider"
                            aria-selected="false"
                          >
                            New Arrivals
                          </button>
                        </li>
                      </ul>
                      <div className="tab-content" id="myTabContent">
                        <div
                          className="tab-pane fade show active"
                          id="RecommendSlider"
                          role="tabpanel"
                          aria-labelledby="RecommendSlider-tab"
                        >
                          <div className="row">
                            <div className="col-12">
                              <RecommendSlider
                                whereCondition={whereCondition}
                                refreshedQueryOptions={refreshedQueryOptions}
                              />
                            </div>
                          </div>
                        </div>
                        <div
                          className="tab-pane fade"
                          id="NewArrivalsSlider"
                          role="tabpanel"
                          aria-labelledby="NewArrivalsSlider-tab"
                        >
                          <div className="row">
                            <div className="col-12">
                              <NewArrivalsSlider
                                whereCondition={whereCondition}
                                refreshedQueryOptions={refreshedQueryOptions}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}

              <div className="row gy-4 gx-lg-0 gy-lg-3">
                <div className="col-12">
                  <div className="course-heading d-flex align-items-center justify-content-between">
                    <div className="title font-title head1 py-3">
                      {filtercondition === false ? "All Webinars" : "Webinars"}
                    </div>
                    <div className="filter d-flex d-lg-block">
                      <div className="input-group mb-3">
                        <div className="input-group-text bg-transparent border-end-0 pe-0">
                          <img
                            src={toAbsoluteUrl("/media/logo/filter.png")}
                            alt=""
                          />
                        </div>
                        <select
                          className="border border-color3 form-select course-select-filter shadpw-none me-3 me-lg-0 border-start-0"
                          id="inputGroupSelect02"
                          onChange={(e) => setSelectedSort(e.target.value)}
                        >
                          <option selected disabled>
                            Sort By
                          </option>
                          {filteroptions.map((value: any) => {
                            return (
                              <>
                                <option value={value.value}>
                                  {value.name}
                                </option>
                              </>
                            );
                          })}
                        </select>
                      </div>
                      <div className="mobile-filter d-block d-lg-none">
                        <div className="p-2 border border-color2 rounded-3">
                          <i className="fa-solid fa-filter"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {loading ? (
                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ minHeight: "300px" }}
                  >
                    <Loader />
                  </div>
                ) : (
                  <div className="col-12">
                    <div className="webinar-filters-tags">
                      {filtercondition === true ? (
                        <>
                          <div className="card border-0 bg-transparent">
                            <div className="card-body">
                              <div className="row">
                                <div className="col-12">
                                  <div className="d-flex flex-wrap">
                                    {filtercondition === true && (
                                      <div
                                        className="cursor-pointer text-nowrap p-2 m-1 rounded-3 bg-transparent text-primary  "
                                        onClick={resetData}
                                      >
                                        {" "}
                                        <span> Clear All</span>
                                        <i className="fa fa-close mx-1 text-nowrap" />
                                      </div>
                                    )}
                                    {checkedCategory.map((value: any) => {
                                      return (
                                        <>
                                          <div
                                            className="cursor-pointer text-nowrap p-2 border m-1 rounded-3"
                                            onClick={() => handleClick(value)}
                                          >
                                            {" "}
                                            <span> {value.name} </span>
                                            <i className="fa fa-close mx-1 text-nowrap" />
                                          </div>
                                        </>
                                      );
                                    })}
                                    {checkedLevel.map((value: any) => {
                                      return (
                                        <>
                                          <div
                                            className="cursor-pointer text-nowrap p-2 border m-1 rounded-3"
                                            onClick={() =>
                                              handleLevelClose(value)
                                            }
                                          >
                                            {" "}
                                            <span> {value.name} </span>
                                            <i className="fa fa-close mx-1 text-nowrap" />
                                          </div>
                                        </>
                                      );
                                    })}
                                    {checkedMode.map((value: any) => {
                                      return (
                                        <>
                                          <div
                                            className="cursor-pointer text-nowrap p-2 border m-1 rounded-3"
                                            onClick={() =>
                                              handleModeClose(value)
                                            }
                                          >
                                            {" "}
                                            <span> {value.name} </span>
                                            <i className="fa fa-close mx-1 text-nowrap" />
                                          </div>
                                        </>
                                      );
                                    })}
                                    {checkedLanguage.map((value: any) => {
                                      return (
                                        <>
                                          <div
                                            className="cursor-pointer text-nowrap p-2 border m-1 rounded-3"
                                            onClick={() =>
                                              handleLanguageClose(value)
                                            }
                                          >
                                            {" "}
                                            <span> {value.name} </span>
                                            <i className="fa fa-close mx-1 text-nowrap" />
                                          </div>
                                        </>
                                      );
                                    })}
                                    {selectedPrice && (
                                      <div
                                        className="cursor-pointer text-nowrap p-2 border m-1 rounded-3"
                                        onClick={() => handlePriceClose()}
                                      >
                                        {" "}
                                        <span>
                                          {" "}
                                          {selectedPrice === "FREE" &&
                                            "Free"}{" "}
                                          {selectedPrice === "PAID" && "Paid"}
                                        </span>
                                        <i className="fa fa-close mx-1 text-nowrap" />
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : null}
                    </div>
                    <div className="webinar-list-courses">
                      {allCourseRecord.length > 0 ? (
                        <>
                          <div className="row gx-3 gy-3">
                            {allCourseRecord
                              .filter(
                                (value: any) =>
                                  new Date(value.endDatetime) > new Date(),
                              )
                              .map((value: any) => {
                                // const date = moment.utc(value.startDatetime).local().format("YYYY-MM-DD HH:mm:ss");
                                const formattedDate = moment
                                  .utc(value.startDatetime)
                                  .format("ddd, DD MMM YYYY • hh:mm A");
                                const endDate = new Date(value.endDatetime);
                                return (
                                  <>
                                    <div className="col-12 col-md-6 col-lg-4">
                                      <div className="recomanded-courses h-100">
                                        <div
                                          className="card p-3 course-card round overflow-hidden border-muted h-100 cursor-pointer fixed-height-card"
                                          onClick={() => handleOpen(value.slug)}
                                        >
                                          <div className="card-body p-0">
                                            <div className="row d-flex gy-4 gy-lg-3">
                                              <div className="col-xl-12 col-lg-12 col-12">
                                                <div className="card-img image position-relative overflow-hidden">
                                                  {value?.images?.length > 0 ? (
                                                    <>
                                                      {value?.images?.map(
                                                        (value: any) => {
                                                          return (
                                                            <>
                                                              <img
                                                                alt=""
                                                                src={
                                                                  value.original
                                                                }
                                                                className="card-img-top  image img-fluid round bg-light"
                                                              />
                                                            </>
                                                          );
                                                        },
                                                      )}
                                                    </>
                                                  ) : (
                                                    <img
                                                      alt=""
                                                      src={toAbsoluteUrl(
                                                        "/media/course/1.jpg",
                                                      )}
                                                      className=" card-img-top  image img-fluid round bg-light"
                                                    />
                                                  )}
                                                  {currentAuthToken !==
                                                    null && (
                                                    <div
                                                      className="course-tag cursor-pointer"
                                                      onClick={() => {
                                                        handleWishList(
                                                          value.id,
                                                        );
                                                      }}
                                                    >
                                                      <div className="course-badge text-dark p-2 py-1 rounded-1">
                                                        <i className="fa fa-bookmark tag fs-6"></i>
                                                      </div>
                                                    </div>
                                                  )}
                                                </div>
                                              </div>
                                              <div className="col-xl-12 col-lg-12 col-12">
                                                <div className="d-flex justify-content-between mb-2 justify-content-lg-between">
                                                  <span className="btn btn-outline-success course-btn rounded-pill px-3 py-1 text-dark">
                                                    {value.courseCategory.name
                                                      ? value.courseCategory
                                                          .name
                                                      : "Technical"}
                                                  </span>

                                                  <div className="align-items-center border border-orange course_difficulty d-flex justify-content-center px-2 rounded-pill text-center">
                                                    <i
                                                      className={`mt-0 icon__course-difficulty difficulty-${value.courseLevel.name} position-relative`}
                                                      data-bs-toggle="tooltip"
                                                      data-bs-placement="top"
                                                      data-bs-html="true"
                                                      title={`Course Defficulty : ${value.courseLevel.name}`}
                                                    >
                                                      <span
                                                        className={` bar-1 ${
                                                          value.courseLevel
                                                            .name ===
                                                            "Advanced" ||
                                                          value.courseLevel
                                                            .name ===
                                                            "Intermediate" ||
                                                          value.courseLevel
                                                            .name === "Beginner"
                                                            ? "opacity-100"
                                                            : "opacity-25"
                                                        } `}
                                                      ></span>
                                                      <span
                                                        className={` bar-2 ${
                                                          value.courseLevel
                                                            .name ===
                                                            "Advanced" ||
                                                          value.courseLevel
                                                            .name ===
                                                            "Intermediate"
                                                            ? "opacity-100"
                                                            : "opacity-25"
                                                        } `}
                                                      ></span>
                                                      <span
                                                        className={` bar-3 ${
                                                          value.courseLevel
                                                            .name === "Advanced"
                                                            ? "opacity-100"
                                                            : "opacity-25"
                                                        } `}
                                                      ></span>
                                                    </i>
                                                  </div>
                                                </div>
                                                <div
                                                  className="card-title mt-2 fw-bold head1 cursor-pointer"
                                                  onClick={() =>
                                                    handleOpen(value.slug)
                                                  }
                                                >
                                                  {value.name ?? ""}
                                                </div>
                                                <div className="text-muted">
                                                  {value?.mentors?.map(
                                                    (
                                                      mentor: any,
                                                      index: any,
                                                    ) => (
                                                      <React.Fragment
                                                        key={index}
                                                      >
                                                        <a
                                                          className="text-decoration-none text-muted-dark"
                                                          href={`/mentor-view/${mentor.mentor.id}`}
                                                        >
                                                          {index > 0 ? (
                                                            <>and&nbsp;</>
                                                          ) : (
                                                            <span>
                                                              By&nbsp;
                                                            </span>
                                                          )}
                                                          {mentor.mentor
                                                            .firstName ??
                                                            ""}{" "}
                                                          {mentor.mentor
                                                            .lastName ?? ""}
                                                          {index <
                                                          value.mentors.length -
                                                            1 ? (
                                                            <></>
                                                          ) : null}
                                                        </a>
                                                      </React.Fragment>
                                                    ),
                                                  )}

                                                  {value.startDatetime ===
                                                  null ? (
                                                    ""
                                                  ) : (
                                                    <>
                                                      <div className="my-2 d-flex text-muted-dark">
                                                        <img
                                                          src="../media/icons/calendar-event-fill.svg"
                                                          alt=""
                                                          className="fs-5 me-1"
                                                        />
                                                        {formattedDate}
                                                      </div>
                                                    </>
                                                  )}
                                                  <div className="text-muted-dark">
                                                    <HiMiniComputerDesktop className="fs-5 me-1" />
                                                    {value.courseMode?.name ??
                                                      ""}
                                                  </div>
                                                </div>
                                                <div className="course-price py-1">
                                                  <div className="price">
                                                    {value.amount === 0 &&
                                                      value.sellPrice === 0 && (
                                                        <>
                                                          <span className="course-free">
                                                            Free
                                                          </span>
                                                        </>
                                                      )}
                                                    {value.sellPrice !== 0 ? (
                                                      <>
                                                        <span className="amount">
                                                          <i className="fa-solid fa-indian-rupee-sign"></i>
                                                          <span className="">
                                                            {value.sellPrice}
                                                          </span>
                                                        </span>
                                                        <span className="ms-2 sell text-decoration-line-through">
                                                          <i className="fa-solid fa-indian-rupee-sign"></i>
                                                          <span className="">
                                                            {value.amount}
                                                          </span>
                                                        </span>
                                                      </>
                                                    ) : value.amount !== 0 ? (
                                                      <>
                                                        <span className="amount">
                                                          <i className="fa-solid fa-indian-rupee-sign"></i>
                                                          <span className="">
                                                            {value.amount}
                                                          </span>
                                                        </span>
                                                      </>
                                                    ) : (
                                                      ""
                                                    )}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                );
                              })}
                          </div>
                        </>
                      ) : (
                        <>
                          <div
                            className="alert alert-warning mt-4 py-2 px-2"
                            role="alert"
                          >
                            <span>
                              <i className="fa-solid fa-circle-info me-3"></i>No
                              upcoming Webinars as of now, we will soon be
                              coming with one!
                            </span>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Webinar;
