import React from "react";
import { useNavigate } from "react-router-dom";
import { Tab, Tabs } from "react-tabs";
import { TabList } from "react-tabs";
import { toAbsoluteUrl } from "../../../assets/helpers/AssetHelpers";
import Slider from "react-slick";
import YourUpCard from "./YourUpCard";
const settings = {
  // prevArrow: <PrevArrow />,
  // nextArrow: <NextArrow />,
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 2,
  slidesToScroll: 2,
  initialSlide: 0,
  adaptiveHeight: false,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const YourUpcoming = () => {
  const navigate = useNavigate();
  const yog = () => {
    navigate("/yourlearning");
  };
  return (
    <>
      <section className="section">
        <div className="container ">
          <div className="row ">
            <div className="col-12 mt-5">
              <div className="flex mb-3">
                <div className="font-title title head1">Your Learnings</div>
              </div>
              <Tabs className="mt-4">
                <TabList>
                  <Tab onClick={() => yog()}>Ongoing</Tab>
                  <Tab>Upcoming</Tab>
                </TabList>
              </Tabs>
            </div>
            <div className="col-lg-12 ">
              <Slider {...settings}>
                <YourUpCard />
                <YourUpCard />
                <YourUpCard />
                <YourUpCard />
              </Slider>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default YourUpcoming;
