import React, { useCallback, useEffect, useState } from 'react';
import { Course, RatingStatusEnum } from '../Course/type';
import StarRating from '../CourseDetail/DetailHeroSection/StarRatings';
import moment from 'moment';
import {
	getCoursePaymentSubUrl,
	getDetailsSubUrl,
	getViewCourseWithSearchSlug,
} from 'pages/routing/routePages';
import { useNavigate } from 'react-router-dom';
import ProgressBar from 'components/_v2/CommonComponents/ProgressBar';
import { UserType } from 'types/globalTypes';
import { mentorNamesRenderer } from 'components/_v2/CommonComponents/helper';
import { ButtonBorderType, ButtonType, CardHeight } from 'components/_v2/CommonComponents/types';
import './CardInformation.css';
import { Image } from 'react-bootstrap';
import { toAbsoluteUrl } from 'assets/helpers/AssetHelpers';
import Loading from './Loading';
import Button from './Button';
import { useAuthModalContext } from 'context/AuthContext';
import SubmitStarRatings from 'components/_v2/CommonComponents/SubmitStarRatings/SubmitStarRatings';

const CardInformation = ({
	course,
	disableHoverInteraction,
	handleDownloadPDF,
	isUserSpecificContent,
	userCourses,
	user,
	cardHeight,
	showBackdropShadow,
	allowDownloadCertificate = false,
	areQuizzesCompleted,
	isSliderCard,
	hasCardOffset = false,
	onSubmitRatings,
}: {
	course: Course;
	disableHoverInteraction: boolean;
	handleDownloadPDF?: (setLoading: (loading: boolean) => void) => void;
	isUserSpecificContent: boolean;
	userCourses: Course[];
	user: UserType;
	cardHeight: CardHeight;
	showBackdropShadow?: boolean;
	allowDownloadCertificate?: boolean;
	areQuizzesCompleted?: boolean;
	isSliderCard?: boolean;
	hasCardOffset?: boolean;
	onSubmitRatings?: ({
		courseId,
		review,
		starCount,
	}: {
		courseId: string;
		review: string;
		starCount: number;
	}) => void;
}) => {
	const navigate = useNavigate();
	const [isHovered, setIsHovered] = useState<boolean>(false);
	const [timeLeft, setTimeLeft] = useState('');
	const [loading, setLoading] = useState<boolean>(false);
	const { toggleLoginOpen } = useAuthModalContext();

	const handleContinueLearning = useCallback(() => {
		navigate(getViewCourseWithSearchSlug(course.slug));
	}, [course]);

	const handleDetailPageReroute = useCallback(() => {
		navigate(getDetailsSubUrl(course.slug));
	}, [course.slug, navigate]);

	const handleEnrollPageReroute = useCallback(() => {
		if (user) {
			navigate(getCoursePaymentSubUrl(course.slug));
		} else {
			toggleLoginOpen(); // Open the registration modal
		}
	}, [course.slug, user]);

	const isCourseSubscribed = userCourses.some((userCourse) => userCourse.id === course.id);
	const cardClasses = hasCardOffset ? 'col-xl-6 col-xxl-4' : 'col-xl-4 col-xxl-3';

	useEffect(() => {
		const updateCountdown = () => {
			const now = moment();
			const targetDate = moment(course.startDatetime);

			const duration = moment.duration(targetDate.diff(now));

			if (duration.asMilliseconds() <= 0) {
				setTimeLeft('Starts Today');
			} else {
				const days = Math.floor(duration.asDays());
				const hours = duration.hours().toString().padStart(2, '0');
				const minutes = duration.minutes().toString().padStart(2, '0');

				setTimeLeft(`Starts in ${days} days : ${hours} hours : ${minutes} minutes`);
			}
		};
		updateCountdown();
		const interval = setInterval(updateCountdown, 1000);

		return () => clearInterval(interval);
	}, [course]);

	const renderFirstCard = useCallback(() => {
		const isStartDateAheadOfCurrentDate = moment(moment()).isBefore(moment(course.startDatetime));

		if (!course) {
			return null;
		}
		const courseImage = course?.images?.[0]?.original;
		const courseMode = course?.courseMode?.name;

		return (
			<div
				className={`card ${
					showBackdropShadow ? 'card-custom-shadow' : ''
				} flex-fill p-3 ${cardHeight}`}
				onClick={() => {
					isUserSpecificContent && disableHoverInteraction && handleContinueLearning();
				}}>
				<img src={courseImage} className="card-img image-fit-contain" alt="Course_Image" />
				{isStartDateAheadOfCurrentDate && (
					<span
						className="d-flex justify-content-center g_semi"
						style={{
							background: '#FFCD0099',
							borderRadius: '0 0 8px 8px',
							fontSize: '12px',
						}}>
						{timeLeft}
					</span>
				)}
				<div className="d-flex justify-content-between pt-3">
					<button className="btn rounded-pill button-tag g_reg">
						{courseMode === 'Online' ? (
							<div>
								<span className="course-button" /> &nbsp;Live
							</div>
						) : (
							courseMode
						)}
					</button>

					<div className="d-flex align-items-center gap-2">
						<span className="mt-2">
							<StarRating rating={course?.courseRatings?.starCount || 4.5} />
						</span>
						<span className="review_number g_reg">({course?.ratingCounts || 0})</span>
					</div>
				</div>
				<p className="card-text g_semi my-3">{course.name}</p>
				{isUserSpecificContent ? (
					<div className="align-self-start d-flex flex-column w-100 gap-3">
						<span className="g_reg fs-14">{mentorNamesRenderer(course.mentors)}</span>
						{allowDownloadCertificate && course.progressPercentage === 100 ? (
							<>
								<Button
									disabled={!areQuizzesCompleted || loading}
									buttonText={
										<>
											{loading && <Loading width="50px" height="25px" />}
											Download Certificate
										</>
									}
									buttonType={ButtonType.PRIMARY_BUTTON}
									classes="align-self-baseline download-certificate-btn g_mid d-flex justify-content-center align-items-center"
									handleClick={(e) => {
										e.preventDefault();
										e.stopPropagation();
										setLoading(true);
										handleDownloadPDF(setLoading);
									}}
									buttonBorderType={ButtonBorderType.NO_BORDER}
								/>
								{!areQuizzesCompleted ? (
									<p
										className="d-flex align-items-center mt-2 gap-2"
										style={{ color: '#EE0000', fontSize: '12px' }}>
										<Image
											src={toAbsoluteUrl('/media/course-detail-images/information-circle.svg')}
											height="14px"
											color="red"
										/>
										There are some pending lessons to be completed!
									</p>
								) : (
									<div onClick={(e) => e.stopPropagation()}>
										{(() => {
											const courseRatingData = course.myCourseRatings;
											return (
												<SubmitStarRatings
													courseName={course.name}
													courseRatingStatus={courseRatingData?.status || RatingStatusEnum.PENDING}
													currentRatings={courseRatingData?.starCount || 0}
													courseDescription={courseRatingData?.review || ''}
													onSubmit={(starCount: number, description: string) =>
														onSubmitRatings({
															courseId: course.id,
															starCount,
															review: description,
														})
													}
												/>
											);
										})()}
									</div>
								)}
							</>
						) : (
							<span className="g_mid">
								Next Class: {moment(course?.nextChapter?.startDate).format('Do MMMM YYYY')}
							</span>
						)}
						<ProgressBar percentage={course.progressPercentage} />
					</div>
				) : (
					<div className="g_reg fs-14">
						<p>Start Date:&nbsp;{moment(course.startDatetime).format('DD MMMM YYYY')}</p>
						<p>
							Duration:&nbsp;
							{Math.ceil(
								moment(moment(course.endDatetime)).diff(moment(course.startDatetime), 'days') / 7,
							)}{' '}
							Weeks
						</p>
						<p className="platform-location-text">
							{courseMode === 'Online' ? 'Platform: Zoom' : `Location: ${course.address}`}
						</p>
					</div>
				)}
			</div>
		);
	}, [course, user, timeLeft, loading]);

	const renderSecondCard = useCallback(() => {
		if (disableHoverInteraction) {
			return null;
		}
		if (course.mentors.length === 0) {
			return null;
		}

		return (
			<div
				className={`card ${
					showBackdropShadow ? 'card-custom-shadow' : ''
				} flex-fill p-3 ${cardHeight}`}>
				<div className="card-body d-flex flex-column px-0 pb-0">
					<div>
						<button className="btn rounded-pill card-button button-tag g_reg">
							{course.courseLevel?.name || 'Unknown Course'}
						</button>
					</div>
					<p className="card-text lora fw-semibold fs-18 my-3">{course.name}</p>
					<p className="card-desc g_reg">
						Mentor: <span>{mentorNamesRenderer(course.mentors)}</span>
					</p>
					<p className="final-price g_bold">₹{course.sellPrice}/-</p>
					<p className="actual-price g_reg">
						<del>₹{course.amount}/-</del>
					</p>
					<div className="d-flex justify-content-between flex-column mt-auto mb-4 gap-2">
						{/* <button
							className="btn primary-button card-button g_mid w-100"
							onClick={isCourseSubscribed ? handleContinueLearning : handleEnrollPageReroute}>
							{isCourseSubscribed ? 'Keep Learning' : 'Enroll Now'}
						</button> */}
						<Button
							buttonText={isCourseSubscribed ? 'Keep Learning' : 'Enroll Now'}
							buttonType={ButtonType.PRIMARY_BUTTON}
							classes="g_mid w-100 card-information-padding"
							handleClick={isCourseSubscribed ? handleContinueLearning : handleEnrollPageReroute}
						/>
						<Button
							buttonText="View Details"
							buttonType={ButtonType.WHITE_BUTTON}
							classes="g_mid w-100 card-information-detail-padding"
							handleClick={handleDetailPageReroute}
							buttonBorderType={ButtonBorderType.BLACK_BORDER}
						/>
					</div>
				</div>
			</div>
		);
	}, [course, isCourseSubscribed, user, disableHoverInteraction]);

	return (
		<div
			className={`${
				isSliderCard ? '' : `col-md-6 col-sx-12 col-lg-6 ${cardClasses}`
			} d-flex flex-column my-3`}
			onMouseEnter={() => (disableHoverInteraction ? () => null : setIsHovered(true))}
			onMouseLeave={() => setIsHovered(false)}>
			<div
				className="flip-card"
				onMouseEnter={() => setIsHovered(true)}
				onMouseLeave={() => setIsHovered(false)}>
				<div
					className={`flip-card-inner ${
						isHovered && !disableHoverInteraction ? 'is-flipped' : ''
					}`}>
					<div className="flip-card-front">{renderFirstCard()}</div>
					<div className="flip-card-back">{renderSecondCard()}</div>
				</div>
			</div>
		</div>
	);
};
export default CardInformation;
