import React from "react";
import { toAbsoluteUrl } from "../../../assets/helpers/AssetHelpers";

const OngoingCard = () => {
  return (
    <>
      <div className="col-12 mt-5 ">
        <div className="upcoming-courses h-100">
          <div className="card pb-3 px-2 round course-card overflow-hidden border-0 m-2 h-100 cursor-pointer">
            <div className="card-body p-0">
              <div className="row d-flex gy-4 gy-lg-0">
                <div className="col-lg-5">
                  <img
                    src={toAbsoluteUrl("../media/course/myLearning1.jpg")}
                    alt="Image"
                    className="img-fluid mt-3 px-2"
                    style={{ height: "25vh" }}
                  />
                </div>
                <div className="col-md-7 d-flex">
                  <div className="card-body">
                    <div className="d-flex">
                      <button
                        className="btn btn-outline-success course-btn rounded-pill px-3 py-1 text-dark"
                        type="button"
                      >
                        Technical Analysis
                      </button>
                      <div className="course-bar-btn rounded-pill ms-1 ms-lg-2 ms-md-2 px-1 py-1 text-dark">
                        <img
                          src={toAbsoluteUrl("../media/logo/rate.jpg")}
                          alt=""
                        />
                      </div>
                    </div>
                    <div>
                      <p className="card-text mt-2 fw-bold">
                        Pro Options Trader Course-Virtual Edition 6.0
                        <p className="fw-normal">By Dharmesh Chauhan</p>
                      </p>
                    </div>
                    <button
                      className="btn btn-primary"
                      style={{ marginTop: "18%" }}
                    >
                      Continue Learning
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OngoingCard;
