import React from "react";
import { toAbsoluteUrl } from "../../../assets/helpers/AssetHelpers";

const YourUpCard = () => {
  return (
    <>
      <div className="col-12 mt-5">
        <div className="upcoming-courses h-100">
          <div className="card pt-2 pb-1 px-2 round course-card overflow-hidden border-0 m-2 h-100 cursor-pointer">
            <div className="card-body p-0">
              <div className="row d-flex gy-4 gy-lg-0">
                <div className="col-lg-5">
                  <img
                    src={toAbsoluteUrl("../media/course/mylearning1.jpg")}
                    alt="Image"
                    className="img-fluid mt-3 px-2"
                    style={{ height: "25vh" }}
                  />
                </div>
                <div className="col-md-7 d-flex">
                  <div className="card-body">
                    <div className="d-flex">
                      <button
                        className="btn btn-outline-success course-btn rounded-pill px-3 py-1 text-dark"
                        type="button"
                      >
                        Technical Analysis
                      </button>
                      <div className="course-bar-btn rounded-pill ms-1 ms-lg-2 ms-md-2 px-1 py-1 text-dark">
                        <img
                          src={toAbsoluteUrl("../media/logo/rate.jpg")}
                          alt=""
                        />
                      </div>
                    </div>
                    <div>
                      <p className="card-text mt-2 fw-bold">
                        Pro Options Trader Course-Virtual Edition 6.0
                        <p className="fw-normal">By Dharmesh Chauhan</p>
                      </p>
                      <p className="d-flex text-muted-dark">
                        <img
                          src="../media/course/ca.png"
                          alt="ca"
                          className="img-fluid me-2 "
                        />
                        Mon 23 Aug 2023 • 2:00 PM
                      </p>
                      <div className="d-flex text-muted-dark">
                        <i className="fa-solid fa-desktop mx-1 mt-1 me-2"></i>
                        <p>Online</p>
                      </div>
                      <div
                        className="w-60 p-1 px-3 py-2"
                        style={{
                          backgroundColor: "rgba(255, 120, 60, 0.15)",
                          borderRadius: "15px",
                        }}
                        role="alert"
                      >
                        <>
                          Starts in <strong> 1d : 21h : 45m : 08s</strong>
                        </>{" "}
                        :
                        {/* <button type="button" className="btn-close  py-2 px-3 " data-bs-dismiss="alert" aria-label="Close"></button> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default YourUpCard;
