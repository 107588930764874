import React from "react";
import HeadingSection from "../PreRecordedCourses/HeadingSection";
import QuizSidebar from "./QuizSidebar";
import { useNavigate } from "react-router-dom";
import { GET_QUIZ_RESULT } from "../Courses-Page/core/requests";
import { useQuery } from "@apollo/client";

const QuizSummary = (props: any) => {
  const {
    quizId,
    courseId,
    setQuizStarted,
    setActiveTab,
    questionArray,
    setQuizSubmitted,
    activeDivId,
    prevNext,
  } = props;
  const { data } = useQuery(GET_QUIZ_RESULT, {
    variables: {
      courseId: courseId,
      quizId: quizId,
    },
  });
  const quizResultData = data?.getQuizResult;
  const navigate = useNavigate();
  const goback = () => {
    setQuizStarted(true);
    setQuizSubmitted(false);
    setActiveTab(1);
  };

  return (
    <>
      {/* <section className="section px-xl-4 -x-lg-3 px-2"> */}
      {/* <section className="section px-xl-4 -x-lg-3 px-2"> */}
      <div
        className="d-lg-block d-md-block d-none"
        style={{ marginTop: "-12%" }}
      >
        <div className="row">
          <div className="col-lg-12">
            <div className="row justify-content-lg-center align-items-lg-center p-4">
              <div className="col-12 ">
                <div className=" pb-lg-5 text-lg-center">
                  <img
                    src="../media/preRecord/celebration.png"
                    alt="celebration"
                    height="140"
                    width="140"
                    className="img-fluid"
                  />
                  <div className="course-head fs-3 fw-bold font-subtitle ">
                    You got{" "}
                    {
                      quizResultData?.questionAnswer.filter((item: any) =>
                        item.correctAnswer.every(
                          (correctAnswer: string, correctAnswerIndex: number) =>
                            item.answer.includes(correctAnswer),
                        ),
                      ).length
                    }{" "}
                    out of {quizResultData?.questionAnswer.length} correct.
                  </div>
                  <p>
                    <span className="color2">Congratulations!</span> You have
                    successfully finished the quiz "Quiz Name".
                  </p>
                  <div className="d-flex justify-content-center w-60 mx-auto">
                    <button
                      className="btn btn-primary me-3"
                      onClick={() => prevNext(activeDivId)}
                    >
                      Continue Learning
                    </button>
                    <button
                      className="btn btn-outline-primary"
                      onClick={() => goback()}
                    >
                      Try Again
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <p className="fs-3 font-title fw-bold mt-5">Quiz Summary</p>
            </div>
            {quizResultData?.questionAnswer.map((item: any, index: number) => {
              const variable = item.correctAnswer.every(
                (correctAnswer: string, correctAnswerIndex: number) =>
                  item.answer.includes(correctAnswer),
              );
              return (
                <div
                  key={index}
                  className="p-3 mt-3"
                  style={{ border: "1px solid  #F2F4F7" }}
                >
                  <p className="fs-5">
                    {index + 1}. {item.question}
                  </p>
                  <div
                    className={`d-flex ${
                      variable === true
                        ? "alert alert-success"
                        : "alert alert-danger"
                    }`}
                    role="alert"
                  >
                    {variable === true ? (
                      <span className="me-2 color6 ">
                        <i className="fa-solid fa-circle-check fs-5"></i>
                      </span>
                    ) : (
                      <i
                        className="fa-sharp fa-solid fa-circle-xmark me-3 fs-5"
                        style={{ color: "#e60f0f" }}
                      ></i>
                    )}
                    {item.answer.map((answer: string, answerIndex: number) => (
                      <div key={answerIndex}>{answer}</div>
                    ))}
                  </div>
                  {variable === false && (
                    <>
                      <p>Right Answer</p>
                      <div className="alert alert-success" role="alert">
                        {item.correctAnswer.map(
                          (
                            correctAnswer: string,
                            correctAnswerIndex: number,
                          ) => (
                            <div key={correctAnswerIndex}>{correctAnswer}</div>
                          ),
                        )}
                      </div>
                    </>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>

      {/*
-------------------------------
 Responsive Mobile View Code 
 ---------------------------
 */}

      <div
        className="d-lg-none d-md-none d-block"
        style={{ marginTop: "-45%" }}
      >
        <div className="row">
          <div className="col-lg-12">
            <div className="row justify-content-lg-center align-items-lg-center p-4">
              <div className="col-12 ">
                <div className="pb-lg-5 text-center">
                  <img
                    src="../media/preRecord/celebration.png"
                    alt="celebration"
                    height="140"
                    width="140"
                    className="img-fluid"
                  />
                  <div className="course-head fs-3 fw-bold font-subtitle text-center">
                    You got{" "}
                    {
                      quizResultData?.questionAnswer.filter((item: any) =>
                        item.correctAnswer.every(
                          (correctAnswer: string, correctAnswerIndex: number) =>
                            item.answer.includes(correctAnswer),
                        ),
                      ).length
                    }{" "}
                    out of {quizResultData?.questionAnswer.length} correct.
                  </div>
                  <p className="text-center">
                    <span className="color2">Congratulations!</span> You have
                    successfully finished the quiz "Quiz Name".
                  </p>
                  <div className="d-flex justify-content-center w-100 mx-auto">
                    <button className="btn btn-primary me-3">
                      Continue Learning
                    </button>
                    <button
                      className="btn btn-outline-primary"
                      onClick={() => goback()}
                    >
                      Try Again
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <p className="fs-3 font-title fw-bold mt-5 text-center">
                Quiz Summary
              </p>
            </div>
            {quizResultData?.questionAnswer.map((item: any, index: number) => {
              const variable = item.correctAnswer.every(
                (correctAnswer: string, correctAnswerIndex: number) =>
                  item.answer.includes(correctAnswer),
              );
              return (
                <div
                  key={index}
                  className="p-3 mt-3"
                  style={{ border: "1px solid  #F2F4F7" }}
                >
                  <p className="fs-5">
                    {index + 1}. {item.question}
                  </p>
                  <div
                    className={`d-flex ${
                      variable === true
                        ? "alert alert-success"
                        : "alert alert-danger"
                    }`}
                    role="alert"
                  >
                    {variable === true ? (
                      <span className="me-2 color6 ">
                        <i className="fa-solid fa-circle-check fs-5"></i>
                      </span>
                    ) : (
                      <i
                        className="fa-sharp fa-solid fa-circle-xmark me-3 fs-5"
                        style={{ color: "#e60f0f" }}
                      ></i>
                    )}
                    {item.answer.map((answer: string, answerIndex: number) => (
                      <div key={answerIndex}>{answer}</div>
                    ))}
                  </div>
                  {variable === false && (
                    <>
                      <p>Right Answer</p>
                      <div className="alert alert-success" role="alert">
                        {item.correctAnswer.map(
                          (
                            correctAnswer: string,
                            correctAnswerIndex: number,
                          ) => (
                            <div key={correctAnswerIndex}>{correctAnswer}</div>
                          ),
                        )}
                      </div>
                    </>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
      {/* </section> */}
      {/* </section> */}
    </>
  );
};

export default QuizSummary;
