import React, { useCallback, useEffect, useRef } from 'react';
import { ListGroup, Image } from 'react-bootstrap';
import { UserType } from 'types/globalTypes';
import {
	BASE_PAGE_URL,
	MY_COURSE_URL,
	MY_PROFILE_URL,
	PAYMENT_HISTORY_URL,
} from 'pages/routing/routePages';
import './ProfilePopup.css';
import { useNavigate } from 'react-router-dom';
import { useUserContext } from 'context/UserContext';
import toast from 'react-hot-toast';
import { useCourseContext } from 'context/CourseContext';
import ProfileListItems from 'components/_v2/Header/ProfileListItems';

const ProfilePopup = ({ handleClick }: { handleClick: () => void }) => {
	const popupRef = useRef(null);

	const handleClickOutside = (event) => {
		if (popupRef.current && !popupRef.current.contains(event.target)) {
			handleClick();
		}
	};

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	return (
		<div className="menu-container menuPopup" ref={popupRef}>
			<ProfileListItems handleClick={handleClick} />
		</div>
	);
};

export default ProfilePopup;
