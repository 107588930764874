import { useEffect, useState } from "react";
import { Card, Col, Form, Row } from "react-bootstrap";
import ImageModalOpener from "./Quiz/ImageModalOpener";
import { QuizQuestionObjectType } from "./Quiz/type";
import { useQuizContext } from "context/QuizContext";

const QuizRenderer = ({
  quizContent,
}: {
  quizContent: QuizQuestionObjectType;
}) => {
  const { quiz, setAnswer } = useQuizContext();
  const [selectedAnswers, setSelectedAnswers] = useState<string[]>([]);

  useEffect(() => {
    const existingAnswer = quiz?.answersProvided?.find(
      (question) => question?.questionId === quizContent?.id,
    );
    setSelectedAnswers(existingAnswer?.answer || []);
  }, [quizContent]);

  const handleOptionChange = ({
    hasMultipleAnswers,
    answer,
    questionId,
  }: {
    answer: string;
    hasMultipleAnswers: boolean;
    questionId: string;
  }) => {
    if (hasMultipleAnswers) {
      if (selectedAnswers.includes(answer)) {
        setSelectedAnswers(
          selectedAnswers.filter((option) => option !== answer),
        );
      } else {
        setSelectedAnswers((prevAnswers) => {
          const newAnswers = [...prevAnswers, answer];
          setAnswer(questionId, newAnswers);
          return newAnswers;
        });
      }
    } else {
      setSelectedAnswers([answer]);
      setAnswer(questionId, [answer]);
    }
  };

  return (
    <Card className="mb-3 border-0">
      <Card.Body>
        <Card.Title className="quiz-question g_reg">
          {quizContent?.question}
        </Card.Title>
        <Form>
          {quizContent?.options.map((option, index) => (
            <Form.Group
              key={index + option.optionText}
              as={Row}
              className="d-flex align-items-center my-3 optionBorder py-2 mx-0"
            >
              <Col xs="auto">
                <Form.Check
                  className={
                    quizContent?.hasMultipleAnswers
                      ? "custom-checkbox"
                      : "custom-radio"
                  }
                  type={quizContent?.hasMultipleAnswers ? "checkbox" : "radio"}
                  name={`question-${quizContent?.id}`}
                  checked={selectedAnswers.includes(option.optionText)}
                  onChange={() =>
                    handleOptionChange({
                      answer: option.optionText,
                      hasMultipleAnswers: quizContent?.hasMultipleAnswers,
                      questionId: quizContent.id,
                    })
                  }
                />
              </Col>
              <Col className="d-flex justify-content-between quiz-option-layout" onClick={() =>
                handleOptionChange({
                  answer: option.optionText,
                  hasMultipleAnswers: quizContent?.hasMultipleAnswers,
                  questionId: quizContent.id,
                })
              }>
                {option.optionText && (
                  <span className="quiz-option g_reg align-content-center">{option.optionText}</span>
                )}
                {option.optionImage && (
                  <ImageModalOpener imageSrc={option.optionImage} />
                )}
              </Col>
            </Form.Group>
          ))}
        </Form>
      </Card.Body>
    </Card>
  );
};

export default QuizRenderer;
