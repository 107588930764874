import React from "react";
import { Tab, Tabs } from "react-tabs";
import { TabList, TabPanel } from "react-tabs";
import PersonalDetails from "./PersonalDetails";
import Myinterests from "./Myinterests";
import Loader from "../../common/Spinner/Spinner";

export const Profile = (props: any) => {
  const { profile, profilerefetch, loading } = props;
  return (
    <>
      <div className="font-title title fs-2 mx-3 head1">My Profile</div>
      <Tabs className="mt-4">
        <TabList>
          <Tab>Personal Details</Tab>
          {/* <Tab>My Interests</Tab> */}
        </TabList>
        <TabPanel>
          {loading ? (
            <>
              <Loader />
            </>
          ) : (
            <>
              <PersonalDetails
                profile={profile}
                profilerefetch={profilerefetch}
              />
            </>
          )}
        </TabPanel>
        {/* <TabPanel>
                    <Myinterests profile = {profile} />
                </TabPanel>
                 */}
      </Tabs>
    </>
  );
};
