import React from 'react';
import './faqs.css';
import { Accordion, Image } from 'react-bootstrap';
import { useQuery, gql } from '@apollo/client';
import { FAQS_SECTION } from '../../../utils/consts';
import Loading from '../CommonComponents/Loading';
import { toAbsoluteUrl } from 'assets/helpers/AssetHelpers';

// Define the GraphQL query within the component file
const GET_PUBLIC_FAQS_LIST = gql`
	query GetPublicFaqsList {
		getPublicFaqsList(where: {}, paginate: {}) {
			records {
				id
				question
				answer
				sortOrder
				type
				status
				courseId
				createdAt
				updatedAt
				createdById
				updatedById
			}
			pagination {
				totalRecords
				currentPage
			}
		}
	}
`;

interface FAQ {
	id: string;
	question: string;
	answer: string;
	sortOrder: number;
	type: string;
	status: string;
	courseId: string;
	createdAt: string;
	updatedAt: string;
	createdById: string;
	updatedById: string;
}

interface FAQData {
	getPublicFaqsList: {
		records: FAQ[];
		pagination: {
			totalRecords: number;
			currentPage: number;
		};
	};
}

interface FaqProps {
	lgColClass?: string;
	mdColClass?: string;
	smColClass?: string;
	title?: string;
	titleClass?: string;
	faqWrapper?: string;
	ptClass?: string;
	wrapInRow?: boolean;
	subtitle?: string;
	subtitleClass?: string;
}

const NoFaQData = () => {
	return (
		<div className="d-flex w-100 justify-content-center flex-column align-items-center text-center">
			<Image src={toAbsoluteUrl('/media/nodata/faqs-nodata.svg')} className="img-fluid" />
			<span className="g_mid fs-24">There are no FAQs yet</span>
			<span className="g_reg fs-18">We are monitoring Frequently Asked Questions</span>
		</div>
	);
};

const Faq: React.FC<FaqProps> = ({
	faqWrapper = 'faq-wrapper',
	lgColClass = 'col-lg-10',
	ptClass = 'pt-0',
	mdColClass = 'col-md-10',
	smColClass = 'col-11',
	title = 'Frequently Asked Questions',
	titleClass = 'faq-title ibm_semi text-center',
	subtitle = '',
	subtitleClass = 'faq-subtitle g_reg text-center',
	wrapInRow = false,
}) => {
	const { loading, error, data } = useQuery<FAQData>(GET_PUBLIC_FAQS_LIST);
	const faqData = data?.getPublicFaqsList.records || [];

	return (
		<section className={`${faqWrapper} ${wrapInRow ? 'row' : ''}`} id={FAQS_SECTION}>
			{loading ? (
				<Loading width={200} />
			) : (
				<div className="container">
					<div className="row">
						{/* Dynamically pass column classes */}
						<div className={`${lgColClass} ${mdColClass} ${smColClass} layout-set px-0`}>
							<h2 className={titleClass}>{title}</h2>
							<p className={subtitleClass}>{subtitle}</p>
							<div className="mt-4">
								{error || faqData.length === 0 ? (
									<NoFaQData />
								) : (
									<Accordion className="faq">
										{faqData.map((faq) => (
											<Accordion.Item
												eventKey={faq.id}
												key={faq.id}
												className={`border-style-ac my-3 ${faq.id === '1' ? 'border-item' : ''}`}>
												<Accordion.Header className="p_reg faq-btn">
													{faq.question}
												</Accordion.Header>
												<Accordion.Body className={`faq-answrs p_reg ${ptClass}  pb-4`}>
													{faq.answer}
												</Accordion.Body>
											</Accordion.Item>
										))}
									</Accordion>
								)}
							</div>
						</div>
					</div>
				</div>
			)}
		</section>
	);
};

export default Faq;
