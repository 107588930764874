// AuthModalContext.tsx
import React, { createContext, useState, useContext, ReactNode } from 'react';

// Define the type for the context value
interface ModalContextType {
	open: boolean;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
	toggleLoginOpen: () => void;
	openRegister: boolean;
	setOpenRegister: React.Dispatch<React.SetStateAction<boolean>>;
	toggleRegisterOpen: () => void;
	handleRegisterClose: () => void;
}

// Create a context with an initial value of undefined
const AuthModalContext = createContext<ModalContextType | undefined>(undefined);

// Create a provider component
export const AuthModalProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
	const [open, setOpen] = useState<boolean>(false);
	const [openRegister, setOpenRegister] = useState<boolean>(false);

	const toggleLoginOpen = () => setOpen((prev) => !prev);
	const toggleRegisterOpen = () => setOpenRegister((prev) => !prev);

	const handleRegisterClose = () => setOpenRegister(false);

	return (
		<AuthModalContext.Provider
			value={{
				open,
				setOpen,
				toggleLoginOpen,
				openRegister,
				setOpenRegister,
				toggleRegisterOpen,
				handleRegisterClose,
			}}>
			{children}
		</AuthModalContext.Provider>
	);
};

// Custom hook to use the modal context
export const useAuthModalContext = (): ModalContextType => {
	const context = useContext(AuthModalContext);
	if (!context) {
		throw new Error('useModalContext must be used within a ModalProvider');
	}
	return context;
};
