import { toAbsoluteUrl } from "../../../assets/helpers/AssetHelpers";
import { GETMENTOR } from "../core/requests";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import Banner from "../banner/Banner";
import "../../../assets/sass/css/mentor-list.css";
import { useQuery } from "@apollo/client";
import Banner1 from "../banner/Banner1";
import Loader from "../../common/Spinner/Spinner";

export const MentorsList = () => {
  const navigate = useNavigate();
  const { data: mentor, loading } = useQuery(GETMENTOR, {
    variables: {
      paginate: { per_page: 100, page: 0 },
    },
  });
  const mentorRecord: any = mentor?.getPublicInstructors?.records ?? [];
  const handleOpen = (id: string) => {
    console.log("id", id);
    navigate(`/mentor-view/${id}`);
  };
  return (
    <>
      {/* <Banner /> */}
      <Banner1 />
      {loading ? (
        <div>
          <Loader />
        </div>
      ) : (
        <>
          <section className="section ">
            <div className="container px-lg-3">
              <div className="heading mb-2 mx-1">
                <div className="row">
                  <div className="col-xl-9 col-lg-9 col-md-6 col-12">
                    <h2 className="font-title title mb-3 mx-5">
                      Meet Our Mentors
                    </h2>
                  </div>
                </div>
              </div>

              <div className="row slickarrows m-3">
                {mentorRecord.map((val: any) => (
                  <div className="col-xxl-3 col-xl-3 col-lg-3 col-12 col-md-3 h-100 pb-3">
                    <div className="card mentor-card  round mx-xl-2 overflow-hidden border-muted cursor-pointer h-100 m-2">
                      <div className="card-body bg-white p-3">
                        <div className="mentor-image position-relative mentor">
                          {Array.from(val.userProfile).map(
                            (value: any, index: any) => {
                              console.log("mentorRecord", value.userId);
                              return (
                                <>
                                  {value?.imageUrl?.length > 0 ? (
                                    <>
                                      <img
                                        alt=""
                                        key={index}
                                        src={value.imageUrl}
                                        className="card-img-top image img-fluid rounded bg-light"
                                        onClick={() => handleOpen(value.userId)}
                                      />
                                    </>
                                  ) : (
                                    <img
                                      alt=""
                                      src={toAbsoluteUrl("/media/mentor/1.png")}
                                      className=" card-img-top  image img-fluid rounded bg-light"
                                      onClick={() => handleOpen(value.userId)}
                                    />
                                  )}
                                </>
                              );
                            },
                          )}
                          <div className="mt-2">
                            <div
                              className="name fs-4 mb-0"
                              onClick={() => handleOpen(val.id)}
                            >
                              {val.firstName} {val.lastName}
                            </div>
                            <div className="fw-normal text-muted">
                              {Array.from(val.userProfile).map((value: any) => {
                                return <>{value.qualification}</>;
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}

                <div className="d-flex text-start mx-3 mt-4">
                  <div className="d-lg-none d-md-none d-block">
                    <button id="btn" className="btn btn-outline-primary mb-0">
                      Explore all
                      <i className="fa fa-arrow-right ms-1"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default MentorsList;
