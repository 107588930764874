import React, { useState } from "react";
import "./blogcard.css";
import { toAbsoluteUrl } from "../../../assets/helpers/AssetHelpers";

interface BlogCardProps {
  title: string;
  summaryHtml: string;
  description: string;
  imageUrl: string;
  handle: string;
  tags: string[];
  blogUrl: string;
}

const BlogCard: React.FC<BlogCardProps> = ({
  title,
  summaryHtml,
  description,
  imageUrl,
  handle,
  tags,
  blogUrl,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const htmlToText = (html: string) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  };

  const summaryText = htmlToText(summaryHtml);
  const descriptionText = htmlToText(description);

  // Truncate description to 3 lines
  const truncateDescription = (text: string, lines: number) => {
    const lineHeight = 40; // Approximate line height in em units
    const maxHeight = lineHeight * lines;
    return text.length > maxHeight
      ? text.substring(0, maxHeight) + "..."
      : text;
  };

  const truncatedDescription = truncateDescription(descriptionText, 3);

  return (
    <div className="col-lg-4 d-flex flex-column">
      <div className="card p-4 card-custom-shadow flex-fill">
        <img
          src={toAbsoluteUrl(imageUrl)}
          className="card-img blog-img"
          alt="Blog_Image"
        />
        <div className="card-body px-0 pb-0 d-flex flex-column">
          <div>
            <button className="btn btn-outline-secondary button-tag1 g_reg">
              {tags}
            </button>
          </div>

          <p className="blog-card-title lora pt-4">{title}</p>
          <p className="blog-card-desc g_reg pt-2">
            {isExpanded ? descriptionText : truncatedDescription}
            {descriptionText.length > truncatedDescription.length && (
              <span
                className="text-primary ms-2"
                onClick={() => setIsExpanded(!isExpanded)}
                style={{ cursor: "pointer" }}
              >
                {isExpanded ? "Show Less" : "Show More"}
              </span>
            )}
          </p>
        </div>
        <div className="blog-border-style pt-3"></div>
        <div className="pt-4">
          <a
            href={blogUrl}
            target="_blank"
            className="blog-link text-decoration-none me-2 g_mid"
          >
            Read More
          </a>
          <img
            onClick={() => window.open(blogUrl, "_blank")}
            src={toAbsoluteUrl("/media/blog-images/blog-icon.png")}
            alt="Button_icon"
            style={{ cursor: "pointer" }}
          />
        </div>
      </div>
    </div>
  );
};

export default BlogCard;
