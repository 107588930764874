import { LessonType } from 'components/_v2/Course/type';
import LessonStatus from './LessonStatus';
import LessonContent from './LessonContent';
import CourseCompleted from '../CourseCompleted';
import LoadingContent from 'components/_v2/ViewCourseData/LessonTypesComponents/Quiz/LoadingContent';

const DocumentType = ({
	lessonData,
	courseId,
	showCertificate,
	loadingContent,
}: {
	lessonData: LessonType;
	courseId: string;
	showCertificate: boolean;
	loadingContent: boolean;
}) => {
	if (loadingContent) {
		return <LoadingContent />;
	}

	if (showCertificate) {
		return <CourseCompleted courseId={courseId} lessonData={lessonData} />;
	}

	return (
		<>
			<span className="d-none d-sm-block lora lessonHeading px-4 text-center">
				{lessonData?.name}
			</span>
			<LessonStatus lessonData={lessonData} />
			<LessonContent lessonData={lessonData} courseId={courseId} />
		</>
	);
};

export default DocumentType;
