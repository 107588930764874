import React, { useMemo, useState } from 'react';
import PersonalDetail from './PersonalDetail';
import ChangePassword from './ChangePassword';
import './MyProfile.css';
import { useUserContext } from 'context/UserContext';
import { UserSigninType } from 'types/globalTypes';
import { Tooltip } from 'react-bootstrap';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

const MYProfile = () => {
	const [activeTab, setActiveTab] = useState('personal');
	const { user } = useUserContext();
	const renderTooltip = (props) => (
		<Tooltip id="button-tooltip" {...props}>
			Your sign-up method is Google. Password change is not available.
		</Tooltip>
	);
	const userSignInTypeEmail = useMemo(() => user?.signinType === UserSigninType.EMAIL, [user]);

	const handleTabChange = (tab: string) => {
		setActiveTab(tab);
	};
	return (
		<section className="profile-main-layout" style={{ backgroundColor: 'white' }}>
			<div className="container-fluid px-md-5 py-4">
				<ul className="d-flex px-0" style={{ marginBottom: '-5px' }} id="myTab" role="tablist">
					<li className="nav-item nav_dash_item" role="presentation">
						<button
							className={`profile-tabs me-3 ${activeTab === 'personal' ? 'active' : ''}`}
							type="button"
							onClick={() => handleTabChange('personal')}>
							Personal Details
						</button>
					</li>
					<li className="nav-item nav_dash_item" role="presentation">
						{userSignInTypeEmail ? (
							<button
								className={`profile-tabs me-3 ${activeTab === 'password' ? 'active' : ''}`}
								type="button"
								onClick={() => handleTabChange('password')}>
								Change Password
							</button>
						) : (
							<OverlayTrigger
								placement="auto"
								delay={{ show: 250, hide: 250 }}
								overlay={renderTooltip}>
								<span className="d-inline-block">
									<button
										className={`profile-tabs g_mid ${activeTab === 'password' ? 'active' : ''}`}
										type="button"
										disabled
										style={{ pointerEvents: 'none' }}>
										Change Password
									</button>
								</span>
							</OverlayTrigger>
						)}
					</li>
				</ul>
				<div id="myTabContent">
					<div
						className={`tab-pane fade ${activeTab === 'personal' ? 'show active' : ''}`}
						id="home-tab-pane"
						role="tabpanel">
						{activeTab === 'personal' && <PersonalDetail />}
					</div>
					<div
						className={`tab-pane fade ${activeTab === 'password' ? 'show active' : ''}`}
						id="profile-tab-pane"
						role="tabpanel">
						{activeTab === 'password' && <ChangePassword />}
					</div>
				</div>
			</div>
		</section>
	);
};

export default MYProfile;
