import React from "react";
import HeadingSection from "../PreRecordedCourses/HeadingSection";
import QuizSidebar from "./QuizSidebar";
import { useNavigate } from "react-router-dom";

const QuizStart = () => {
  const navigate = useNavigate();
  const quizsummary = () => {
    navigate("/quizsummary");
  };
  return (
    <>
      <section className="section px-xl-4 -x-lg-3 px-2">
        <div className="">
          <div className="row">
            <HeadingSection course={null} />
          </div>
        </div>
        <section className="section px-xl-4 -x-lg-3 px-2">
          <div className="">
            <div className="row">
              <QuizSidebar />
              <div className="col-lg-8 mt-5">
                <div className="mt-4 fs-5">
                  <p className="font-subtitle ">
                    1. Lorem ipsum dolor sit amet consectetur. Magnis volutpat
                    gravida in orci in Et velit tortor donec sodales sed at et
                    sed. Vitae interdum amet morbi dictumst quis fusce?
                  </p>
                </div>
                <div className="border p-2 mx-1 my-3">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="exampleRadios"
                      id="exampleRadios4"
                      value="option4"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="exampleRadios4"
                    >
                      Lorem ipsum dolor sit amet consectetur. Magnis volutpat
                    </label>
                  </div>
                </div>
                <div className="border p-2 mx-1 my-3">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="exampleRadios"
                      id="exampleRadios4"
                      value="option4"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="exampleRadios4"
                    >
                      Lorem ipsum dolor sit amet consectetur. Magnis volutpat
                    </label>
                  </div>
                </div>
                <div className="border p-2 mx-1 my-3">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="exampleRadios"
                      id="exampleRadios4"
                      value="option4"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="exampleRadios4"
                    >
                      Lorem ipsum dolor sit amet consectetur. Magnis volutpat
                    </label>
                  </div>
                </div>
                <div className="border p-2 mx-1 my-3">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="exampleRadios"
                      id="exampleRadios4"
                      value="option4"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="exampleRadios4"
                    >
                      Lorem ipsum dolor sit amet consectetur. Magnis volutpat
                    </label>
                  </div>
                </div>
                <div
                  className="d-flex justify-content-between"
                  style={{ marginTop: "16%" }}
                >
                  <p className="fs-5 font-subtitletitle ">
                    <span className="fw-bold">1</span> of 6 Questions
                  </p>
                  <div className="mt-3">
                    <button
                      className="btn btn-primary"
                      onClick={() => quizsummary()}
                    >
                      Next
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
    </>
  );
};

export default QuizStart;
