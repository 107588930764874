import React from 'react';
import { toAbsoluteUrl } from 'assets/helpers/AssetHelpers';
import { Course } from '../Course/type';
import CardInformation from 'components/_v2/CommonComponents/CardInformation';
import { CardHeight } from 'components/_v2/CommonComponents/types';
import { useCourseContext } from 'context/CourseContext';
import { useUserContext } from 'context/UserContext';
import './OfflineCourse.css';
const OfflineCourse = ({ courses }: { courses: Course[] }) => {
	const { userCourses } = useCourseContext();
	const { user } = useUserContext();
	if (courses?.length === 0) {
		return (
			<section
				className="d-flex align-items-center justify-content-center"
				style={{ height: '70vh' }}>
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="d-flex justify-content-center align-items-center">
								<img
									className="smallImageWidth"
									src={toAbsoluteUrl('/media/nodata/course-nodata.png')}
									alt="Nodata_Found"
								/>
							</div>
							<div className="text-center">
								<h2 className="nodata-title lora">No Courses Here</h2>
								<p className="nodata-label">We will notify you once you have purchased a course.</p>
								<button className="btn btn_goto_course g_mid w-auto">Go to My Courses</button>
							</div>
						</div>
					</div>
				</div>
			</section>
		);
	}

	return (
		<div className="row d-flex course-card-section-spacing gy-lg-4 mt-0">
			{courses?.map((course) => (
				<CardInformation
					course={course}
					disableHoverInteraction={false}
					isUserSpecificContent={false}
					userCourses={userCourses}
					user={user}
					cardHeight={CardHeight.LARGE}
					hasCardOffset
				/>
				// <OfflineCourseCard key={course?.id} course={course} />
			))}
		</div>
	);
};

export default React.memo(OfflineCourse);
