import React, { useMemo } from 'react';
import './discover.css';
import { toAbsoluteUrl } from '../../../assets/helpers/AssetHelpers';

const Discover = () => {
	const HeadingLabel = useMemo(
		() => (image: string, alt: string, heading: string, description: string) => {
			return (
				<>
					<div className="col-lg-4 col-md-12 col-12">
						<img src={image} alt={alt} />
						<p className="discover-detail-heading lora">{heading}</p>
						<p className="discover-detail-subtitle g_reg">{description}</p>
					</div>
				</>
			);
		},
		[],
	);
	return (
		<>
			<section className="discover-wrapper pt-4">
				<div className="container">
					<div className="row">
						<div className="col-lg-10 col-11 col-md-10 layout-set">
							<div className="text-center">
								<h2 className="discover-title ibm_semi">
									Watch Out Latest Market Insights & Trends
								</h2>
								<p className="discover-subtitle p_reg mt-3">
									Learn from the Experienced Pro Traders
								</p>
							</div>
						</div>

						<div className="video-container">
							<div className="video-overlay1" />
							<video
								width="1210"
								height="763"
								controls
								controlsList="nodownload"
								poster="/media/herosection/discover-poster.png"
								className="radius-style">
								<source
									src={toAbsoluteUrl('/media/herosection/discover-section.mp4')}
									type="video/mp4"
								/>
								Your browser does not support the video tag.
							</video>
						</div>

						{/* <div className="col-lg-10 col-md-10 col-12 layout-set discover-detail-layout">
							<div className="d-lg-flex text-center">
								{HeadingLabel(
									toAbsoluteUrl('/media/discover-images/point1.png'),
									'Point1',
									'Engaging Learning Environment',
									'Interactive courses foster active participation.',
								)}
								{HeadingLabel(
									toAbsoluteUrl('/media/discover-images/point2.png'),
									'Point2',
									'Personalized Attention',
									'Tailored guidance ensuring individual progress.',
								)}
								{HeadingLabel(
									toAbsoluteUrl('/media/discover-images/point3.png'),
									'Point3',
									'Transparent Pricing',
									'Clear costs for informed decisions.',
								)}
							</div>
						</div> */}
					</div>
				</div>
			</section>
		</>
	);
};

export default Discover;
