// src/layouts/RootLayout.jsx
import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { MasterLayout } from './MasterLayout';
import { DashboardLayout } from './DashboardLayout';
import { DASHBOARD_URL, INTERNAL_SERVER_ERROR, MY_COURSE_URL } from '../routing/routePages';
import InternalServerError from 'components/_v2/CommonComponents/InternalServerError';

const RootLayout = () => {
	const location = useLocation();
	const isDashboardPath = location.pathname.startsWith('/dashboard');

	if (isDashboardPath && location.pathname === DASHBOARD_URL) {
		return <Navigate to={MY_COURSE_URL} replace />;
	}

	if (location.pathname === INTERNAL_SERVER_ERROR) {
		return <InternalServerError />;
	}

	return isDashboardPath ? <DashboardLayout /> : <MasterLayout />;
};

export { RootLayout };
