import React from "react";
import "./course.css";
import "./global.css";
import { useNavigate } from "react-router";

const HeadingSection = (props: { course: any }) => {
  const courseDet = props.course;
  const navigate = useNavigate();
  const countCompletedSections = () => {
    return courseDet?.sections?.filter(
      (section: any) => section.completed === true,
    ).length;
  };
  const totalSections = courseDet?.sections?.length || 0;
  const completedSections = countCompletedSections();
  const completionPercentage = (completedSections / totalSections) * 100;
  return (
    <>
      <section className="bg-color3 course-sections d-lg-block d-md-block d-none">
        <div className="container px-lg-0 py-lg-0">
          <div className="row align-items-center" style={{ marginLeft: "1%" }}>
            <div className="col-12 col-lg-7 border-end border-light border-3">
              <div className="py-lg-3">
                <div
                  className="fs-4 font-title color5 fw-bold"
                  style={{ marginLeft: "-30px" }}
                >
                  {courseDet?.name}
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-3 border-end border-light border-3">
              <div className="px-lg-1 py-lg-3 d-flex align-items-center">
                <div className="w-30 text-center">
                  {courseDet?.mentors[0]?.mentor?.userProfile[0]?.imageUrl ==
                  null ? (
                    <img
                      src="../media/mentor/profile_image.jpg"
                      alt="mentor.png"
                      height="60"
                      width="60"
                      className="img-fluid"
                    />
                  ) : (
                    <img
                      src={
                        courseDet.mentors[0]?.mentor?.userProfile[0]?.imageUrl
                      }
                      alt="mentor.png"
                      height="60"
                      width="60"
                      className="img-fluid mentor-img"
                    />
                  )}
                </div>
                <div className="w-70">
                  <div className="color2 text-mute font-subtitle fs-6">
                    Mentor
                  </div>
                  <div
                    className="color1 text-mute font-subtitle  fs-5 fw-medium cursor-pointer"
                    onClick={() => {
                      navigate(
                        `/mentor-view/${courseDet?.mentors[0]?.mentor.id}`,
                      );
                    }}
                  >
                    {courseDet?.mentors[0]?.mentor?.firstName +
                      " " +
                      courseDet?.mentors[0]?.mentor.lastName}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-2">
              <div className="px-lg-1 py-lg-3 d-flex align-items-center">
                {courseDet?.completed === true ? (
                  <i className="fa-solid fa-check-circle text-success fs-1 me-2"></i>
                ) : (
                  <div className="w-30">
                    <div
                      className={`radialProgressBar progress-primary ${
                        completionPercentage === 0 &&
                        "progress-secondary progress-0"
                      } ${
                        completionPercentage === 10 &&
                        "progress-primary progress-10"
                      } ${
                        completionPercentage === 20 &&
                        "progress-primary progress-20"
                      } ${
                        completionPercentage === 30 &&
                        "progress-primary progress-30"
                      } ${
                        completionPercentage === 40 &&
                        "progress-primary progress-40"
                      } ${
                        completionPercentage === 50 &&
                        "progress-primary progress-50"
                      } ${
                        completionPercentage === 60 &&
                        "progress-primary progress-50"
                      } ${
                        completionPercentage === 60 &&
                        "progress-primary progress-60"
                      } ${
                        completionPercentage === 70 &&
                        "progress-primary progress-70"
                      } ${
                        completionPercentage === 80 &&
                        "progress-primary progress-80"
                      } ${
                        completionPercentage === 90 &&
                        "progress-primary progress-90"
                      } ${
                        completionPercentage === 100 &&
                        "progress-primary progress-100"
                      } ${
                        completionPercentage === 33.33333333333333 &&
                        "progress-30"
                      } ${
                        completionPercentage === 66.66666666666666 &&
                        "progress-60"
                      }`}
                    >
                      <div className="overlay d-flex justify-content-center align-items-center color1 fs-5">
                        <i className="fa-solid fa-trophy"></i>
                      </div>
                    </div>
                  </div>
                )}
                <div className="w-70">
                  <div className="color2 font-switzer fs-6">
                    Course Progress
                  </div>
                  <div
                    className="color5 font-switze fw-medium"
                    style={{ fontSize: "19px" }}
                  >
                    {countCompletedSections()} of {courseDet?.sections?.length}{" "}
                    completed
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/*
-------------------------------
 Responsive Mobile View Code 
 ---------------------------
 */}

      <section className="bg-color3 course-sections d-lg-none d-md-none d-block">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 border-end border-light border-3 text-center">
              <div className="py-4">
                <div className="fs-5 font-title color5 fw-bold">
                  {courseDet?.name}
                </div>
              </div>
            </div>
            <div className="col-12 border-end border-light border-3 ">
              <div className="d-flex align-items-center">
                <div className="me-4">
                  {courseDet?.mentors[0]?.mentor?.userProfile[0]?.imageUrl ==
                  null ? (
                    <img
                      src="../media/mentor/profile_image.jpg"
                      alt="mentor.png"
                      height="60"
                      width="60"
                      className="img-fluid"
                    />
                  ) : (
                    <img
                      src={
                        courseDet.mentors[0]?.mentor?.userProfile[0]?.imageUrl
                      }
                      alt="mentor.png"
                      height="60"
                      width="60"
                      className="img-fluid mentor-img"
                    />
                  )}
                </div>
                <div className="w-70">
                  <div className="color2 text-mute font-subtitle fs-6">
                    Mentor
                  </div>
                  <div
                    className="color1 text-mute font-subtitle  fs-5 fw-medium cursor-pointer"
                    onClick={() => {
                      navigate(
                        `/mentor-view/${courseDet?.mentors[0]?.mentor.id}`,
                      );
                    }}
                  >
                    {courseDet?.mentors[0]?.mentor?.firstName +
                      " " +
                      courseDet?.mentors[0]?.mentor.lastName}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 py-3 mx-1">
              <div className="d-flex align-items-center">
                {courseDet?.completed === true ? (
                  <i className="fa-solid fa-check-circle text-success fs-1 ms-3 me-3"></i>
                ) : (
                  <div className="me-4">
                    <div
                      className={`radialProgressBar progress-primary ${
                        completionPercentage === 0 &&
                        "progress-secondary progress-0"
                      } ${
                        completionPercentage === 10 &&
                        "progress-primary progress-10"
                      } ${
                        completionPercentage === 20 &&
                        "progress-primary progress-20"
                      } ${
                        completionPercentage === 30 &&
                        "progress-primary progress-30"
                      } ${
                        completionPercentage === 40 &&
                        "progress-primary progress-40"
                      } ${
                        completionPercentage === 50 &&
                        "progress-primary progress-50"
                      } ${
                        completionPercentage === 60 &&
                        "progress-primary progress-50"
                      } ${
                        completionPercentage === 60 &&
                        "progress-primary progress-60"
                      } ${
                        completionPercentage === 70 &&
                        "progress-primary progress-70"
                      } ${
                        completionPercentage === 80 &&
                        "progress-primary progress-80"
                      } ${
                        completionPercentage === 90 &&
                        "progress-primary progress-90"
                      } ${
                        completionPercentage === 100 &&
                        "progress-primary progress-100"
                      } ${
                        completionPercentage === 33.33333333333333 &&
                        "progress-30"
                      } ${
                        completionPercentage === 66.66666666666666 &&
                        "progress-60"
                      }`}
                    >
                      <div className="overlay d-flex justify-content-center align-items-center color1 fs-5">
                        <i className="fa-solid fa-trophy"></i>
                      </div>
                    </div>
                  </div>
                )}
                <div className="w-70">
                  <div className="color2 font-switzer fs-6">
                    Course Progress
                  </div>
                  <div
                    className="color5 font-switze fw-medium"
                    style={{ fontSize: "19px" }}
                  >
                    {countCompletedSections()} of {courseDet?.sections?.length}{" "}
                    completed
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HeadingSection;
