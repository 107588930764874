import React, { useEffect, useRef, createContext, useState } from "react";
import { GiNetworkBars } from "react-icons/gi";
import { Footer } from "../../pages/layout/footer/Footer";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import { toAbsoluteUrl } from "../../assets/helpers/AssetHelpers";

function ThankYou() {
  const navigate = useNavigate();
  const { token } = useParams();
  const { id } = useParams();

  const thankyou = () => {
    navigate(`/my-profile`, { state: { page: "mylearning" } });
  };

  return (
    <>
      <section className="section px-xl-4 px-2">
        <div className="">
          <div className="row">
            <div className="col-12">
              <div className="row justify-content-lg-center align-items-lg-center p-4">
                <div className="col-12 ">
                  <div className="pb-lg-5 text-lg-center">
                    <div className="course-head fs-2 font-title text-capitalize">
                      Thank you for Enrolling.
                    </div>
                    <img
                      src={toAbsoluteUrl(
                        "/media/icons/duotune/general/success.svg",
                      )}
                      alt="celebration"
                      height="140"
                      width="140"
                      className="my-3 img-fluid"
                    />
                    <div className="d-flex justify-content-center w-60 mx-auto">
                      <div
                        className="btn fs-18 font-switzer w-30 btn btn-primary my-lg-2"
                        id="start-over"
                        onClick={() => thankyou()}
                      >
                        Go to MyLibrary
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ThankYou;
