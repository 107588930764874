import React, { Suspense } from "react";
import { Accordion } from "react-bootstrap";
import { GET_PUBLIC_FAQS } from "../Home-Page/core/requests";
import { useQuery } from "@apollo/client";

export const FAQs = () => {
  const { data: faqRecords } = useQuery(GET_PUBLIC_FAQS, {
    variables: {
      where: {}, // Specify your where clause if needed
      paginate: {}, // Specify your pagination options if needed
    },
  });
  const faqResult: any = faqRecords?.getPublicFaqsList?.records ?? [];
  return (
    <section className="section">
      <div className="container mt-4">
        <div className="heading text-center mb-5">
          <div className="font-title title ">Frequently Asked Questions</div>
        </div>
        {faqResult?.map((value: any) => {
          return (
            <Accordion className="faq p-2 ">
              <Accordion.Item eventKey={value.id} className="  ">
                <Accordion.Header className="bg-light">
                  {value.question}
                </Accordion.Header>
                <Accordion.Body className="text-muted font-italic  fs-6">
                  {value.answer}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          );
        })}
      </div>
    </section>
  );
};
