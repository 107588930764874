import { toAbsoluteUrl } from "../../../assets/helpers/AssetHelpers";
// import { getBanners } from "../core/requests";
function Banner1() {
  return (
    <>
      <section className="">
        <div
          className="d-lg-block d-md-block d-none "
          style={{
            backgroundImage: `url(${toAbsoluteUrl(
              "/media/banner/webinar.jpg",
            )})`,
            width: "100%",
            height: "350px",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        ></div>
        <div
          className="d-lg-none d-md-none d-block p-0 img-fluid"
          style={{
            backgroundImage: `url(${toAbsoluteUrl(
              "/media/banner/webinar.jpg",
            )})`,
            width: "100%",
            height: "180px",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        ></div>
      </section>
    </>
  );
}

export default Banner1;
