import React from 'react';
import { useQuery, gql } from '@apollo/client';
import './blog.css';
import { BLOGS_SECTION } from '../../../utils/consts';
import BlogCard from './BlogCard';
import { toAbsoluteUrl } from 'assets/helpers/AssetHelpers';
import Loading from '../CommonComponents/Loading';
import Button from '../CommonComponents/Button';
import { ButtonBorderType, ButtonType } from '../CommonComponents/types';

// Define the GraphQL query
const GET_BLOGS = gql`
	query GetBlogs {
		getBlogs {
			id
			title
			summaryHtml
			description
			imageUrl
			handle
			tags
			blogUrl
		}
	}
`;

const Blog = () => {
	// Fetch the blog data
	const { loading, error, data } = useQuery(GET_BLOGS);

	if (loading) return <Loading width={200} />;
	if (error) {
		console.error('Error fetching blogs:', error);
		return (
			<div className="course-card-section-spacing d-flex justify-content-center">
				There was some error in fetching blogs. Please try again is some time.
			</div>
		);
	}

	// Limit to the latest 3 blogs
	const latestBlogs = data.getBlogs.slice(0, 3);

	const handleViewAll = () => {
		window.open('https://blog.hogrowth.com/', '_blank');
	};

	return (
		<section className="" id={BLOGS_SECTION}>
			<div className="container">
				<div className="row">
					<div className="col-lg-10 col-md-10 col-11 layout-set">
						<div className="text-center">
							<h2 className="p_mid blog-title">Most Recent Articles</h2>
						</div>

						<div className="row d-flex justify-content-between course-card-section-spacing gy-4">
							{!latestBlogs.length ? (
								<div className="d-flex flex-column align-items-center gap-2">
									<img
										src={toAbsoluteUrl('/media/blog-images/no-blogs.svg')}
										alt=""
										className="no-blog-image"
									/>
									<span className="no-blog-text d-flex ibm_reg justify-content-center align-items-center text-center">
										Currently there are no Blogs, we are actively working to bring you latest
										content. Stay tuned!
									</span>
								</div>
							) : (
								latestBlogs.map((blog) => (
									<BlogCard
										key={blog.id}
										title={blog.title}
										summaryHtml={blog.summaryHtml}
										description={blog.description}
										imageUrl={blog.imageUrl}
										handle={blog.handle}
										tags={blog.tags}
										blogUrl={blog.blogUrl}
									/>
								))
							)}
						</div>
						{latestBlogs.length > 0 && (
							<div className="text-center">
								<Button
									buttonText="View All"
									buttonType={ButtonType.SECONDARY_BUTTON}
									handleClick={handleViewAll}
									buttonBorderType={ButtonBorderType.PRIMARY_BORDER}
									classes="g_mid"
								/>
							</div>
						)}
					</div>
				</div>
			</div>
		</section>
	);
};

export default Blog;
