import { useEffect, useState } from "react";
import htmlToDraft from "html-to-draftjs";
import CourseCompleteCard from "./CourseCompleteCard";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import { GETCOURSEBYSLUG } from "../Courses-Page/core/requests";
import moment from "moment";
import "../../components/PreRecordedCourses/global.css";
import { EditorState, ContentState } from "draft-js";
import { useQuery } from "@apollo/client";
import { DOWNLOADCERTIFICATE } from "../Cart/core/requests";
import { Recommendation } from "../Courses-Page/Detail-Page/Recommendation";

const CourseCompleted = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const courseSlug = location.pathname.split("/").reverse()[0];
  const startover = () => {
    navigate(`/coursecontentvideo/${courseSlug}`);
  };

  const { data: courseRecord } = useQuery(GETCOURSEBYSLUG, {
    variables: {
      slug: courseSlug ?? "",
    },
  });
  const { data: certificateData } = useQuery(DOWNLOADCERTIFICATE, {
    variables: {
      course_id: courseRecord?.getPublicCourseBySlug?.id,
    },
    // onError: (err:any) => {
    //     toast.error(err.message)
    // }
  });
  const downloadPDF = async (pdf: any) => {
    const linkSource = `data:application/pdf;base64,${pdf}`;
    const downloadLink = document.createElement("a");
    const courseID = courseRecord?.getPublicCourseBySlug?.id;
    const utcTimestamp = new Date().toISOString().replace(/:/g, "-");
    const fileName = `${courseID}_${utcTimestamp}.pdf`;
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  const mentorProfileImage = courseRecord?.getPublicCourseBySlug?.mentors[0]
    ?.mentor?.userProfile
    ? courseRecord?.getPublicCourseBySlug?.mentors[0]?.mentor?.userProfile[0]
        ?.imageUrl
    : null;
  const mentorname = courseRecord?.getPublicCourseBySlug?.mentors[0]?.mentor
    ? courseRecord?.getPublicCourseBySlug?.mentors[0]?.mentor?.firstName +
      " " +
      courseRecord?.getPublicCourseBySlug?.mentors[0]?.mentor?.lastName
    : "";

  const [description, setDescription] = useState(() => {
    if (
      courseRecord &&
      courseRecord?.getPublicCourseBySlug &&
      courseRecord?.getPublicCourseBySlug?.description
    ) {
      const blocksFromHtml = htmlToDraft(
        courseRecord?.getPublicCourseBySlug?.description,
      );
      const { contentBlocks, entityMap } = blocksFromHtml;
      const contentState = ContentState.createFromBlockArray(
        contentBlocks,
        entityMap,
      );
      const editorState = EditorState.createWithContent(contentState);
      return editorState;
    }
  });
  useEffect(() => {
    if (
      courseRecord &&
      courseRecord?.getPublicCourseBySlug &&
      courseRecord?.getPublicCourseBySlug?.description
    ) {
      const blocksFromHtml = htmlToDraft(
        courseRecord &&
          courseRecord?.getPublicCourseBySlug &&
          courseRecord?.getPublicCourseBySlug?.description,
      );
      const { contentBlocks, entityMap } = blocksFromHtml;
      const contentState = ContentState.createFromBlockArray(
        contentBlocks,
        entityMap,
      );
      const editorState = EditorState.createWithContent(contentState);
      setDescription(editorState);
    }
  }, [courseRecord]); // Trigger effect when the course prop changes
  return (
    <>
      <section className="section px-xl-4 -x-lg-3 px-2 d-lg-block d-md-block d-none">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="row justify-content-lg-center align-items-lg-center border-muted p-4">
                <div className="col-12 ">
                  <div className="border-bottom pb-lg-5 text-lg-center">
                    <div className="course-head fs-2 font-title mb-lg-3 head1">
                      {courseRecord?.getPublicCourseBySlug?.name}
                    </div>
                    <img
                      src="../media/preRecord/celebration.png"
                      alt="celebration"
                      height="140"
                      width="140"
                      className="img-fluid"
                    />
                    <div className="course-head fs-4 fw-bold font-title mb-lg-3">
                      Congratulations!
                    </div>
                    <div className="course-head fs-18 fw-medium font-subtitle color2">
                      You have successfully completed this course on
                    </div>
                    <div className="course-head fs-18 fw-bold font-subtitle  mb-lg-3 color2">
                      {moment
                        .utc(new Date())
                        .utcOffset("+05:30")
                        .format("Do MMMM YYYY")}
                    </div>
                    <div className="d-flex justify-content-center w-60 mx-auto">
                      <div
                        className="btn fs-18 font-switzer w-30 btn btn-primary my-lg-2 me-3"
                        id="start-over"
                        onClick={() =>
                          downloadPDF(certificateData?.downloadCertificate)
                        }
                      >
                        DownLoad Certificate
                      </div>
                      <div
                        className="btn fs-18 font-switzer w-30 btn-outline-color1 my-lg-2"
                        id="start-over"
                        onClick={() => startover()}
                      >
                        Start Over
                      </div>
                    </div>
                  </div>
                </div>
                {description?.getCurrentContent().hasText() === true ? (
                  <div className="col-12">
                    <div className="pt-lg-5">
                      <div className="course-head fs-4 font-title mb-lg-3 fw-bold head1">
                        Course Details
                      </div>
                      <div className="course-head fs-20 fw-medium font-title mb-lg-2 fw-bold head1">
                        Overview
                      </div>
                      <div
                        className="font-subtitle mt-1 subtitle"
                        dangerouslySetInnerHTML={{
                          __html:
                            courseRecord.getPublicCourseBySlug.description,
                        }}
                      />
                    </div>
                  </div>
                ) : (
                  ""
                )}
                <div className="pt-lg-3 mb-5">
                  <div className="course-head fs-4 fw-medium font-ibm mb-lg-2 color5 fw-bold head1">
                    Mentor
                  </div>
                  <div className="d-flex align-items-center w-15">
                    <div className="w-30 text-center">
                      {mentorProfileImage ? (
                        <img
                          src={mentorProfileImage}
                          alt="mentor.png"
                          height="60"
                          width="60"
                          className="img-fluid mentor-img"
                        />
                      ) : (
                        <img
                          src="../media/mentor/profile_image.jpg"
                          alt="mentor.png"
                          height="60"
                          width="60"
                          className="img-fluid"
                        />
                      )}
                    </div>
                    <div className="w-85 px-lg-2">
                      <div className="color1 font-switzer fs-5 fw-medium">
                        {mentorname}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className='container d-flex justify-content-between'>
                    <div style={{marginLeft : "-10px"}}>
                        <h1 className='fs-2 mt-3 font-title title mb-3 head1'>You May Also Like</h1>
                        <p>Some short description/subtext goes here like this</p>
                    </div>
                    <div className=''>
                        <button className='btn btn-outline-primary mt-5'>Explore All <i className="fa-solid fa-arrow-right"></i></button>
                    </div>
                </div> */}
        <div className="container mx-4">
          <div className="row justify-content-center">
            <Recommendation />
          </div>
        </div>
      </section>

      {/*
-------------------------------
 Responsive Mobile View Code 
 ---------------------------
 */}

      <section className="section px-xl-4 -x-lg-3 px-2 d-lg-none d-md-none d-block">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="row justify-content-center align-items-center border-muted p-4">
                <div className="col-12 ">
                  <div className="border-bottom pb-5 text-center">
                    <div className="course-head fs-4 font-title fw-bold head1 text-center">
                      {courseRecord?.getPublicCourseBySlug?.name}
                    </div>
                    <img
                      src="../media/preRecord/celebration.png"
                      alt="celebration"
                      height="140"
                      width="140"
                      className="img-fluid"
                    />
                    <div className="course-head fs-4 fw-bold font-title mb-lg-3">
                      Congratulations!
                    </div>
                    <div className="course-head fs-18 fw-medium font-subtitle color2">
                      You have successfully completed this course on
                    </div>
                    <div className="course-head fs-18 fw-bold font-subtitle  mb-lg-3 color2">
                      {moment
                        .utc(new Date())
                        .utcOffset("+05:30")
                        .format("Do MMMM YYYY")}
                    </div>
                    <div className="d-flex justify-content-center w-100 mt-4">
                      <div
                        className="btn fs-18 font-switzer btn btn-primary my-lg-2 me-3"
                        id="start-over"
                        onClick={() =>
                          downloadPDF(certificateData?.downloadCertificate)
                        }
                      >
                        DownLoad Certificate
                      </div>
                      <div
                        className="btn fs-18 font-switzer btn-outline-color1 my-lg-2"
                        id="start-over"
                        onClick={() => startover()}
                      >
                        Start Over
                      </div>
                    </div>
                  </div>
                </div>
                {description?.getCurrentContent().hasText() === true ? (
                  <div className="col-12">
                    <div className="pt-3">
                      <div className="course-head fs-4 font-title mb-3 fw-bold head1">
                        Course Details
                      </div>
                      <div className="course-head fs-20 fw-medium font-title fw-bold head1">
                        Overview
                      </div>
                      <div
                        className="font-subtitle mt-1 subtitle"
                        dangerouslySetInnerHTML={{
                          __html:
                            courseRecord.getPublicCourseBySlug.description,
                        }}
                      />
                    </div>
                  </div>
                ) : (
                  ""
                )}
                <div className="mb-3">
                  <div className="course-head fs-4 fw-medium font-title mb-2 color5 fw-bold head1">
                    Mentor
                  </div>
                  <div className="d-flex align-items-center w-100 pt-3">
                    <div className="me-4">
                      {mentorProfileImage ? (
                        <img
                          src={mentorProfileImage}
                          alt="mentor.png"
                          height="60"
                          width="60"
                          className="img-fluid mentor-img"
                        />
                      ) : (
                        <img
                          src="../media/mentor/profile_image.jpg"
                          alt="mentor.png"
                          height="60"
                          width="60"
                          className="img-fluid"
                        />
                      )}
                    </div>
                    <div className="w-70">
                      <div className="color1 font-subtitle fs-5 fw-medium">
                        {mentorname}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className='container d-flex justify-content-between'>
                    <div style={{marginLeft : "-10px"}}>
                        <h1 className='fs-2 mt-3 font-title title mb-3 head1'>You May Also Like</h1>
                        <p>Some short description/subtext goes here like this</p>
                    </div>
                    <div className=''>
                        <button className='btn btn-outline-primary mt-5'>Explore All <i className="fa-solid fa-arrow-right"></i></button>
                    </div>
                </div> */}
        <div className="container">
          <div className="row justify-content-center">
            <Recommendation />
          </div>
        </div>
      </section>
    </>
  );
};

export default CourseCompleted;
