import React from "react";
// import AnotherTopicSide from './AnotherTopicSide'

const QuizSidebar = () => {
  return (
    <>
      <div className="col-12 col-lg-4 mb-auto">
        <div className="course-content   my-lg-4 ">
          <div className="fs-2 font-title color5 fw-medium">Course Content</div>
          <div className="color2 font-subtitle fs-14">
            6 Sessions | 1h 21min
          </div>
        </div>
        <div
          className="accordion border h-100 p-lg-2border-color3"
          id="accordionExample"
        >
          <div className="accordion-item border-0 border-bottom border-1 border-color3">
            <h2 className="accordion-header" id="headingOne">
              <button
                className="accordion-button accordion_button px-lg-2 py-lg-0 collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"
              >
                <div className="px-lg-1 py-lg-3 d-flex align-items-center w-100">
                  <div className="w-20">
                    <div className="radialProgressBar progress-success progress-100">
                      <div className="overlay d-flex justify-content-center align-items-center color1 fs-6">
                        <i className="fa-solid fa-check"></i>
                      </div>
                    </div>
                  </div>
                  <div className="w-80" style={{ marginLeft: "-0.625rem" }}>
                    <div className="color5 font-subtitle fs-5 fw-medium mb-lg-2">
                      Another Topic here....{" "}
                    </div>
                    <div className="color2 font-subtitle fs-6">
                      3 sessions | 30mins
                    </div>
                  </div>
                </div>
              </button>
            </h2>
            <div
              id="collapseOne"
              className="accordion-collapse collapse"
              aria-labelledby="headingOne"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body px-2">
                <div className="mb-lg-3 topic-success">
                  <div className="d-flex align-items-center w-100">
                    <div className="w-15">
                      <div className="d-flex align-items-center color1 fs-5">
                        <div className="bg-color4 py-1 px-2 mx-2 rounded_circle rounded-circle">
                          <i className="fa-solid complete fa-circle-check"></i>
                        </div>
                      </div>
                    </div>
                    <div className="w-85 mx-4">
                      <div className="color5 font-switzer fs-6  fw-medium mb-lg-2 nav_topic_title">
                        1.2 . Another good chapter name Amet minim mollit non
                      </div>
                      <div className="color2 font-switzer fs-14">2m 15s</div>
                    </div>
                  </div>
                </div>
                <div className="mb-lg-3 topic-success">
                  <div className="d-flex align-items-center w-100">
                    <div className="w-15">
                      <div className="d-flex align-items-center mx-2 color1 fs-5">
                        <div className="bg-color4 py-1 px-2 rounded_circle rounded-circle">
                          <i className="fa-solid complete fa-book"></i>
                        </div>
                      </div>
                    </div>
                    <div className="w-85 mx-4">
                      <div className="color5 font-switzer fs-6 fw-medium mb-lg-2 nav_topic_title">
                        1.2 . Another good chapter name Amet minim mollit non
                      </div>
                      <div className="color2 font-switzer fs-14">2m 15s</div>
                    </div>
                  </div>
                </div>
                <div className="mb-lg-3 topic-success">
                  <div className="d-flex align-items-center w-100">
                    <div className="w-15">
                      <div className="d-flex align-items-center mx-2 color1 fs-5">
                        <div className="bg-color4 py-1 px-2  rounded_circle rounded-circle">
                          <i className="fa-solid complete fa-circle-play"></i>
                        </div>
                      </div>
                    </div>
                    <div className="w-85 mx-4">
                      <div className="color5 font-switzer fs-6  fw-medium mb-lg-2 nav_topic_title">
                        1.2 . Another good chapter name Amet minim mollit non
                      </div>
                      <div className="color2 font-switzer fs-14">2m 15s</div>
                    </div>
                  </div>
                </div>
                <div className="mb-lg-3 topic-success">
                  <div className="d-flex align-items-center w-100">
                    <div className="w-15">
                      <div className="d-flex align-items-center mx-2 color1 fs-5">
                        <div className="bg-color4 py-1 px-2  rounded_circle rounded-circle">
                          <i className="fa-solid complete fa-list-check"></i>
                        </div>
                      </div>
                    </div>
                    <div className="w-85 mx-4">
                      <div className="color5 font-switzer fs-6 fw-medium mb-lg-2 nav_topic_title">
                        1.2 . Another good chapter name Amet minim mollit non
                      </div>
                      <div className="color2 font-switzer fs-14">2m 15s</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="accordion-item border-0 border-bottom border-1 border-color3">
            <h2 className="accordion-header" id="headingOne">
              <button
                className="accordion-button accordion_button px-lg-2 py-lg-0 collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTwo"
                aria-expanded="true"
                aria-controls="collapseTwo"
              >
                <div className="px-lg-1 py-lg-3 d-flex align-items-center w-100">
                  <div className="w-20">
                    <div className="radialProgressBar progress-success progress-100">
                      <div className="overlay d-flex justify-content-center align-items-center color1 fs-6">
                        <i className="fa-solid fa-check"></i>
                      </div>
                    </div>
                  </div>
                  <div className="w-80" style={{ marginLeft: "-0.625rem" }}>
                    <div className="color5 font-subtitle fs-5 fw-medium mb-lg-2">
                      Another Topic here....{" "}
                    </div>
                    <div className="color2 font-subtitle fs-6">
                      3 sessions | 30mins
                    </div>
                  </div>
                </div>
              </button>
            </h2>
            <div
              id="collapseTwo"
              className="accordion-collapse collapse"
              aria-labelledby="headingOne"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body px-2">
                <div className="mb-lg-3 topic-success">
                  <div className="d-flex align-items-center w-100">
                    <div className="w-15">
                      <div className="d-flex align-items-center color1 fs-5">
                        <div className="bg-color4 py-1 px-2 mx-2 rounded_circle rounded-circle">
                          <i className="fa-solid complete fa-circle-check"></i>
                        </div>
                      </div>
                    </div>
                    <div className="w-85 mx-4">
                      <div className="color5 font-switzer fs-6  fw-medium mb-lg-2 nav_topic_title">
                        1.2 . Another good chapter name Amet minim mollit non
                      </div>
                      <div className="color2 font-switzer fs-14">2m 15s</div>
                    </div>
                  </div>
                </div>
                <div className="mb-lg-3 topic-success">
                  <div className="d-flex align-items-center w-100">
                    <div className="w-15">
                      <div className="d-flex align-items-center mx-2 color1 fs-5">
                        <div className="bg-color4 py-1 px-2 rounded_circle rounded-circle">
                          <i className="fa-solid complete fa-book"></i>
                        </div>
                      </div>
                    </div>
                    <div className="w-85 mx-4">
                      <div className="color5 font-switzer fs-6 fw-medium mb-lg-2 nav_topic_title">
                        1.2 . Another good chapter name Amet minim mollit non
                      </div>
                      <div className="color2 font-switzer fs-14">2m 15s</div>
                    </div>
                  </div>
                </div>
                <div className="mb-lg-3 topic-success">
                  <div className="d-flex align-items-center w-100">
                    <div className="w-15">
                      <div className="d-flex align-items-center mx-2 color1 fs-5">
                        <div className="bg-color4 py-1 px-2  rounded_circle rounded-circle">
                          <i className="fa-solid complete fa-circle-play"></i>
                        </div>
                      </div>
                    </div>
                    <div className="w-85 mx-4">
                      <div className="color5 font-switzer fs-6  fw-medium mb-lg-2 nav_topic_title">
                        1.2 . Another good chapter name Amet minim mollit non
                      </div>
                      <div className="color2 font-switzer fs-14">2m 15s</div>
                    </div>
                  </div>
                </div>
                <div className="mb-lg-3 topic-success">
                  <div className="d-flex align-items-center w-100">
                    <div className="w-15">
                      <div className="d-flex align-items-center mx-2 color1 fs-5">
                        <div className="bg-color4 py-1 px-2  rounded_circle rounded-circle">
                          <i className="fa-solid complete fa-list-check"></i>
                        </div>
                      </div>
                    </div>
                    <div className="w-85 mx-4">
                      <div className="color5 font-switzer fs-6 fw-medium mb-lg-2 nav_topic_title">
                        1.2 . Another good chapter name Amet minim mollit non
                      </div>
                      <div className="color2 font-switzer fs-14">2m 15s</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="accordion-item border-0 border-bottom border-1 border-color3">
            <h2 className="accordion-header" id="headingOne">
              <button
                className="accordion-button accordion_button px-lg-2 py-lg-0 collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseThree"
                aria-expanded="true"
                aria-controls="collapseThree"
              >
                <div className="px-lg-1 py-lg-3 d-flex align-items-center w-100">
                  <div className="w-20">
                    <div className="radialProgressBar progress-success progress-100">
                      <div className="overlay d-flex justify-content-center align-items-center color1 fs-6">
                        <i className="fa-solid fa-check"></i>
                      </div>
                    </div>
                  </div>
                  <div className="w-80" style={{ marginLeft: "-0.625rem" }}>
                    <div className="color5 font-subtitle fs-5 fw-medium mb-lg-2">
                      Another Topic here....{" "}
                    </div>
                    <div className="color2 font-subtitle fs-6">
                      3 sessions | 30mins
                    </div>
                  </div>
                </div>
              </button>
            </h2>
            <div
              id="collapseThree"
              className="accordion-collapse collapse"
              aria-labelledby="headingOne"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body px-2">
                <div className="mb-lg-3 topic-success">
                  <div className="d-flex align-items-center w-100">
                    <div className="w-15">
                      <div className="d-flex align-items-center color1 fs-5">
                        <div className="bg-color4 py-1 px-2 mx-2 rounded_circle rounded-circle">
                          <i className="fa-solid complete fa-circle-check"></i>
                        </div>
                      </div>
                    </div>
                    <div className="w-85 mx-4">
                      <div className="color5 font-switzer fs-6  fw-medium mb-lg-2 nav_topic_title">
                        1.2 . Another good chapter name Amet minim mollit non
                      </div>
                      <div className="color2 font-switzer fs-14">2m 15s</div>
                    </div>
                  </div>
                </div>
                <div className="mb-lg-3 topic-success">
                  <div className="d-flex align-items-center w-100">
                    <div className="w-15">
                      <div className="d-flex align-items-center mx-2 color1 fs-5">
                        <div className="bg-color4 py-1 px-2 rounded_circle rounded-circle">
                          <i className="fa-solid complete fa-book"></i>
                        </div>
                      </div>
                    </div>
                    <div className="w-85 mx-4">
                      <div className="color5 font-switzer fs-6 fw-medium mb-lg-2 nav_topic_title">
                        1.2 . Another good chapter name Amet minim mollit non
                      </div>
                      <div className="color2 font-switzer fs-14">2m 15s</div>
                    </div>
                  </div>
                </div>
                <div className="mb-lg-3 topic-success">
                  <div className="d-flex align-items-center w-100">
                    <div className="w-15">
                      <div className="d-flex align-items-center mx-2 color1 fs-5">
                        <div className="bg-color4 py-1 px-2  rounded_circle rounded-circle">
                          <i className="fa-solid complete fa-circle-play"></i>
                        </div>
                      </div>
                    </div>
                    <div className="w-85 mx-4">
                      <div className="color5 font-switzer fs-6  fw-medium mb-lg-2 nav_topic_title">
                        1.2 . Another good chapter name Amet minim mollit non
                      </div>
                      <div className="color2 font-switzer fs-14">2m 15s</div>
                    </div>
                  </div>
                </div>
                <div className="mb-lg-3 topic-success">
                  <div className="d-flex align-items-center w-100">
                    <div className="w-15">
                      <div className="d-flex align-items-center mx-2 color1 fs-5">
                        <div className="bg-color4 py-1 px-2  rounded_circle rounded-circle">
                          <i className="fa-solid complete fa-list-check"></i>
                        </div>
                      </div>
                    </div>
                    <div className="w-85 mx-4">
                      <div className="color5 font-switzer fs-6 fw-medium mb-lg-2 nav_topic_title">
                        1.2 . Another good chapter name Amet minim mollit non
                      </div>
                      <div className="color2 font-switzer fs-14">2m 15s</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="accordion-item border-0 border-bottom border-1 border-color3">
            <h2 className="accordion-header" id="headingTwo">
              <button
                className="accordion-button accordion_button px-lg-2 py-lg-0"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseFour"
                aria-expanded="false"
                aria-controls="collapseFour"
              >
                <div className="px-lg-1 py-lg-3 d-flex align-items-center w-100">
                  <div className="w-20">
                    <div className="radialProgressBar accordion_proccess progress-primary progress-30">
                      <div className="overlay d-flex justify-content-center align-items-center color1 fs-6">
                        4
                      </div>
                    </div>
                  </div>
                  <div className="w-80" style={{ marginLeft: "-0.625rem" }}>
                    <div className="color5 font-switzer fs-5 fw-medium mb-lg-2">
                      Test 1
                    </div>
                    <div className="color2 font-switzer fs-6">
                      3 sessions | 30mins
                    </div>
                  </div>
                </div>
              </button>
            </h2>
            <div
              id="collapseFour"
              className="accordion-collapse collapse show"
              aria-labelledby="headingTwo"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body px-2">
                <div
                  className="nav flex-column nav-pills"
                  id="v-pills-tab"
                  role="tablist"
                  aria-orientation="vertical"
                >
                  <button
                    className="nav-link nav_link"
                    id="v-pills-intro-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#v-pills-intro"
                    type="button"
                    role="tab"
                    aria-controls="v-pills-intro"
                    aria-selected="true"
                  >
                    <div className="mb-lg-3 topic-primary">
                      <div className="d-flex align-items-center w-100">
                        <div className="w-15">
                          <div className="d-flex align-items-center mx-2 color1 fs-5">
                            <div className="bg-color4 py-1 px-2 rounded_circle rounded-circle">
                              <i className="fa-solid complete fa-circle-check"></i>
                            </div>
                          </div>
                        </div>
                        <div className="w-85 mx-4">
                          <div className="color5 font-switzer fs-6 fw-medium mb-lg-2 nav_topic_title">
                            4.1 .Test1{" "}
                          </div>
                          <div className="color2 font-switzer fs-14">
                            2m 15s
                          </div>
                        </div>
                      </div>
                    </div>
                  </button>
                  <button
                    className="nav-link nav_link active"
                    id="v-topic-section-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#v-topic-section"
                    type="button"
                    role="tab"
                    aria-controls="v-topic-section"
                    aria-selected="false"
                  >
                    <div className="mb-lg-3 topic-primary">
                      <div className="d-flex align-items-center w-100">
                        <div className="w-15">
                          <div className="d-flex align-items-center mx-2 color1 fs-5">
                            <div className="bg-color4 py-1 px-2 rounded_circle rounded-circle">
                              <i className="fa-solid active fa-book"></i>
                            </div>
                          </div>
                        </div>
                        <div className="w-85 mx-4">
                          <div className="color5 font-switzer fs-6 fw-medium mb-lg-2 nav_topic_title">
                            4.2 .Test2{" "}
                          </div>
                          <div className="color2 font-switzer fs-14">
                            2m 15s
                          </div>
                        </div>
                      </div>
                    </div>
                  </button>
                  <button
                    className="nav-link nav_link"
                    id="v-pills-intro-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#v-pills-intro"
                    type="button"
                    role="tab"
                    aria-controls="v-pills-intro"
                    aria-selected="false"
                  >
                    <div className="mb-lg-3 topic-primary">
                      <div className="d-flex align-items-center w-100">
                        <div className="w-15">
                          <div className="d-flex align-items-center mx-2 color1 fs-5">
                            <div className="bg-color4 py-1 px-2 rounded_circle rounded-circle">
                              <i className="fa-solid fa-circle-play"></i>
                            </div>
                          </div>
                        </div>
                        <div className="w-85 mx-4">
                          <div className="color5 font-switzer fs-6 fw-medium mb-lg-2 nav_topic_title">
                            4.3 .Test3{" "}
                          </div>
                          <div className="color2 font-switzer fs-14">
                            2m 15s
                          </div>
                        </div>
                      </div>
                    </div>
                  </button>
                  <button
                    className="nav-link nav_link"
                    id="v-pills-topic-section-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#v-pills-topic-section"
                    type="button"
                    role="tab"
                    aria-controls="v-pills-topic-section"
                    aria-selected="false"
                  >
                    <div className="mb-lg-3 topic-primary">
                      <div className="d-flex align-items-center w-100">
                        <div className="w-15">
                          <div className="d-flex align-items-center mx-2 color1 fs-5">
                            <div className="bg-color4 py-1 px-2 rounded_circle rounded-circle">
                              <i className="fa-solid fa-list-check"></i>
                            </div>
                          </div>
                        </div>
                        <div className="w-85 mx-4">
                          <div className="color5 font-switzer fs-6 fw-medium mb-lg-2 nav_topic_title">
                            4.4 .Test4{" "}
                          </div>
                          <div className="color2 font-switzer fs-14">
                            2m 15s
                          </div>
                        </div>
                      </div>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="accordion-item border-0 border-bottom border-1 border-color3">
            <h2 className="accordion-header" id="headingTwo">
              <button
                className="accordion-button accordion_button px-lg-2 py-lg-0 collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseFive"
                aria-expanded="false"
                aria-controls="collapseFive"
              >
                <div className="px-lg-1 py-lg-3 d-flex align-items-center w-100">
                  <div className="w-20">
                    <div className="radialProgressBar accordion_proccess progress-secondary progress-0">
                      <div className="overlay d-flex justify-content-center align-items-center color1 fs-6">
                        5
                      </div>
                    </div>
                  </div>
                  <div className="w-80" style={{ marginLeft: "-0.625rem" }}>
                    <div className="color5 font-switzer fs-5 fw-medium mb-lg-2">
                      Another Topic here....{" "}
                    </div>
                    <div className="color2 font-switzer fs-6">
                      3 sessions | 30mins
                    </div>
                  </div>
                </div>
              </button>
            </h2>
            <div
              id="collapseFive"
              className="accordion-collapse collapse"
              aria-labelledby="headingTwo"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body px-2">
                <div className="mb-lg-3 topic-secondary">
                  <div className="d-flex align-items-center w-100">
                    <div className="w-15">
                      <div className="d-flex align-items-center mx-2 color1 fs-5">
                        <div className="bg-color4 py-1 px-2 rounded_circle rounded-circle">
                          <i className="fa-solid fa-circle-check"></i>
                        </div>
                      </div>
                    </div>
                    <div className="w-85 mx-4">
                      <div className="color5 font-switzer fs-6 fw-medium mb-lg-2 nav_topic_title">
                        5.1 . Another good chapter name Amet minim mollit non
                      </div>
                      <div className="color2 font-switzer fs-14">2m 15s</div>
                    </div>
                  </div>
                </div>
                <div className="mb-lg-3 topic-secondary">
                  <div className="d-flex align-items-center w-100">
                    <div className="w-15">
                      <div className="d-flex align-items-center  mx-2 color1 fs-5">
                        <div className="bg-color4 py-1 px-2 rounded_circle rounded-circle">
                          <i className="fa-solid fa-book"></i>
                        </div>
                      </div>
                    </div>
                    <div className="w-85 mx-4">
                      <div className="color5 font-switzer fs-6 fw-medium mb-lg-2 nav_topic_title">
                        5.2 . Another good chapter name Amet minim mollit non
                      </div>
                      <div className="color2 font-switzer fs-14">2m 15s</div>
                    </div>
                  </div>
                </div>
                <div className="mb-lg-3 topic-secondary">
                  <div className="d-flex align-items-center w-100">
                    <div className="w-15">
                      <div className="d-flex align-items-center  mx-2 color1 fs-5">
                        <div className="bg-color4 py-1 px-2 rounded_circle rounded-circle">
                          <i className="fa-solid fa-circle-play"></i>
                        </div>
                      </div>
                    </div>
                    <div className="w-85 mx-4">
                      <div className="color5 font-switzer fs-6 fw-medium mb-lg-2 nav_topic_title">
                        5.3 . Another good chapter name Amet minim mollit non
                      </div>
                      <div className="color2 font-switzer fs-14">2m 15s</div>
                    </div>
                  </div>
                </div>
                <div className="mb-lg-3 topic-secondary">
                  <div className="d-flex align-items-center w-100">
                    <div className="w-15">
                      <div className="d-flex align-items-center  mx-2 color1 fs-5">
                        <div className="bg-color4 py-1 px-2 rounded_circle rounded-circle">
                          <i className="fa-solid fa-list-check"></i>
                        </div>
                      </div>
                    </div>
                    <div className="w-85 mx-4">
                      <div className="color5 font-switzer fs-6 fw-medium mb-lg-2 nav_topic_title">
                        5.4 . Another good chapter name Amet minim mollit non
                      </div>
                      <div className="color2 font-switzer fs-14">2m 15s</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="accordion-item border-0 border-bottom border-1 border-color3">
            <h2 className="accordion-header" id="headingTwo">
              <button
                className="accordion-button accordion_button px-lg-2 py-lg-0 collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapsesix"
                aria-expanded="false"
                aria-controls="collapsesix"
              >
                <div className="px-lg-1 py-lg-3 d-flex align-items-center w-100">
                  <div className="w-20">
                    <div className="radialProgressBar accordion_proccess progress-secondary progress-0">
                      <div className="overlay d-flex justify-content-center align-items-center color1 fs-6">
                        6
                      </div>
                    </div>
                  </div>
                  <div className="w-80" style={{ marginLeft: "-0.625rem" }}>
                    <div className="color5 font-switzer fs-5 fw-medium mb-lg-2">
                      Another Topic here....{" "}
                    </div>
                    <div className="color2 font-switzer fs-6">
                      3 sessions | 30mins
                    </div>
                  </div>
                </div>
              </button>
            </h2>
            <div
              id="collapsesix"
              className="accordion-collapse collapse"
              aria-labelledby="headingTwo"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body px-2">
                <div className="mb-lg-3 topic-secondary">
                  <div className="d-flex align-items-center w-100">
                    <div className="w-15">
                      <div className="d-flex align-items-center mx-2 color1 fs-5">
                        <div className="bg-color4 py-1 px-2 rounded_circle rounded-circle">
                          <i className="fa-solid fa-circle-check"></i>
                        </div>
                      </div>
                    </div>
                    <div className="w-85 mx-4">
                      <div className="color5 font-switzer fs-6 fw-medium mb-lg-2 nav_topic_title">
                        6.1 . Another good chapter name Amet minim mollit non
                      </div>
                      <div className="color2 font-switzer fs-14">2m 15s</div>
                    </div>
                  </div>
                </div>
                <div className="mb-lg-3 topic-secondary">
                  <div className="d-flex align-items-center w-100">
                    <div className="w-15">
                      <div className="d-flex align-items-center  mx-2 color1 fs-5">
                        <div className="bg-color4 py-1 px-2 rounded_circle rounded-circle">
                          <i className="fa-solid fa-book"></i>
                        </div>
                      </div>
                    </div>
                    <div className="w-85 mx-4">
                      <div className="color5 font-switzer fs-6 fw-medium mb-lg-2 nav_topic_title">
                        6.2 . Another good chapter name Amet minim mollit non
                      </div>
                      <div className="color2 font-switzer fs-14">2m 15s</div>
                    </div>
                  </div>
                </div>
                <div className="mb-lg-3 topic-secondary">
                  <div className="d-flex align-items-center w-100">
                    <div className="w-15">
                      <div className="d-flex align-items-center  mx-2 color1 fs-5">
                        <div className="bg-color4 py-1 px-2 rounded_circle rounded-circle">
                          <i className="fa-solid fa-circle-play"></i>
                        </div>
                      </div>
                    </div>
                    <div className="w-85 mx-4">
                      <div className="color5 font-switzer fs-6 fw-medium mb-lg-2 nav_topic_title">
                        6.3 . Another good chapter name Amet minim mollit non
                      </div>
                      <div className="color2 font-switzer fs-14">2m 15s</div>
                    </div>
                  </div>
                </div>
                <div className="mb-lg-3 topic-secondary">
                  <div className="d-flex align-items-center w-100">
                    <div className="w-15">
                      <div className="d-flex align-items-center  mx-2 color1 fs-5">
                        <div className="bg-color4 py-1 px-2 rounded_circle rounded-circle">
                          <i className="fa-solid fa-list-check"></i>
                        </div>
                      </div>
                    </div>
                    <div className="w-85 mx-4">
                      <div className="color5 font-switzer fs-6 fw-medium mb-lg-2 nav_topic_title">
                        6.4 . Another good chapter name Amet minim mollit non
                      </div>
                      <div className="color2 font-switzer fs-14">2m 15s</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default QuizSidebar;
