import { toAbsoluteUrl } from 'assets/helpers/AssetHelpers';
import { Image } from 'react-bootstrap';
import React from 'react';

const Stars = ({
	index,
	onClickHandler,
	onMouseEnter,
	onMouseLeave,
	ratings,
}: {
	index: number;
	onClickHandler: () => void;
	onMouseEnter: () => void;
	onMouseLeave: () => void;
	ratings: number;
}) => {
	return (
		<Image
			key={index}
			className="pe-2 py-1"
			onClick={(e) => {
				e.preventDefault();
				e.stopPropagation();
				onClickHandler();
			}}
			height={32}
			width={32}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
			src={
				index + 1 <= ratings
					? toAbsoluteUrl('/media/MyCourses/star-golden.svg') // golden star
					: toAbsoluteUrl('/media/MyCourses/star.svg') // regular star
			}
			alt={`${index + 1} star`}
		/>
	);
};

export default Stars;
