import React, { useEffect, useMemo, useRef, useState } from 'react';
import { FormikProps, useFormik } from 'formik';
import * as Yup from 'yup';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { toAbsoluteUrl } from 'assets/helpers/AssetHelpers';
import { useLazyQuery, useMutation } from '@apollo/client';
import { gql } from 'graphql-tag';
import toast from 'react-hot-toast';
import { useUserContext } from 'context/UserContext';
import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import { getUserByToken } from 'pages/auth/core/_requests';
import './PersonalDetail.css';
import { UsersGendersEnum } from 'components/_v2/Course/type';

import Button from '../CommonComponents/Button';
import { ButtonBorderType, ButtonType } from '../CommonComponents/types';
import { NewUpdateStudentProfileInput } from 'generated/types';
import {
	CityObjectType,
	CountryObjectType,
	GetCountriesWithCode,
	StateObjectType,
} from '../../../types/globalTypes';
import CommonSelect from 'components/_v2/CommonComponents/CommonSelect';
import Loading from 'components/_v2/CommonComponents/Loading';
const UPDATE_IMAGE = gql`
	mutation UpdateImage($imageUploadInput: ImageUploadInput!) {
		UpdateImage(imageUploadInput: $imageUploadInput)
	}
`;

const UPDATE_STUDENT_PROFILE = gql`
	mutation NewUpdateStudentProfile($input: NewUpdateStudentProfileInput!) {
		NewUpdateStudentProfile(NewupdateStudentProfileInput: $input)
	}
`;

const GET_COUNTRIES_WITH_CODE = gql`
	query GetCountriesWithCode {
		getCountriesWithCode {
			id
			countryName
			countryStdCode
			countryCode
			states {
				id
				countryId
				stateName
				stateCode
				cities {
					id
					stateId
					cityName
				}
			}
		}
	}
`;

const SEND_MOBILE_OTP = gql`
	mutation sendOtp($mobileNo: String!) {
		sendOtp(mobileNo: $mobileNo)
	}
`;

const SEND_EMAIL_OTP = gql`
	mutation sendEmailOtp {
		sendEmailOtp
	}
`;

const VERIFY_EMAIL_OTP = gql`
	mutation verifyEmailOtp($otp: String!) {
		verifyEmailOtp(otp: $otp)
	}
`;

const VERIFY_OTP = gql`
	mutation verifyOtp($otp: String!) {
		verifyOtp(otp: $otp)
	}
`;

type ValuePiece = Date | null;
type Value = ValuePiece | [ValuePiece, ValuePiece];

const genderOptions = [
	{ value: 'MALE', label: 'Male' },
	{ value: 'FEMALE', label: 'Female' },
	{ value: 'OTHER', label: 'Other' },
];

interface FormValues {
	fullname: string;
	birthdate: Date;
	phoneNumber: string;
	email: string;
	country: string;
	state: string;
	city: string;
	gender: UsersGendersEnum;
	pincode: string | null;
	otpCode: string | null;
	genderDropdownOpen: boolean;
	countryDropdownOpen: boolean;
	stateDropdownOpen: boolean;
	cityDropdownOpen: boolean;
}

const PersonalDetail = () => {
	const { user, setUser, localStorageAuthToken } = useUserContext();
	const [mobileNo, setMobileNo] = React.useState('');
	const [emailVerificationOpen, setEmailVerificationOpen] = useState(false);
	const [image, setImage] = useState<string | null>(null);
	const [displayImage, setDisplayImage] = useState<string | null>(null);
	const [changesMade, setChangesMade] = useState<boolean>(false);
	const [searchCountry, setSearchCountry] = useState('');
	const [searchState, setSearchState] = useState('');
	const [searchCity, setSearchCity] = useState('');
	const [hasImageChanged, setHasImageChanged] = useState(false);
	const [countries, setCountries] = useState<CountryObjectType[]>([]);
	const [countryNames, setCountryNames] = useState<string[]>([]);
	const [state, setState] = useState<StateObjectType | null>();
	const [city, setCity] = useState<CityObjectType | null>();

	const [otp, setOtp] = useState('');
	const [modalOpen, setModalOpen] = useState(false);
	const [modalEmailOpen, setModalEmailOpen] = useState(false);
	const [seconds, setSeconds] = useState(89);
	const [canResend, setCanResend] = useState(false);
	const [isVerifyingMobileOrEmail, setIsVerifyingMobileOrEmail] = useState<boolean>(false);

	const [updateImage] = useMutation(UPDATE_IMAGE);
	const [updateStudentProfile] = useMutation<NewUpdateStudentProfileInput>(UPDATE_STUDENT_PROFILE);
	const [sendMobileOtp] = useMutation(SEND_MOBILE_OTP);
	const [verifyOtp, { loading: verifyOtpLoading, error: verifyOtpError }] = useMutation(VERIFY_OTP);
	const [sendEmailOtp] = useMutation(SEND_EMAIL_OTP);
	const [verifyEmailOtp] = useMutation(VERIFY_EMAIL_OTP);
	const countryDropdownRef = useRef(null);
	const stateDropdownRef = useRef(null);
	const cityDropdownRef = useRef(null);
	const genderDropdownRef = useRef(null);

	const [getCountriesData] = useLazyQuery<GetCountriesWithCode>(GET_COUNTRIES_WITH_CODE);

	useEffect(() => {
		getCountriesData().then((data) => {
			const allCountriesData = data.data.getCountriesWithCode;
			setCountries(allCountriesData);
			const countryNames = allCountriesData.map((country) => country.countryName);
			setCountryNames(countryNames);
			const userProfileAddressData = user?.userAddress?.[0];
			const userState = allCountriesData
				.find((country) =>
					country.countryName
						.toLowerCase()
						.includes(user?.userProfile?.[0]?.country?.countryName.toLowerCase()),
				)
				?.states?.find((state) =>
					state.stateName.toLowerCase().includes(userProfileAddressData?.state.toLowerCase()),
				);
			setState(userState);
			const userCity = userState?.cities?.find((city) =>
				city.cityName.toLowerCase().includes(userProfileAddressData?.city.toLowerCase()),
			);
			setCity(userCity);
		});
	}, [countries]);

	const filteredCountryNames = countryNames
		.filter((country) => country.toLowerCase().includes(searchCountry.toLowerCase()))
		.map((countryName) => {
			return { value: countryName, label: countryName };
		});

	const validationSchema = Yup.object({
		fullname: Yup.string()
			.required('Please enter your full name')
			.min(3, 'Full Name must be greater than 3 characters')
			.max(100, 'Full Name must be shorter than 100 characters!')
			.matches(/^[A-Za-z\s]+$/, 'Only Character Allow'),
		birthdate: Yup.date()
			.nullable(false)
			.required('Please select your birthdate')
			.max(new Date(Date.now()), 'Birthdate cannot be in the future'),
		phoneNumber: Yup.string()
			.required('Please enter your phone number')
			.matches(/^\d{10}$/, 'Phone Number must be 10 digits'),
		email: Yup.string().email('Invalid email address').required('Please enter your email'),
		country: Yup.string()
			.required('Please select your country')
			.oneOf(countryNames, 'Country is required'),
		state: Yup.string()
			.required('Please select your state')
			.matches(/^[A-Za-z\s\(\)]+$/, 'Only Character Allow'),
		city: Yup.string()
			.required('Please select your city')
			.matches(/^[A-Za-z\s\(\)]+$/, 'Only Character Allow'),
		gender: Yup.string()
			.required('Please select your gender')
			.oneOf(
				[UsersGendersEnum.MALE, UsersGendersEnum.FEMALE, UsersGendersEnum.OTHER],
				'Gender is required',
			),
		otpCode: Yup.string()
			.matches(/^\d{6}$/, 'OTP must be 6 digits long')
			.nullable()
			.notRequired(),
	});

	useEffect(() => {
		if (user && user.userProfile && user.userProfile.length > 0) {
			const imageUrl = user.userProfile[0].imageUrl;
			setDisplayImage(imageUrl);
		}
	}, [user]);
	const formik: FormikProps<FormValues> = useFormik({
		initialValues: {
			fullname: user?.fullName,
			birthdate: user?.userProfile[0]?.dob || new Date(),
			phoneNumber: user?.userProfile[0]?.mobileNo || '',
			email: user?.email || '',
			country: user?.userProfile[0]?.country?.countryName || 'Select Country',
			state: user?.userAddress[0]?.state || '',
			city: user?.userAddress[0]?.city || '',
			gender: user?.userProfile[0]?.gender || UsersGendersEnum.OTHER,
			pincode: user?.userAddress[0]?.zipCode || null,
			otpCode: null,
			genderDropdownOpen: false,
			countryDropdownOpen: false,
			stateDropdownOpen: false,
			cityDropdownOpen: false,
		},
		validationSchema: validationSchema,
		onSubmit: async (values, { resetForm }) => {
			try {
				await updateStudentProfile({
					variables: {
						input: {
							name: values.fullname,
							dob: values.birthdate,
							mobileNo: values.phoneNumber,
							email: values.email,
							countryId: values.country,
							stateId: state.id,
							cityId: city.id,
							state: values.state,
							city: values.city,
							gender: values.gender,
							pincode: values.pincode ?? '',
						},
					},
					onCompleted: async () => {
						toast.success('Profile updated successfully');

						const { data } = await getUserByToken(localStorageAuthToken);

						if (data?.data?.getMe) {
							setUser(data.data.getMe);
						}
						resetForm({
							values: {
								fullname: values.fullname,
								birthdate: values.birthdate,
								phoneNumber: values.phoneNumber,
								email: values.email,
								country: values.country,
								state: values.state,
								city: values.city,
								gender: values.gender,
								pincode: values.pincode,
								otpCode: null,
								genderDropdownOpen: false,
								countryDropdownOpen: false,
								stateDropdownOpen: false,
								cityDropdownOpen: false,
							},
						});
					},
				});
			} catch (error) {
				console.error('Error updating profile', error);
				toast.error(error?.message);
			}
		},
	});

	const filteredStateNames = useMemo(() => {
		const statesWithoutSearch = countries
			.find((country) =>
				country.countryName.toLowerCase().includes(formik.values.country.toLowerCase()),
			)
			?.states?.map((state) => {
				return { value: state?.stateName, label: state?.stateName };
			});

		return searchState
			? statesWithoutSearch.filter((state) =>
					state.value.toLowerCase().includes(searchState.toLowerCase()),
			  )
			: statesWithoutSearch;
	}, [filteredCountryNames]);

	const filteredCityNames = useMemo(() => {
		const list = searchCity
			? state?.cities?.filter((city) =>
					city?.cityName?.toLowerCase()?.includes(searchCity.toLowerCase()),
			  )
			: state?.cities;
		return list?.map((listItem) => {
			return { label: listItem?.cityName, value: listItem?.cityName };
		});
	}, [state, filteredStateNames, searchCity]);

	const handleVerifyClick = async () => {
		try {
			const mobileNo = formik.values.phoneNumber;
			setIsVerifyingMobileOrEmail(true);
			setMobileNo(mobileNo);
			await sendMobileOtp({
				variables: {
					mobileNo,
				},
				onCompleted: () => {
					setIsVerifyingMobileOrEmail(false);
				},
			});
			toast.success('OTP sent successfully');
			setModalOpen(true);
			setSeconds(89);
		} catch (error) {
			console.error('Error sending OTP', error);
			toast.error('Failed to send OTP');
		}
	};

	const handleEmailVerifyClick = async () => {
		try {
			const email = formik.values.email; // Get the current mobile number from the form
			setMobileNo(email); // Set the mobileNo state variable
			setIsVerifyingMobileOrEmail(true);
			await sendEmailOtp({
				onCompleted: () => {
					setIsVerifyingMobileOrEmail(false);
				},
			});
			toast.success('OTP sent successfully');
			setModalEmailOpen(true); // Open the modal
		} catch (error) {
			console.error('Error sending OTP', error);
			toast.error('Failed to send OTP');
		}
	};
	const handleOtpVerification = async () => {
		try {
			await verifyEmailOtp({ variables: { otp } });
			toast.success('Email verified successfully');
			setEmailVerificationOpen(false);
			setModalEmailOpen(false);
			const { data } = await getUserByToken(localStorageAuthToken);
			if (data?.data?.getMe) {
				setUser(data.data.getMe); // Update the user context with the new data
			}
		} catch (error) {
			console.error('Error verifying OTP', error);
			toast.error('OTP does not match');
		}
	};

	const handleVerifyOtp = async () => {
		try {
			await verifyOtp({ variables: { otp: formik.values.otpCode } });
			toast.success('Mobile Number verified successfully');
			setModalOpen(false);
			const { data } = await getUserByToken(localStorageAuthToken);
			if (data?.data?.getMe) {
				setUser(data.data.getMe); // Update the user context with the new data
			}
		} catch (error) {
			const errorMessage = error?.graphQLErrors?.[0]?.message || 'An error occurred';
			console.error('Error verifying OTP:', errorMessage);
			toast.error(errorMessage);
		}
	};

	const handleVerificationClose = () => {
		setModalOpen(false);
	};

	const handleEmailVerificationClose = () => {
		setModalEmailOpen(false);
	};

	const handleImageChange = (e: any) => {
		setHasImageChanged(true);
		const file = e.target.files[0];
		const reader: any = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => {
			setImage(reader.result);
			setDisplayImage(reader.result);
			setChangesMade(true);
		};
	};

	const handleImageUpload = async () => {
		if (!image) return;

		const imageData = {
			newImage: {
				name: 'profile-image.png',
				content: image.split(',')[1],
			},
		};

		try {
			await updateImage({
				variables: {
					imageUploadInput: imageData,
				},
				onCompleted: async () => {
					setDisplayImage(image);
					setImage(null);
					setChangesMade(false);
					setHasImageChanged(false);

					const { data } = await getUserByToken(localStorageAuthToken);
					if (data?.data?.getMe) {
						setUser(data.data.getMe);
					}

					toast.success('Image updated successfully');
				},
			});
		} catch (error) {
			console.error('Error updating image', error);
			setHasImageChanged(false);
			toast.error('Failed to update image');
		}
	};

	useEffect(() => {
		if (seconds > 0) {
			const timer = setTimeout(() => setSeconds(seconds - 1), 1000);
			return () => clearTimeout(timer);
		} else {
			setCanResend(true);
		}
	}, [seconds]);

	const handleResendMobile = () => {
		setSeconds(89);
		setCanResend(false);
		handleVerifyClick();
	};
	const handleResendEmail = () => {
		setSeconds(89);
		setCanResend(false);
		handleEmailVerifyClick();
	};
	useEffect(() => {
		function handleClickOutside(event) {
			if (countryDropdownRef.current && !countryDropdownRef.current.contains(event.target)) {
				formik.setFieldValue('countryDropdownOpen', false);
			}
			if (stateDropdownRef.current && !stateDropdownRef.current.contains(event.target)) {
				formik.setFieldValue('stateDropdownOpen', false);
			}
			if (cityDropdownRef.current && !cityDropdownRef.current.contains(event.target)) {
				formik.setFieldValue('cityDropdownOpen', false);
			}
			if (genderDropdownRef.current && !genderDropdownRef.current.contains(event.target)) {
				formik.setFieldValue('genderDropdownOpen', false);
			}
		}
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [formik]);

	const handleSelectValueChange = async ({
		id,
		selectedOption,
	}: {
		id: string;
		selectedOption: { value: string; label: string };
	}) => {
		await formik.setFieldValue(id, selectedOption.value);
		await formik.setFieldTouched(id, true);
		await formik.validateField(id);
	};

	return (
		<div className="row mt-5">
			<form onSubmit={formik.handleSubmit}>
				<div className="d-flex align-items-center dp-image-layout">
					<div>
						<img
							src={displayImage || toAbsoluteUrl('/media/header/static-user.svg')}
							alt="Profile_Image"
							style={{ width: '100px', height: '100px', borderRadius: '50%' }}
						/>
					</div>
					<div className="d-flex profile-change-btn">
						{!hasImageChanged ? (
							<div>
								<input
									type="file"
									accept="image/*"
									onChange={handleImageChange}
									style={{ display: 'none' }}
									id="imageUpload"
								/>
								<label htmlFor="imageUpload" className="upload-label ms-md-4 ms-2">
									Upload New
								</label>
							</div>
						) : (
							<Button
								buttonText="Save Image"
								buttonType={ButtonType.PRIMARY_BUTTON}
								classes="g_reg ms-md-4 ms-2 border-0 fs-14 bg-primaryColor white"
								handleClick={handleImageUpload}
								buttonBorderType={ButtonBorderType.PRIMARY_BORDER}
								px="16px"
								py="8px"
							/>
						)}
					</div>
				</div>
				<div className="row gy-lg-4 mt-3">
					<div className="col-12 col-md-6 col-lg-4 mt-md-4 mt-2">
						<label htmlFor="fullname" className="profile-labels g_mid">
							Full Name
						</label>
						<span className="text-danger">*</span>
						<br />
						<input
							className="input-box-style form-control g_reg"
							type="text"
							name="fullname"
							pattern="[A-Za-z\s\(\)]+"
							title="Only Character Allow"
							placeholder="Enter Full Name"
							{...formik.getFieldProps('fullname')}
						/>
						{formik.touched.fullname && formik.errors.fullname ? (
							<div className="text-danger fs-7 mt-1">{String(formik.errors.fullname)}</div>
						) : null}
					</div>
					<div className="col-12 col-md-6 col-lg-4 mt-md-4 mt-2">
						<CommonSelect
							handleChange={(selectedOption: { value: string; label: string }) =>
								handleSelectValueChange({ id: 'gender', selectedOption })
							}
							options={genderOptions}
							id="gender"
							isRequired
							isSearchable={false}
							formik={formik}
							label="Gender"
						/>
					</div>
					<div className="col-12 col-md-6 col-lg-4 mt-md-4 mt-2">
						<label htmlFor="birthdate" className="profile-labels g_mid">
							Birth Date
						</label>
						<span className="text-danger">*</span>
						<br />
						<DatePicker
							clearIcon={null}
							className="form-control g_reg show border-color16"
							onChange={(date) => {
								formik.setFieldValue('birthdate', date);
							}}
							maxDate={new Date()}
							value={formik.values.birthdate}
							dayPlaceholder="dd"
							monthPlaceholder="mm"
							yearPlaceholder="yyyy"
							format="dd-MM-yyyy"
							calendarIcon={
								<img src={toAbsoluteUrl('/media/svg/shapes/calendar.svg')} alt="Calendar" />
							}
						/>
						{formik.touched.birthdate && formik.errors.birthdate ? (
							<div className="text-danger fs-7 mt-1">{String(formik.errors.birthdate)}</div>
						) : null}
					</div>
					<div className="col-12 col-md-6 col-lg-4 mt-md-4 mt-2">
						<label htmlFor="phoneNumber" className="profile-labels g_mid">
							Phone Number
							<span className="text-danger">*</span>
							{user?.isMobileNumberVerified ? (
								<span className="veri-text g_mid ms-2">
									<img src={toAbsoluteUrl('/media/logo/veri-new.png')} alt="" className="me-1" />
									Verified
								</span>
							) : (
								<span
									className={`${isVerifyingMobileOrEmail ? 'disabled' : ''} v-text g_mid ms-2`}
									role="button"
									onClick={handleVerifyClick}>
									Verify
								</span>
							)}
						</label>
						<br />
						<input
							className="input-box-style form-control g_reg"
							type="text"
							name="phoneNumber"
							pattern="[0-9]{10}"
							placeholder="Enter Phone Number"
							{...formik.getFieldProps('phoneNumber')}
							onInput={(e: React.FormEvent<HTMLInputElement>) => {
								const input = e.currentTarget;
								input.value = input.value.replace(/[^0-9]/g, '').slice(0, 10);
							}}
						/>
						{formik.touched.phoneNumber && formik.errors.phoneNumber ? (
							<div className="text-danger fs-7 mt-1">{formik.errors.phoneNumber}</div>
						) : null}
					</div>

					<div className="col-12 col-md-6 col-lg-4 mt-md-4 mt-2">
						<label htmlFor="email" className="profile-labels g_mid">
							Email
							<span className="text-danger">*</span>
							{user?.isVerified ? (
								<span className="veri-text g_mid ms-2">
									<img src={toAbsoluteUrl('/media/logo/veri-new.png')} alt="" className="me-1" />
									Verified
								</span>
							) : (
								<span
									role="button"
									className={`${isVerifyingMobileOrEmail ? 'disabled' : ''} v-text g_mid ms-2`}
									onClick={handleEmailVerifyClick}>
									Verify
								</span>
							)}
						</label>
						<br />
						<input
							className="input-box-style form-control g_reg"
							type="text"
							name="email"
							placeholder="Enter Email"
							{...formik.getFieldProps('email')}
						/>
						{formik.touched.email && formik.errors.email ? (
							<div className="text-danger fs-7 mt-1">{formik.errors.email}</div>
						) : null}
					</div>

					<div className="col-12 col-md-6 col-lg-4 mt-md-4 mt-2">
						<CommonSelect
							handleChange={(selectedOption: { value: string; label: string }) =>
								handleSelectValueChange({ id: 'country', selectedOption })
							}
							options={filteredCountryNames}
							id="country"
							isRequired
							isSearchable={true}
							formik={formik}
							label="Country"
						/>
					</div>

					<div className="col-12 col-md-6 col-lg-4 mt-md-4 mt-2">
						<CommonSelect
							handleChange={(selectedOption: { value: string; label: string }) => {
								handleSelectValueChange({ id: 'state', selectedOption });
								const currentStateCityDetails = countries
									.find((country) =>
										country.countryName.toLowerCase().includes(formik.values.country.toLowerCase()),
									)
									?.states?.find((state) => state?.stateName === selectedOption.value);
								setState(currentStateCityDetails);
								setCity(currentStateCityDetails?.cities?.[0]);
								formik.setFieldValue('city', currentStateCityDetails.cities[0].cityName);
							}}
							options={filteredStateNames}
							id="state"
							isRequired
							isSearchable
							formik={formik}
							label="State"
						/>
					</div>

					<div className="col-12 col-md-6 col-lg-4 mt-md-4 mt-2">
						<CommonSelect
							handleChange={(selectedOption: { value: string; label: string }) => {
								handleSelectValueChange({ id: 'city', selectedOption });
							}}
							options={filteredCityNames}
							id="city"
							isRequired
							isSearchable
							formik={formik}
							label="City"
						/>
					</div>

					<div className="col-12 col-md-6 col-lg-4 mt-md-4 mt-2">
						<label htmlFor="pincode" className="profile-labels g_mid">
							Pincode
						</label>
						<br />
						<input
							className="input-box-style form-control g_reg border-color16"
							type="text"
							name="pincode"
							placeholder="Enter Pincode"
							onInput={(e: React.FormEvent<HTMLInputElement>) => {
								const input = e.currentTarget;
								input.value = input.value.replace(/[^0-9]/g, '').slice(0, 6);
							}}
							{...formik.getFieldProps('pincode')}
						/>
						{formik.touched.pincode && formik.errors.pincode ? (
							<div className="text-danger fs-7 mt-1">{formik.errors.pincode}</div>
						) : null}
					</div>
				</div>

				<div className="row mt-4">
					<div className="col-12">
						<Button
							buttonText={
								<div className="d-flex justify-content-center align-items-center flex-row gap-2">
									{formik.isSubmitting && formik.isValid ? <Loading width={20} /> : <></>}
									Save Changes
								</div>
							}
							buttonType={ButtonType.PRIMARY_BUTTON}
							classes={`fs-16 g_mid ${!formik.dirty ? 'disabled' : ''}`}
							buttonBorderType={ButtonBorderType.BLACK_BORDER}
							px="26px"
							py="8px"
							type="submit"
							disabled={!formik.dirty}
						/>
					</div>
				</div>
			</form>
			{modalOpen && (
				<div className="modal-backdrop">
					<div
						className="modal fade show"
						id="exampleModalToggle"
						aria-hidden="true"
						aria-labelledby="exampleModalToggleLabel"
						tabIndex={-1}
						style={{ display: 'block' }}>
						<div className="modal-dialog modal-dialog-centered mx-auto ">
							<div className="modal-content container">
								<div className="modal-header border-0">
									<div className="mx-auto text-center">
										<img src={toAbsoluteUrl('/media/logo/veri.png')} alt="Verification" />
										<h2 className="veri-title lora">Verification</h2>
										<p className="veri-label veri-otp-layout g_reg">
											Please enter the 6-digit code sent to <br />
											<span className="veri-label-strong">{mobileNo}</span>
										</p>
									</div>
								</div>

								<form>
									<div className="fv-row mb-10 px-4">
										<label className="label-style g_reg">
											OTP <span className="text-danger">*</span>
										</label>
										<input
											className="form-control input-inner-style g_reg"
											name="otpCode"
											placeholder="Enter OTP"
											type="password"
											value={formik.values.otpCode}
											onChange={(e) => {
												const numericValue = e.target.value.replace(/[^0-9]/g, ''); // Allow only numbers
												formik.setFieldValue('otpCode', numericValue); // Update Formik's value
											}}
											onBlur={formik.handleBlur}
										/>
										<div className="veri-desc mt-2 mb-2 text-start">
											{canResend ? (
												<button className="btn resend-btn" onClick={handleResendMobile}>
													Resend OTP
												</button>
											) : (
												<>
													Please wait&nbsp;
													<span className="veri-desc-strong">{seconds}s</span>
													&nbsp;before requesting another code
												</>
											)}
										</div>
										{formik.touched.otpCode && formik.errors.otpCode ? (
											<div className="text-danger fs-7">{formik.errors.otpCode}</div>
										) : null}
									</div>
									<div className="col-12 flex-column-reverse d-md-block d-flex mt-4 mb-4 gap-4 text-center">
										<div className="row">
											<div className="col-6">
												<Button
													buttonText="Cancel"
													buttonType={ButtonType.GREY_BUTTON}
													classes="g_mid fs-16 w-100"
													buttonBorderType={ButtonBorderType.GREY_BORDER}
													disabled={formik.isSubmitting}
													handleClick={() => {
														handleVerificationClose();
													}}
												/>
											</div>
											<div className="col-6">
												<Button
													buttonText="Continue"
													buttonType={ButtonType.PRIMARY_BUTTON}
													classes="g_mid fs-16 w-100"
													buttonBorderType={ButtonBorderType.BLACK_BORDER}
													disabled={!formik.values.otpCode || !!formik.errors.otpCode}
													handleClick={async (e) => {
														e.preventDefault();
														await handleVerifyOtp();
													}}
												/>
											</div>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			)}

			{modalEmailOpen && (
				<div className="modal-backdrop">
					<div
						className="modal fade show"
						id="exampleModalToggle"
						aria-hidden="true"
						aria-labelledby="exampleModalToggleLabel"
						tabIndex={-1}
						style={{ display: 'block' }}>
						<div className="modal-dialog modal-dialog-centered mx-auto ">
							<div className="modal-content container">
								<div className="modal-header border-0">
									<div className="mx-auto text-center">
										<img src={toAbsoluteUrl('/media/logo/veri.png')} alt="Verification" />
										<h2 className="veri-title lora">Verification</h2>
										<p className="veri-label veri-otp-layout g_reg">
											Please enter the 6-digit code sent to
											<br />
											<span className="veri-label-strong">{mobileNo}</span>
										</p>
									</div>
								</div>

								<form>
									<div className="fv-row mb-10 px-4">
										<label className="label-style g_reg">
											OTP <span className="text-danger">*</span>
										</label>
										<input
											className="form-control input-inner-style g_reg"
											name="otp"
											placeholder="Enter OTP"
											type="password"
											value={otp}
											onChange={(e) => setOtp(e.target.value)}
										/>
										<div className="veri-desc mt-2 mb-4 text-start">
											{canResend ? (
												<button className="btn resend-btn" onClick={handleResendEmail}>
													Resend OTP
												</button>
											) : (
												<>
													Please wait&nbsp;
													<span className="veri-desc-strong">{seconds}s</span>
													&nbsp;before requesting another code
												</>
											)}
										</div>
									</div>
									<div className="col-12 mb-4 text-center">
										<button
											className="btn g_mid me-3 new-btn-fg-cancel-layout"
											type="button"
											onClick={handleEmailVerificationClose}
											disabled={formik.isSubmitting}>
											Cancel
										</button>
										<button
											className="btn bg-color2 g_mid new-btn-fg-layout"
											type="button"
											onClick={handleOtpVerification}>
											Continue
										</button>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default PersonalDetail;
