import React, { useEffect } from "react";
import { GiNetworkBars } from "react-icons/gi";
import { Footer } from "../../pages/layout/footer/Footer";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import { verifyEmail } from "../../pages/auth/core/_requests";
import { toAbsoluteUrl } from "../../assets/helpers/AssetHelpers";

function EmailVerification() {
  const navigate = useNavigate();
  const { token } = useParams();
  const fetchData = async (token: any) => {
    const record = await verifyEmail(token);
  };
  useEffect(() => {
    fetchData(token);
  }, []);
  const startover = () => {
    navigate("/");
  };

  return (
    <>
      <section className="py-lg-4 py-1"></section>
      <section className="section px-xl-4 px-2">
        <div className="">
          <div className="row">
            <div className="col-12">
              <div className="row justify-content-lg-center align-items-lg-center p-4">
                <div className="col-12 ">
                  <div className="pb-lg-5 text-center text-lg-center">
                    <div className="course-head fs-2 font-title text-capitalize">
                      thanks for email verification
                    </div>
                    <img
                      src={toAbsoluteUrl(
                        "/media/icons/duotune/general/success.svg",
                      )}
                      alt="celebration"
                      height="140"
                      width="140"
                      className="my-3 img-fluid"
                    />
                    {/* <img src="../media/preRecord/celebration.png" alt="celebration" height="140" width="140" className="my-3 img-fluid" /> */}
                    <div className="d-flex w-100 justify-content-center w-60 mx-auto">
                      <div
                        className="btn btn- fs-18 font-switzer w-30 btn-outline-color1 my-lg-2"
                        id="start-over"
                        onClick={() => startover()}
                      >
                        Start Now
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default EmailVerification;
