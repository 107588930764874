import React, { useEffect, useState } from 'react';
import { useQuery, gql } from '@apollo/client';
import './NotificationList.css';
import { toAbsoluteUrl } from 'assets/helpers/AssetHelpers';
import Pagination from './Pagination';
import useNotification from 'hooks/useNotification';
import { useNotificationContext } from 'context/NotificationContext';
import useIsSmallScreen from 'hooks/useIsSmallScreen';
import IndividualNotification from '../CommonComponents/IndividualNotification';

const NotificationList: React.FC = () => {
	const [currentPage, setCurrentPage] = useState(1);
	const isSmallScreen = useIsSmallScreen(992);
	const recordsPerPage = 10;
	const { studentNotifications, notificationPagination } = useNotificationContext();
	const { getStudentNotifications } = useNotification();
	useEffect(() => {
		getStudentNotifications().catch((error) => {
			console.error('Error fetching notifications:', error);
			return <p className="text-center">Error: {error.message}</p>;
		});
	}, []);

	const notifications = studentNotifications
		.slice()
		.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());

	const totalRecords = notificationPagination.totalRecords;
	const totalPages = Math.ceil(totalRecords / recordsPerPage);

	const indexOfLastRecord = currentPage * recordsPerPage;
	const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
	const currentNotifications = notifications.slice(indexOfFirstRecord, indexOfLastRecord);
	const mobileNotificationsToShow = notifications.slice(0, currentPage * recordsPerPage);

	return isSmallScreen ? (
		<section style={{ marginTop: 88 }}>
			<div>
				{mobileNotificationsToShow.map((notification) => (
					<IndividualNotification notification={notification} key={notification.id} />
				))}
			</div>
			{currentPage != totalPages && (
				<div className="w-100 d-flex justify-content-center p-3">
					<span role="button" onClick={() => setCurrentPage((prevState) => prevState + 1)}>
						Show More
					</span>
				</div>
			)}
		</section>
	) : (
		<section className="my-5 py-5">
			<div className="container">
				<div className="row">
					<div className="col-lg-10 layout-set notification-table  overflow-x-auto">
						<table className="notification-table-data w-100">
							<thead>
								<tr className="lora noti-table-title">
									<th scope="col">SR NO</th>
									<th scope="col">Icon</th>
									<th scope="col">Title</th>
									<th scope="col">Message</th>
									<th scope="col">Created At</th>
								</tr>
							</thead>
							<tbody>
								{currentNotifications.length > 0 ? (
									currentNotifications.map((notification, index) => (
										<tr className="g_reg" key={notification.id}>
											<td scope="row">{(currentPage - 1) * recordsPerPage + index + 1}</td>
											<td>
												<div>
													<img
														src={toAbsoluteUrl('/media/notification-images/n1.svg')}
														width="40px"
														alt="Icon"
													/>
												</div>
											</td>
											<td>{notification.title}</td>
											<td>{notification.message}</td>
											<td>{new Date(notification.createdAt).toLocaleString()}</td>
										</tr>
									))
								) : (
									<tr>
										<td colSpan={5} className="text-center">
											<div>
												<div className="d-flex justify-content-center">
													<img
														src={toAbsoluteUrl('/media/notification-images/no-notification.svg')}
														alt="No Notifications"
													/>
												</div>
												<div className="no-notifications-title lora my-3 text-center">
													No Notifications Yet
												</div>
												<div className="no-notifications-desc g_mid my-3 text-center">
													You have no notifications right now, come back later
												</div>
											</div>
										</td>
									</tr>
								)}
							</tbody>
						</table>
						<Pagination
							currentPage={currentPage}
							totalPages={totalPages}
							onPageChange={setCurrentPage}
						/>
					</div>
				</div>
			</div>
		</section>
	);
};

export default NotificationList;
