import React from "react";

const CourseSidebar = () => {
  return (
    <>
      <div className="col-12 col-lg-4">
        <div className="course-content  my-lg-4 ">
          <div className="fs-3 font-title  color5 fw-bold">Course Content</div>
          <div className="color2 font-switzer fs-14">3 sessions | 30mins</div>
        </div>
        <div
          className="live_course_sections border p-lg-3 border-color3"
          id="accordionExample"
        >
          <div
            className="nav flex-column nav-pills"
            id="v-pills-tab"
            role="tablist"
            aria-orientation="vertical"
          >
            <button
              className="nav-link nav_link"
              data-tag="start"
              id="v-pills-start-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-start"
              type="button"
              role="tab"
              aria-controls="v-pills-start"
              aria-selected="true"
            >
              <div className="topic-success border-0 border-bottom border-1 border-color3 mb-lg-2">
                <div className="px-lg-1 d-flex align-items-center w-100 py-2 accordion_button">
                  <div className="w-20">
                    <div
                      className="radialProgressBar accordion_proccess progress-reverse-success progress-100"
                      id="start_radialProgressBar"
                    >
                      <div className="overlay reverse d-flex justify-content-center align-items-center color1 fs-6">
                        1
                      </div>
                    </div>
                  </div>
                  <div className="w-80">
                    <div className="color5 font-switzer fs-5 fw-medium">
                      Course Start
                    </div>
                    <div className="color2 font-switzer fs-6">
                      3 sessions | 30mins
                    </div>
                  </div>
                </div>
              </div>
            </button>
            <button
              className="nav-link nav_link active"
              data-tag="live"
              id="v-pills-live-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-live"
              type="button"
              role="tab"
              aria-controls="v-pills-live"
              aria-selected="true"
            >
              <div className="topic-primary border-0 border-bottom border-1 border-color3 mb-lg-2">
                <div className="px-lg-1 d-flex align-items-center w-100 py-2 accordion_button">
                  <div className="w-20">
                    <div
                      className="radialProgressBar accordion_proccess progress-reverse-primary progress-100"
                      id="live_radialProgressBar"
                    >
                      <div className="overlay reverse d-flex justify-content-center align-items-center color1 fs-6">
                        2
                      </div>
                    </div>
                  </div>
                  <div className="w-80">
                    <div className="color5 font-switzer fs-5 fw-medium">
                      Course Live
                    </div>
                    <div className="color2 font-switzer fs-6">
                      3 sessions | 30mins
                    </div>
                  </div>
                </div>
              </div>
            </button>
            <button
              className="nav-link nav_link"
              data-tag="topic"
              id="v-pills-topic-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-topic"
              type="button"
              role="tab"
              aria-controls="v-pills-topic"
              aria-selected="true"
            >
              <div className="topic-secondary border-0 border-bottom border-1 border-color3 mb-lg-2">
                <div className="px-lg-1 d-flex align-items-center w-100 py-2 accordion_button">
                  <div className="w-20">
                    <div
                      className="radialProgressBar accordion_proccess progress-reverse-secondary progress-100"
                      id="topic_radialProgressBar"
                    >
                      <div className="overlay reverse d-flex justify-content-center align-items-center color1 fs-6">
                        3
                      </div>
                    </div>
                  </div>
                  <div className="w-80">
                    <div className="color5 font-switzer fs-5 fw-medium">
                      Course Intro
                    </div>
                    <div className="color2 font-switzer fs-6">
                      3 sessions | 30mins
                    </div>
                  </div>
                </div>
              </div>
            </button>
            <button
              className="nav-link nav_link"
              data-tag="next"
              id="v-pills-next-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-next"
              type="button"
              role="tab"
              aria-controls="v-pills-next"
              aria-selected="true"
            >
              <div className="topic-secondary border-0 border-bottom border-1 border-color3 mb-lg-2">
                <div className="px-lg-1 d-flex align-items-center w-100 py-2 accordion_button">
                  <div className="w-20">
                    <div
                      className="radialProgressBar accordion_proccess progress-reverse-secondary progress-100"
                      id="next_radialProgressBar"
                    >
                      <div className="overlay reverse d-flex justify-content-center align-items-center color1 fs-6">
                        4
                      </div>
                    </div>
                  </div>
                  <div className="w-80">
                    <div className="color5 font-switzer fs-5 fw-medium">
                      Next Course
                    </div>
                    <div className="color2 font-switzer fs-6">
                      3 sessions | 30mins
                    </div>
                  </div>
                </div>
              </div>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default CourseSidebar;
