import React, { useCallback, useEffect, useState } from "react";
import BookmarkCard from "./BookmarkCard";
import { GETWISHLISTS, REMOVEFROMWISHLIST } from "./core/requests";
import { toAbsoluteUrl } from "../../assets/helpers/AssetHelpers";
import toast from "react-hot-toast";
import { useMutation, useQuery } from "@apollo/client";
import Loader from "../common/Spinner/Spinner";
const Bookmark = () => {
  const [commit] = useMutation(REMOVEFROMWISHLIST);
  const [currentPage, setCurrentPage] = useState(0);
  const [hasMoreData, setHasMoreData] = useState(false);
  const {
    data: wishList,
    loading,
    refetch,
    fetchMore,
  } = useQuery(GETWISHLISTS, {
    variables: {
      paginate: {
        page: currentPage,
        per_page: 10,
      },
    },
  });
  const wishListRecord: any = wishList?.getWishlists?.records ?? [];
  const handleLoadMore = () => {
    const nextPage = currentPage + 1;
    fetchMore({
      variables: {
        paginate: {
          page: nextPage,
          per_page: 10,
        },
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        const newRecords = fetchMoreResult.getWishlists.records.filter(
          (newRecord: { courseId: any }) =>
            !prev.getWishlists.records.some(
              (prevRecord: { courseId: any }) =>
                prevRecord.courseId === newRecord.courseId,
            ),
        );
        setHasMoreData(true);
        return {
          getWishlists: {
            ...prev.getWishlists,
            records: [...prev.getWishlists.records, ...newRecords],
            pagination: fetchMoreResult.getWishlists.pagination,
          },
        };
      },
    });
  };
  useEffect(() => {
    refetch();
  }, [currentPage]);
  const handleDeleteWishList = async (id: any) => {
    commit({
      variables: {
        courseId: id,
      },
      onCompleted: () => {
        toast.success("Successfully removed from wishlist!", {
          style: {
            padding: "16px",
          },
        });
        refetch();
      },
      onError: (err: any) => {
        toast.error(err.message);
      },
    });
  };
  return (
    <>
      <section className="section px-xl-4 -x-lg-3 px-2 d-lg-block d-md-block d-none">
        <div className="">
          <div className="row">
            <div className="col-lg-12">
              <div
                className="d-lg-flex justify-content-lg-between align-items-lg-center mx-3 mb-2"
                style={{ marginTop: "-5%" }}
              >
                <h1 className="font-title fw-bold fs-2 head1">Bookmark</h1>
              </div>
              {loading ? (
                <>
                  <Loader />
                </>
              ) : (
                <>
                  {wishListRecord.length > 0 ? (
                    <div className="container mx-0">
                      <div className="row">
                        {wishListRecord?.map((value: any) => {
                          return (
                            <BookmarkCard
                              value={value}
                              onDeleteWishList={handleDeleteWishList}
                            />
                          );
                        })}
                      </div>
                      {wishListRecord.length > 9 &&
                        wishListRecord.length <
                          wishList?.getWishlists?.pagination?.totalRecords && (
                          <div className="d-grid border round mt-2">
                            <button
                              className="btn text-muted-dark"
                              type="button"
                              onClick={handleLoadMore}
                            >
                              Load more
                            </button>
                          </div>
                        )}
                    </div>
                  ) : (
                    <div className="row justify-content-center mx-5 align-items-center">
                      <div
                        className="col-12 text-center"
                        style={{ marginTop: "8%" }}
                      >
                        <img
                          src={toAbsoluteUrl("../media/logo/bookmarkimage.png")}
                          alt="bookmark"
                          height="100"
                          width="100"
                          className="my-3 img-fluid col-12 col-lg-1"
                        />
                        <div className="my-3">
                          <p className="fw-bold fs-4 font-title head1">
                            No Bookmarks Yet!!
                          </p>
                          <p className="fs-6 text-muted">
                            When you set as a Bookmarks, we will show up here.
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </section>

      <section className="section px-xl-4 -x-lg-3 px-2 d-md-none d-lg-none d-block">
        <div className="">
          <div className="row">
            <div className="col-lg-12">
              <div
                className="d-lg-flex justify-content-lg-between align-items-lg-center mx-3 mb-2"
                style={{ marginTop: "-5%" }}
              >
                <h1 className="font-title fw-bold fs-2 head1">Bookmark</h1>
              </div>
              {wishListRecord.length > 0 ? (
                <div className="container mx-0">
                  <div className="row">
                    {wishListRecord?.map((value: any) => {
                      return (
                        <BookmarkCard
                          value={value}
                          onDeleteWishList={handleDeleteWishList}
                        />
                      );
                    })}
                  </div>
                  {wishListRecord.length > 9 &&
                    wishListRecord.length <
                      wishList?.getWishlists?.pagination?.totalRecords && (
                      <div className="d-grid border round mt-2">
                        <button
                          className="btn text-muted-dark"
                          type="button"
                          onClick={handleLoadMore}
                        >
                          Load more
                        </button>
                      </div>
                    )}
                </div>
              ) : (
                <div className="row justify-content-center mx-5 align-items-center">
                  <div
                    className="col-12 text-center"
                    style={{ marginTop: "8%" }}
                  >
                    <img
                      src={toAbsoluteUrl("../media/logo/bookmarkimage.png")}
                      alt="bookmark"
                      height="100"
                      width="100"
                      className="my-3 img-fluid col-8"
                    />
                    <div className="my-3">
                      <p className="fw-bold fs-4 font-title head1">
                        No Bookmarks Yet!!
                      </p>
                      <p className="fs-6 text-muted">
                        When you set as a Bookmarks, we will show up here.
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Bookmark;
