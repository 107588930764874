import { useEffect, useRef, useState } from "react";
import { toAbsoluteUrl } from "../../assets/helpers/AssetHelpers";
import OrderCard from "./OrderCard";
import { GETSTUDENTORDERHISTORY } from "./core/requests";
import { useQuery } from "@apollo/client";
import dayjs, { Dayjs } from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import { set } from "lodash";
import Loader from "../common/Spinner/Spinner";
// import "./order.css"
enum StatusName {
  PENDING = "PENDING",
  PAID = "PAID",
  FAILED = "FAILED",
}
type PriceItem = {
  id: number;
  value: string;
  name: StatusName; // This enforces that name can only be "PAID" or "FREE"
};
const price: PriceItem[] = [
  { id: 1, value: "Pending", name: StatusName.PENDING },
  { id: 2, value: "Paid", name: StatusName.PAID },
  { id: 3, value: "Failed", name: StatusName.FAILED },
];
const OrderPaymentDetails = (props: any) => {
  const [whereCondition, setWhereCondition] = useState({});
  const [selectedOption, setSelectedOption] = useState("Sort By Date");
  const [selectedSort, setSelectedSort] = useState("PAID");
  const [currentPage, setCurrentPage] = useState(0);
  const [startDate, setStartDate] = useState<moment.Moment | null>(null);
  const [datePickerVisible, setDatePickerVisible] = useState(false);
  const [endDate, setEndDate] = useState<moment.Moment | null>(null);
  const {
    data: studentOrderHistory,
    loading,
    fetchMore,
    refetch,
  } = useQuery(GETSTUDENTORDERHISTORY, {
    variables: {
      where: whereCondition,
      paginate: {
        per_page: 10,
        page: currentPage,
      },
    },
  });
  const allStudentHistory =
    studentOrderHistory?.getStudentOrderHistory?.records ?? [];
  const allStudentrecord = allStudentHistory;
  const datePickerContainerRef = useRef<HTMLDivElement | null>(null);
  // useEffect(() => {
  //     const handleClickOutside = (event:any) => {
  //         if (datePickerContainerRef.current && !datePickerContainerRef?.current?.contains(event.target)) {
  //             setDatePickerVisible(false);
  //         }
  //     };
  //    document.addEventListener('mousedown', handleClickOutside);
  //     return () => {
  //         document.removeEventListener('mousedown', handleClickOutside);
  //     };
  // }, []);
  const toggleDatePicker = () => {
    setDatePickerVisible((prevVisible) => !prevVisible);
  };

  const handleSortChange = (e: any) => {
    const selectedValue = e.target.value;
    if (selectedValue === "All") {
      setWhereCondition({});
    } else {
      setWhereCondition({ status: { eq: selectedValue } });
      setSelectedSort(selectedValue);
    }
  };
  const handleTodayClick = () => {
    const today = moment().format("YYYY-MM-DD");
    const conditions = {
      transaction_date_time: { like: `%${today}%` },
    };
    const todayMoment = moment(today);
    // console.log("todayMoment handleTodayClick",todayMoment)
    // setStartDate(todayMoment);
    // setEndDate(todayMoment);
    setDatePickerVisible(false);
    setWhereCondition(conditions);
  };
  const handleYesterDayClick = () => {
    const today = moment().subtract(1, "day");
    const tomorrow = moment().add(1, "day").format("YYYY-MM-DD");
    const conditions = {
      transaction_date_time: { like: `%${today.format("YYYY-MM-DD")}%` },
    };
    const todayMoment = moment(today);
    // console.log("todayMoment handleYesterDayClick",todayMoment,tomorrow)
    // setStartDate(todayMoment);
    // setEndDate(moment());
    setDatePickerVisible(false);
    setWhereCondition(conditions);
    updateSelectedOption(today.startOf("day"), today.endOf("day"));
  };
  const handleLastMonthClick = () => {
    const today = moment().format("yyyy-MM");
    const conditions = {
      transaction_date_time: { like: `%${today}%` },
    };
    const todayMoment = moment(today);
    // console.log("handleLastMonthClick",todayMoment)
    // setStartDate(todayMoment);
    // setEndDate(todayMoment);
    setDatePickerVisible(false);
    setWhereCondition(conditions);
  };
  const generateDateBetweenCondition = (start: string, end: string) => {
    return {
      between: [start, end],
    };
  };
  const handleStartDateChange = (date: moment.Moment | null) => {
    setStartDate(date);
    if (endDate) {
      const conditions = {
        transaction_date_time: generateDateBetweenCondition(
          date!.format("DD-MM-YYYY"),
          endDate.format("YYYY-MM-DD"),
        ),
      };
      setWhereCondition(conditions);
    }
    updateSelectedOption(date, endDate);
  };
  const handleEndDateChange = (date: moment.Moment | null) => {
    if (startDate && date && date.isBefore(startDate)) {
      setEndDate(startDate);
    } else {
      setEndDate(date);
    }
    if (startDate && endDate) {
      const conditions = {
        transaction_date_time: generateDateBetweenCondition(
          startDate.format("YYYY-MM-DD"),
          date!.format("YYYY-MM-DD"),
        ),
      };
      setWhereCondition(conditions);
    }
    updateSelectedOption(startDate, date);
    setDatePickerVisible(false);
  };
  const handleLastSixMonthClick = () => {
    const today = moment();
    const startOfSixMonthsAgo = today
      .subtract(6, "months")
      .startOf("month")
      .format("YYYY-MM-DD");
    const endOfToday = moment().format("YYYY-MM-DD");
    const conditions = {
      transaction_date_time: generateDateBetweenCondition(
        startOfSixMonthsAgo,
        endOfToday,
      ),
    };
    // console.log("endOfToday",endOfToday,startOfSixMonthsAgo)
    // setStartDate(moment(startOfSixMonthsAgo));
    // setEndDate(moment(endOfToday));
    setDatePickerVisible(false);
    setWhereCondition(conditions);
  };
  const handleResetClick = () => {
    setSelectedOption("Sort By Date");
    setWhereCondition({});
    setStartDate(null);
    setEndDate(null);
    setDatePickerVisible(false);
  };
  const updateSelectedOption = (
    startDate: moment.Moment | null,
    endDate: moment.Moment | null,
  ) => {
    const today = moment();
    const startOfSixMonthsAgo = today.subtract(6, "months").startOf("month");
    if (startDate && endDate) {
      if (
        startDate.isSame(moment(), "day") &&
        endDate.isSame(moment(), "day")
      ) {
        setSelectedOption("Today");
      } else if (
        startDate?.isSame(moment().subtract(1, "day"), "day") &&
        endDate.isSame(moment().subtract(1, "day"), "day")
      ) {
        setSelectedOption("Yesterday");
      } else if (
        startDate.isSame(
          moment().subtract(6, "months").startOf("month"),
          "day",
        ) &&
        endDate.isSame(moment(), "day")
      ) {
        setSelectedOption("Last 6 Months");
      } else if (
        startDate.isSame(moment().startOf("month"), "day") &&
        endDate.isSame(moment().endOf("month"), "day")
      ) {
        setSelectedOption("This Month");
      } else if (
        startDate.isSame(moment().subtract(15, "days").startOf("day"), "day") &&
        endDate.isSame(moment().subtract(10, "days").endOf("day"), "day")
      ) {
        setSelectedOption("Last 10-15 Days");
      }
    }
  };
  const handleLoadMore = () => {
    const nextPage = currentPage + 1;
    fetchMore({
      variables: {
        paginate: {
          page: nextPage,
          per_page: 10,
        },
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        const newRecords =
          fetchMoreResult.getStudentOrderHistory?.records.filter(
            (newRecord: { id: any }) =>
              !prev.getStudentOrderHistory?.records.some(
                (prevRecord: { id: any }) => prevRecord.id === newRecord.id,
              ),
          );
        console.log(
          "[...prev.getStudentOrderHistory?.records, ...newRecords]",
          [...prev.getStudentOrderHistory?.records, ...newRecords],
        );
        return {
          getStudentOrderHistory: {
            ...prev.getStudentOrderHistory,
            records: [...prev.getStudentOrderHistory?.records, ...newRecords],
            pagination: fetchMoreResult.getStudentOrderHistory?.pagination,
          },
        };
      },
    });
  };
  useEffect(() => {
    refetch();
  }, [currentPage]);
  return (
    <>
      <section className="section px-xl-4 -x-lg-3 px-2 d-lg-block d-md-block d-none">
        <div className="">
          <div className="row">
            <section className="order-sections">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-12" style={{ marginTop: "-5%" }}>
                    <div className="d-lg-flex justify-content-lg-between align-items-lg-center">
                      <h1 className="font-title fw-bold fs-2 head1">
                        Orders & Payments
                      </h1>
                      <div className="row w-40">
                        <div className="col-8">
                          <div>
                            <div
                              className="dropdown position-absolute z-3"
                              style={{
                                backgroundColor: "white",
                                width: "300px",
                              }}
                            >
                              <button
                                className="form-select position-relative border-color3 fs-16 font-switzer shadow-none color2 bg-transparent text-start"
                                type="button"
                                aria-expanded={datePickerVisible}
                                onClick={toggleDatePicker}
                              >
                                {selectedOption === "Sort By Date"
                                  ? "Sort By Date"
                                  : selectedOption}
                              </button>
                              {datePickerVisible && (
                                <div
                                  ref={datePickerContainerRef}
                                  className="row gx-lg-2 mb-lg-2"
                                >
                                  <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                  >
                                    <div className="col-6">
                                      <label
                                        htmlFor="from-date"
                                        className="form-label mb-lg-0 color2"
                                      ></label>
                                      <div>
                                        <DatePicker
                                          label="From"
                                          value={startDate}
                                          onChange={(
                                            date: moment.Moment | null,
                                          ) => handleStartDateChange(date)}
                                          format="DD-MM-YYYY"
                                          views={["month", "year", "day"]}
                                          // minDate={moment()}
                                          disableFuture={true}
                                        />
                                      </div>
                                    </div>
                                    <div className="col-6">
                                      <label
                                        htmlFor="to-date"
                                        className="form-label mb-lg-0 color2"
                                      ></label>
                                      <div className="input-group border-color3 mb-lg-1">
                                        <DatePicker
                                          label="To"
                                          value={endDate}
                                          onChange={(
                                            date: moment.Moment | null,
                                          ) => handleEndDateChange(date)}
                                          format="DD-MM-YYYY"
                                          views={["month", "year", "day"]}
                                          disableFuture={true}
                                        />
                                      </div>
                                    </div>
                                  </LocalizationProvider>
                                  <button
                                    className="border-muted py-2 fs-16 font-switzer shadow-none color2 bg-transparent text-center"
                                    type="button"
                                    onClick={() => {
                                      setSelectedOption("Today");
                                      handleTodayClick();
                                    }}
                                  >
                                    Today
                                  </button>
                                  <button
                                    className="border-muted py-2 fs-16 font-switzer shadow-none color2 bg-transparent text-center"
                                    type="button"
                                    onClick={() => {
                                      setSelectedOption("Yesterday");
                                      handleYesterDayClick();
                                    }}
                                  >
                                    Yesterday
                                  </button>
                                  <button
                                    className="border-muted py-2 fs-16 font-switzer shadow-none color2 bg-transparent text-center"
                                    type="button"
                                    onClick={() => {
                                      setSelectedOption("This Month");
                                      handleLastMonthClick();
                                    }}
                                  >
                                    This Month
                                  </button>
                                  <button
                                    className="border-muted py-2 fs-16 font-switzer shadow-none color2 bg-transparent text-center"
                                    type="button"
                                    onClick={() => {
                                      setSelectedOption("Last 6 Months");
                                      handleLastSixMonthClick();
                                    }}
                                  >
                                    Last 6 Months
                                  </button>
                                  <button
                                    className="border-muted py-2 fs-16 font-switzer shadow-none color2 bg-transparent text-center"
                                    type="button"
                                    onClick={handleResetClick}
                                  >
                                    Reset
                                  </button>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-4">
                          <div className="dropdown" style={{ width: "150px" }}>
                            <select
                              className="form-select course-select-filter shadpw-none me-3 me-lg-0 "
                              aria-label="Default select example"
                              onChange={handleSortChange}
                            >
                              <option selected value="All">
                                All
                              </option>
                              {price.map((value: any) => {
                                return (
                                  <>
                                    <option value={value.name}>
                                      {value.value}
                                    </option>
                                  </>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    {loading ? (
                      <div
                        className="d-flex justify-content-center align-items-center"
                        style={{ minHeight: "300px" }}
                      >
                        <Loader />
                      </div>
                    ) : (
                      <>
                        {allStudentrecord?.length > 0 ? (
                          <div className="order-list-sections my-lg-4">
                            {allStudentrecord?.map((value: any) => (
                              <OrderCard order={value} />
                            ))}
                            {allStudentrecord.length > 9 &&
                              allStudentrecord.length <
                                studentOrderHistory?.getStudentOrderHistory
                                  ?.pagination?.totalRecords && (
                                <div className="d-grid border round mt-2">
                                  <button
                                    className="btn text-muted-dark"
                                    type="button"
                                    onClick={handleLoadMore}
                                  >
                                    Load more
                                  </button>
                                </div>
                              )}
                          </div>
                        ) : (
                          <div className="row">
                            <div className="col-12">
                              {/* <div className="row justify-content-lg-center align-items-lg-center p-4">
                                                                <div className="col-12 ">
                                                                    <div className="pb-lg-5 text-lg-center">
                                                                        <img src={toAbsoluteUrl("../media/logo/wishlistempty.jpg")} alt="celebration" height="100" width="100" className="my-3 img-fluid col-12 col-lg-1" />
                                                                        <div className="d-flex justify-content-center mx-auto">
                                                                            <div className="btn fs-18 font-subtitle my-lg-2" id="start-over">
                                                                                <p className='fw-bold fs-4 font-title'>No Orders Available Yet!!</p>
                                                                                <p className='fs-6'>When you set as a Orders, we will show up here.</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div> */}
                              <div className="row justify-content-center mx-5 align-items-center">
                                <div
                                  className="col-12 text-center"
                                  style={{ marginTop: "8%" }}
                                >
                                  <img
                                    src={toAbsoluteUrl(
                                      "../media/logo/bookmarkimage.png",
                                    )}
                                    alt="bookmark"
                                    height="100"
                                    width="100"
                                    className="my-3 img-fluid col-12 col-lg-1"
                                  />
                                  <div className="my-3">
                                    <p className="fw-bold fs-4 font-title head1">
                                      No Orders Available Yet!!
                                    </p>
                                    <p className="fs-6 text-muted">
                                      When you set as a Orders, we will show up
                                      here.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </section>

      <section className="section px-xl-4 -x-lg-3 px-2 d-lg-none d-md-none d-block">
        <div className="">
          <div className="row">
            <section className="order-sections">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-12" style={{ marginTop: "-5%" }}>
                    <div className="d-lg-flex justify-content-lg-between align-items-lg-center">
                      <h1 className="font-title fw-bold fs-2 head1 text-center py-3">
                        Orders & Payments
                      </h1>
                      <div className="row w-40">
                        <div className="col-12">
                          <div>
                            <div
                              className="dropdown z-2"
                              style={{
                                backgroundColor: "white",
                                width: "300px",
                              }}
                            >
                              <button
                                className="form-select position-relative border-color3 fs-16 font-switzer shadow-none color2 bg-transparent text-start"
                                type="button"
                                aria-expanded={datePickerVisible}
                                onClick={toggleDatePicker}
                              >
                                Sort By Date
                              </button>
                              {datePickerVisible && (
                                <div
                                  ref={datePickerContainerRef}
                                  className="row gx-lg-2 mb-lg-2"
                                >
                                  <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                  >
                                    <div className="col-6">
                                      <label
                                        htmlFor="from-date"
                                        className="form-label mb-lg-0 color2"
                                      ></label>
                                      <div>
                                        <DatePicker
                                          label="From"
                                          value={startDate}
                                          onChange={(
                                            date: moment.Moment | null,
                                          ) => handleStartDateChange(date)}
                                        />
                                      </div>
                                    </div>
                                    <div className="col-6">
                                      <label
                                        htmlFor="to-date"
                                        className="form-label mb-lg-0 color2"
                                      ></label>
                                      <div className="input-group border-color3 mb-lg-1">
                                        <DatePicker
                                          label="To"
                                          value={endDate}
                                          onChange={(
                                            date: moment.Moment | null,
                                          ) => handleEndDateChange(date)}
                                        />
                                      </div>
                                    </div>
                                  </LocalizationProvider>
                                  <button
                                    className="border-muted py-2 fs-16 font-switzer shadow-none color2 bg-transparent text-center"
                                    type="button"
                                    onClick={handleTodayClick}
                                  >
                                    Today
                                  </button>
                                  <button
                                    className="border-muted py-2 fs-16 font-switzer shadow-none color2 bg-transparent text-center"
                                    type="button"
                                    onClick={handleYesterDayClick}
                                  >
                                    Yesterday
                                  </button>
                                  <button
                                    className="border-muted py-2 fs-16 font-switzer shadow-none color2 bg-transparent text-center"
                                    type="button"
                                    onClick={handleLastMonthClick}
                                  >
                                    This Month
                                  </button>
                                  <button
                                    className="border-muted py-2 fs-16 font-switzer shadow-none color2 bg-transparent text-center"
                                    type="button"
                                    onClick={handleLastSixMonthClick}
                                  >
                                    Last 6 Months
                                  </button>
                                  <button
                                    className="border-muted py-2 fs-16 font-switzer shadow-none color2 bg-transparent text-center"
                                    type="button"
                                    onClick={handleResetClick}
                                  >
                                    Reset
                                  </button>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-12 mt-3">
                          <div className="dropdown" style={{ width: "150px" }}>
                            <select
                              className="form-select course-select-filter shadpw-none ms-1 me-lg-0 "
                              aria-label="Default select example"
                              onChange={handleSortChange}
                            >
                              <option selected value="All">
                                All
                              </option>
                              {price.map((value: any) => {
                                return (
                                  <>
                                    <option value={value.name}>
                                      {value.value}
                                    </option>
                                  </>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    {loading ? (
                      <div
                        className="d-flex justify-content-center align-items-center"
                        style={{ minHeight: "300px" }}
                      >
                        <Loader />
                      </div>
                    ) : (
                      <>
                        {allStudentrecord?.length > 0 ? (
                          <div className="order-list-sections my-lg-4">
                            {allStudentrecord?.map((value: any) => (
                              <OrderCard order={value} />
                            ))}
                            {allStudentrecord.length > 9 &&
                              allStudentrecord.length <
                                studentOrderHistory?.getStudentOrderHistory
                                  ?.pagination?.totalRecords && (
                                <div className="d-grid border round mt-2">
                                  <button
                                    className="btn text-muted-dark"
                                    type="button"
                                    onClick={handleLoadMore}
                                  >
                                    Load more
                                  </button>
                                </div>
                              )}
                          </div>
                        ) : (
                          <div className="row">
                            <div className="col-12">
                              <div className="row justify-content-lg-center align-items-center p-4">
                                <div className="col-12 ">
                                  <div className="pb-lg-5 text-center">
                                    <img
                                      src={toAbsoluteUrl(
                                        "../media/logo/wishlistempty.jpg",
                                      )}
                                      alt="celebration"
                                      height="100"
                                      width="100"
                                      className="my-3 img-fluid col-6 col-lg-1"
                                    />
                                    <div className="d-flex justify-content-center mx-auto">
                                      <div
                                        className="btn fs-18 font-subtitle my-lg-2"
                                        id="start-over"
                                      >
                                        <p className="fw-bold fs-4 font-title">
                                          No Orders Available Yet!!
                                        </p>
                                        <p className="fs-6">
                                          When you set as a Orders, we will show
                                          up here.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </section>
    </>
  );
};

export default OrderPaymentDetails;
