import React, { useState } from "react";
import { toAbsoluteUrl } from "../../assets/helpers/AssetHelpers";
import CompletedCourse from "./CompletedCourse";
import OnGoingCourse from "./OnGoingCourse";
import "./MyCourses.css";
const MyCourses = () => {
  const [activeTab, setActiveTab] = useState("ongoing");
  const handleTabChange = (tab: any) => {
    setActiveTab(tab);
  };
  return (
    <>
      <div>lfdngfdjon</div>
    </>
  );
};

export default MyCourses;
