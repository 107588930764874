import React from "react";

export const Terms = () => {
  return (
    <section className="section">
      <div className="container col-lg-8">
        <div className="row">
          <div className="mt-4">
            <h1 className="fw-bold font-title">Terms of Use</h1>
            <h4 className="font-subtitle mt-4">Terms of Use</h4>
          </div>
          <div className="mt-4">
            <div
              className="ql-editor"
              data-gramm="false"
              contentEditable="false"
            >
              <p>
                <span className="fw-bold text-muted-dark fs-5">
                  Terms &amp; Conditions
                </span>
              </p>
              <p>
                This document is an electronic record in terms of the
                Information Technology Act, 2000 and Rules made thereunder and
                other applicable law as amended from time to time. This document
                is published in accordance with the provisions of Rule 3 (1) of
                the Information Technology (Intermediaries guidelines) Rules,
                2011 that require publishing the rules and regulations, privacy
                policy and terms for access or usage of https://SmartLearn.com/
                website; and “SmartLearn” applications for mobile and handheld
                devices (collectively called the “Platform”).
              </p>
              <p>
                Please read these Terms &amp; Conditions of Service (hereinafter
                referred to as “Terms of Service / Terms”) carefully before
                accessing or using the Platform. You agree to be bound by the
                terms described herein. If you do not agree to these terms,
                please do not use the Platform. Using the Platform will be
                considered as a deemed acceptance of these Platform Terms. This
                agreement does not require a physical/digital signature. This
                agreement shall not be invalidated solely on the ground that it
                is not physically signed.
              </p>
              <p>
                For the purpose of these Terms, wherever the context so requires
                “you” or “User” shall mean any natural or legal person who
                accesses and registers with the Platform to avail services
                rendered by the Platform.
              </p>
              <p></p>
              <p>
                <span className="fw-bold text-muted-dark fs-5">
                  Content &amp; Purpose
                </span>
              </p>
              <p>
                The Platform is owned and operated by Jainam Broking Limited, a
                Company incorporated under the Companies Act, 2013 and having
                its registered office at Plot No 14 - 42, Jainam House, Nr.
                Shardayatan School, Piplod, Surat, 395007, Gujarat, India
                (“SmartLearn” or “we” or “us” or “our”). The Platform provides
                courses in online &amp; offline form related to the stock market
                and finance. The Platform does not provide any stock / trade
                recommendations and is not a broker / research house. SmartLearn
                is not an investment advisor registered with the Securities
                Exchange Board of India (“SEBI”). The Platform is not engaged in
                the service of executing trade orders using automated execution
                logic (Algorithmic Trading) as described by SEBI.
              </p>
              <p>
                These Terms of Service govern your access to and usage of our
                Services as part of a Subscription availed by you during the
                Term. Please refer to the Platform Terms for information
                regarding access to and usage of our Platform and how you can
                exercise your rights in relation to the access to and usage of
                our Platform.
              </p>
              <p>Definitions</p>
              <p>
                A. Account: means any accounts or instances created by or on
                behalf of you for access and use of the Services. You may have
                multiple Subscriptions which may be subject to separate and
                distinct plans. For the avoidance of doubt, reference to an
                Account herein refers to (i) Your trial / free account; or (ii)
                Your paid account.
              </p>
              <p>
                B. Applicable Data Protection Law: means all laws and
                regulations of India applicable to the Processing of personal
                information / User Information under these Terms of Service.
              </p>
              <p>
                C. Broker: in the context of the securities market means an
                intermediate authorized by the SEBI to act as a stock broker as
                per the meaning ascribed in the Securities and Exchange Board of
                India (Stock Brokers and Sub-Brokers) Rules, 1992; in the
                context of other financial markets refers to equivalent
                intermediate / platform that facilitates the buying and selling
                of financial instruments / assets / currency between a willing
                buyer and a willing seller.
              </p>
              <p>
                D. Government ID: means any proof of identity or address issued
                by the appropriate Government having jurisdiction over you, and
                includes the tax registration number or its equivalent.
              </p>
              <p>
                E. PAN: Permanent Account Number issued by the India tax
                authorities.
              </p>
              <p>
                F. Plan: means the pricing plan that you choose in connection
                with a Subscription for the associated functionality and
                Services.
              </p>
              <p>
                G. Process/Processing: means any operation or set of operations
                which is performed upon personal data / User Information,
                whether or not by automatic means, such as collection,
                recording, organization, storage, adaptation or alteration,
                retrieval, consultation, use, disclosure by transmission,
                dissemination or otherwise making available, alignment or
                combination, blocking, erasure or destruction.
              </p>
              <p>
                H. Reports: means any written or electronic statement, images,
                video, text or sounds (including statistics or insights) issued
                via the Platform or any other form of written communication
                specifying the results of the assessment or analysis or rating
                performed by the Company based on information provided by the
                User to the Company.
              </p>
              <p>
                I. Services: means the Service based on our proprietary
                technology that you subscribe to, which renders assessment or
                analysis or rating of the information provided by you, and / or
                any new products, services or features that the Company may
                introduce as a Service to which you may subscribe to, and any
                Updates to the Services.
              </p>
              <p>
                J. Subscription: means your subscription to the Services through
                a Plan of your choice for your access and use of an account.
              </p>
              <p>
                K. Subscription Fees: means the fees you pay to the Company for
                availing the Subscription, based on the Plan of your choice.
              </p>
              <p>
                L. Taxes: means taxes levied on you or us by the appropriate
                Government / tax authority having jurisdiction over you or us
                respectively, including, but not limited to goods &amp; services
                tax, income tax, custom duty, and / or other national, state, or
                local levies. It shall also include any withholding tax and tax
                collection at source obligations imposed on either you or us
                under any applicable statute.
              </p>
              <p>
                M. Trade: in the context of the securities market means the
                purchase or sale of securities between a willing buyer and a
                willing seller, over a stock exchange; in the context of other
                financial markets refers to the purchase or sale of financial
                instruments / assets / currency between a willing buyer and a
                willing seller, over an appropriate platform / exchange.
              </p>
              <p>
                N. Trade Book: means a ledger or statement of completed or
                executed Trades issued to you by your Broker.
              </p>
              <p>
                O. User Information: User name, e-mail ID, phone number, PAN /
                Government ID, date of birth, Broker, ID issued by your Broker,
                and payment or banking information.
              </p>
              <p></p>
              <p>
                <span className="fw-bold text-muted-dark fs-5">
                  Rights to use the Services
                </span>
              </p>
              <p>
                Subject to your compliance with the Privacy Policy and the
                Platform Terms, the Company grants you a limited,
                non-transferable, revocable, non-sublicensable and non-exclusive
                license to access and make personal use of the Platform and the
                Services. You agree to provide us with current, complete and
                accurate registration information as prompted by the process,
                and as outlined in the Platform Terms. You agree not to omit or
                misrepresent any registration data, and further agree to update
                the registration data periodically to ensure that it is current,
                complete and accurate. You further authorize us to verify the
                registration data as required for your use of the Services.
              </p>
              <p></p>
              <p>
                <span className="fw-bold text-muted-dark fs-5">Privacy</span>
              </p>
              <p>
                Any information that you provide to SmartLearn is subject to our
                Privacy Policy, which governs our collection and use of your
                information. You understand that through your use of the
                Services, you consent to the collection and use (as set forth in
                the Privacy Policy) of this information, including the transfer
                of this information to our service providers, as and when
                required. As part of providing to you the Services, we may need
                to provide you with certain communications, such as service
                announcements and administrative messages. These communications
                are considered part of the Services and your account, which you
                may not be able to opt ¬out from receiving.
              </p>
              <p>Account activities</p>
              <p>You are solely responsible for:</p>
              <p>
                • Your access and use of the Services in compliance with these
                Terms of Service and the Platform Terms and Applicable Data
                Protection Law;
              </p>
              <p>
                • Providing us with the relevant and accurate registration
                information as per the Platform Terms, including PAN (or other
                Government ID, in case you are a tax resident of an overseas
                jurisdiction other than the Republic of India);
              </p>
              <p>
                • Connecting your Trade account / handle in compliance with your
                Brokers privacy policy or other terms and conditions;
              </p>
              <p>
                • All activities that occur regarding your Account regardless of
                whether the activities are undertaken by you, your contractors,
                your agents, your representatives, your successors, or any other
                person authorized by you;
              </p>
              <p>
                • Safeguarding the password that you use to access the Services
                and for any activities or actions under your password. We
                encourage you to use “strong” passwords (passwords that use a
                combination of upper- and lower-case letters, numbers and
                symbols) with your account;
              </p>
              <p>
                • Immediately ceasing use of the Services for a prohibited
                activity or purpose if we inform you that a specified activity
                or purpose is prohibited with respect to the Services; and
              </p>
              <p>
                • Determining whether the Services or the information generated
                thereby is accurate or sufficient for your purposes.
              </p>
              <p>
                SmartLearn (including its officers, directors, executives,
                employees, agents, representatives, affiliates, service
                providers) will not be responsible for any alteration,
                compromise, corruption, damage or loss that may result from your
                failure to protect your login information, including any
                passwords. You agree to notify us immediately of any
                unauthorized access or use of your Account, log-in information,
                credentials or passwords, or any unauthorized activity in your
                Account. We have the right to suspend or terminate the Services
                without notice to you, if we reasonably suspect any unauthorized
                activity and you agree to cooperate with us in any investigation
                into suspected or actual unauthorized activity. Where your
                Account is so suspended and the breach remains uncured post any
                cure period we may grant you at our sole discretion, we reserve
                the right to terminate your Subscription with immediate effect.
              </p>
              <p></p>
              <p>
                <span className="fw-bold text-muted-dark fs-5">
                  Changes to the Services
                </span>
              </p>
              <p>
                We have the right but not the obligation to change content on
                our Platform at any time. We may discontinue or change any part
                of our Platform, that does not affect the Services, without
                notifying you. We may upgrade, update, or modify the provision
                of all or any part of the Services from time to time, including
                to improve the Services or to address any legal or regulatory
                requirements or to address a change in our policies ("Updates")
                and you may receive notifications where the Update is material.
                What constitutes a "material Update" will be determined by us in
                good faith using our reasonable judgment but will only include
                an update that materially affects your use of the Services.
                Unless otherwise stated, the Updates are also subject to these
                Terms of Service and we reserve the right to deploy Updates at
                any time. Where you do not agree to an Update, your sole remedy
                shall be to terminate your use of the Services and to comply
                with the termination obligations set forth in these Terms of
                Service. Any use of the Services after the Update will
                constitute your acceptance of the Update.
              </p>
              <p></p>
              <p>
                <span className="fw-bold text-muted-dark fs-5">Feedback</span>
              </p>
              <p>
                You may provide us suggestions, recommendations, enhancement
                requests or other feedback about the Services ("Feedback"). By
                submitting any Feedback, you agree that such Feedback is
                gratuitous, unsolicited and without restriction. You grant us a
                fully paid up, royalty-free, worldwide, transferable,
                sub-licensable, irrevocable and perpetual license to incorporate
                Feedback, in whole or in part, into the Services or Platform.
                Further, unless you notify us otherwise via
                smartlearn@jainam.in, you grant us a fully paid up,
                royalty-free, worldwide, transferable license to use your name
                and logo to identify you as our customer on the Platform or in
                other sales or marketing materials, provided however, we will
                not issue any press release without your prior consent.
              </p>
              <p></p>
              <p>
                <span className="fw-bold text-muted-dark fs-5">
                  Sub-processors and Other Services
                </span>
              </p>
              <p>
                A. Sub-processors: Certain third parties (each, a
                "Sub-processor") may assist us in providing the Services by
                delivering their products and/or services as part of the
                Services. We contract directly with the Sub-processors for the
                provision of their products and/or services, which are
                integrated with or are an integral part of the Services. The
                products and services provided by the Sub-processors do not
                include any of the Other Services.
              </p>
              <p>
                B. Other Services: Certain other services ("Other Services")
                such as integrations and apps may be made available to you
                through forums for their integration with the Services. These
                Other Services are governed by their own terms and privacy
                policies and you agree that we are not responsible for your use
                of these Other Services where you choose to enable these Other
                Services and integrate them into the Services. By enabling the
                Other Services, you understand and agree that we do not provide
                any warranties for Other Services and we are not liable for any
                damage or loss caused or alleged to be caused by or in
                connection with your enablement, access or use of any such Other
                Services, or your reliance on the privacy practices, data
                security processes or other policies of such Other Services. You
                understand that we are not responsible for providing technical
                support for Other Services or for the data hosting and data
                transfer practices followed by providers of such Other Services.
                To this extent, you agree to address any comments, queries,
                complaints or feedback about such Other Services with the
                respective developers or publishers as specified in those
                forums.
              </p>
              <p></p>
              <p>
                <span className="fw-bold text-muted-dark fs-5">
                  Billing and Payments&nbsp;
                </span>
              </p>
              <p>A. Payment:</p>
              <p>
                You agree to pay the Fees through a payment method acceptable to
                us. You hereby authorize us and/or our authorized agents, as
                applicable, to bill you in accordance with your Plan for Your
                purchase (and any renewal thereof). Your payment is due in full
                immediately upon each due date with respect to your purchase.
                You must notify us of any change in your payment account
                information, either by updating your Account or via e-mail to
                smartlearn@jainam.in.
              </p>
              <p>B. Renewal:</p>
              <p>
                For any Renewal Term (as defined below), we reserve the right to
                change the course/webinar/semianr/workshop/mastercalss Fees and
                billing methods upon prior notice.&nbsp;
              </p>
              <p>C. Refunds:</p>
              <p>
                Unless otherwise specified in these Terms of Service, all
                courses/seminars/webinars/workshop/masterclass fees once paid
                are non-refundable on voulnteery cancellation. We do not provide
                refunds or credits for any partial use or non-use of the
                Services.
              </p>
              <p>D. Taxes:&nbsp;</p>
              <p>
                The paid course/seminars/webinars/workshop/masterlass are
                inclusive of GST.&nbsp;
              </p>
              <p>E. Benefits:</p>
              <p>
                We may, at our sole discretion, offer you certain
                non-transferrable benefits (such as discounts on Fees) specific
                to a Course type. The benefits may have an expiry date. If they
                do not have an expiry date, they will expire upon completion of
                twelve (12) months from their date of offer.
              </p>
              <p></p>
              <p>
                <span className="fw-bold text-muted-dark fs-5">
                  Confidentiality
                </span>
              </p>
              <p>
                A. The access, disclosure and use of Confidential Information
                and any other rights and obligations regarding such information
                shall be solely and exclusively governed by these Terms of
                Service. All confidentiality obligations shall remain in force
                and effect for the Term plus one (1) year.
              </p>
              <p>
                B. You or us (the "Disclosing Party") may from time to time,
                while Your Subscriptions are valid, disclose to the other (the
                "Receiving Party") the Disclosing Partys confidential,
                proprietary and/or non-public information, materials or
                knowledge that is designated as confidential or that reasonably
                should be understood to be confidential given the nature of the
                information, materials or knowledge and/or the circumstances of
                disclosure (the "Confidential Information"). For purposes of
                these Terms of Service, Your Confidential Information includes
                your Trade Book and service data, and our Confidential
                Information includes the Services. The Receiving Party agrees to
                protect the Disclosing Partys Confidential Information from
                unauthorized access, disclosure or use in the same manner that
                the Receiving Party protects its own confidential or proprietary
                information of a similar nature but with no less than reasonable
                care. The Receiving Party will only access and use the
                Disclosing Partys Confidential Information in connection with
                performing these Terms of Service (or as expressly authorized by
                the Disclosing Party in writing), and will disclose the
                Disclosing Partys Confidential Information only to the employees
                and contractors of the Receiving Party who have a need to know
                the Confidential Information for purposes of performing these
                Terms of Service (or for other purposes as expressly authorized
                by the Disclosing Party in writing) and who are under a duty of
                confidentiality no less restrictive than the Receiving Partys
                duty hereunder.
              </p>
              <p>
                C. The Receiving Partys obligations with respect to Confidential
                Information of the Disclosing Party will terminate if the
                Receiving Party can document that such information (i) was
                already lawfully known to the Receiving Party at the time of
                disclosure by the Disclosing Party free from any obligation of
                confidence, (ii) was disclosed to the Receiving Party by a third
                party who had the right to make such disclosure without any
                confidentiality restrictions, (iii) is, or through no fault of
                the Receiving Party has become, generally available to the
                public or (iv) was independently developed by the Receiving
                Party without access to, or use of, the Disclosing Partys
                Confidential Information. In addition, the Receiving Party may
                disclose Confidential Information of the Disclosing Party to the
                extent compelled by law or a court or other judicial or
                administrative body, provided that the Receiving Party notifies
                the Disclosing Party of such compelled disclosure promptly and
                in writing (to the extent legally permitted) and cooperates with
                the Disclosing Party, at the Disclosing Partys reasonable
                request and expense, in any lawful action to contest or limit
                the scope of such required disclosure.
              </p>
              <p>
                D. The Receiving Party will return to the Disclosing Party all
                Confidential Information of the Disclosing Party in the
                Receiving Partys possession or control and permanently erase all
                electronic copies of such Confidential Information promptly upon
                the written request of the Disclosing Party. At the Disclosing
                Partys request, the Receiving Party will certify in writing that
                it has fully complied with its obligations under this Section.
                Notwithstanding this, the User may for personal use, download or
                make copies of the Reports generated by the Platform and is
                under no obligation to return these pursuant to Termination.
              </p>
              <p></p>
              <p>
                <span className="fw-bold text-muted-dark fs-5">
                  Indemnity and liability
                </span>
              </p>
              <p>
                You agree to indemnify and hold the Company (including its
                officers, directors, executives, employees, agents,
                representatives, affiliates, service providers) harmless from
                any and against any claims, causes of action, demands,
                recoveries, losses, damages, fines, penalties or other costs or
                expenses of any kind or nature, including reasonable attorneys
                fees, or arising out of or related to: (a) loss of your data
                (including personal information) disclosed by you in connection
                to these Terms of Service; and (b) your breach of these Terms of
                Service, your violation of any law or the rights of a third
                party, or your use of the Platform.
              </p>
              <p>
                You acknowledge and agree that SmartLearn has been irrevocably
                appointed to act as sole and exclusive agent of these
                Indemnified Parties for the purpose of recovering (whether
                through court proceedings or otherwise) the Losses of the
                Indemnified Parties and enforcing the indemnities.
              </p>
              <p>To the maximum extent permitted by Law:</p>
              <p>
                a) SmartLearn and its personnel will not be liable for any
                Indirect Loss; and
              </p>
              <p>
                b) Where applicable, SmartLearns personnel maximum liability
                arising out of or in connection with these Terms of Service and
                the Terms of Platform, whether in contract, tort, breach of
                warranty or statutory guarantee or otherwise, will be limited to
                the: (i) the resupply of the services to you; or (ii) the
                payment of the cost of having the services supplied again.
              </p>
              <p>Our relationship</p>
              <p>
                The Company and the User are independent contractors. Nothing in
                these Terms of Service shall be deemed to constitute a
                partnership, joint venture, employment or franchise between the
                Company and the User, nor constitute either as the agent of the
                other for any purpose or entitle either to commit or bind the
                other in any manner.
              </p>
              <p></p>
              <p>
                <span className="fw-bold text-muted-dark fs-5">
                  Changes to these Terms of Service
                </span>
              </p>
              <p>
                We may revise/update these Terms of Service from time to time.
                The most current version of the Terms of Service will govern our
                processing of your data and will always be at https://
                SmartLearn.com/termsofservice. If we make any change to these
                Terms of Service that, in our sole discretion, is material, we
                will endeavour to notify you via prominent notice on the
                Platform or send an email to the email address associated with
                your account. To the extent permitted under the applicable law,
                by continuing to access or use our Services after the changes
                become effective, you agree to be bound by the revised Terms of
                Service.
              </p>
              <p></p>
              <p>
                <span className="fw-bold text-muted-dark fs-5">
                  Non-assignment
                </span>
              </p>
              <p>
                You shall not assign or transfer or purport to assign or
                transfer the contract between you and us to any other person.
                However, the Company may assign its rights and duties under
                these Terms of Service without any such assignment being
                considered a change to the Terms of Service and without any
                notice to you.
              </p>
              <p></p>
              <p>
                <span className="fw-bold text-muted-dark fs-5">
                  Force Majeure and other events
                </span>
              </p>
              <p>
                We will make commercially reasonable efforts to keep the
                Services operational twenty-four (24) hours a day and seven (7)
                days a week, however, we shall not be liable for inadequacy of
                or irregularity in the Services caused by: (i) any circumstance
                beyond our reasonable control, including acts of God, acts of
                Government, flood, fire, earthquakes, civil unrest, acts of
                terror, strikes or other labour problems, technical failures
                (including failures in telecommunications, internet, internet
                service provider or hosting facilities, power shortages) or acts
                undertaken by third parties, including without limitation,
                denial of service attacks; (ii) sub optimal functionality,
                availability or downtime of your payment gateway and/or Other
                Services; (iii) your use of the Services in an unauthorized,
                improper or unlawful manner; or for any misuse or modification
                or damage of the Services caused by you; or any breach of these
                Terms of Service by you; or (iv) planned downtime for
                maintenance, upgrades and updates for which we will use
                commercially reasonable efforts to provide at least forty-eight
                (48) hours prior notice (collectively, the "Force Majeure and
                Other Events"). Despite our efforts, the Services or any
                functionality may from time to time encounter technical or other
                problems and may not continue to be uninterrupted. We are not
                responsible for any such problems, interruption, any ongoing
                obligation to offer our Services or any functionality, or any
                damages resulting therefrom.
              </p>
              <p></p>
              <p>
                <span className="fw-bold text-muted-dark fs-5">
                  Severability
                </span>
              </p>
              <p>
                If any of these Terms of Service should be determined to be
                illegal, invalid or otherwise unenforceable by reason of the
                laws of any state or country in which these Terms of Service are
                intended to be effective, then to the extent and within the
                jurisdiction where that term is illegal, invalid or
                unenforceable, it shall be severed and deleted and the remaining
                Terms of Service shall survive, remain in full force and effect
                and continue to be binding and enforceable.
              </p>
              <p></p>
              <p>
                <span className="fw-bold text-muted-dark fs-5">
                  Entire Agreement
                </span>
              </p>
              <p>
                These Terms of Service, our Platform Terms, and our Privacy
                Policy are the entire agreement between SmartLearn and you
                regarding the Services (excluding any services for which you
                have a separate agreement with us that is explicitly in addition
                or in place of these Terms of Service), and these Terms of
                Service supersede and replace any prior agreements between
                SmartLearn and you regarding the Services.
              </p>
              <p></p>
              <p>
                <span className="fw-bold text-muted-dark fs-5">
                  Governing law and dispute resolution
                </span>
              </p>
              <p>
                A. These Terms are subject to the laws of India. Any dispute,
                claim or controversy arising out of or relating to these Terms
                or the breach, termination, enforcement, interpretation or
                validity thereof or the use of the Platform or the Services
                (hereinafter collectively referred to as “Disputes”) the parties
                shall attempt to settle the same amicably, through negotiation
                and consultation at such offices of the Company as the Company
                may designate. B. In the event the dispute is not resolved
                internally between the Parties after at least 30 (thirty) days
                of negotiation, in good faith, the same shall be subject to
                binding and final arbitration in accordance with the Arbitration
                and Conciliation Act, 1996, as amended from time to time or in
                case the Arbitration and Conciliation Act, 1996, is no longer in
                force, as per any law relating to arbitration in force at the
                time of such reference. C. The reference shall be made to a sole
                arbitrator appointed by the Company. The place of the
                arbitration shall be Bangalore, Karnataka. Subject to the above,
                any Dispute will be subject to the exclusive jurisdiction of
                Courts in Bangalore, Karnataka.
              </p>
              <p></p>
              <p>
                <span className="fw-bold text-muted-dark fs-5">
                  Surviving provisions
                </span>
              </p>
              <p>
                When the Terms of Service come to an end, those terms that by
                their nature are intended to continue indefinitely will continue
                to apply.
              </p>
              <p></p>
              <p>
                <span className="fw-bold text-muted-dark fs-5">
                  Refund Policy
                </span>
              </p>
              <p>
                This document is an electronic record in terms of the
                Information Technology Act, 2000 and Rules made thereunder and
                other applicable law as amended from time to time. This document
                is published in accordance with the provisions of Rule 3 (1) of
                the Information Technology (Intermediaries guidelines) Rules,
                2011 that require publishing the rules and regulations, privacy
                policy and terms for access or usage of
                https://smartlearn.jainam.in/ website; and SmartLearn
                applications for mobile and handheld devices (collectively
                called the “Platform”).
              </p>
              <p></p>
              <p>
                <span className="fw-bold text-muted-dark fs-5">Refunds:</span>
              </p>
              <p>
                Unless otherwise specified in these Terms of Service, all
                Course/Seminars/Webinars/Workshop/Masterclass Fees once paid are
                non-refundable. When you subscribe to a paid
                Course/Seminars/Webinars/Workshop/Masterclass, you pay in
                advance for the Course/Seminars/Webinars/Workshop/Masterclass
                period indicated in the service description. We do not provide
                refunds or credits for any partial use or non-use of the
                Services.
              </p>
              <p></p>
              <p>
                <span className="fw-bold text-muted-dark fs-5">
                  Complaints and Grievance Redressal
                </span>
              </p>
              <p>
                Any complaints, abuse or concerns with regards to content and or
                comment or breach of these terms shall be immediately informed
                to the designated Compliance Officer as mentioned below via in
                writing or through email signed with the electronic signature.
              </p>
              <p>Compliance Officer</p>
              <p>Jainam Fincap Private Limited,</p>
              <p>Corporate Office:</p>
              <p>Plot No 14 - 42, Jainam House, Nr. Shardayatan School,</p>
              <p>Piplod</p>
              <p>Surat</p>
              <p>395007 Gujarat, India&nbsp;</p>
              <p>Email ID: smartlearn@jainam.in</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
