import Loading from 'components/_v2/CommonComponents/Loading';

const LoadingContent = () => {
	return (
		<div className="d-flex flex-column justify-content-center">
			<Loading width={200} />
			<span className="g_mid fs-18">Loading up your course—just a few moments!🎓</span>
		</div>
	);
};

export default LoadingContent;
