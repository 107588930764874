import { BASE_PAGE_URL, COURSE_URL, DETAIL_URL } from 'pages/routing/routePages';
import { EHeaderType } from '../types/globalTypes';

export const RUPEE_SYMBOL = '₹';
export const BLOGS_SECTION = 'blogs';
export const FAQS_SECTION = 'faqs';
export const STORIES_SECTION = 'stories';
export const EXPLORE_OFFLINE_SECTION = 'offline-course';
export const EXPLORE_LIVE_SECTION = 'live-courses';

export const headerPageType: { [key in string]: EHeaderType } = {
	[BASE_PAGE_URL]: EHeaderType.BLACK,
	[COURSE_URL]: EHeaderType.WHITE,
	[DETAIL_URL]: EHeaderType.WHITE,
};
