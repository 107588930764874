import React, { useState } from "react";
import { BsArrowRight, BsShieldFillCheck } from "react-icons/bs";
import { BsTicketPerforatedFill } from "react-icons/bs";
import { AiFillCheckCircle } from "react-icons/ai";
import { GETCART } from "../core/requests";
import { useQuery } from "@apollo/client";
import { getUserData } from "../../../utils/getUserData";
import { toAbsoluteUrl } from "../../../assets/helpers/AssetHelpers";

export const Summary = (props: any) => {
  const { onDelete } = props;
  const [open, setOpen] = useState(false);
  const [coupon, setCoupon] = useState("");
  const [value, setValue] = useState("");
  const handleSubmit = (e: any) => {
    e.preventDefault();
    setValue(coupon);
    setOpen(false);
  };
  const { data: cartDataRecord, refetch } = useQuery(GETCART);
  const cartData = cartDataRecord?.getCart ?? [];
  const calculateTotalPrice = () => {
    const total = cartData.reduce(
      (acc: any, item: any) => {
        return {
          totalPrice: acc.totalPrice + item.totalPrice,
          totalTaxAmount: acc.totalTaxAmount + item.taxAmount,
        };
      },
      { totalPrice: 0, totalTaxAmount: 0 },
    );
    return total;
  };
  const handleDelete = (id: any) => {
    onDelete(id);
  };
  const total = calculateTotalPrice();
  return (
    <>
      {cartData.length > 0 ? (
        <section className="section">
          <div className="card">
            <div className="card-body border-muted border-0 p-3">
              <div className="summary">
                <h5 className="font-title fw-bold mb-2">Summary</h5>
              </div>
              <div className="item border-bottom mt-3">
                <h6 className="font-subtitle text-muted ">Items</h6>
                {cartData?.map((items: any) => {
                  return (
                    <div className="fw-semibold">
                      <button
                        className="font-subtitle btn btn-outline-success course-btn rounded-pill px-2 py-1 mt-3 text-dark"
                        type="button"
                      >
                        {items.course?.courseType?.name}
                      </button>
                      <span className="text-end float-end">
                        <div className="text-primary">
                          <button
                            type="button"
                            className="btn-close text-reset"
                            aria-label="Close"
                            onClick={() => {
                              handleDelete(items.id);
                            }}
                          ></button>
                        </div>
                      </span>
                      <div className="row fs-5 mt-2 mb-3">
                        <div className="col-8 float-start fs-6 fw-semibold">
                          {items?.course?.name}
                        </div>
                        <div className="col-4 ">
                          <div className="float-end fs-6 fw-bold">
                            <i className="fa fa-indian-rupee me-1"></i>
                            {items?.price}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              {/* {open === false && (
            <div className="discount border-bottom py-3">
              <div className="btn btn-primary-50 w-100 text-start py-2">
                <BsTicketPerforatedFill className="me-2 text-primary fs-5" />
                Have Discount Code?
                <span
                  onClick={() => {
                    setOpen(true);
                  }}
                  className="text-primary text-decoration-none"
                >
                  Click Here
                </span>
              </div>
            </div>
          )}
          {open === true && (
            <>
              <div className="py-3 border-bottom">
                <div className="text-muted fs-6">Coupon Code</div>
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-lg-10">
                      <input
                        type="text"
                        className="w-100 form-control py-1"
                        onChange={(e) => setCoupon(e.target.value)}
                      />
                    </div>
                    <div className="col-lg-2 p-0">
                      <button className="btn btn-primary py-1 px-3 justify-content-center align-items-center">
                        <BsArrowRight />
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </>
          )}
          {value !== "" && (
            <>
              <div className="py-3 border-bottom">
                <div className="text-muted fs-6">Coupon Code</div>
                <div className="fs-6 mt-1">
                  {value}
                  <span
                    className="ms-1 text-primary cursor-pointer"
                    onClick={() => {
                      setValue("");
                    }}
                  >
                    Remove
                  </span>
                </div>
                <div className="fs-7 text-muted mt-1">
                  <AiFillCheckCircle className="font-subtitle text-success me-1 fs-6 mb-1" />
                  Coupon code applied successfully
                </div>
              </div>
            </>
          )} */}

              <div className="gst border-bottom py-3">
                <div className="row fs-5 mt-2 mb-3">
                  <div className="col-8 float-start fs-6">GST (18%)</div>
                  <div className="col-4 ">
                    <div className="float-end fs-6 fw-bold">
                      <i className="fa fa-indian-rupee me-1"></i>
                      {total.totalTaxAmount}
                    </div>
                  </div>
                </div>
              </div>
              <div className="amount py-3 pb-0">
                <div className="row fs-5 mt-2 mb-3">
                  <div className="font-subtitle col-8 fw-bold fs-4">
                    Total Payable
                  </div>
                  <div className="col-4 ">
                    <div className="float-end fs-6 fw-bold">
                      <i className="fa fa-indian-rupee me-1"></i>
                      {total.totalPrice}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="text-muted mt-3 align-items-center">
            <BsShieldFillCheck className="text-primary me-2 fs-4 mx-1" />
            <span>Safe and Secured Payments</span>
          </div>
        </section>
      ) : (
        <div className="row">
          <div className="col-12">
            <div className="row justify-content-lg-center align-items-lg-center p-4">
              <div className="col-12 ">
                <div className="pb-lg-5 text-lg-center">
                  <img
                    src={toAbsoluteUrl("../media/logo/cartempty.jpg")}
                    alt="celebration"
                    className="my-3 img-fluid col-12 col-lg-3 h-200 w-40"
                  />
                  <div className="d-flex justify-content-center mx-auto">
                    <div
                      className="btn fs-18 font-subtitle my-lg-2"
                      id="start-over"
                    >
                      <p className="fw-bold fs-4 font-title">
                        Your Cart is Empty!!
                      </p>
                      <p className="fs-6 font-subtitle text-muted-dark">
                        Looks like you haven’t added anything to your cart yet
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
