import React from 'react';
import './coursecta.css';
import { toAbsoluteUrl } from 'assets/helpers/AssetHelpers';
import { useNavigate } from 'react-router-dom';
import { COURSE_URL } from 'pages/routing/routePages';
import Button from '../CommonComponents/Button';
import { ButtonBorderType, ButtonType } from '../CommonComponents/types';

const CourseCta = () => {
	const navigate = useNavigate();
	const handleAllCourse = () => {
		navigate(COURSE_URL);
	};
	return (
		<section className="cta-wrapper border">
			<div className="container">
				<div className="row cta-layout">
					<div className="col-lg-11 col-md-10 col-10 layout-set d-flex ">
						<div className="row">
							<div className="col-lg-7 text-lg-start col-md-12 col-12 d-flex text-center">
								<div className="mt-2">
									<h2 className="cta-title ibm_semi">
										Transform Your Career with Skills Learned at
										 <span className="goldColor"> HoGrowth Academy</span>
									</h2>
									<p className="cta-subtitle p_reg my-4">
										Empower your career with skills from HoGrowth Academy.
									</p>
									<div className="text-lg-start text-center">
										<Button
											buttonText="Explore All Courses"
											buttonType={ButtonType.PRIMARY_BUTTON}
											handleClick={handleAllCourse}
											buttonBorderType={ButtonBorderType.BLACK_BORDER}
											classes="p_med"
										/>
									</div>
								</div>
							</div>
							<div className="col-12 col-lg-5">
								<div className="mt-lg-0 d-flex career-img d-flex justify-content-sm-center mt-5">
									<img
										className="img-fluid"
										srcSet={`${toAbsoluteUrl('/media/cta-images/cta@2x.png')} 2x, ${toAbsoluteUrl(
											'/media/cta-images/cta@3x.png',
										)} 3x`}
										alt="CTA"
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default CourseCta;
