import React from 'react';
import TopTitleSection from 'components/_v2/CommonComponents/TopTitleSection';
import GetInTouch from 'components/_v2/ContactUs/GetInTouch';
import Best from 'components/_v2/BeTheBest/Best';
import OurTeam from 'components/_v2/ContactUs/OurTeam';
import Partners from 'components/_v2/Partners/Partners';
import Faq from 'components/_v2/HgFAQ/Faq';

const ContactUs = () => {
	return (
		<>
			<section className="">
				<div className="container-fluid info-layout">
					<div className="row mt-5">
						<TopTitleSection
							title="Contact Us"
							description="Feel free to reach out to us at anytime."
						/>
					</div>
				</div>
			</section>

			<section className="about-us-wrapper">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="row">
								<div className="col-lg-10 col-md-11 col-11 layout-set mx-auto">
									<div style={{ margin: '3rem -0.7rem' }}>
										<iframe
											src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7442.484703593781!2d72.78255006747102!3d21.142751478461015!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be0530051dd2139%3A0x32afa62f7fabac6!2sVIP%20PLAZA!5e0!3m2!1sen!2sin!4v1728035122367!5m2!1sen!2sin"
											className="w-100 mx-1"
											height="600"
											style={{ border: 0 }}
											allowFullScreen
											loading="lazy"
											referrerPolicy="no-referrer-when-downgrade"
										/>
									</div>
									<GetInTouch />
									<Best
										wrapperClass="custom-best-wrapper mx-0"
										headingClass="custom-heading-style"
										descriptionClass="custom-description-style"
										titleClass="custom-title"
										wrapInRow
									/>
									<OurTeam wrapInRow />
									<Partners
										lgColClass="col-lg-12"
										mdColClass="col-md-12"
										smColClass="col-12"
										title="Trusted By"
										titleClass="trusted-class"
										wrapInRow
									/>
									<Faq
										faqWrapper="contact-faq-wrapper p-lg-5 p-md-4 px-1 py-2 mb-5"
										lgColClass="col-lg-12"
										mdColClass="col-md-12"
										smColClass="col-12"
										title="Frequently Asked Questions"
										titleClass="contact-faq"
										subtitleClass="contact-subtitle"
										ptClass="pt-2"
										wrapInRow
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default ContactUs;
