import { useEffect, useState } from 'react';
import { Accordion } from 'react-bootstrap';
import { useNavigate } from 'react-router';
import htmlToDraft from 'html-to-draftjs';
import { EditorState, ContentState } from 'draft-js';
import { GET_PUBLIC_FAQS } from '../../../components/Home-Page/core/requests';
import '../../../assets/sass/css/detail-page.css';
import { toAbsoluteUrl } from '../../../assets/helpers/AssetHelpers';
import moment from 'moment';
import { useQuery } from '@apollo/client';

export const AboutCourse = (props: { course: any; error: any }) => {
	const { course, error } = props;
	const navigate = useNavigate();
	const [description, setDescription] = useState(() => {
		if (course && course?.description) {
			const blocksFromHtml = htmlToDraft(course && course?.description);
			const { contentBlocks, entityMap } = blocksFromHtml;
			const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
			const editorState = EditorState.createWithContent(contentState);
			return editorState;
		}
	});

	const [requirements, setRequirements] = useState(() => {
		if (course && course?.requirements) {
			const blocksFromHtml = htmlToDraft(course?.requirements);
			const { contentBlocks, entityMap } = blocksFromHtml;
			const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
			const editorState = EditorState.createWithContent(contentState);
			return editorState;
		}
	});

	const { data: faqRecords } = useQuery(GET_PUBLIC_FAQS, {
		variables: {
			where: {
				course_id: {
					eq: course.id,
				},
			},
			paginate: {},
		},
	});
	const faqResult: any = faqRecords?.getPublicFaqsList?.records ?? [];
	const startDate = moment(course?.startDatetime);
	const endDate = moment(course?.endDatetime);
	// const formattedStartDate = startDate.format('DD');
	const formattedStartDate = startDate.format("DD MMM 'YY");
	const formattedEndDate = endDate.format("DD MMM 'YY");
	// const dateRange = formattedStartDate + ' - ' + formattedEndDate;
	const dateRange = formattedStartDate;

	const ctartDate = moment.utc(course?.startDatetime);
	const cendDate = moment.utc(course?.endDatetime);
	const startHour = ctartDate.format('h:mm');
	const endHour = cendDate.format('h:mm A');

	const formattedTime = moment(course?.startDatetime).format('hh');
	const durationToAdd = moment.duration(60, 'minutes');
	const formattedendTime = moment(course?.endDatetime).add(durationToAdd).format('hh a');
	const timeRange = formattedTime + ' - ' + formattedendTime;
	useEffect(() => {
		// Update description state when course prop changes
		if (course && course?.description) {
			const blocksFromHtml = htmlToDraft(course && course?.description);
			const { contentBlocks, entityMap } = blocksFromHtml;
			const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
			const editorState = EditorState.createWithContent(contentState);
			setDescription(editorState);
		}

		// Update requirements state when course prop changes
		if (course && course?.requirements) {
			const blocksFromHtml = htmlToDraft(course?.requirements);
			const { contentBlocks, entityMap } = blocksFromHtml;
			const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
			const editorState = EditorState.createWithContent(contentState);
			setRequirements(editorState);
		}
	}, [course]); // Trigger effect when the course prop changes
	let dateTimeConditionArray = ['workshop', 'webinar', 'seminar'];
	let ratingConditionArray = ['recorded-course', 'live-course'];
	let slugLowerCase = course?.courseType?.slug?.toLowerCase();
	return (
		<>
			<section className="section pb-0">
				<div className="container">
					<div className="col-lg-12">
						<div className="course-absolute ps-2" style={{ marginRight: '-20px' }}>
							<div className="card round position-relative overflow-hidden border-0  px-3 pt-3 shadow">
								<div className="card-body">
									<div className="text">
										<div className="features-icon mb-2">
											<i className="uil uil-chart-bar" />
										</div>
										<div className="row">
											{slugLowerCase && dateTimeConditionArray.includes(slugLowerCase) ? (
												<div className="col-lg-3 col-md-6 col-12">
													<div className="d-flex mb-2">
														<div className="text-center">
															<img
																src="../media/course/calendar.png"
																alt="calendar"
																style={{ width: '50px' }}
															/>
														</div>
														<div className="ms-2 flex-1">
															<h5 className="fw-bold mb-1">{dateRange}</h5>
															<p className="text-muted fs-6">Date</p>
														</div>
													</div>
												</div>
											) : (
												''
											)}
											{slugLowerCase && dateTimeConditionArray.includes(slugLowerCase) ? (
												<div className="col-lg-3 col-md-6 col-12">
													<div className="d-flex mb-2">
														<div className="text-center">
															<img
																src="../media/course/clock.png"
																alt="clock"
																style={{ width: '50px' }}
															/>
														</div>
														<div className="ms-2 flex-1">
															<h5 className="fw-bold mb-1">
																{startHour} - {endHour}
															</h5>
															<p className="text-muted fs-6">Time</p>
														</div>
													</div>
												</div>
											) : (
												''
											)}
											{slugLowerCase && ratingConditionArray.includes(slugLowerCase) ? (
												<div className="col-lg-3 col-md-6 col-12">
													<div className="d-flex mb-2">
														<div className="text-center">
															<img
																src="../media/course/rating.png"
																alt="Rating"
																className="img-fluid"
																style={{ width: '50px' }}
															/>
														</div>
														<div className="ms-2 flex-1">
															<h5 className="fw-bold mb-1">4.5</h5>
															<p className="text-muted fs-6">Ratings</p>
														</div>
													</div>
												</div>
											) : (
												''
											)}

											<div className="col-lg-3 col-md-6 col-12">
												<div className="d-flex mb-2">
													<div className="text-center">
														<img
															src="../media/course/message.png"
															alt="message0"
															style={{ width: '50px' }}
														/>
													</div>
													<div className="ms-2 flex-1">
														<h5 className="fw-bold mb-1">
															{course?.courseLanguages?.map(
																(value: any, index: number, array: any[]) => (
																	<span key={index}>
																		{value.name}
																		{index < array.length - 1 && '/'}
																	</span>
																),
															)}
														</h5>
														<p className="text-muted fs-6">Course Language</p>
													</div>
												</div>
											</div>
											<div className="col-lg-3 col-md-6 col-12">
												<div className="d-flex mb-2">
													<div className="level-icon text-center">
														{course?.courseLevel?.name === 'Advanced' && (
															<img src={toAbsoluteUrl('/media/course/advanced.jpg')} />
														)}
														{course?.courseLevel?.name === 'Intermediate' && (
															<img src={toAbsoluteUrl('/media/course/intermediate.jpg')} />
														)}
														{course?.courseLevel?.name === 'Beginner' && (
															<img src={toAbsoluteUrl('/media/course/beginner.jpg')} />
														)}
													</div>
													<div className="ms-2 flex-1">
														<h5 className="fw-bold mb-1">{course?.courseLevel?.name ?? ''}</h5>
														<p className="text-muted fs-6">Difficulty Level</p>
													</div>
												</div>
											</div>
											<div className="col-lg-3 col-md-6 col-12">
												<div className="d-flex mb-2">
													<div className="text-center">
														<img
															src="../media/course/mode.png"
															alt="mode"
															style={{ width: '50px' }}
														/>
													</div>
													<div className="ms-2 flex-1">
														<h5 className="fw-bold mb-1">{course?.courseMode?.name ?? ''}</h5>
														<p className="text-muted fs-6">Study Mode</p>
													</div>
												</div>
											</div>
											<div className="col-lg-3 col-md-6 col-12">
												{course.address && (
													<div className="d-flex mb-2">
														<div className="text-center">
															<img
																src="../media/banner/detaillocation.png"
																alt="location"
																className="img-fluid"
																style={{ width: '50px' }}
															/>
														</div>
														<div className="ms-2 flex-1">
															<h5 className="fw-bold mb-1">{course.address}</h5>
															<p className="text-muted fs-6">Location</p>
														</div>
													</div>
												)}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					{/* end col */}
				</div>
				{/* End container */}
			</section>
			<section className="section">
				{error && (
					<div className="alert alert-danger" role="alert">
						{error}
					</div>
				)}
				{requirements?.getCurrentContent().hasText() === true ? (
					<div className="card bg-light round border-0">
						<div className="card-body p-3 pb-0">
							<div className="font-subtitle fs-5 fw-bold head1 ">Pre requisites</div>
							<div
								className="font-subtitle subtitle head2 mt-2"
								dangerouslySetInnerHTML={{ __html: course?.requirements }}
							/>
						</div>
					</div>
				) : (
					''
				)}
				{course?.objectives?.length > 0 ? (
					<div className="card mt-2 border-0">
						<div className="card-body p-3 pb-0">
							<div className="font-title title fs-4 heading-text head1">Learning objectives</div>
							<div className="mt-2">
								{course?.objectives?.map((element: any) => {
									return <li className="font-subtitle fs-6 text-dark mb-1">{element.objective}</li>;
								})}
							</div>
						</div>
					</div>
				) : (
					''
				)}
				{course?.courseSkills?.length > 0 ? (
					<div className="card mt-4 border-0">
						<div className="card-body p-3 pb-0">
							<div className="fs-4 heading-text font-title head1">Skills Covered</div>
							<div className="d-flex mt-2">
								{course?.courseSkills?.map((element: any) => {
									return (
										<button className="btn btn-outline-success btn-sideborder-rounded m-1 text-black">
											{element.name}
										</button>
									);
								})}
							</div>
						</div>
					</div>
				) : (
					''
				)}

				{description?.getCurrentContent()?.hasText() ? (
					<div className="card mt-3 border-0">
						<div className="card-body p-3 pb-0">
							<div className="font-title fs-4 heading-text head1">
								{course?.courseType?.slug?.charAt(0)?.toUpperCase() +
									course?.courseType?.slug?.slice(1) ===
									'Recorded-course' ||
								course?.courseType?.slug?.charAt(0)?.toUpperCase() +
									course?.courseType?.slug?.slice(1) ===
									'Live-course'
									? 'Course'
									: course?.courseType?.slug?.charAt(0)?.toUpperCase() +
									  course?.courseType?.slug?.slice(1)}{' '}
								description
							</div>
							<div
								className="font-subtitle subtitle mt-1"
								dangerouslySetInnerHTML={{ __html: course?.description }}
							/>
						</div>
					</div>
				) : (
					''
				)}

				<div className="card mt-4 border-0">
					{course?.courseType?.slug?.charAt(0)?.toUpperCase() +
						course?.courseType?.slug?.slice(1) ===
					'Recorded-course' ? (
						<>
							{course?.sections?.length > 0 ? (
								<div className="card-body p-3 pb-0">
									<div className="font-title fs-4 heading-text head1">Topics in the course</div>
									<>
										{course?.sections?.map((section: any) => {
											return (
												<>
													<Accordion className="lesson-accordion mt-2 p-0">
														<Accordion.Item eventKey={section.id}>
															<Accordion.Header className="">
																Lesson : {section?.name ?? ''}
															</Accordion.Header>
															{section?.lessons?.length > 0 ? (
																<>
																	{section?.lessons?.map((lesson: any) => {
																		return (
																			<Accordion.Body className="fs-6 border-bottom border-light py-2">
																				{lesson.type === 'VIDEO' && (
																					<div className="lesson-content">
																						<span className="lesson-text">
																							<i className="fa fa-file-text me-2 lesson-icon"></i>
																							{lesson?.name ?? ''}
																						</span>
																						<span className="float-end">
																							<span>
																								{lesson?.duration ?? 0} min
																								<i className="fa fa-play-circle ms-2 text-icon"></i>
																							</span>
																						</span>
																					</div>
																				)}
																				{lesson.type === 'QUIZ' && (
																					<div className="lesson-content">
																						<span className="lesson-text">
																							<i className="fa fa-file-pen me-2 lesson-icon"></i>
																							{lesson?.name ?? ''}
																						</span>
																						<span className="float-end">
																							<span>
																								{lesson?.quiz?.questions.length} questions
																								<i className="fa fa-lock ms-2 text-icon"></i>
																							</span>
																						</span>
																					</div>
																				)}
																				{lesson.type === 'DOCS' && (
																					<div className="lesson-content">
																						<span className="lesson-text">
																							<i className="fa fa-file-pen me-2 lesson-icon"></i>
																							{lesson?.name ?? ''}
																						</span>
																						<span className="float-end">
																							<span>
																								{lesson?.quiz?.questions.length} questions
																								<i className="fa fa-lock ms-2 text-icon"></i>
																							</span>
																						</span>
																					</div>
																				)}
																			</Accordion.Body>
																		);
																	})}
																</>
															) : (
																''
															)}
														</Accordion.Item>
													</Accordion>
												</>
											);
										})}
									</>
								</div>
							) : (
								''
							)}
						</>
					) : (
						''
					)}
					{/* Data */}
					{course?.courseType?.slug?.charAt(0)?.toUpperCase() +
						course?.courseType?.slug?.slice(1) ===
					'Seminar' ? (
						<>
							{course?.sections?.length > 0 ? (
								<div className="card-body p-3 pb-0">
									<div className="font-title fs-4 heading-text head1">Seminar Schedule</div>
									<>
										{course?.sections?.map((section: any) => {
											return (
												<>
													<Accordion className="lesson-accordion mt-2 p-0">
														<Accordion.Item eventKey={section.id}>
															<Accordion.Header className="">
																Lesson : {section?.name ?? ''}
															</Accordion.Header>
															{section?.lessons?.length > 0 ? (
																<>
																	{section?.lessons?.map((lesson: any) => {
																		return (
																			<Accordion.Body className="fs-6 border-bottom border-light py-2">
																				{lesson.type === 'VIDEO' && (
																					<div className="lesson-content">
																						<span className="lesson-text">
																							<i className="fa fa-file-text me-2 lesson-icon"></i>
																							{lesson?.name ?? ''}
																						</span>
																						<span className="float-end">
																							<span>
																								{lesson?.duration ?? 0} min
																								<i className="fa fa-play-circle ms-2 text-icon"></i>
																							</span>
																						</span>
																					</div>
																				)}
																				{lesson.type === 'QUIZ' && (
																					<div className="lesson-content">
																						<span className="lesson-text">
																							<i className="fa fa-file-pen me-2 lesson-icon"></i>
																							{lesson?.name ?? ''}
																						</span>
																						<span className="float-end">
																							<span>
																								{lesson?.quiz?.questions.length} questions
																								<i className="fa fa-lock ms-2 text-icon"></i>
																							</span>
																						</span>
																					</div>
																				)}
																				{lesson.type === 'DOCS' && (
																					<div className="lesson-content">
																						<span className="lesson-text">
																							<i className="fa fa-file-pen me-2 lesson-icon"></i>
																							{lesson?.name ?? ''}
																						</span>
																						<span className="float-end">
																							<span>
																								{lesson?.quiz?.questions.length} questions
																								<i className="fa fa-lock ms-2 text-icon"></i>
																							</span>
																						</span>
																					</div>
																				)}
																			</Accordion.Body>
																		);
																	})}
																</>
															) : (
																''
															)}
														</Accordion.Item>
													</Accordion>
												</>
											);
										})}
									</>
								</div>
							) : (
								''
							)}
						</>
					) : (
						''
					)}
				</div>
				{course?.mentors?.length > 0 ? (
					<>
						<div className="card mt-4 border-0">
							<div className="card-body p-3 pb-0">
								<div className="font-title title fs-4 heading-text head1">
									{course?.mentors.length === 1 ? (
										<>
											{course?.courseType.slug.charAt(0).toUpperCase() +
												course?.courseType.slug.slice(1) ===
												'Recorded-course' ||
											course?.courseType.slug.charAt(0).toUpperCase() +
												course?.courseType.slug.slice(1) ===
												'Live-course'
												? ' Course Mentor'
												: 'Instructor'}
										</>
									) : (
										<>
											{course?.courseType.slug.charAt(0).toUpperCase() +
												course?.courseType.slug.slice(1) ===
												'Recorded-course' ||
											course?.courseType.slug.charAt(0).toUpperCase() +
												course?.courseType.slug.slice(1) ===
												'Live-course'
												? ' Course Mentors'
												: 'Instructors'}
										</>
									)}
								</div>
								{course?.mentors?.map((mentor: any, index: any) => (
									<div className="card bg-light mt-2 border-0">
										<div className="card-body">
											{mentor?.mentor?.userProfile?.map((user: any) => {
												return (
													<>
														<div className="row">
															<div className="col-lg-5 col-md-5 col-12">
																<div className="d-lg-flex align-items-center">
																	{/* <img
                                                                        className="img-fluid rounded-circle avatar avatar-small me-3"
                                                                        src={user.imageUrl ? user.imageUrl : `${toAbsoluteUrl("../media/mentor/profile_image.jpg")}`}
                                                                        alt=""
                                                                    /> */}
																	<img
																		alt=""
																		className="img-fluid rounded-circle avatar avatar-small me-3"
																		src={user?.imageUrl}
																	/>
																	<div className="">
																		<div className="content-para mt-lg-0 mt-2">
																			<h6
																				onClick={() =>
																					navigate(`/mentor-view/${mentor.mentor?.id}`)
																				}
																				className="name fs-5 head1 mb-0 cursor-pointer py-2">
																				{mentor.mentor?.firstName}
																				{mentor.mentor?.lastName}
																			</h6>
																			<small className="designation text-muted">
																				{/* {user.description} */}
																			</small>
																		</div>
																	</div>
																</div>
															</div>
															<div className="col-lg-7 d-lg-grid d-block justify-content-end ">
																<span>
																	<div
																		onClick={() => navigate(`/mentor-view/${mentor.mentor?.id}`)}
																		className="text-primary fs-6 cursor-pointer border-0">
																		View Profile
																	</div>
																</span>
															</div>
														</div>

														<p className="d-block d-lg-grid d-flex justify-content-start review-para font-italic fs-6 mt-3 mb-3 gap-2">
															{user.description}
														</p>
													</>
												);
											})}
										</div>
									</div>
								))}

								{/* end::Item */}
							</div>
						</div>
					</>
				) : (
					''
				)}
				<>
					{course?.courseType?.slug?.charAt(0)?.toLowerCase() !== 'recorded-course' &&
					faqResult.length > 0 ? (
						<section className="section">
							<div className="container mt-4">
								<div className="heading mb-3 px-2">
									<div className="font-title title">Frequently Asked Questions</div>
								</div>
								<div className="course-new-faq accordion p-2" id="FAQaccordion">
									{faqResult?.map((value: any) => {
										return (
											<>
												<div className="accordion-item rounded-2 border-color3 mb-2 overflow-hidden border">
													<h2 className="accordion-header" id={`heading${value.id}`}>
														<button
															className="accordion-button collapsed font-subtitle"
															type="button"
															data-bs-toggle="collapse"
															data-bs-target={`#collapse-${value.id}`}
															aria-expanded="true"
															aria-controls={`collapse-${value.id}`}>
															{value.question}
														</button>
													</h2>
													<div
														id={`collapse-${value.id}`}
														className="accordion-collapse collapse"
														aria-labelledby={`heading${value.id}`}
														data-bs-parent="#FAQaccordion">
														<div className="accordion-body font-subtitle">{value.answer}</div>
													</div>
												</div>
											</>
										);
									})}
								</div>
							</div>
						</section>
					) : (
						''
					)}
				</>
			</section>
		</>
	);
};
