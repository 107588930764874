import React, { useCallback, useEffect, useMemo } from 'react';
import { Course, LessonType, LessonTypeEnum } from '../Course/type';
import DocumentType from './LessonTypesComponents/DocumentType';
import LessonButtons from './LessonTypesComponents/LessonButtons';
import QuizCompleted from './LessonTypesComponents/QuizCompleted';
import QuizCompletedButtons from './LessonTypesComponents/Quiz/QuizCompletedButtons';
import {
	calculateCorrectMarksFromQuestionAnswers,
	calculateTotalMarksForQuiz,
} from './LessonTypesComponents/Quiz/helper';
import { useQuizContext } from 'context/QuizContext';
import { LessonChange } from './ViewCourseData';
import { CERTIFICATE_LESSON } from 'types/globalTypes';
import './CourseLessonData.css';
import useCourses from 'hooks/useCourses';
import { useNavigate } from 'react-router-dom';
import { MY_COURSE_URL } from 'pages/routing/routePages';
import {
	QuizReattemptDetails,
	QuizResultStatusEnum,
} from 'components/_v2/ViewCourseData/LessonTypesComponents/Quiz/type';
import useQuiz from 'hooks/useQuiz';
import moment from 'moment';
import useIsSmallScreen from '../../../hooks/useIsSmallScreen';

const CourseLessonData = ({
	lessonData,
	courseId,
	handleLessonChange,
	isFirstLessonOfFirstSection,
	isLastLessonOfLastSection,
	hasCertificate = false,
	quizReattemptList,
	setQuizReattemptList,
	course,
}: {
	lessonData: LessonType;
	courseId: string;
	handleLessonChange: (shiftType: LessonChange) => void;
	isFirstLessonOfFirstSection: boolean;
	isLastLessonOfLastSection: boolean;
	hasCertificate: boolean;
	quizReattemptList?: QuizReattemptDetails[];
	setQuizReattemptList: React.Dispatch<React.SetStateAction<QuizReattemptDetails[]>>;
	course: Course;
}) => {
	const {
		quizResult,
		resetQuizResultState,
		resetQuizState,
		setQuizResult,
		loadingGetQuizReattempt,
		loadingGetQuizResult,
	} = useQuizContext();
	const { handleQuizSubmit, handleQuizReattempt } = useQuiz();
	const isSmallScreen = useIsSmallScreen(576);
	const navigate = useNavigate();
	const { downloadCertificateForCourse, loadingStudentCourses } = useCourses();
	const showCertificate = hasCertificate && lessonData?.id === CERTIFICATE_LESSON;

	const quizReattemptDetails = quizReattemptList.find(
		(listItem) => listItem.lessonId === lessonData?.id && listItem.quizId === lessonData?.quizId,
	);

	const handleReattemptQuiz = () => {
		handleQuizSubmit({
			lessonId: lessonData.id,
			isSubmit: false,
			courseId,
			questionAnswer: [],
			quizId: lessonData.quizId,
			sectionId: lessonData.sectionId,
			is_retake: true,
			callbackFunction: async (data) => {
				resetQuizState();
				resetQuizResultState();
				setQuizResult({
					quizStartTime: moment().toISOString(),
					quizEndTime: data.submitQuiz.endTime,
					isSubmit: false,
					questionAnswer: [],
					quizSubmitTime: data.submitQuiz.quizSubmitTime,
				});
				await handleQuizReattempt(courseId, setQuizReattemptList);
			},
		});
	};

	const totalMarksAttained = useMemo(
		() => calculateCorrectMarksFromQuestionAnswers(quizResult.questionAnswer),
		[quizResult],
	);

	const totalQuizMarks = useMemo(
		() => calculateTotalMarksForQuiz(quizResult.questionAnswer),
		[quizResult],
	);

	const handleDownloadCertificate = (setLoading: (loading: boolean) => void, courseId: string) => {
		setLoading(true);
		downloadCertificateForCourse({
			courseId,
			onCompletedCallback: () => setLoading(false),
			onErrorCallback: () => setLoading(false),
		});
	};

	if (!lessonData) {
		return null;
	}

	return (
		<div className={`${isSmallScreen ? 'borderSmallDevices py-3' : ''}`}>
			<div
				className={`w-100 courseContainer d-flex justify-content-center align-items-center flex-column gap-3 ${
					isSmallScreen ? 'border-0' : ''
				}`}>
				{quizResult.isSubmit === true ? (
					<QuizCompleted
						totalQuizMarks={totalQuizMarks}
						totalMarksAttained={totalMarksAttained}
						handleLessonChange={handleLessonChange}
						isLastLessonOfLastSection={isLastLessonOfLastSection}
						allowReattempt={quizReattemptDetails?.allowReAttempt}
						reattemptStatus={quizReattemptDetails?.status}
						handleReattemptQuiz={handleReattemptQuiz}
					/>
				) : (
					<DocumentType
						lessonData={lessonData}
						courseId={courseId}
						showCertificate={showCertificate}
						loadingContent={
							loadingStudentCourses || loadingGetQuizReattempt || loadingGetQuizResult
						}
					/>
				)}
			</div>
			<div className={`d-flex justify-content-end w-100 ${isSmallScreen ? '' : 'lectureButtons'}`}>
				{quizResult.isSubmit &&
				lessonData.type === LessonTypeEnum.QUIZ &&
				!quizReattemptDetails?.allowReAttempt &&
				![QuizResultStatusEnum.FAILED, QuizResultStatusEnum.PENDING].includes(
					quizReattemptDetails?.status,
				) ? (
					<QuizCompletedButtons
						courseId={courseId}
						lessonData={lessonData}
						totalQuizMarks={totalQuizMarks}
						totalMarksAttained={totalMarksAttained}
					/>
				) : (
					<LessonButtons
						nextButtonText={showCertificate ? 'Download Certificate' : 'Next Lecture'}
						previousButtonText={showCertificate ? 'Go Back to My Courses' : 'Previous Lecture'}
						lessonName={lessonData?.name}
						isFirstLessonOfFirstSection={isFirstLessonOfFirstSection}
						isLastLessonOfLastSection={!showCertificate && isLastLessonOfLastSection}
						onNextClick={(setLoading) =>
							showCertificate
								? handleDownloadCertificate(setLoading, courseId)
								: handleLessonChange(LessonChange.NEXT)
						}
						isDownloadCertificateButton={showCertificate}
						course={course}
						onPreviousClick={() =>
							showCertificate ? navigate(MY_COURSE_URL) : handleLessonChange(LessonChange.PREVIOUS)
						}
					/>
				)}
			</div>
		</div>
	);
};

export default CourseLessonData;
