import React from 'react';
import './ProgressBar.css';

const ProgressBar = ({ percentage }) => {
	return (
		<div className="progress-bar-container">
			<span className="percentage-text">{percentage}%</span>
			<div className="progress-bar mt-2">
				<div className="progress-bar-fill" style={{ width: `${percentage}%` }}></div>
			</div>
		</div>
	);
};

export default ProgressBar;
