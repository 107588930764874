import React from 'react';
import './partners.css';
import { toAbsoluteUrl } from '../../../assets/helpers/AssetHelpers';

const partnerLogos = [
	{ src: '/media/partners-images/nsc.png', alt: 'NSC' },
	{ src: '/media/partners-images/bse.png', alt: 'BSE' },
	{ src: '/media/partners-images/mcx.png', alt: 'MCX' },
	{ src: '/media/partners-images/cdsl.png', alt: 'CDSL' },
	{ src: '/media/partners-images/ncdex.png', alt: 'NCDEX' },
];

const Partners = ({
	lgColClass = '',
	mdColClass = '',
	smColClass = '',
	title = 'Trusted By',
	titleClass = 'text-center partners-title ibm_semi ',
	wrapInRow = false,
}: {
	lgColClass?: string;
	mdColClass?: string;
	smColClass?: string;
	title?: string;
	titleClass?: string;
	wrapInRow?: boolean;
}) => {
	const logoElements = (keyValueMultiplier: number) =>
		partnerLogos.map((partner, index) => (
			<span key={partner.alt + (index + 1) * keyValueMultiplier}>
				<img src={toAbsoluteUrl(partner.src)} alt={partner.alt} className="logo-spacing" />
			</span>
		));

	// Repeat logos for seamless looping effect
	const marqueeContent = [...logoElements(1), ...logoElements(2)];

	return (
		<section className={wrapInRow ? 'row' : ''}>
			<div className="container">
				<div className="row d-flex justify-content-center my-5">
					<div className={`${lgColClass} ${mdColClass} ${smColClass} lora p-md-2 p-0`}>
						<h2 style={{ color: '#757575' }} className={titleClass}>
							{title}
						</h2>
						<div className="marquee my-5">
							<div className="marquee-content my-3">{marqueeContent}</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Partners;
