import { toAbsoluteUrl } from 'assets/helpers/AssetHelpers';
import React from 'react';

interface TeamCardProps {
	title: string;
	description: string;
	buttonText: string;
	imageSrc: string;
	buttonImageSrc: string;
	wrapperClassName?: string;
	titleClassName?: string;
	descClassName?: string;
	buttonClassName?: string;
}

const TeamCard: React.FC<TeamCardProps> = ({
	title,
	description,
	buttonText,
	imageSrc,
	buttonImageSrc,
	wrapperClassName = 'team-card-wrapper card h-100 p-lg-3 p-md-3 border-0 p-2 bg-color24',
	titleClassName = 'team-card-title mb-0',
	descClassName = 'team-card-desc my-4',
	buttonClassName = 'team-btn btn align-items-center',
}) => {
	return (
		<div className={wrapperClassName}>
			<div className="card-body">
				<div className="text-start">
					<div className="row align-items-center justify-content-center my-2">
						<div className="d-flex  align-items-center justify-content-start">
							<img src={toAbsoluteUrl(imageSrc)} alt="Icon" className="team-img-style me-3 " />
							<h2 className={titleClassName}>{title}</h2>
						</div>
					</div>
					<div className={descClassName}>{description}</div>
					<button className={buttonClassName}>
						{buttonText}
						<img src={toAbsoluteUrl(buttonImageSrc)} alt="Arrow" className="ms-3" />
					</button>
				</div>
			</div>
		</div>
	);
};

export default TeamCard;
