import { toAbsoluteUrl } from "assets/helpers/AssetHelpers";
import { Image } from "react-bootstrap";

const FullScreenButton = ({
  handleFullScreen,
  isFullScreen = false,
}: {
  isFullScreen?: boolean;
  handleFullScreen?: () => void;
}) => {
  return (
    <div
      className="d-flex align-items-center gap-2"
      role="button"
      onClick={handleFullScreen}
    >
      <Image
        src={toAbsoluteUrl(
          `/media/course-detail-images/${
            isFullScreen ? "exit-fullscreen" : "fullscreen"
          }.svg`,
        )}
      />
      <span className="d-flex align-items-center quiz-question-range">
        {isFullScreen ? "Exit Full Screen" : "Full Screen"}
      </span>
    </div>
  );
};

export default FullScreenButton;
