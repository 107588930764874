import { toAbsoluteUrl } from 'assets/helpers/AssetHelpers';
import moment from 'moment';
import { Image } from 'react-bootstrap';
import { LessonType } from '../Course/type';
import useIsSmallScreen from '../../../hooks/useIsSmallScreen';
import Loading from 'components/_v2/CommonComponents/Loading';
import { useState } from 'react';
import { MY_COURSE_URL } from '../../../pages/routing/routePages';
import { useNavigate } from 'react-router-dom';
import useCourses from '../../../hooks/useCourses';

const CourseCompleted = ({
	lessonData,
	courseId,
}: {
	lessonData: LessonType;
	courseId: string;
}) => {
	const isSmallScreen = useIsSmallScreen(576);
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();
	const { downloadCertificateForCourse } = useCourses();
	return (
		<div className="d-flex flex-column align-items-center gap-1">
			<Image
				src={toAbsoluteUrl('/media/course-detail-images/course_completed.svg')}
				height={246}
				width={147}
			/>
			<p className="lora completedHeading">Congratulations!</p>
			<p className="g_light d-flex align-items-center flex-column">
				You have successfully completed this course
				<p className="g_light">
					on <span>{moment(lessonData.startDate).format('Do MMMM, YYYY')}</span>
				</p>
			</p>
			{isSmallScreen && (
				<div className="d-flex gap-2 px-1 pb-4">
					<button
						className="btn btn-previous g_mid"
						style={{ padding: '0px' }}
						onClick={() => navigate(MY_COURSE_URL)}>
						Go Back to My Courses
					</button>
					<button
						className="btn bg-color2 btn-next-lesson g_mid"
						disabled={loading}
						style={{ padding: '0px' }}
						onClick={() => {
							setLoading(true);
							downloadCertificateForCourse({
								courseId,
								onCompletedCallback: () => setLoading(false),
								onErrorCallback: () => setLoading(false),
							});
						}}>
						<div className="d-flex align-items-center justify-content-center gap-2">
							{loading && <Loading width="50px" height="25px" />}
							Download Certificate
						</div>
					</button>
				</div>
			)}
		</div>
	);
};

export default CourseCompleted;
