const CurrentQuestionProgress = ({
  questionNumber,
  totalQuestions,
}: {
  questionNumber: number;
  totalQuestions: number;
}) => {
  return (
    <span className="g_reg d-flex align-items-center quiz-question-range">
      Question {questionNumber} of {totalQuestions}
    </span>
  );
};

export default CurrentQuestionProgress;
