import React, { useState } from "react";
import { Summary } from "./Summary/Summary";
import { Checkout } from "./Checkout/Checkout";
import CartSlider from "./cart-slider/CartSlider";
import { GETCART, REMOVEFROMCART } from "./core/requests";
import { useMutation, useQuery } from "@apollo/client";
import toast from "react-hot-toast";
export const Cart = () => {
  const [commitDeleteCart] = useMutation(REMOVEFROMCART);
  const [showLoader, setShowLoader] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const { data: cartDataRecord, refetch } = useQuery(GETCART);
  const cartData: any = cartDataRecord?.getCart ?? [];
  const onDelete = (id: any) => {
    commitDeleteCart({
      variables: {
        cartId: id,
      },
      onCompleted: () => {
        toast.success("Successfully removed from Cart!", {
          style: {
            padding: "16px",
          },
        });
        refetch();
      },
      onError: (err: any) => {
        toast.error(err.message);
      },
    });
  };

  return (
    <>
      <section className="section px-xl-4 px-lg-3 px-2">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <Checkout
                setShowLoader={setShowLoader}
                showLoader={showLoader}
                formSubmitted={formSubmitted}
                setFormSubmitted={setFormSubmitted}
              />
            </div>
            <div className="col-lg-4">
              {showLoader === true ? null : <Summary onDelete={onDelete} />}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
