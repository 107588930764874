import { gql, useLazyQuery, useMutation } from '@apollo/client';
import {
	QuizReattemptDetails,
	QuizResultResponse,
	QuizSubmitResponse,
	QuizSubmitType,
	UserQuizResultStatusListObjectType,
} from 'components/_v2/ViewCourseData/LessonTypesComponents/Quiz/type';
import { useQuizContext } from 'context/QuizContext';
import { useUserContext } from 'context/UserContext';
import moment from 'moment';
import { SUBMIT_QUIZ_PROGRESS } from 'pages/auth/core/_requests';
import React from 'react';

const quizLocalStorageKey = 'quizDetails';

export const QUIZ_REATTEMPT = gql`
	query getStudentQuizResultStatusList($courseId: String!) {
		getStudentQuizResultStatusList(courseId: $courseId) {
			quizDetails {
				sectionId
				lessonId
				quizId
				status
				allowReAttempt
			}
		}
	}
`;

export const GET_QUIZ_RESULT = gql`
	query GetQuizResult(
		$courseId: String!
		$quizId: String!
		$sectionId: String!
		$lessonId: String!
	) {
		getQuizResult(
			courseId: $courseId
			quizId: $quizId
			sectionId: $sectionId
			lessonId: $lessonId
		) {
			id
			userId
			isSubmit
			courseId
			quizId
			quizStartTime
			quizEndTime
			quizSubmitTime
			questionAnswer {
				answer
				correctAnswer
				mark
				questionId
			}
			createdAt
			updatedAt
		}
	}
`;

const useQuiz = () => {
	const { localStorageAuthToken } = useUserContext();
	const {
		setQuizResult,
		setEndTime,
		setRemainingTime,
		setLoadingGetQuizReattempt,
		setLoadingGetQuizResult,
	} = useQuizContext();
	const [startQuiz] = useMutation(SUBMIT_QUIZ_PROGRESS, {
		context: {
			headers: {
				authorization: `Bearer ${localStorageAuthToken}`,
			},
		},
	});

	const [getQuizResult] = useLazyQuery<QuizResultResponse>(GET_QUIZ_RESULT, {
		context: {
			headers: {
				authorization: `Bearer ${localStorageAuthToken}`,
			},
		},
		fetchPolicy: 'network-only',
	});

	const [getQuizReattempt, { loading: loadingGetQuizReattempt }] =
		useLazyQuery<UserQuizResultStatusListObjectType>(QUIZ_REATTEMPT, {
			context: {
				headers: {
					authorization: `Bearer ${localStorageAuthToken}`,
				},
			},
		});

	const handleQuizReattempt = (
		courseId: string,
		setResult: React.Dispatch<React.SetStateAction<QuizReattemptDetails[]>>,
	) => {
		setLoadingGetQuizReattempt(true);
		getQuizReattempt({
			variables: {
				courseId,
			},
			fetchPolicy: 'network-only',
			onCompleted: (data) => {
				setResult(data.getStudentQuizResultStatusList.quizDetails);
				setLoadingGetQuizReattempt(false);
			},
			onError: () => {
				setLoadingGetQuizReattempt(false);
			},
		});
	};

	const handleGetQuizResult = ({
		courseId,
		lessonId,
		quizId,
		sectionId,
	}: {
		courseId: string;
		quizId: string;
		sectionId: string;
		lessonId: string;
	}) => {
		setLoadingGetQuizResult(true);
		getQuizResult({
			variables: {
				courseId,
				lessonId,
				quizId,
				sectionId,
			},
			onError: (error: any) => {
				setQuizResult((prevState) => ({
					...prevState,
					isSubmit: false,
					questionAnswer: [],
				}));
				setLoadingGetQuizResult(false);
			},
			onCompleted: (data: QuizResultResponse) => {
				const quizResult = data.getQuizResult;
				setQuizResult(quizResult);
				if (quizResult.quizEndTime) {
					setEndTime(quizResult.quizEndTime);
					let timeDifferenceRemaining = moment(quizResult.quizEndTime).diff(moment(), 'seconds');
					setRemainingTime(timeDifferenceRemaining > 0 ? timeDifferenceRemaining : 0);
				}
				setLoadingGetQuizResult(false);
			},
		});
	};

	const handleQuizSubmit = ({
		courseId,
		isSubmit,
		lessonId,
		questionAnswer,
		quizId,
		sectionId,
		is_retake = false,
		callbackFunction,
	}: QuizSubmitType) =>
		startQuiz({
			variables: {
				input: {
					courseId,
					isSubmit,
					lessonId,
					questionAnswer,
					quizId,
					sectionId,
					is_retake,
				},
			},
			onCompleted: (data: QuizSubmitResponse) => {
				const existingLocalStorageData = JSON.parse(localStorage.getItem(quizLocalStorageKey));
				setQuizResult((prevState) => ({
					...prevState,
					isSubmit: data.submitQuiz.isSubmit,
					questionAnswer: data.submitQuiz.questionAnswer,
					quizSubmitTime: data.submitQuiz.quizSubmitTime,
				}));
				localStorage.setItem(
					quizLocalStorageKey,
					JSON.stringify({
						...existingLocalStorageData,
						[lessonId]: {
							courseId,
							quizId,
							sectionId,
							endTime: data.submitQuiz.endTime,
						},
					}),
				);
				if (callbackFunction) {
					callbackFunction(data);
				}
			},
		});
	return {
		handleQuizSubmit,
		handleGetQuizResult,
		handleQuizReattempt,
	};
};

export default useQuiz;
