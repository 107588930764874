import { useState } from 'react';
import { Image, Modal } from 'react-bootstrap';
import useIsSmallScreen from '../../../../../hooks/useIsSmallScreen';

const ImageModalOpener = ({
	imageSrc,
	width,
	height,
}: {
	imageSrc: string;
	width?: string | number;
	height?: string | number;
}) => {
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const isSmallScreen = useIsSmallScreen(576);
	const imageDimensions = isSmallScreen ? 200 : 400;

	return (
		<>
			<div onClick={() => setIsModalOpen(true)}>
				<Image
					src={imageSrc}
					width={width}
					height={height}
					style={{ width: imageDimensions, height: imageDimensions }}
				/>
			</div>
			{isModalOpen && (
				<Modal
					show={isModalOpen}
					onHide={() => setIsModalOpen(false)}
					keyboard
					animation={false}
					centered
					size="xl">
					<div className="d-flex align-items-center justify-content-center">
						<Image src={imageSrc} width="100%" height="100%" />
					</div>
				</Modal>
			)}
		</>
	);
};

export default ImageModalOpener;
