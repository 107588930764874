import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

const CourseDetails = () => {
  const { slug } = useParams();
  useEffect(() => {
    console.log("slug", slug);
    localStorage.setItem("activeIndex", "1");
  }, [slug]);
  return <>Course Name : {slug}</>;
};

export default CourseDetails;
