import React from 'react';

interface AboutContentCardProps {
	title: string;
	description: string;
	buttonText: string;
	reverseOrder?: boolean;
	buttonClass?: string;
	titleClass?: string;
	descriptionClass?: string;
	onClickHandler?: () => void;
}

const AboutContentCard: React.FC<AboutContentCardProps> = ({
	title,
	description,
	buttonText,
	reverseOrder = false,
	buttonClass = 'about-content-card-btn',
	titleClass = 'about-content-card-title',
	descriptionClass = 'about-content-card-subtitle',
	onClickHandler,
}) => {
	return (
		<div className="card h-100 p-lg-3 p-md-3 custom-card-shadow border-0 p-2">
			<div className="card-body">
				<div className="text-start">
					{reverseOrder ? (
						// If reverseOrder is true, show the content in reverse
						<>
							<button className={`${buttonClass} btn my-2`} onClick={onClickHandler}>
								{buttonText}
							</button>
							<h2 className={`${titleClass} my-3`}>{title}</h2>
							<p className={`${descriptionClass} my-3`}>{description}</p>
						</>
					) : (
						// Default order: title -> description -> button
						<>
							<h2 className={`${titleClass} my-3`}>{title}</h2>
							<p className={`${descriptionClass} my-3`}>{description}</p>
							<button className={`${buttonClass} btn my-2`} onClick={onClickHandler}>
								{buttonText}
							</button>
						</>
					)}
				</div>
			</div>
		</div>
	);
};

export default AboutContentCard;
