import { toAbsoluteUrl } from 'assets/helpers/AssetHelpers';
import React from 'react';

const AboutCTA = () => {
	return (
		<section className="bg-color16 py-lg-5 px-lg-5 rounded-4 mb-5">
			<div className="d-flex align-items-center justify-content-center">
				<div className="row p-lg-4 p-md-5 p-4">
					<div className="col-lg-6 d-flex flex-column align-items-center text-white">
						<div className="text-start">
							<h2 className="about-cta-title">Beyond Finance</h2>
							<p className="about-cta-subtitle mt-4">
								While our current focus is on finance and capital markets, we envision a future
								where our practical approach extends beyond these sectors. At HoGrowth Academy,
								we're committed to expanding our offerings and continuously evolving to meet the
								diverse educational needs of our learners.
							</p>
						</div>
					</div>
					<div className="col-lg-6 d-flex justify-content-center align-items-center py-lg-0 py-md-0 py-2">
						<img
							src={toAbsoluteUrl('/media/aboutus/ctalogo.png')}
							alt="HoGrowth_Logo_Image"
							className="img-fluid"
						/>
					</div>
				</div>
			</div>
		</section>
	);
};

export default AboutCTA;
