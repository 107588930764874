export interface NotificationsDataType {
	getNotifications: {
		message: string;
		title: string;
		createdAt?: Date;
	};
}

export interface StudentNotificationsListDataType {
	getStudentNotificationList: {
		records: NotificationsObjectType[];
		pagination: {
			totalRecords: number;
			currentPage: number;
		};
	};
}
export enum NotificationTypeEnum {
	GENERAL = 'GENERAL',
	COURSE_SPECIFIC = 'COURSE_SPECIFIC',
	ENROLLED_USER = 'ENROLLED_USER',
	GUEST_USER = 'GUEST_USER',
}
export enum NotificationStatusEnum {
	DRAFT = 'DRAFT',
	APPROVED = 'APPROVED',
	REJECTED = 'REJECTED',
	IN_REVIEW = 'IN_REVIEW',
}
export enum NotificationSentStatusEnum {
	SENT = 'SENT',
	NOT_SENT = 'NOT_SENT',
}
export interface NotificationsObjectType {
	id: string;
	courseId?: string;
	type: NotificationTypeEnum;
	status: NotificationStatusEnum;
	sentStatus: NotificationSentStatusEnum;
	sentTime?: string;
	title: string;
	message: string;
	sentBy?: string;
	createdAt?: Date;
	updatedAt?: Date;
	createdById?: string;
	updatedById?: string;
	readStatus: boolean;
}
