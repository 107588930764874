import React from "react";

export const PrivacyPolicy = () => {
  return (
    <section className="section">
      <div className="container col-lg-7">
        <div className="row">
          <div className=" mt-5">
            <div className="">
              <h1 className="fw-bold font-title">Privacy Policy</h1>
              <h4 className="font-subtitle mt-4">Privacy Policy</h4>
            </div>
            <div className="ql-container ql-snow ql-disabled mt-4">
              <div
                className="ql-editor"
                data-gramm="false"
                contentEditable="false"
              >
                <p>
                  <span className="fw-bold text-muted-dark fs-5">
                    Privacy Policy
                  </span>
                </p>
                <p>
                  This document is an electronic record in terms of the
                  Information Technology Act, 2000 and Rules made thereunder and
                  other applicable law as amended from time to time. This
                  document is published in accordance with the provisions of
                  Rule 3 (1) of the Information Technology (Intermediaries
                  guidelines) Rules, 2011 that require publishing the rules and
                  regulations, privacy policy and terms for access or usage of
                  https://smartLearn.jainam.in&nbsp;website; and “SmartLearn”
                  applications for mobile and handheld devices (collectively
                  called the “Platform”).
                </p>
                <p>
                  Please read this Privacy Policy (hereinafter referred to as
                  “Privacy Policy”) carefully before accessing or using the
                  Platform. You agree to be bound by the terms described herein.
                  If you do not agree to these terms, please do not use the
                  Platform. Using the Platform will be considered as a deemed
                  acceptance of this Privacy Policy. This agreement does not
                  require a physical/digital signature. This agreement shall not
                  be invalidated solely on the ground that it is not physically
                  signed.
                </p>
                <p>
                  For the purpose of these Terms, wherever the context so
                  requires “you” or “User” shall mean any natural or legal
                  person who accesses the Platform, whether or not such person
                  registers with the Platform.
                </p>
                <p>
                  Any term not defined herein shall have the meaning ascribed to
                  it in the Terms of Service.
                </p>
                <p></p>
                <p>
                  <span className="fw-bold text-muted-dark fs-5">
                    Content &amp; Purpose
                  </span>
                </p>
                <p>
                  The Platform is owned and operated by Jainam Broking Limited,
                  a Company incorporated under the Companies Act, 2013 and
                  having its registered office at Plot No 14 - 42, Jainam House,
                  Nr. Shardayatan School, Piplod, Surat, 395007, Gujarat, India.
                  The Platform provides courses in online &amp; offline form
                  related to the stock market and finance. The Platform does not
                  provide any stock / trade recommendations and is not a broker
                  / research house. SmartLearn is not an investment advisor
                  registered with the Securities Exchange Board of India
                  (“SEBI”). The Platform is not engaged in the service of
                  executing trade orders using automated execution logic
                  (Algorithmic Trading) as described by SEBI.
                </p>
                <p>
                  This Privacy Policy explains how we use and share your
                  personal information and how you can exercise your rights in
                  relation to your personal information.
                </p>
                <p>Information collected from you:</p>
                <p>
                  A. As part of the registration process, or when you contact
                  us, we may collect the following categories of personal
                  information from the Users (hereinafter collectively referred
                  to as “User Information”): name, e-mail ID, phone number,
                  permanent account number (“PAN”), date of birth, your Broker,
                  the ID issued by your Broker, and payment or banking
                  information. If you sign in to the Platform through your
                  Brokers sign-in services, an option of which may be provided
                  to you by SmartLearn at its sole discretion, you will be
                  allowing us to pass through and receive from the Broker your
                  log-in information and other user data. Please see your
                  Brokers privacy policy and help centre for more information
                  about how they share information when you choose to connect
                  your account.
                </p>
                <p>
                  B. Content provided to us, which may include your Trade Book,
                  reviews, and other information you provide on the Platform
                  (“Your Content”). Our usage of your Trade Book is limited to
                  the purpose of providing our Services and we do not store such
                  information.
                </p>
                <p>
                  C. When you use our Platform, we may collect and store
                  information about you to process your requests and
                  automatically complete forms for future transactions,
                  including (but not limited to) your name, phone number,
                  address, email, billing information and credit or payment card
                  information.
                </p>
                <p>
                  D. Depending on the Services that you use, and your app
                  settings or device permissions, we may collect your real time
                  information, or approximate location information as determined
                  through data such as Global Positioning System (“GPS”),
                  Internet Protocol (“IP”) address.
                </p>
                <p>
                  E. We may also receive and/or hold information about your
                  browsing history including the Uniform Resource Locator
                  (“URL”) of the site that you visited prior to visiting the
                  Platform as well as the IP address of your computer (or the
                  proxy server used to access the World Wide Web), your computer
                  operating system and type of web browser as well as the name
                  of your Internet Service Provider (“ISP”). The Platform may
                  use temporary cookies to store certain data (that is not
                  sensitive personal data or information) that is used by us for
                  the technical administration of the Platform, research and
                  development, and for user administration.
                </p>
                <p>
                  F. We may collect information as to how you interact with our
                  Services, the results of our Services, preferences expressed
                  and settings chosen. This may also include your browser type
                  and version, date and time stamp, User agent, cookie ID, time
                  zone setting, browser plug-in types and versions, operating
                  system and platform, and other information about User
                  activities on the Platform, as well as on third party sites
                  and services that have embedded our pixels, widgets, plug-ins,
                  buttons, or related services. This also includes your
                  preferences in receiving marketing from us and third parties
                  and your communication preferences.
                </p>
                <p>
                  G. We collect transaction details related to your use of our
                  Services, and information about your activity on the Services,
                  including the full URL, the type of Services you requested or
                  provided, comments, domain names, search results selected,
                  number of clicks, information and pages viewed and searched
                  for, the order of those pages, length of your visit to our
                  Services, the date and time you used the Services, payments
                  processed via our Platform, amount charged, details regarding
                  application of promotional code, methods used to browse away
                  from the page and any phone number used to call our customer
                  service number and other related transaction details.
                </p>
                <p>
                  H. Your correspondences with customer support teams, and
                  problems reported for troubleshooting. This also includes
                  telephone calls / chats through the Platform.
                </p>
                <p>
                  I. When you avail the interactive services offered by the
                  Platform such as contests, competitions, promotions or
                  surveys, etc., we may collect information on such services
                  availed.
                </p>
                <p>
                  We are committed to protecting the personal information of the
                  Users and take all reasonable precautions for maintaining
                  confidentiality of the Users personal information. Please note
                  that the above information will be stored/disclosed by
                  SmartLearn only in the manner specified in this Privacy
                  Policy, and information that would not be stored or disclosed
                  is specifically mentioned in this Privacy Policy. We do not
                  knowingly collect Personal Information from children.
                </p>
                <p>Purposes for which we may use the Information</p>
                <p>
                  We will retain User Information only to the extent it is
                  necessary to provide Services to the Users. The information,
                  which we collect from you, may be utilized for various
                  business and/or regulatory purposes including for the
                  following purposes:
                </p>
                <p>A. Registration of the User on the Platform;</p>
                <p>
                  B. Completing transactions with Users effectively and billing;
                </p>
                <p>
                  C. Technical administration and customization of Platform;
                </p>
                <p>
                  D. Ensuring that the Platform content is presented to the
                  Users in an effective manner;
                </p>
                <p>E. Delivery of personalized information to the User;</p>
                <p>
                  F. Improvement of Services, features and functionality of the
                  Platform;
                </p>
                <p>
                  G. Research and development and for User administration
                  (including conducting User surveys);
                </p>
                <p>
                  H. Non-personally identifiable information, exclusively owned
                  by the Company may be used in an aggregated or non-personally
                  identifiable form for internal research, statistical analysis
                  and business intelligence purposes including those for the
                  purposes of determining the number of visitors and
                  transactional details, and we may sell or otherwise transfer
                  such research, statistical or intelligence data in an
                  aggregated or non-personally identifiable form to third
                  parties and affiliates;
                </p>
                <p>
                  I. Dealing with requests, enquiries, complaints or disputes
                  and other customer care related activities including those
                  arising out of the Users request of the Services and all other
                  general administrative and business purposes;
                </p>
                <p>
                  J. Communicate any changes in our Services or this Privacy
                  Policy or the Terms of Use to the Users;
                </p>
                <p>
                  K. Verification of identity of Users and perform checks to
                  prevent frauds;
                </p>
                <p>
                  L. Investigating, enforcing, resolving disputes and applying
                  our Terms of Use and Privacy Policy, either ourselves or
                  through third party service providers; and.
                </p>
                <p>
                  M. Disclosure to the Indian Government or other Indian
                  statutory authorities for various tax, regulatory, and legal
                  compliance purposes;
                </p>
                <p>
                  N. Disclosure to third party suppliers that provide services
                  to us, such as information technology service providers;
                </p>
                <p>
                  O. We may share aggregate findings (not specific information)
                  based on information relating to the Users internet use to
                  prospective, investors, strategic partners, sponsors and
                  others in order to help growth of Companys business;
                </p>
                <p>
                  P. We may also disclose or transfer the User Information, to
                  another third party as part of reorganization or a sale of the
                  assets or business of Company. Any third party to which the
                  Company transfers or sells its assets will have the right to
                  continue to use the Personal Information and/or other
                  information that a User provide to us.
                </p>
                <p>
                  Q. We may share your information other than as described in
                  this Policy if we notify you and you consent to the sharing.
                  We may combine the information that we receive from third
                  parties with the information you give to us and information we
                  collect about you for the purposes set out above. Further, we
                  may anonymize and/or de-identify information collected from
                  you through the Services or via other means, including via the
                  use of third-party web analytic tools. As a result, our use
                  and disclosure of aggregated and/or de-identified information
                  is not restricted by this Privacy Policy, and it may be used
                  and disclosed to others without limitation. We analyse the log
                  files of our Platform that may contain IP addresses, browser
                  type and language, ISP, referring, app crashes, page viewed
                  and exit websites and applications, operating system,
                  date/time stamp, and clickstream data. This helps us to
                  administer the website, to learn about User behavior on the
                  site, to improve our product and services, and to gather
                  demographic information about our User base as a whole.
                </p>
                <p>
                  The Company makes all User Information accessible to its
                  employees and data processors only on a need-to-know basis.
                  All the employees and data processors, who have access to, and
                  are associated with the processing of User Information, are
                  obliged to respect its confidentiality.
                </p>
                <p>
                  Notwithstanding anything to the contrary in this Privacy
                  Policy, we may preserve, use, or disclose your personal data
                  or other safety data if we believe that it is reasonably
                  necessary to comply with a law, regulation, legal process, or
                  governmental request; to protect the safety of any person; to
                  protect the safety or integrity of our platform, including to
                  help prevent spam, abuse, or malicious actors on our services,
                  address security or technical issues; or to protect our rights
                  or property or the rights or property of those who use our
                  services. However, nothing in this Privacy Policy is intended
                  to limit any legal defenses or objections that you may have to
                  a third partys, including a governments, request to disclose
                  your personal data.
                </p>
                <p></p>
                <p>
                  <span className="fw-bold text-muted-dark fs-5">
                    Security Practices and Procedures
                  </span>
                </p>
                <p>
                  SmartLearn adopts reasonable security practices and procedures
                  to include, technical, operational, managerial and physical
                  security control measures in order to protect the personal
                  information in its possession from loss, misuse and
                  unauthorized access, disclosure, alteration and destruction.
                  SmartLearn takes adequate steps to ensure that third parties
                  to whom the User sensitive personal data or Information may be
                  transferred adopt reasonable level of security practices and
                  procedures to ensure security of personal information. You
                  hereby acknowledge that SmartLearn is not responsible for any
                  intercepted information sent via the internet, and you hereby
                  release us from any and all claims arising out of or related
                  to the use of intercepted information in any unauthorized
                  manner.
                </p>
                <p></p>
                <p>
                  Users rights in relation to their personal information
                  collected
                </p>
                <p>
                  All the information provided to SmartLearn by a User,
                  including sensitive personal data or information, is
                  voluntary. User has the right to withdraw his/her/its consent
                  at any time, in accordance with the terms of this Privacy
                  Policy and the Terms of Use, but please note that withdrawal
                  of consent will not be retroactive. Users can access, modify,
                  correct and delete the personal information provided by them
                  which has been voluntarily given by the User and collected by
                  SmartLearn in accordance with this Privacy Policy and Terms of
                  Use. However, if the User updates his/her information,
                  SmartLearn may keep a copy of the information which User
                  originally provided to the Company in its archives for User
                  documented herein. In case the User seeks to update or
                  correct, his / her personal information, the User may exercise
                  these rights by emailing us at smartlearn@jainam.in and
                  communicate the change(s) for updating the records. In case
                  the User does not provide his/her information or consent for
                  usage of personal information or subsequently withdraws
                  his/her consent for usage of the personal information so
                  collected, SmartLearn reserves the right to discontinue the
                  services for which the said information was sought.
                </p>
                <p></p>
                <p>
                  <span className="fw-bold text-muted-dark fs-5">
                    Third party websites
                  </span>
                </p>
                <p>
                  The Platform may contain links to other websites. Any personal
                  information about you collected whilst visiting such websites
                  is not governed by this Privacy Policy. The Company shall not
                  be responsible for and has no control over the practices and
                  content of any website accessed using the links contained on
                  the Platform.
                </p>
                <p></p>
                <p>
                  <span className="fw-bold text-muted-dark fs-5">
                    Service providers
                  </span>
                </p>
                <p>
                  We engage service providers to perform functions and provide
                  services to us on the Platform. We may share your private
                  personal data with such service providers subject to
                  obligations consistent with this Privacy Policy and any other
                  appropriate confidentiality and security measures, and on the
                  condition that the third parties use your private personal
                  data only on our behalf and pursuant to our instructions
                  (service providers may use other non-personal data for their
                  own benefit). This Privacy Policy shall not apply to any
                  information you may disclose to any of our service providers /
                  service personnel which we do not require you to disclose to
                  us or any of our service providers under this Privacy Policy.
                </p>
                <p></p>
                <p>
                  <span className="fw-bold text-muted-dark fs-5">
                    Changes to the Privacy Policy
                  </span>
                </p>
                <p>
                  We may revise/update this Privacy Policy from time to time.
                  The most current version of the Privacy Policy will govern our
                  processing of your data and will always be at https://
                  SmartLearn.com/privacy. If we make any change to this Privacy
                  Policy that, in our sole discretion, is material, we will
                  endeavour to notify you via prominent notice on the Platform
                  or send an email to the email address associated with your
                  account. To the extent permitted under the applicable law, by
                  continuing to access or use our Services after the changes
                  become effective, you agree to be bound by the revised Privacy
                  Policy.
                </p>
                <p></p>
                <p>
                  <span className="fw-bold text-muted-dark fs-5">
                    Complaints and Grievance Redressal
                  </span>
                </p>
                <p>
                  Any complaints, abuse or concerns with regards to content and
                  or comment or breach of these terms shall be immediately
                  informed to the designated Compliance Officer as mentioned
                  below via in writing or through email signed with the
                  electronic signature.
                </p>
                <p>Compliance Officer</p>
                <p>Jainam Fincap Private Limited,</p>
                <p>Corporate Office:</p>
                <p>Plot No 14 - 42, Jainam House, Nr. Shardayatan School,</p>
                <p>Piplod</p>
                <p>Surat</p>
                <p>395007 Gujarat, India&nbsp;</p>
                <p>Email ID: smartlearn@jainam.in</p>
                <p className="ql-align-justify"></p>
              </div>
              <div
                className="ql-clipboard"
                contentEditable="true"
                tabIndex={-1}
              ></div>
              <div className="ql-tooltip ql-hidden">
                <a
                  className="ql-preview"
                  rel="noopener noreferrer"
                  target="_blank"
                  href="about:blank"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
