import React from "react";
import { toAbsoluteUrl } from "../../../../assets/helpers/AssetHelpers";

function DetailBanner(props: { profile: any }) {
  const { profile } = props;
  return (
    <>
      <section className="bg-detail position-relative round d-lg-none d-md-none d-block">
        <div className="detail-wrapper-light"></div>
        <div className="bg-primary-30 detail-wrapper">
          <div className="slider fullscreen">
            <div
              className="img-fluid "
              style={{
                backgroundImage: `url(${toAbsoluteUrl(
                  "/media/banner/mentorm.png",
                )})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center center",
                position: "relative",
                height: "60vh",
              }}
            ></div>
            <div className="container-lg justify-content-center align-items-center mx-5">
              <div id="text-on-slider">
                <div className="heading mx-5" style={{ marginTop: "-100%" }}>
                  <div className="title mb-2 text-white mx-3">
                    {profile?.firstName + " " + profile?.lastName}
                  </div>
                  <p className="sub-title-main" style={{ color: "#BEDCFF" }}>
                    {profile &&
                      profile?.userProfile &&
                      profile?.userProfile[0] &&
                      profile?.userProfile[0]?.qualification}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="hero-section-top-padding d-lg-block d-md-block d-none">
        <div className="container-fluid px-lg-0 px-md-0 px-0">
          <div className="row gx-0">
            <div className="col-12">
              <div className="hero-carousel-slide">
                <div className="container-lg justify-content-center align-items-center mx-5">
                  <div id="text-on-slider">
                    <div
                      className="heading text-white fw-normal mx-3"
                      style={{ marginBottom: "60%" }}
                    >
                      <div className="title mb-3">
                        {profile?.firstName + " " + profile?.lastName}
                      </div>
                      <p
                        className="sub-title-main"
                        style={{ color: "#BEDCFF" }}
                      >
                        {profile &&
                          profile?.userProfile &&
                          profile?.userProfile[0] &&
                          profile?.userProfile[0]?.qualification}
                      </p>
                    </div>
                  </div>
                </div>
                <div id="hero-section-slider" className="carousel slide">
                  <div className="carousel-inner">
                    <div className="carousel-item active">
                      <img
                        alt=""
                        src={toAbsoluteUrl("/media/banner/mentor1banner.jpg")}
                        className="img-fluid w-100 d-md-block d-none "
                      />
                      <img
                        alt=""
                        src={toAbsoluteUrl("/media/banner/mentor1banner.jpg")}
                        className="img-fluid w-100 d-md-none d-block "
                      />
                    </div>
                    <div className="carousel-item">
                      <img
                        alt=""
                        src={toAbsoluteUrl("/media/banner/mentor1banner.jpg")}
                        className="img-fluid w-100 d-md-block d-none "
                      />
                      <img
                        alt=""
                        src={toAbsoluteUrl("/media/banner/mentor1banner.jpg")}
                        className="img-fluid w-100 d-md-none d-block "
                      />
                    </div>
                    <div className="carousel-item">
                      <img
                        alt=""
                        src={toAbsoluteUrl("/media/banner/mentor1banner.jpg")}
                        className="img-fluid w-100 d-md-block d-none "
                      />
                      <img
                        alt=""
                        src={toAbsoluteUrl("/media/banner/mentor1banner.jpg")}
                        className="img-fluid w-100 d-md-none d-block "
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default DetailBanner;
