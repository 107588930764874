import React from 'react';
import './CourseProgramOverview.scss';
import AccordionBody from 'components/_v2/CommonComponents/AccordionBody';
import AccordionHeader from 'components/_v2/CommonComponents/AccordionHeader';
import { CourseSections } from 'components/_v2/Course/type';
import { Image } from 'react-bootstrap';
import { toAbsoluteUrl } from 'assets/helpers/AssetHelpers';
import { QuizReattemptDetails } from 'components/_v2/ViewCourseData/LessonTypesComponents/Quiz/type';
import useIsSmallScreen from 'hooks/useIsSmallScreen';

const CourseProgramOverview = ({
	sections = [],
	fullScale = false,
	showLockIcon = false,
	showCloseIcon = false,
	onCloseClick,
	handleLessonChange,
	currentSelectedLessonId,
	quizReattemptList,
	noBorderAccordion = false,
}: {
	sections: CourseSections[];
	fullScale?: boolean;
	showLockIcon?: boolean;
	showCloseIcon?: boolean;
	onCloseClick?: () => void;
	handleLessonChange?: (lessonId: string) => void;
	currentSelectedLessonId?: string;
	quizReattemptList?: QuizReattemptDetails[];
	noBorderAccordion?: boolean;
}) => {
	const isSmallScreen = useIsSmallScreen();

	return (
		<section className={fullScale ? '' : isSmallScreen ? 'pt-3' : 'p-3'}>
			<div className="container">
				<div className={`row ${fullScale ? '' : 'px-lg-5 px-sm-3 px-2'}`}>
					<div className={` ${fullScale ? 'col-xl-12' : 'col-lg-7 col-12'}`}>
						<div className="row">
							<div className={`${fullScale ? '' : 'offset-lg-1'} p-lg-0`}>
								<h2
									className={`ibm_semi course-content-title-${
										fullScale ? 'full-scale d-flex justify-content-between' : 'normal-scale'
									} lora`}>
									{fullScale ? 'Course Content' : 'Program Overview'}
									{showCloseIcon && (
										<Image
											onClick={onCloseClick}
											role="button"
											src={toAbsoluteUrl('/media/course-detail-images/cancel.svg')}
										/>
									)}
								</h2>
								<div>
									<div className="accordion" id="accordionExample">
										{sections?.map((individualSection, index) => {
											const uniqueKey = `collapse${index}`;
											const isCurrentSectionMarkedAsSelected = individualSection.lessons.some(
												(lesson) => lesson.id === currentSelectedLessonId,
											);
											return (
												<div
													key={uniqueKey}
													className={`accordion-item accordion-item-shadow ${
														noBorderAccordion ? 'border-0' : ''
													}`}>
													<AccordionHeader
														heading={individualSection.name}
														uniqueKey={uniqueKey}
														fullScale={fullScale}
														isSectionSelected={isCurrentSectionMarkedAsSelected}
													/>
													<div
														id={uniqueKey}
														className={`accordion-collapse collapse ${
															isCurrentSectionMarkedAsSelected ? 'show' : ''
														}`}
														data-bs-parent="#accordionExample">
														<AccordionBody
															lessons={individualSection.lessons}
															showLockIcon={showLockIcon}
															currentSelectedLessonId={currentSelectedLessonId}
															handleLessonChange={handleLessonChange}
															quizReattemptList={quizReattemptList}
															fullScale={fullScale}
														/>
													</div>
												</div>
											);
										})}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default CourseProgramOverview;
