import React, { useState, useRef, ReactElement } from "react";
import { Button } from "react-bootstrap";

// Define the prop types for the child component
interface ChildProps {
  handleFullScreen: () => void;
}

// Ensure children is a valid ReactElement with the required props
interface FullScreenComponentProps {
  children: ReactElement<ChildProps>;
}

const FullScreenComponent: React.FC<FullScreenComponentProps> = ({
  children,
}: {
  children: React.ReactElement;
}) => {
  const [isFullScreen, setIsFullScreen] = useState(false);
  const fullScreenRef = useRef(null);

  const handleFullScreen = () => {
    if (!isFullScreen) {
      if (fullScreenRef.current.requestFullscreen) {
        fullScreenRef.current.requestFullscreen();
      } else if (fullScreenRef.current.mozRequestFullScreen) {
        fullScreenRef.current.mozRequestFullScreen();
      } else if (fullScreenRef.current.webkitRequestFullscreen) {
        fullScreenRef.current.webkitRequestFullscreen();
      } else if (fullScreenRef.current.msRequestFullscreen) {
        fullScreenRef.current.msRequestFullscreen();
      }
      setIsFullScreen(true);
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
      setIsFullScreen(false);
    }
  };

  // Check if children is a valid ReactElement and clone it if it is
  const clonedChild = React.cloneElement(children, {
    handleFullScreen,
    isFullScreen,
  });

  return (
    <div
      ref={fullScreenRef}
      style={{
        backgroundColor: "white",
      }}
      className={isFullScreen ? "manageFullScreenPadding" : ""}
    >
      {clonedChild}
    </div>
  );
};

export default FullScreenComponent;
