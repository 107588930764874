import React from "react";
import { toAbsoluteUrl } from "../../../assets/helpers/AssetHelpers";
import "../../../assets/sass/css/detail-page.css";

export const DetailBanner = (props: { course: any }) => {
  const { course } = props;
  return (
    <>
      <section className="bg-detail position-relative round">
        <div className="detail-wrapper-light"></div>
        <div className="bg-primary-30 detail-wrapper round">
          <div className="slider fullscreen">
            <div
              className=""
              style={{
                backgroundImage: `url(${toAbsoluteUrl(
                  "/media/banner/courseba.png",
                )})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center left",
                position: "relative",
                height: "60vh",
              }}
            ></div>
            <div className="container tag-on-slider ">
              <div className="row mx-2" style={{ marginTop: "10%" }}>
                <div className="col-md-5 col-12">
                  <div className="col-xl-2 col-4 p-1 ">
                    <div className="header-tag justify-content-center fw-bold">
                      {course?.courseType?.name === "Recorded Course"
                        ? "Course"
                        : course?.courseType?.name}
                    </div>
                  </div>
                  <div className="col-md-12 heading mx-0 ">
                    <div className="p-1 heading-text head1 fs-2 text-white font-title">
                      {course?.name}
                    </div>
                    <div className="p-1">
                      {course?.mentors?.length > 0 && (
                        <>
                          <div className="d-flex align-items-center fs-6">
                            <span
                              className="me-lg-2 font-subtitle"
                              style={{ color: "#B4BEC8" }}
                            >
                              By
                            </span>
                            <div className="position-relative d-flex">
                              {course?.mentors?.map(
                                (mentor: any, index: any) => (
                                  <div className="mentor-1 hero-mentors-imgs">
                                    <img
                                      src={
                                        mentor.mentor.userProfile[0].imageUrl
                                          ? mentor.mentor.userProfile[0]
                                              .imageUrl
                                          : toAbsoluteUrl(
                                              "../media/mentor/mentor1.png",
                                            )
                                      }
                                      alt="mentor_image"
                                      className="img-fluid rounded-circle avatar avatar-small"
                                    />
                                  </div>
                                ),
                              )}
                            </div>
                            {course?.mentors?.map((mentor: any, index: any) => (
                              <React.Fragment key={index}>
                                <a
                                  className="text-decoration-none text-white font-subtitle"
                                  href={`/mentor-view/${mentor.mentor.id}`}
                                >
                                  <a
                                    className="text-decoration-none text-white me-1 ms-1"
                                    style={{ fontSize: "16px" }}
                                  >
                                    {index > 0 &&
                                    index === course.mentors.length - 1 ? (
                                      <span style={{ color: "#B4BEC8" }}>
                                        &nbsp;and&nbsp;
                                      </span>
                                    ) : null}
                                  </a>
                                  {mentor.mentor.firstName ?? ""}{" "}
                                  {mentor.mentor.lastName ?? ""}
                                  {index < course.mentors.length - 2 ? (
                                    <>,&nbsp;</>
                                  ) : null}
                                </a>
                              </React.Fragment>
                            ))}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="hero-section-top-padding">
                <div className="container-fluid px-lg-0 px-md-0 px-0">
                    <div className="row gx-0">
                        <div className="col-12">
                            <div className="hero-carousel-slide">
                                <div className="container tag-on-slider ">
                                    <div className="row mx-4" >
                                        <div className="col-md-5">
                                            <div className="col-xl-2 col-4 p-1 ">
                                                <div className="header-tag justify-content-center fw-bold">
                                                    {course?.courseType?.name === "Recorded Course" ? "Course":course?.courseType?.name}
                                                </div>
                                            </div>
                                            <div className="col-md-12 heading mx-0">
                                                <div className="p-1 heading-text head1 fs-2 text-white font-title">
                                                    {course?.name}
                                                </div>
                                                <div className='p-1'>
                                                    {
                                                        course?.mentors?.length > 0 && (
                                                            <>
                                                                <div className="d-flex align-items-center fs-6">
                                                                    <span className='me-lg-2 font-subtitle' style={{color : "#B4BEC8"}}>By</span>
                                                                    <div className="position-relative d-flex">
                                                                        {course?.mentors?.map((mentor: any, index: any) => (
                                                                            <div className="mentor-1 hero-mentors-imgs">
                                                                                <img src={mentor.mentor.userProfile[0].imageUrl ? mentor.mentor.userProfile[0].imageUrl : toAbsoluteUrl("../media/mentor/mentor1.png")}
                                                                                    alt="mentor_image" className='img-fluid rounded-circle avatar avatar-small' />
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                    {course?.mentors?.map((mentor: any, index: any) => (
                                                                        <React.Fragment key={index}>
                                                                            <a className="text-decoration-none text-white font-subtitle" href={`/mentor-view/${mentor.mentor.id}`}>
                                                                                <a className='text-decoration-none text-white me-1 ms-1' style={{fontSize : "16px"}}>
                                                                                    {index > 0 && index === course.mentors.length - 1 ? <span style={{color : "#B4BEC8"}}>&nbsp;and&nbsp;</span> : null}
                                                                                </a>
                                                                                {mentor.mentor.firstName ?? ""} {mentor.mentor.lastName ?? ""}
                                                                                {index < course.mentors.length - 2 ? <>,&nbsp;</> : null}
                                                                            </a>
                                                                        </React.Fragment>
                                                                    ))}
                                                                </div>
                                                            </>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div id="hero-section-slider" className="carousel slide">
                                    <div className="carousel-inner">
                                        <div className="carousel-item active">
                                            <img alt="" src={toAbsoluteUrl("/media/banner/courseba.png")} className="img-fluid w-100 d-md-block d-none " />
                                            <img alt="" src={toAbsoluteUrl("/media/banner/courseba.png")} className="img-fluid w-100 d-md-none d-block " />
                                        </div>
                                        <div className="carousel-item">
                                            <img alt="" src={toAbsoluteUrl("/media/banner/courseba.png")} className="img-fluid w-100 d-md-block d-none " />
                                            <img alt="" src={toAbsoluteUrl("/media/banner/courseba.png")} className="img-fluid w-100 d-md-none d-block " />
                                        </div>
                                        <div className="carousel-item">
                                            <img alt="" src={toAbsoluteUrl("/media/banner/courseba.png")} className="img-fluid w-100 d-md-block d-none " />
                                            <img alt="" src={toAbsoluteUrl("/media/banner/courseba.png")} className="img-fluid w-100 d-md-none d-block " />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
    </>
  );
};
