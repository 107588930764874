import { useNavigate } from "react-router-dom";
import { GETTOPSELLINGCOURSE } from "../../Home-Page/core/requests";
import { slider_four } from "../../../assets/helpers/Slider";
import Slider from "react-slick";
import { MdDateRange } from "react-icons/md";
import { HiMiniComputerDesktop } from "react-icons/hi2";
import moment from "moment";
import { toAbsoluteUrl } from "../../../assets/helpers/AssetHelpers";
import { useMutation, useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { getUserData } from "../../../utils/getUserData";
import { ADDTOWISHLIST } from "../core/requests";
import toast from "react-hot-toast";
import { Login } from "../../../pages/auth/Login";

export const Recommendation = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [openRegister, setOpenRegister] = useState(false);
  // const { authToken: currentAuthToken } = getUserData() ?? { authToken: '' };
  const [currentAuthToken, setCurrentAuthToken] = React.useState(
    getUserData().authToken,
  );
  const [commit] = useMutation(ADDTOWISHLIST);

  const { data: topSellingResult } = useQuery(GETTOPSELLINGCOURSE);
  const topSellingRecord =
    topSellingResult?.getPublicTopSellingCourses?.records ?? [];

  let wishlistEvent = false;
  const handleOpen = (slug: string) => {
    if (wishlistEvent === false) {
      navigate(`/course-view/${slug}`);
    }
  };
  const handleWishList = async (id: any) => {
    wishlistEvent = true;
    if (currentAuthToken) {
      const variables = {
        courseId: id,
      };
      commit({
        variables: {
          courseId: id,
        },
        onCompleted: () => {
          toast.success("successfully added to wishlist", {
            style: {
              padding: "16px",
            },
          });
          navigate(`/my-profile`, { state: { page: "bookmark" } });
        },
        onError: (err: any) => {
          toast.error(err.message);
        },
      });
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  React.useEffect(() => {
    const handleStorageChange = () => {
      setCurrentAuthToken(getUserData().authToken);
    };

    window.addEventListener("storage", handleStorageChange);
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  React.useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentAuthToken(getUserData().authToken);
    }, 500);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <>
      <section className="section slickarrows d-lg-block d-md-block d-none">
        <div className="container mx-4">
          <div className="row align-items-center">
            <div className="col-xl-9 col-lg-9 col-md-6 col-12">
              <div className="heading mb-4 mx-1">
                <div className=" font-title title mb-3 head1">
                  You May Also Like
                </div>
                <p className="font-subtitle sub-title text-muted-dark head2">
                  Explore our bestsellers and elevate your learning! See what's
                  trending- check out our top picks!
                </p>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-6 col-12 d-grid gap-2 d-md-flex justify-content-md-end mt-4 ">
              <div className="d-lg-block d-md-block d-none">
                <button
                  id="btn"
                  className="btn btn-outline-primary mb-0"
                  onClick={() => {
                    navigate("/courses");
                  }}
                >
                  Explore all
                  <i className="fa fa-arrow-right ms-1"></i>
                </button>
              </div>
            </div>
          </div>
          <Slider {...slider_four}>
            {topSellingRecord.map((value: any) => {
              const date = moment
                .utc(value.startDatetime)
                .local()
                .format("YYYY-MM-DD HH:mm:ss");
              return (
                <div className="col-xxl-3 col-xl-3 col-lg-3 col-6 col-md-3 p-2 topSelling">
                  <div
                    className="card course-card rounded overflow-hidden border-muted h-100 cursor-pointer fixed-height-card"
                    onClick={() => {
                      handleOpen(value.slug);
                      window.scrollTo(0, 0); // Scroll to the top when a card is clicked
                    }}
                  >
                    <div className="card-body p-4">
                      <div className="card-img image position-relative overflow-hidden">
                        {value?.images?.length > 0 ? (
                          <>
                            {value?.images?.map((value: any) => {
                              return (
                                <>
                                  <img
                                    alt=""
                                    src={value.original}
                                    className="card-img-top  image img-fluid round bg-light"
                                  />
                                </>
                              );
                            })}
                          </>
                        ) : (
                          <img
                            alt=""
                            src={toAbsoluteUrl("/media/course/1.jpg")}
                            className=" card-img-top  image img-fluid round bg-light"
                          />
                        )}
                        {currentAuthToken !== null && (
                          <div
                            className="course-tag cursor-pointer"
                            onClick={() => {
                              handleWishList(value.id);
                            }}
                          >
                            <div className="course-badge text-dark p-2 py-1 rounded-1">
                              <i className="fa fa-bookmark tag fs-6"></i>
                            </div>
                          </div>
                        )}
                      </div>

                      <div className="card-text mt-3">
                        <div className="d-flex justify-content-between mb-2 justify-content-lg-between">
                          <span className="btn btn-outline-success course-btn rounded-pill px-3 py-1 text-dark">
                            {value.courseCategory.name
                              ? value.courseCategory.name
                              : "Technical"}
                          </span>
                          <div className="align-items-center border border-orange course_difficulty d-flex justify-content-center px-2 rounded-pill text-center">
                            <i
                              className={`mt-0 icon__course-difficulty difficulty-${value.courseLevel.name} position-relative`}
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              data-bs-html="true"
                              title={`Course Defficulty : ${value.courseLevel.name}`}
                            >
                              <span
                                className={` bar-1 ${
                                  value.courseLevel.name === "Advanced" ||
                                  value.courseLevel.name === "Intermediate" ||
                                  value.courseLevel.name === "Beginner"
                                    ? "opacity-100"
                                    : "opacity-25"
                                } `}
                              ></span>
                              <span
                                className={` bar-2 ${
                                  value.courseLevel.name === "Advanced" ||
                                  value.courseLevel.name === "Intermediate"
                                    ? "opacity-100"
                                    : "opacity-25"
                                } `}
                              ></span>
                              <span
                                className={` bar-3 ${
                                  value.courseLevel.name === "Advanced"
                                    ? "opacity-100"
                                    : "opacity-25"
                                } `}
                              ></span>
                            </i>
                          </div>
                        </div>
                        <div
                          className="fs-6 fw-bold cursor-pointer text-dark head1"
                          onClick={() => handleOpen(value.slug)}
                        >
                          {value.name ?? ""}
                        </div>
                        <p className="text-muted mb-0">
                          {value?.mentors?.map((mentor: any, index: any) => (
                            <React.Fragment key={index}>
                              <a
                                className="text-decoration-none text-muted-dark"
                                href={`/mentor-view/${mentor.mentor.id}`}
                              >
                                {index > 0 ? (
                                  <>and&nbsp;</>
                                ) : (
                                  <span>By&nbsp;</span>
                                )}
                                {mentor.mentor.firstName ?? ""}{" "}
                                {mentor.mentor.lastName ?? ""}
                                {index < value.mentors.length - 1 ? (
                                  <></>
                                ) : null}
                              </a>
                            </React.Fragment>
                          ))}
                          <div className="mt-1 d-flex">
                            <img
                              src="../media/icons/calendar-event-fill.svg"
                              alt=""
                              className="fs-5 me-1"
                            />
                            {/* <MdDateRange className="fs-5 me-1" /> */}
                            {moment(date).format("dddd").substring(0, 3) +
                              ", " +
                              moment(date).format("DD") +
                              " " +
                              moment(date).format("MMM").substring(0, 3) +
                              " " +
                              moment(date).format("YYYY") +
                              " • " +
                              moment(date).format("HH:mm")}
                          </div>
                          <div className="mt-1">
                            <HiMiniComputerDesktop className="fs-5 me-1" />{" "}
                            {value.courseMode?.name ?? ""}
                          </div>
                        </p>
                        <div className="mt-2 fw-bolder align-items-center">
                          {value.amount === 0 && value.sellPrice === 0 && (
                            <div className="mt-2 fw-bolder align-items-center">
                              <span className="fs-4">Free</span>
                            </div>
                          )}
                          {value.sellPrice !== 0 ? (
                            <>
                              <i className="fa fa-indian-rupee"></i>
                              <span className="fs-4">{value.sellPrice}</span>
                            </>
                          ) : (
                            ""
                          )}

                          {value.amount !== 0 ? (
                            <>
                              <del className="text-muted fs-6 ms-2">
                                <i className="fa fa-indian-rupee"></i>
                                <span className="fs-6">{value.amount}</span>
                              </del>
                            </>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </Slider>
          <div className="d-flex  text-center mt-lg-0 mt-md-0 mt-4">
            <div className="d-lg-none d-md-none d-block">
              <button id="btn" className="btn btn-outline-primary mb-0">
                Explore all
                <i className="fa fa-arrow-right ms-1"></i>
              </button>
            </div>
          </div>
        </div>
      </section>

      {/*
-------------------------------
 Responsive Mobile View Code 
 ---------------------------
 */}

      <section className="bg-light py-5 d-lg-none d-md-none d-block">
        <div className="container">
          <div className="row justify-content-lg-center">
            <div className="col-12">
              <div className="heading mb-4 mx-3">
                <div className=" font-title title mb-3 head1">
                  You May Also Like
                </div>
                <p className="font-subtitle sub-title text-muted-dark head2">
                  Explore our bestsellers and elevate your learning! See what's
                  trending- check out our top picks!
                </p>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-6 col-12 d-grid gap-2 d-md-flex justify-content-md-end mt-4 ">
              <div className="d-lg-block d-md-block d-none">
                <button
                  id="btn"
                  className="btn btn-outline-primary mb-0"
                  onClick={() => {
                    navigate("/courses");
                  }}
                >
                  Explore all
                  <i className="fa fa-arrow-right ms-1"></i>
                </button>
              </div>
            </div>
          </div>
          <Slider {...slider_four}>
            {topSellingRecord.map((value: any) => {
              const date = moment
                .utc(value.startDatetime)
                .local()
                .format("YYYY-MM-DD HH:mm:ss");
              return (
                <div className="col-xxl-3 col-xl-3 col-lg-3 col-6 col-md-3 p-2 topSelling">
                  <div
                    className="card course-card rounded overflow-hidden border-muted h-100 cursor-pointer fixed-height-card"
                    onClick={() => {
                      handleOpen(value.id);
                      window.scrollTo(0, 0); // Scroll to the top when a card is clicked
                    }}
                  >
                    <div className="card-body p-4">
                      <div className="card-img image position-relative overflow-hidden">
                        {value?.images?.length > 0 ? (
                          <>
                            {value?.images?.map((value: any) => {
                              return (
                                <>
                                  <img
                                    alt=""
                                    src={value.original}
                                    className="card-img-top  image img-fluid round bg-light"
                                  />
                                </>
                              );
                            })}
                          </>
                        ) : (
                          <img
                            alt=""
                            src={toAbsoluteUrl("/media/course/1.jpg")}
                            className=" card-img-top  image img-fluid round bg-light"
                          />
                        )}
                        {currentAuthToken !== null && (
                          <div
                            className="course-tag cursor-pointer"
                            onClick={() => {
                              handleWishList(value.id);
                            }}
                          >
                            <div className="course-badge text-dark p-2 py-1 rounded-1">
                              <i className="fa fa-bookmark tag fs-6"></i>
                            </div>
                          </div>
                        )}
                      </div>

                      <div className="card-text mt-3">
                        <div className="d-flex justify-content-between mb-2 justify-content-lg-between">
                          <span className="btn btn-outline-success course-btn rounded-pill px-3 py-1 text-dark">
                            {value.courseCategory.name
                              ? value.courseCategory.name
                              : "Technical"}
                          </span>
                          <div className="align-items-center border border-orange course_difficulty d-flex justify-content-center px-2 rounded-pill text-center">
                            <i
                              className={`mt-0 icon__course-difficulty difficulty-${value.courseLevel.name} position-relative`}
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              data-bs-html="true"
                              title={`Course Defficulty : ${value.courseLevel.name}`}
                            >
                              <span
                                className={` bar-1 ${
                                  value.courseLevel.name === "Advanced" ||
                                  value.courseLevel.name === "Intermediate" ||
                                  value.courseLevel.name === "Beginner"
                                    ? "opacity-100"
                                    : "opacity-25"
                                } `}
                              ></span>
                              <span
                                className={` bar-2 ${
                                  value.courseLevel.name === "Advanced" ||
                                  value.courseLevel.name === "Intermediate"
                                    ? "opacity-100"
                                    : "opacity-25"
                                } `}
                              ></span>
                              <span
                                className={` bar-3 ${
                                  value.courseLevel.name === "Advanced"
                                    ? "opacity-100"
                                    : "opacity-25"
                                } `}
                              ></span>
                            </i>
                          </div>
                        </div>
                        <div
                          className="fs-6 fw-bold cursor-pointer text-dark head1"
                          onClick={() => handleOpen(value.id)}
                        >
                          {value.name ?? ""}
                        </div>
                        <p className="text-muted mb-0">
                          {value?.mentors?.map((mentor: any, index: any) => (
                            <React.Fragment key={index}>
                              <a
                                className="text-decoration-none text-muted-dark"
                                href={`/mentor-view/${mentor.mentor.id}`}
                              >
                                {index > 0 ? (
                                  <>and&nbsp;</>
                                ) : (
                                  <span>By&nbsp;</span>
                                )}
                                {mentor.mentor.firstName ?? ""}{" "}
                                {mentor.mentor.lastName ?? ""}
                                {index < value.mentors.length - 1 ? (
                                  <></>
                                ) : null}
                              </a>
                            </React.Fragment>
                          ))}
                          <div className="mt-1 d-flex">
                            <img
                              src="../media/icons/calendar-event-fill.svg"
                              alt=""
                              className="fs-5 me-1"
                            />
                            {moment(date).format("dddd").substring(0, 3) +
                              ", " +
                              moment(date).format("DD") +
                              " " +
                              moment(date).format("MMM").substring(0, 3) +
                              " " +
                              moment(date).format("YYYY") +
                              " • " +
                              moment(date).format("HH:mm")}
                          </div>
                          <div className="mt-1">
                            <HiMiniComputerDesktop className="fs-5 me-1" />{" "}
                            {value.courseMode?.name ?? ""}
                          </div>
                        </p>
                        <div className="mt-2 fw-bolder align-items-center">
                          {value.amount === 0 && value.sellPrice === 0 && (
                            <div className="mt-2 fw-bolder align-items-center">
                              <span className="fs-4">Free</span>
                            </div>
                          )}
                          {value.sellPrice !== 0 ? (
                            <>
                              <i className="fa fa-indian-rupee"></i>
                              <span className="fs-4">{value.sellPrice}</span>
                            </>
                          ) : (
                            ""
                          )}

                          {value.amount !== 0 ? (
                            <>
                              <del className="text-muted fs-6 ms-2">
                                <i className="fa fa-indian-rupee"></i>
                                <span className="fs-6">{value.amount}</span>
                              </del>
                            </>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </Slider>
          <div className="d-flex  text-center mt-lg-0 mt-md-0 mt-4 mx-2">
            <div className="d-lg-none d-md-none d-block">
              <button id="btn" className="btn btn-outline-primary mb-0">
                Explore all
                <i className="fa fa-arrow-right ms-1"></i>
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
