import { Course, CourseEnrollmentObjectType } from 'components/_v2/Course/type';
import React, { createContext, useState, ReactNode, useContext, useMemo } from 'react';

interface UserCourseData {
	inProgressCourses: CourseEnrollmentObjectType[];
	completedCourses: CourseEnrollmentObjectType[];
	enrolledCourses: CourseEnrollmentObjectType[];
}

// Define the context type
type CourseContextType = {
	courses: Course[];
	setCourses: React.Dispatch<React.SetStateAction<Course[]>>;
	userCourses: Course[];
	setUserCourses: React.Dispatch<React.SetStateAction<Course[]>>;
	hasValueSet: boolean;
	setHasValueSet: React.Dispatch<React.SetStateAction<boolean>>;
	userCourseData: UserCourseData;
	setUserCourseData: React.Dispatch<React.SetStateAction<UserCourseData>>;
};
// Create the context
const CourseContext = createContext<CourseContextType | undefined>(undefined);

// Create the provider component
const CourseProvider = ({ children }: { children: ReactNode }) => {
	const [courses, setCourses] = useState<Course[]>([]);
	const [userCourses, setUserCourses] = useState<Course[]>([]);
	const [userCourseData, setUserCourseData] = useState<UserCourseData>({
		inProgressCourses: [],
		completedCourses: [],
		enrolledCourses: [],
	});

	const [hasValueSet, setHasValueSet] = useState<boolean>(false);

	const value = useMemo(
		() => ({
			courses,
			setCourses,
			hasValueSet,
			setHasValueSet,
			userCourses,
			setUserCourses,
			userCourseData,
			setUserCourseData,
		}),
		[
			courses,
			setCourses,
			hasValueSet,
			setHasValueSet,
			userCourses,
			setUserCourses,
			userCourseData,
			setUserCourseData,
		],
	);

	return <CourseContext.Provider value={value}>{children}</CourseContext.Provider>;
};

// Create a custom hook for accessing the context
const useCourseContext = () => {
	const context = useContext(CourseContext);
	if (context === undefined) {
		throw new Error('useCourseContext must be used within a CourseProvider');
	}
	return context;
};

export { CourseProvider, useCourseContext };
