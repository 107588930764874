import React, { useEffect, useState } from "react";
import WebinarLiveHeader from "./WebinarLiveHeader";
import "./global.css";
// import "./font.css"
import "./course.css";
import "./webinar-live.css";
import { Footer } from "../../pages/layout/footer/Footer";
import { useLocation, useNavigate } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { GETCOURSEBYSLUG } from "../Courses-Page/core/requests";
import Counter from "../../components/Courses-Page/Detail-Page/Counter";
import moment from "moment";
import htmlToDraft from "html-to-draftjs";
import { ContentState, EditorState } from "draft-js";

const WebinarIsLive = () => {
  const location = useLocation();
  const courseSlug = location.pathname.split("/").reverse()[0];
  const { data: courseRecord, refetch } = useQuery(GETCOURSEBYSLUG, {
    variables: {
      slug: courseSlug ?? "",
    },
    onCompleted: () => {
      refetch();
    },
  });
  const course: any = courseRecord?.getPublicCourseBySlug ?? [];
  const [description, setDescription] = useState(() => {
    if (course && course?.description) {
      const blocksFromHtml = htmlToDraft(course && course?.description);
      const { contentBlocks, entityMap } = blocksFromHtml;
      const contentState = ContentState.createFromBlockArray(
        contentBlocks,
        entityMap,
      );
      const editorState = EditorState.createWithContent(contentState);
      return editorState;
    }
  });
  const navigate = useNavigate();

  const gotocompleted = () => {
    window.location.href = course.meetingLink;
  };
  const gotocompleted1 = () => {
    navigate(`/webinarcompleted/${courseSlug}`);
  };

  const courseStartDate = new Date(course?.startDatetime);
  const originalDate = moment(course?.startDatetime);
  const formattedDate = originalDate.format("DD MMM YYYY");
  useEffect(() => {
    if (course && course?.description) {
      const blocksFromHtml = htmlToDraft(course && course?.description);
      const { contentBlocks, entityMap } = blocksFromHtml;
      const contentState = ContentState.createFromBlockArray(
        contentBlocks,
        entityMap,
      );
      const editorState = EditorState.createWithContent(contentState);
      setDescription(editorState);
    }
  }, [course]);
  return (
    <>
      <section className="section px-xl-4 -x-lg-3 px-2">
        <div className="">
          <div className="row">
            <WebinarLiveHeader course={course} />
          </div>
        </div>
      </section>
      {courseStartDate > new Date() &&
      course?.courseType.slug.charAt(0).toUpperCase() +
        course?.courseType.slug.slice(1) !==
        "Recorded-course" ? (
        <Counter data={courseStartDate} ctype={false}></Counter>
      ) : (
        ""
      )}
      <section
        className="webinar-counter-senction py-lg-5 d-lg-block d-md-block d-none"
        style={{ display: "bolck" }}
      >
        <div className="container-fluid">
          <div
            className="tab-pane fade show active"
            id="v-pills-live"
            role="tabpanel"
            aria-labelledby="v-pills-live-tab"
          >
            <div className="row justify-content-lg-center align-items-lg-center p-4">
              {courseStartDate <= new Date() && (
                <div className="col-12">
                  <div className="border-bottom pb-lg-5 text-lg-center course-counter-section">
                    <div className="live-dot glow mx-auto mb-lg-3"></div>
                    <div className="course-head fs-20 font-title fw-bold mb-lg-2">
                      {course?.courseType?.name} is live
                    </div>
                    <div className="d-flex justify-content-center w-50 mx-auto">
                      <div
                        className="btn fs-18 font-subtitle w-30 btn-color1 my-lg-2 me-2"
                        id="join-now"
                        onClick={() => gotocompleted()}
                      >
                        Join Now
                      </div>
                      <div
                        className="btn btn-primary fs-18 font-subtitle w-30  my-lg-2"
                        id="join-now"
                        onClick={() => gotocompleted1()}
                      >
                        Completed
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="col-12">
                <div className="pt-lg-4 container">
                  <div className="course-head fs-4 fw-bold font-title mb-lg-3">
                    {course?.courseType?.name} Details
                  </div>
                  <div className="course-head fs-18 font-subtitle fw-medium mb-lg-3">
                    <span className="me-2 color9">
                      <i className="fa-solid fa-calendar-day"></i>
                    </span>
                    <span>{formattedDate} </span>
                  </div>
                  <div className="course-head fs-18 font-subtitle fw-medium mb-lg-3">
                    <span className="me-2 color9">
                      <i className="fa-solid fa-clock"></i>
                    </span>
                    <span>6:00 pm to 8:00 pm </span>
                  </div>
                  {description?.getCurrentContent()?.hasText() ? (
                    <div className="card border-0 mt-3">
                      <div className="pt-2">
                        <div className="course-head fs-20 fw-bold font-title mb-lg-2">
                          Overview
                        </div>
                        <div
                          className="font-subtitle mt-1 subtitle"
                          dangerouslySetInnerHTML={{
                            __html: course?.description,
                          }}
                        />
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {/* <div className="course-head fs-20 fw-bold font-title mb-lg-2">Overview</div>
                                    <div className="course-head fs-18 font-subtitle mb-lg-3">{description}</div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/*
-------------------------------
 Responsive Mobile View Code 
 ---------------------------
 */}

      <section className="webinar-counter-senction mb-5 d-lg-none d-md-none d-block">
        <div className="container-fluid">
          <div
            className="tab-pane fade show active"
            id="v-pills-live"
            role="tabpanel"
            aria-labelledby="v-pills-live-tab"
          >
            <div className="row justify-content-lg-center align-items-lg-center">
              {courseStartDate <= new Date() && (
                <div className="col-12">
                  <div className="border-bottom pb-5 text-lg-center course-counter-section">
                    <div className="live-dot glow mx-auto mb-3"></div>
                    <div className="course-head fs-20 font-title fw-bold mb-2 text-center">
                      {course?.courseType?.name} is live
                    </div>
                    <div className="d-flex justify-content-center w-100 mx-auto">
                      <div
                        className="btn fs-18 font-subtitle  btn-color1 my-2 me-2"
                        id="join-now"
                        onClick={() => gotocompleted()}
                      >
                        Join Now
                      </div>
                      <div
                        className="btn btn-primary fs-18 font-subtitle   my-2"
                        id="join-now"
                        onClick={() => gotocompleted1()}
                      >
                        Completed
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="col-12">
                <div className="container">
                  <div className="course-head fs-4 fw-bold font-title mb-3 mt-3">
                    {course?.courseType?.name} Details
                  </div>
                  <div className="course-head fs-18 font-subtitle fw-medium mb-3">
                    <span className="me-2 color9">
                      <i className="fa-solid fa-calendar-day"></i>
                    </span>
                    <span>{formattedDate} </span>
                  </div>
                  <div className="course-head fs-18 font-subtitle fw-medium mb-3">
                    <span className="me-2 color9">
                      <i className="fa-solid fa-clock"></i>
                    </span>
                    <span>6:00 pm to 8:00 pm </span>
                  </div>
                  {description?.getCurrentContent()?.hasText() ? (
                    <div className="card border-0 mt-3">
                      <div className="pt-2">
                        <div className="course-head fs-20 fw-bold font-title mb-2">
                          Overview
                        </div>
                        <div
                          className="font-subtitle mt-1 subtitle"
                          dangerouslySetInnerHTML={{
                            __html: course?.description,
                          }}
                        />
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {/* <div className="course-head fs-20 fw-bold font-title mb-lg-2">Overview</div>
                                    <div className="course-head fs-18 font-subtitle mb-lg-3">{description}</div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <Footer/> */}
    </>
  );
};

export default WebinarIsLive;
