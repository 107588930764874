import { useEffect } from 'react';
import { ApolloError, gql, useLazyQuery, useMutation } from '@apollo/client';
import {
	Course,
	CourseApiGraphQL,
	CourseData,
	CourseEnrollmentObjectType,
	CourseEnrollmentStatusEnum,
	StudentCoursesResponse,
} from 'components/_v2/Course/type';
import { useCourseContext } from 'context/CourseContext';
import { GET_STUDENT_COURSES } from 'pages/auth/core/_requests';
import _ from 'lodash';
import { certificateSectionLesson } from '../types/globalTypes';
import { useLocation, useNavigate } from 'react-router-dom';
import { BASE_PAGE_URL, DASHBOARD_URL, NOTIFICATIONS_LIST } from '../pages/routing/routePages';

const GET_PUBLIC_COURSES = gql`
  query GetPublicCourses {
    getPublicCourses(where: {}, paginate: {per_page: 0, page: 0}) {
      records {
        ${CourseApiGraphQL(false)}
      }
      pagination {
        totalRecords
        currentPage
      }
    }
  }
`;

const ADD_COURSE_RATING = gql`
	mutation addUserCourseRating($addUserCourseRatingsInput: AddUserCourseRatingsInput!) {
		addUserCourseRating(addUserCourseRatingsInput: $addUserCourseRatingsInput) {
			id
			courseId
			starCount
			review
		}
	}
`;

const DOWNLOAD_CERTIFICATE_MUTATION = gql`
	mutation DownloadCertificate($courseId: String!) {
		downloadCertificate(course_id: $courseId)
	}
`;

const sortedCourseDataBySectionAndLesson = (
	courses: Course[],
	isUserCourse: boolean,
	certificateCourseKeyMap?: {
		[key: string]: { hasCertificate: boolean; isCourseCompleted: boolean };
	},
): Course[] => {
	return courses.map((course) => {
		const sortedSections = _.sortBy(course.sections, 'sortNumber').map((section, index) => {
			const sortedLessons = _.sortBy(section.lessons, 'sortNumber');
			if (isUserCourse && index === course.sections.length - 1) {
				if (certificateCourseKeyMap?.[course.id].hasCertificate) {
					sortedLessons.push(
						certificateSectionLesson({
							sectionId: section.id,
							nameOfCertificate: 'Download Certificate',
							isLocked: !certificateCourseKeyMap[course.id].isCourseCompleted,
							disabledSelection: !certificateCourseKeyMap[course.id].isCourseCompleted,
						}),
					);
				}
			}
			return {
				...section,
				lessons: sortedLessons,
			};
		});

		return { ...course, sections: sortedSections };
	});
};

const useCourses = () => {
	const {
		hasValueSet,
		setHasValueSet,
		setCourses,
		courses,
		setUserCourses,
		setUserCourseData,
		userCourses,
	} = useCourseContext();
	const [loadCourses, { loading: loadingPublicCourses, error, data }] =
		useLazyQuery<CourseData>(GET_PUBLIC_COURSES);
	const navigate = useNavigate();
	const location = useLocation();

	useEffect(() => {
		if (data && !hasValueSet) {
			const sortedCourses = sortedCourseDataBySectionAndLesson(
				[...data.getPublicCourses.records],
				false,
			);
			setCourses(sortedCourses);
			setHasValueSet(true);
		}
	}, [data, hasValueSet, setCourses, setHasValueSet]);

	const getCourses = async () => {
		if (!hasValueSet) {
			await loadCourses();
			setHasValueSet(true);
		}
	};

	const [getStudentCourses, { loading: loadingStudentCourses }] =
		useLazyQuery<StudentCoursesResponse>(GET_STUDENT_COURSES(false), {
			fetchPolicy: 'network-only', // Always fetch from the network
		});

	const [downloadCertificate] = useMutation(DOWNLOAD_CERTIFICATE_MUTATION);

	const [addCourseReview] = useMutation(ADD_COURSE_RATING);

	const handleDownloadPdf = (downloadCertificateData: any, courseId: string) => {
		const linkSource = `data:application/pdf;base64,${downloadCertificateData}`;
		const downloadLink = document.createElement('a');
		const utcTimestamp = new Date().toISOString().replace(/:/g, '-');
		const fileName = `${courseId}_${utcTimestamp}.pdf`;
		downloadLink.href = linkSource;
		downloadLink.download = fileName;
		document.body.appendChild(downloadLink);
		downloadLink.click();
		document.body.removeChild(downloadLink);
	};

	const addReviewForCourse = ({
		courseId,
		review,
		starCount,
		onCompletedCallback,
		onErrorCallback,
	}: {
		courseId: string;
		starCount: number;
		review: string;
		onCompletedCallback?: () => void;
		onErrorCallback?: () => void;
	}) => {
		addCourseReview({
			variables: { addUserCourseRatingsInput: { courseId, starCount, review } },
			onCompleted: () => {
				if (onCompletedCallback) {
					onCompletedCallback();
				}
			},
			onError: (error: ApolloError) => {
				if (onErrorCallback) {
					onErrorCallback();
				}
				console.error(error.message);
			},
		});
	};

	const downloadCertificateForCourse = ({
		courseId,
		onCompletedCallback,
		onErrorCallback,
	}: {
		courseId: string;
		onCompletedCallback?: () => void;
		onErrorCallback?: () => void;
	}) => {
		downloadCertificate({
			variables: { courseId },
			onCompleted: (response: { downloadCertificate: string }) => {
				const { downloadCertificate } = response;
				handleDownloadPdf(downloadCertificate, courseId);
				if (onCompletedCallback) {
					onCompletedCallback();
				}
			},
			onError: (err: ApolloError) => {
				if (onErrorCallback) {
					//Do not remove this log, it will display the error in console only.
					onErrorCallback();
				}
				// setError(
				//   "An error occurred while downloading the certificate. Please try again later.",
				// );
			},
		});
	};

	const getUserCourses = async (
		callbackFunction?: (data: CourseEnrollmentObjectType[]) => void,
	) => {
		try {
			const { data } = await getStudentCourses();

			setUserCourseData({
				inProgressCourses: data.getStudentCourses.records.filter(
					(course) => course.status === CourseEnrollmentStatusEnum.IN_PROGRESS,
				),
				completedCourses: data.getStudentCourses.records.filter(
					(course) => course.status === CourseEnrollmentStatusEnum.COMPLETED,
				),
				enrolledCourses: data.getStudentCourses.records.filter(
					(course) => course.status === CourseEnrollmentStatusEnum.ENROLLED,
				),
			});

			const courseData: Course[] = (data.getStudentCourses.records || [])?.reduce(
				(accumulator, currentValue) => {
					accumulator.push(currentValue.course);
					return accumulator;
				},
				[],
			);
			const certificateCourseKeyMap = (data.getStudentCourses.records || [])?.reduce(
				(accumulator, currentValue) => {
					if (!accumulator[currentValue.courseId]) {
						accumulator[currentValue.courseId] = {
							hasCertificate: false,
							isCourseCompleted: false,
						};
					}
					accumulator[currentValue.courseId].hasCertificate =
						currentValue.allow_certificate_download;
					accumulator[currentValue.courseId].isCourseCompleted =
						currentValue.status === CourseEnrollmentStatusEnum.COMPLETED;
					return accumulator;
				},
				{},
			);

			setUserCourses(sortedCourseDataBySectionAndLesson(courseData, true, certificateCourseKeyMap));
			if (callbackFunction) {
				callbackFunction(data.getStudentCourses.records);
			}
		} catch (error) {
			console.error('Query failed:', error);
			const currentUrl = location.pathname;
			if (currentUrl.includes(DASHBOARD_URL) || currentUrl.includes(NOTIFICATIONS_LIST)) {
				navigate(BASE_PAGE_URL);
			}
		}
	};

	const getCourseFromSlug = (courseSlug: string) =>
		userCourses.find((course) => course.slug === courseSlug);

	return {
		loadingPublicCourses,
		loadingStudentCourses,
		error,
		courses: courses || [],
		getCourses,
		getCourseFromSlug,
		getUserCourses,
		downloadCertificateForCourse,
		handleDownloadPdf,
		addReviewForCourse,
	};
};

export default useCourses;
