import React from 'react';
import './PaymentInformation.css';
import { toAbsoluteUrl } from 'assets/helpers/AssetHelpers';
import { BASE_PAGE_URL, MY_COURSE_URL } from 'pages/routing/routePages';
import { useNavigate } from 'react-router-dom';
import Button from './Button';
import { ButtonBorderType, ButtonType } from './types';

interface PaymentStatus {
	image: string;
	alt: string;
	title: string;
	description: React.ReactNode;
	hideButton?: boolean;
}

const PaymentSuccess: React.FC<PaymentStatus> = ({
	image,
	alt,
	title,
	description,
	hideButton = false,
}) => {
	const navigate = useNavigate();

	return (
		<section className="payment-detail-wrapper">
			<div className="container">
				<div className="row justify-content-center">
					<div className="payment-layout mt-5">
						<div className="card px-4">
							<div className="layout-success text-center">
								<img
									src={toAbsoluteUrl('/media/logo/hoGrowthBlack.svg')}
									alt="Logo"
									className="pb-4"
								/>
								<div>
									<img srcSet={image} alt={alt} className="img-fluid mb-4" />
								</div>
								<h2 className="payment-status lora">{title}</h2>
								<p className="payment-desc g_mid">{description}</p>
								<div className="d-flex gap-md-0 justify-content-evenly mb-4 flex-wrap gap-3">
									<Button
										buttonText="Back to Home Page"
										buttonType={ButtonType.GREY_BUTTON}
										classes="g_mid w-auto fs-16"
										handleClick={() => navigate(BASE_PAGE_URL)}
										buttonBorderType={ButtonBorderType.GREY_BORDER}
									/>

									{!hideButton && (
										<Button
											buttonText="Go to My Courses"
											buttonType={ButtonType.PRIMARY_BUTTON}
											classes="g_mid w-auto fs-16"
											handleClick={() => navigate(MY_COURSE_URL)}
											buttonBorderType={ButtonBorderType.BLACK_BORDER}
										/>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default PaymentSuccess;
