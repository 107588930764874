import React, { useMemo, useState } from 'react';
import './Home.css';
import OfflineCourse from '../OfflineCourse/OfflineCourse';
import LiveCourse from '../LiveCourse/LiveCourse';
import useCourses from 'hooks/useCourses';
import Loading from '../CommonComponents/Loading';

const Home = () => {
	const [activeTab, setActiveTab] = useState('offline');
	const { courses, error, loadingPublicCourses } = useCourses();

	const offlineCourses = useMemo(() => {
		return courses?.filter((course) => course?.courseMode?.name === 'Offline');
	}, [courses]);

	const liveCourses = useMemo(() => {
		return courses?.filter((course) => course?.courseMode?.name === 'Online');
	}, [courses]);

	if (loadingPublicCourses) return <Loading width={200} />;
	if (error) return <p>Error: {error.message}</p>;

	const handleTabChange = (tab: string) => {
		setActiveTab(tab);
	};

	return (
		<section style={{ backgroundColor: 'white' }}>
			<div className="container-fluid">
				<ul className="px-0" style={{ marginBottom: '-5px' }} id="myTab" role="tablist">
					<li className="nav-item nav_dash_item" role="presentation">
						<button
							className={`nav-style g_mid ${activeTab === 'offline' ? 'active' : ''}`}
							type="button"
							onClick={() => handleTabChange('offline')}>
							Offline Courses
						</button>
					</li>
					<li className="nav-item nav_dash_item" role="presentation">
						<button
							className={`nav-style g_mid ${activeTab === 'live' ? 'active' : ''}`}
							type="button"
							onClick={() => handleTabChange('live')}>
							Live Courses
						</button>
					</li>
				</ul>
				<div id="myTabContent">
					<div
						className={`tab-pane fade ${activeTab === 'offline' ? 'show active' : ''}`}
						id="home-tab-pane"
						role="tabpanel">
						{activeTab === 'offline' && <OfflineCourse courses={offlineCourses} />}
					</div>
					<div
						className={`tab-pane fade ${activeTab === 'live' ? 'show active' : ''}`}
						id="profile-tab-pane"
						role="tabpanel">
						{activeTab === 'live' && (
							<LiveCourse
								courses={liveCourses}
								onExploreOfflineCourses={() => handleTabChange('offline')}
							/>
						)}
					</div>
				</div>
			</div>
		</section>
	);
};

export default Home;
