import React from "react";
import WebinarLiveHeader from "./WebinarLiveHeader";
import "./global.css";
// import "./font.css"
import "./course.css";
import { Footer } from "../../pages/layout/footer/Footer";
import { useNavigate } from "react-router-dom";
// import "./webinar-live.css"

const WebinarWillGoLive = () => {
  const navigate = useNavigate();

  const gotolive = () => {
    navigate("/webinarislive");
  };

  return (
    <>
      <section className="section px-xl-4 -x-lg-3 px-2">
        <div className="">
          <div className="row">
            <WebinarLiveHeader />
          </div>
        </div>
      </section>
      <section
        className="webinar-counter-senction py-lg-5"
        style={{ display: "bolck" }}
      >
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-11">
              <div className="row justify-content-lg-center align-items-lg-center border p-4">
                <div className="col-12">
                  <div className="border-bottom pb-lg-5 text-lg-center course-counter-section">
                    <div className="course-head fs-20 font-title fw-bold mb-lg-4 ">
                      Webinar will go live in
                    </div>
                    <div className="uk">
                      <div className="uk">
                        <div
                          className="d-lg-flex uk-grid-small uk-child-width-auto justify-content-center"
                          uk-grid
                          uk-countdown="date: 2023-11-21T17:33:42+00:00"
                        >
                          <div>
                            <div className="uk-countdown-number uk-countdown-days">
                              00
                            </div>
                            <div className="uk-countdown-label uk-margin-small uk-text-center uk-visible@s">
                              Days
                            </div>
                          </div>
                          <div className="uk-countdown-separator mt-4 me-2 ms-2">
                            :
                          </div>
                          <div>
                            <div className="uk-countdown-number uk-countdown-hours">
                              12
                            </div>
                            <div className="uk-countdown-label uk-margin-small uk-text-center uk-visible@s">
                              Hours
                            </div>
                          </div>
                          <div className="uk-countdown-separator mt-4 me-2 ms-2">
                            :
                          </div>
                          <div>
                            <div className="uk-countdown-number uk-countdown-minutes">
                              45
                            </div>
                            <div className="uk-countdown-label uk-margin-small uk-text-center uk-visible@s">
                              Minutes
                            </div>
                          </div>
                          <div className="uk-countdown-separator mt-4 me-2 ms-2">
                            :
                          </div>
                          <div>
                            <div className="uk-countdown-number uk-countdown-seconds">
                              34
                            </div>
                            <div className="uk-countdown-label uk-margin-small uk-text-center uk-visible@s">
                              Seconds
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-center w-50 mx-auto ">
                      <div
                        className="btn fs-18 font-switzer w-30 btn-color1 my-lg-4"
                        id="add-to-calendar"
                        onClick={() => gotolive()}
                      >
                        Add to Calendar
                      </div>
                    </div>
                  </div>

                  <div
                    className="border-bottom pb-lg-5 text-lg-center course-live-section"
                    style={{ display: "none" }}
                  >
                    <div className="live-dot glow mx-auto mb-lg-3"></div>
                    <div className="course-head fs-20 font-title fw-medium mb-lg-2">
                      Webinar is live
                    </div>
                    <div className="d-flex justify-content-center w-50 mx-auto">
                      <div
                        className="btn fs-18 font-switzer w-30 btn-color1 my-lg-2"
                        id="join-now"
                      >
                        Join Now
                      </div>
                    </div>
                  </div>

                  <div
                    className="border-bottom pb-lg-5 text-lg-center cerificate-section"
                    style={{ display: "none" }}
                  >
                    <div className="course-head fs-2 font-title mb-lg-3">
                      Basics of Stock Market Investments
                    </div>
                    <img
                      src="../media/preRecord/celebration.png"
                      alt=""
                      height="140"
                      width="140"
                      className="img-fluid"
                    />
                    <div className="course-head fs-4 fw-medium font-title mb-lg-3">
                      Congratulations!
                    </div>
                    <div className="course-head fs-18 fw-medium font-switzer color2">
                      You have successfully completed this course on
                    </div>
                    <div className="course-head fs-18 fw-bold font-switzer mb-lg-3 color2">
                      23rd September, 2023.
                    </div>
                    <div className="d-flex justify-content-center w-60 mx-auto">
                      <div
                        className="btn fs-18 font-switzer w-30 btn-color1 my-lg-2 me-3"
                        id="download-certificate"
                      >
                        Download Certificate
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="pt-lg-5">
                    <div className="course-head fs-4 fw-bold font-title mb-lg-3">
                      Webinar Details
                    </div>
                    <div className="course-head fs-18 font-switzer fw-medium mb-lg-3">
                      <span className="me-2 color9">
                        <i className="fa-solid fa-calendar-day"></i>
                      </span>
                      <span>21 Sept 2023 </span>
                    </div>
                    <div className="course-head fs-18 font-switzer fw-medium mb-lg-3">
                      <span className="me-2 color9">
                        <i className="fa-solid fa-clock"></i>
                      </span>
                      <span>6:00 pm to 8:00 pm </span>
                    </div>
                    <div className="course-head fs-20 fw-bold font-title mb-lg-2">
                      Overview
                    </div>
                    <div className="course-head fs-18 font-switzer mb-lg-3">
                      Amet minim mollit non deserunt ullamco est sit aliqua
                      dolor do amet sint. Velit officia consequat duis enim
                      velit mollit. Exercitation veniam consequat sunt nostrud
                      amet. Amet minim mollit non deserunt ullamco est sit
                      aliqua dolor do amet sint. Velit officia consequat duis
                      enim velit mollit.
                    </div>
                  </div>
                  <div
                    className="pt-lg-2 mentor-details"
                    style={{ display: "none" }}
                  >
                    <div className="course-head fs-4 fw-medium font-title mb-lg-2 color5">
                      Mentor
                    </div>
                    <div className="d-flex align-items-center w-25">
                      <div className="w-15 text-center">
                        <img
                          src="../media/preRecord/mentor.png"
                          alt="mentor.png"
                          height="36"
                          width="36"
                          className="img-fluid"
                        />
                      </div>
                      <div className="w-85 px-lg-2">
                        <div className="color1 font-switzer fs-5 fw-medium">
                          Wade Warren
                        </div>
                      </div>
                    </div>
                    <div className="pt-lg-3">
                      <div className="course-head fs-20 fw-medium font-title mb-lg-2">
                        Downloadable Resources
                      </div>
                      <div className="d-lg-flex flex-lg-column w-25">
                        <a
                          className="text-decoration-none color5 fs-16 font-switzer bg-color3 px-lg-3 py-lg-2 my-lg-2"
                          download="./pdf/sample.pdf"
                          href="./pdf/sample.pdf"
                        >
                          <span className="me-2 color1">
                            <i className="fa-solid fa-file-arrow-down"></i>
                          </span>
                          <span>Cheat sheet to xyz strategy.pdf</span>
                        </a>
                        <a
                          className="text-decoration-none color5 fs-16 font-switzer bg-color3 px-lg-3 py-lg-2 my-lg-2"
                          download="./pdf/sample.pdf"
                          href="./pdf/sample.pdf"
                        >
                          <span className="me-2 color1">
                            <i className="fa-solid fa-file-arrow-down"></i>
                          </span>
                          <span>Amet-non-deserunt-aliqua.xlsx</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default WebinarWillGoLive;
