import React, { useEffect, useState } from "react";
import CourseCompleteCard from "../PreRecordedCourses/CourseCompleteCard";
import { Footer } from "../../pages/layout/footer/Footer";
import { useLocation, useNavigate } from "react-router-dom";
import htmlToDraft from "html-to-draftjs";
import { ContentState, EditorState } from "draft-js";
import { GETCOURSEBYSLUG } from "../Courses-Page/core/requests";
import { useQuery } from "@apollo/client";
import moment from "moment";
import { DOWNLOADCERTIFICATE } from "../Cart/core/requests";
import { Recommendation } from "../Courses-Page/Detail-Page/Recommendation";

const WebinarCompleted = () => {
  const location = useLocation();
  const courseSlug = location.pathname.split("/").reverse()[0];
  const { data: courseRecord, refetch } = useQuery(GETCOURSEBYSLUG, {
    variables: {
      slug: courseSlug ?? "",
    },
    onCompleted: () => {
      refetch();
    },
  });
  const course: any = courseRecord?.getPublicCourseBySlug ?? [];
  const { data: certificateData } = useQuery(DOWNLOADCERTIFICATE, {
    variables: {
      course_id: course?.id,
    },
    // onError: (err:any) => {
    //     toast.error(err.message)
    // }
  });
  const [description, setDescription] = useState(() => {
    if (course && course?.description) {
      const blocksFromHtml = htmlToDraft(course && course?.description);
      const { contentBlocks, entityMap } = blocksFromHtml;
      const contentState = ContentState.createFromBlockArray(
        contentBlocks,
        entityMap,
      );
      const editorState = EditorState.createWithContent(contentState);
      return editorState;
    }
  });
  const navigate = useNavigate();
  const gotohome = () => {
    navigate(`/webinarwillgolive`);
  };

  const courseStartDate = new Date(course?.startDatetime);
  const originalDate = moment(course?.startDatetime);
  const formattedDate = originalDate.format("DD MMM YYYY");
  useEffect(() => {
    if (course && course?.description) {
      const blocksFromHtml = htmlToDraft(course && course?.description);
      const { contentBlocks, entityMap } = blocksFromHtml;
      const contentState = ContentState.createFromBlockArray(
        contentBlocks,
        entityMap,
      );
      const editorState = EditorState.createWithContent(contentState);
      setDescription(editorState);
    }
  }, [course]);
  const downloadPDF = async (pdf: any) => {
    const linkSource = `data:application/pdf;base64,${pdf}`;
    const downloadLink = document.createElement("a");
    const courseID = course?.id;
    const utcTimestamp = new Date().toISOString().replace(/:/g, "-");
    const fileName = `${courseID}_${utcTimestamp}.pdf`;
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };
  const startover = () => {
    navigate(`/webinarislive/${courseSlug}`);
  };

  const mentorProfileImage =
    course &&
    course?.mentors &&
    course?.mentors[0] &&
    course?.mentors[0]?.mentor &&
    course?.mentors[0]?.mentor?.userProfile
      ? course?.mentors[0]?.mentor?.userProfile[0]?.imageUrl
      : null;
  const mentorname =
    course &&
    course?.mentors &&
    course?.mentors[0] &&
    course?.mentors[0]?.mentor
      ? course?.mentors[0]?.mentor?.firstName +
        " " +
        course?.mentors[0]?.mentor?.lastName
      : "";
  return (
    <>
      <section className="section px-xl-4 -x-lg-3 px-2 d-lg-block d-md-block d-none">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="row justify-content-lg-center align-items-lg-center border p-4">
                <div className="col-12">
                  <div className="border-bottom pb-lg-5 text-lg-center">
                    <div className="font-title course-head fs-2 font-title mb-lg-3 head1">
                      {course?.name}
                    </div>
                    <img
                      src="../media/preRecord/celebration.png"
                      alt="celebration"
                      height="140"
                      width="140"
                      className="img-fluid"
                    />
                    <div className="course-head fs-4 fw-bold font-title mb-lg-3">
                      Congratulations!
                    </div>
                    <div className="course-head fs-18 fw-medium font-subtitle color2">
                      You have successfully completed this course on
                    </div>
                    <div className="course-head fs-18 fw-bold font-subtitle mb-lg-3 color2">
                      {" "}
                      {moment
                        .utc(new Date())
                        .utcOffset("+05:30")
                        .format("Do MMMM YYYY")}
                    </div>
                    <div className="d-flex justify-content-center w-60 mx-auto">
                      <div
                        className="btn fs-18 font-subtitle w-30 btn-color1 my-lg-2 me-3"
                        id="download-certificate"
                        onClick={() =>
                          downloadPDF(certificateData?.downloadCertificate)
                        }
                      >
                        Download Certificate
                      </div>
                      <div
                        className="btn fs-18 font-subtitle w-30 btn-outline-color1 my-lg-2"
                        id="start-over"
                        onClick={() => startover()}
                      >
                        Start Over
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="pt-lg-4">
                    <div className="course-head fs-4 fw-bold font-title mb-lg-3">
                      {course?.courseType?.name} Details
                    </div>
                    <div className="course-head fs-18 font-subtitle fw-medium mb-lg-3">
                      <span className="me-2 color9">
                        <i className="fa-solid fa-calendar-day"></i>
                      </span>
                      <span>{formattedDate} </span>
                    </div>
                    <div className="course-head fs-18 font-subtitle fw-medium mb-lg-3">
                      <span className="me-2 color9">
                        <i className="fa-solid fa-clock"></i>
                      </span>
                      <span>6:00 pm to 8:00 pm </span>
                    </div>
                    <div className="course-head fs-20 fw-bold font-title mb-lg-2">
                      Overview
                    </div>
                    <div className="course-head fs-18 font-subtitle mb-lg-3">
                      {description?.getCurrentContent()?.hasText() ? (
                        <div className="card border-0">
                          <div className="pb-0">
                            <div
                              className="font-subtitle mt-1 subtitle"
                              dangerouslySetInnerHTML={{
                                __html: course?.description,
                              }}
                            />
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div
                    className="pt-lg-2 mentor-details"
                    style={{ display: "block" }}
                  >
                    <div className="course-head fs-4 fw-bold font-title mb-lg-2 color5">
                      Mentor
                    </div>
                    <div className="d-flex align-items-center w-15">
                      <div className="w-30">
                        {mentorProfileImage ? (
                          <img
                            src={mentorProfileImage}
                            alt="mentor.png"
                            height="60"
                            width="60"
                            className="img-fluid mentor-img"
                          />
                        ) : (
                          <img
                            src="../media/mentor/profile_image.jpg"
                            alt="mentor.png"
                            height="60"
                            width="60"
                            className="img-fluid"
                          />
                        )}
                      </div>
                      <div
                        className="w-85 px-lg-2"
                        onClick={() => {
                          navigate(
                            `/mentor-view/${course?.mentors[0]?.mentor.id}`,
                          );
                        }}
                      >
                        <div className="color1 font-subtitle fs-5 fw-medium">
                          {mentorname}
                        </div>
                      </div>
                    </div>
                    <div className="pt-lg-3">
                      <div className="course-head fs-20 fw-bold font-title mb-lg-2">
                        Downloadable Resources
                      </div>
                      <div className="d-lg-flex flex-lg-column w-25">
                        <a
                          className="text-decoration-none color5 fs-16 font-subtitle bg-color3 px-lg-3 py-lg-2 my-lg-2"
                          download="../pdf/sample.pdf"
                          href="../pdf/sample.pdf"
                        >
                          <span className="me-2 color1">
                            <i className="fa-solid fa-file-arrow-down"></i>
                          </span>
                          <span>Cheat sheet to xyz strategy.pdf</span>
                        </a>
                        <a
                          className="text-decoration-none color5 fs-16 font-subtitle bg-color3 px-lg-3 py-lg-2 my-lg-2"
                          download="./.pdf/sample.pdf"
                          href="../pdf/sample.pdf"
                        >
                          <span className="me-2 color1">
                            <i className="fa-solid fa-file-arrow-down"></i>
                          </span>
                          <span>Amet-non-deserunt-aliqua.xlsx</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row justify-content-center">
            <Recommendation />
          </div>
        </div>
      </section>

      {/*
-------------------------------
 Responsive Mobile View Code 
 ---------------------------
 */}

      <section className="section px-4 px-2 d-lg-none d-md-none d-block">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="row border p-4 mb-5">
                <div className="col-12 text-center">
                  <div className="border-bottom pb-5 text-center">
                    <div className="font-title course-head fs-4 fw-bold font-title mb-3 head1">
                      {course?.name}
                    </div>
                    <img
                      src="../media/preRecord/celebration.png"
                      alt="celebration"
                      height="140"
                      width="140"
                      className="img-fluid"
                    />
                    <div className="course-head fs-4 fw-bold font-title mb-3">
                      Congratulations!
                    </div>
                    <div className="course-head fs-18 fw-medium font-subtitle color2">
                      You have successfully completed this course on
                    </div>
                    <div className="course-head fs-18 fw-bold font-subtitle mb-3 color2">
                      {" "}
                      {moment
                        .utc(new Date())
                        .utcOffset("+05:30")
                        .format("Do MMMM YYYY")}
                    </div>
                    <div className="d-flex justify-content-center w-100 mx-auto">
                      <div
                        className="btn fs-18 font-subtitle  btn-color1 my-2 me-3"
                        id="download-certificate"
                        onClick={() =>
                          downloadPDF(certificateData?.downloadCertificate)
                        }
                      >
                        Download Certificate
                      </div>
                      <div
                        className="btn fs-18 font-subtitle  btn-outline-color1 my-2"
                        id="start-over"
                        onClick={() => startover()}
                      >
                        Start Over
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="pt-3">
                    <div className="course-head fs-4 fw-bold font-title mb-3">
                      {course?.courseType?.name} Details
                    </div>
                    <div className="course-head fs-18 font-subtitle fw-medium mb-3">
                      <span className="me-2 color9">
                        <i className="fa-solid fa-calendar-day"></i>
                      </span>
                      <span>{formattedDate} </span>
                    </div>
                    <div className="course-head fs-18 font-subtitle fw-medium mb-3">
                      <span className="me-2 color9">
                        <i className="fa-solid fa-clock"></i>
                      </span>
                      <span>6:00 pm to 8:00 pm </span>
                    </div>
                    <div className="course-head fs-20 fw-bold font-title mb-2">
                      Overview
                    </div>
                    <div className="course-head fs-18 font-subtitle mb-3">
                      {description?.getCurrentContent()?.hasText() ? (
                        <div className="card border-0">
                          <div className="pb-0">
                            <div
                              className="font-subtitle mt-1 subtitle"
                              dangerouslySetInnerHTML={{
                                __html: course?.description,
                              }}
                            />
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div
                    className="pt-2 mentor-details"
                    style={{ display: "block" }}
                  >
                    <div className="course-head fs-4 fw-bold font-title color5">
                      Mentor
                    </div>
                    <div className="d-flex align-items-center py-3">
                      <div className="w-30">
                        {mentorProfileImage ? (
                          <img
                            src={mentorProfileImage}
                            alt="mentor.png"
                            height="60"
                            width="60"
                            className="img-fluid mentor-img"
                          />
                        ) : (
                          <img
                            src="../media/mentor/profile_image.jpg"
                            alt="mentor.png"
                            height="60"
                            width="60"
                            className="img-fluid"
                          />
                        )}
                      </div>
                      <div
                        className="w-85 px-lg-2"
                        onClick={() => {
                          navigate(
                            `/mentor-view/${course?.mentors[0]?.mentor.id}`,
                          );
                        }}
                      >
                        <div className="color1 font-subtitle fs-5 fw-medium">
                          {mentorname}
                        </div>
                      </div>
                    </div>
                    <div className="pt-lg-3">
                      <div className="course-head fs-20 fw-bold font-title mb-2">
                        Downloadable Resources
                      </div>
                      <div className="d-flex flex-column w-100">
                        <a
                          className="text-decoration-none color5 fs-16 font-subtitle bg-color3 px-3 py-2 my-2"
                          download="../pdf/sample.pdf"
                          href="../pdf/sample.pdf"
                        >
                          <span className="me-2 color1">
                            <i className="fa-solid fa-file-arrow-down"></i>
                          </span>
                          <span>Cheat sheet to xyz strategy.pdf</span>
                        </a>
                        <a
                          className="text-decoration-none color5 fs-16 font-subtitle bg-color3 px-3 py-2 my-2"
                          download="./.pdf/sample.pdf"
                          href="../pdf/sample.pdf"
                        >
                          <span className="me-2 color1">
                            <i className="fa-solid fa-file-arrow-down"></i>
                          </span>
                          <span>Amet-non-deserunt-aliqua.xlsx</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row justify-content-center">
            <Recommendation />
          </div>
        </div>
      </section>
      {/* <Footer /> */}
    </>
  );
};

export default WebinarCompleted;
