import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FAQs } from "./Faqs-cms";
import { Terms } from "./Terms";
import { PrivacyPolicy } from "./PrivacyPolicy";
import { Help } from "./Help";
import { AboutUs } from "./About";
import { ContactUs } from "./ContactUs";

export const Cms = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const cms = location.pathname.split("/").reverse()[0];
  if (!cms) {
    navigate("/");
  }
  return (
    <>
      {cms === "faqs" && <FAQs />}
      {cms === "terms_condition" && <Terms />}
      {cms === "privacy_policy" && <PrivacyPolicy />}
      {cms === "help" && <Help />}
      {cms === "about-us" && <AboutUs />}
      {cms === "contact-us" && <ContactUs />}
    </>
  );
};
