import React from 'react';

interface ApproachCardProps {
  title: string;
  description: string;
  imageUrl: string;
  reverse?: boolean;  // Add prop to control layout reversal
}

const ApproachCard: React.FC<ApproachCardProps> = ({ title, description, imageUrl, reverse }) => {
  return (
    <div className={`row align-items-center ${reverse ? 'flex-row-reverse' : ''} my-5`}>
      <div className={`col-lg-6 col-md-12 col-12 ${reverse ? '' : ''}`}>
        <div className="card-body text-start">
          <h2 className="about-approach-card-title my-1">{title}</h2>
          <p className="about-approach-card-subtitle my-3">{description}</p>
        </div>
      </div>
      <div className={`col-lg-6 col-md-12 col-12 my-md-4 ${!reverse ? '' : ''}`}>
        <img src={imageUrl} alt={title} className="img-fluid cover-image" />
      </div>
    </div>
  );
};

export default ApproachCard;
