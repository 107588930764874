import { toAbsoluteUrl } from 'assets/helpers/AssetHelpers';
import { useEffect, useState } from 'react';
import { Image } from 'react-bootstrap';
import { useQuizContext } from 'context/QuizContext';

const TimerComponent = ({
	remainingTime,
	onTimeFinish,
}: {
	remainingTime: number;
	onTimeFinish: () => void;
}) => {
	const [timeLeft, setTimeLeft] = useState(remainingTime);
	const { setRemainingTime } = useQuizContext();
	useEffect(() => {
		// If timeLeft is 0, we don't need to set up the interval
		if (timeLeft <= 0) {
			onTimeFinish();
			return;
		}

		// Set up an interval to update the timer every second
		const intervalId = setInterval(() => {
			setTimeLeft((prevTime) => {
				// If the time reaches 0, clear the interval
				if (prevTime <= 1) {
					clearInterval(intervalId);
					return 0;
				}
				setRemainingTime(prevTime - 1);
				return prevTime - 1;
			});
		}, 1000);

		// Clean up the interval on component unmount
		return () => clearInterval(intervalId);
	}, [timeLeft]);

	// Convert seconds to MM:SS format
	const formattedTime = (seconds: number) => {
		const minutes = Math.floor(seconds / 60);
		const secs = seconds % 60;
		return `${String(minutes).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
	};

	return (
		<div className="d-flex align-items-center mx-1 gap-2">
			<Image src={toAbsoluteUrl('/media/course-detail-images/time-remaining.svg')} />
			<span className="lora quiz-timer-style">{formattedTime(timeLeft)}</span>
		</div>
	);
};

export default TimerComponent;
