import { gql, useLazyQuery, useQuery } from '@apollo/client';
import { StudentNotificationsListDataType } from 'components/_v2/Header/types';
import { useNotificationContext } from 'context/NotificationContext';

const GET_STUDENT_NOTIFICATION_LIST = gql`
  query GetStudentNotificationList {
    getStudentNotificationList {
      records {
        id
        courseId
        type
        status
        sentStatus
        sentTime
        title
        message
        sentBy
        createdAt
        updatedAt
        createdById
        updatedById
        readStatus
      }
      pagination {
        totalRecords
        currentPage
      }
    }
  }
`;

const useNotification = () => {
  const { setStudentNotifications, setNotificationPagination } = useNotificationContext();
  const [getStudentNotifications] = useLazyQuery<StudentNotificationsListDataType>(
    GET_STUDENT_NOTIFICATION_LIST,
    {
      onCompleted: (data) => {
        setStudentNotifications(data.getStudentNotificationList.records);
        setNotificationPagination(data.getStudentNotificationList.pagination);
      }
    }
  );
  return { getStudentNotifications };
};

export default useNotification;
