import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getCmsDetailBySlug } from "../../pages/auth/core/_requests";
import { Helmet } from "react-helmet";
import createDOMPurify from "dompurify";
interface About {
  id: string;
  name: string;
  seoTitle: string;
  seoDescription: string;
  keywords: string;
  slug: string;
  content: string;
}
export const AboutUs = () => {
  const [aboutcontent, setaboutcontent] = useState<About>();
  const location = useLocation();
  const navigate = useNavigate();
  const cms = location.pathname.split("/").reverse()[0];
  const DOMPurify = createDOMPurify(window);

  function generateHTMLContent(content: any) {
    return { __html: DOMPurify.sanitize(content) };
  }
  if (!cms) {
    navigate("/");
  }
  useEffect(() => {
    const fetchData = async () => {
      const response = await getCmsDetailBySlug(cms);
      setaboutcontent(response.data.data.getCmsDetailBySlug);
    };
    fetchData();
  }, []);
  return (
    <section className="section">
      <div className="container col-lg-7">
        <div className="row">
          <div className=" mt-5">
            <div className="">
              <h1 className="fw-bold font-title">About-Us</h1>
              <h4 className="font-subtitle mt-4">About-us</h4>
            </div>
            {/* <div className='mt-4 text-muted-dark fs-5'>
                            This document is an electronic record in terms of the Information Technology Act, 2000 and Rules made thereunder and other applicable law as amended from time to time.
                            This document is published in accordance with the provisions of Rule 3 (1) of the Information Technology (Intermediaries guidelines) Rules, 2011 that require publishing
                            the rules and regulations, privacy policy and terms for access or usage of https://SmartLearn.com/ website; and ‘SmartLearn’ applications for mobile and handheld devices
                            (collectively called the “Platform”).Please read these Terms & Conditions of Service (hereinafter referred to as “Terms of Service / Terms”) carefully before accessing
                            or using the Platform. You agree to be bound by the terms described herein. If you do not agree to these terms, please do not use the Platform. Using the Platform will
                            be considered as a deemed acceptance of these Platform Terms. This agreement does not require a physical/digital signature. This agreement shall not be invalidated solely
                            on the ground that it is not physically signed.For the purpose of these Termswherever the context so requires “you” or “User” shall mean any natural or legal person who accesses and registers with the Platform to avail services rendered by the Platform
                        </div> */}
            <p className="text-muted-dark fs-6 mt-4">
              This document is an electronic record in terms of the Information
              Technology Act, 2000 and Rules made thereunder and other
              applicable law as amended from time to time. This document is
              published in accordance with the provisions of Rule 3 (1) of the
              Information Technology (Intermediaries guidelines) Rules, 2011
              that require publishing the rules and regulations, privacy policy
              and terms for access or usage of https://SmartLearn.com/ website;
              and ‘SmartLearn’ applications for mobile and handheld devices
              (collectively called the “Platform”).Please read these Terms &amp;
              Conditions of Service (hereinafter referred to as “Terms of
              Service / Terms”) carefully before accessing or using the
              Platform. You agree to be bound by the terms described herein. If
              you do not agree to these terms, please do not use the Platform.
              Using the Platform will be considered as a deemed acceptance of
              these Platform Terms. This agreement does not require a
              physical/digital signature. This agreement shall not be
              invalidated solely on the ground that it is not physically
              signed.For the purpose of these Terms, wherever the context so
              requires “you” or “User” shall mean any natural or legal person
              who accesses and registers with the Platform to avail services
              rendered by the Platform
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};
