import React, { useCallback, useMemo, useState } from 'react';
import { CardHeight, SelectedTabType } from 'components/_v2/CommonComponents/types';
import {
	Course,
	CourseEnrollmentObjectType,
	CourseEnrollmentStatusEnum,
} from 'components/_v2/Course/type';
import { ButtonGroup, ToggleButton } from 'react-bootstrap';
import CardInformation from 'components/_v2/CommonComponents/CardInformation';
import { useUserContext } from 'context/UserContext';
import { useCourseContext } from 'context/CourseContext';
import useCourses from 'hooks/useCourses';
import './CommonTabView.scss';
import NoCourses from 'components/_v2/CommonComponents/NoCourses';
import toast from 'react-hot-toast';

enum CourseEnumsDifferentiators {
	'PUBLIC' = 'public',
	'ENROLLMENT' = 'enrollment',
}

const successfulReview = () => {
	toast.success('Your review has been submitted!');
};

const failedReview = () => {
	toast.error('There was some error submitting your review. Please try again!');
};

const CommonTabView = ({
	isUserSpecificContent,
	selectedTabType = SelectedTabType.ALL,
}: {
	isUserSpecificContent: boolean;
	selectedTabType?: SelectedTabType;
}) => {
	const [radioValue, setRadioValue] = useState<SelectedTabType>(selectedTabType);
	const { user } = useUserContext();
	const { downloadCertificateForCourse, addReviewForCourse, getUserCourses } = useCourses();
	const { courses, userCourses, userCourseData } = useCourseContext();

	const tabRadios = useMemo(() => {
		let radios = [{ name: SelectedTabType.ALL, value: SelectedTabType.ALL }];
		if (isUserSpecificContent) {
			radios = [
				...radios,
				{ name: SelectedTabType.ONGOING, value: SelectedTabType.ONGOING },
				{ name: SelectedTabType.COMPLETED, value: SelectedTabType.COMPLETED },
			];
		} else {
			radios = [
				...radios,
				{ name: SelectedTabType.OFFLINE, value: SelectedTabType.OFFLINE },
				{ name: SelectedTabType.LIVE, value: SelectedTabType.LIVE },
			];
		}
		return radios;
	}, [isUserSpecificContent]);

	const courseObjectForMapping: {
		courses: Course[] | CourseEnrollmentObjectType[];
		type: CourseEnumsDifferentiators;
	} = useMemo(() => {
		switch (radioValue) {
			case SelectedTabType.ALL:
				if (isUserSpecificContent) {
					return {
						courses: [
							...userCourseData.completedCourses,
							...userCourseData.inProgressCourses,
							...userCourseData.enrolledCourses,
						],
						type: CourseEnumsDifferentiators.ENROLLMENT,
					};
				} else {
					return { courses: courses, type: CourseEnumsDifferentiators.PUBLIC };
				}
			case SelectedTabType.ONGOING:
				return {
					courses: userCourseData.inProgressCourses,
					type: CourseEnumsDifferentiators.ENROLLMENT,
				};
			case SelectedTabType.COMPLETED:
				return {
					courses: userCourseData.completedCourses,
					type: CourseEnumsDifferentiators.ENROLLMENT,
				};
			case SelectedTabType.OFFLINE:
				return {
					courses: courses.filter((course) => course.courseMode.name === 'Offline'),
					type: CourseEnumsDifferentiators.PUBLIC,
				};
			case SelectedTabType.LIVE:
				return {
					courses: courses.filter((course) => course.courseMode.name === 'Online'),
					type: CourseEnumsDifferentiators.PUBLIC,
				};
			default:
				return { courses: [], type: CourseEnumsDifferentiators.PUBLIC };
		}
	}, [isUserSpecificContent, radioValue, courses, userCourses, userCourseData]);

	const renderTabContent = useCallback(() => {
		if (courseObjectForMapping.type === CourseEnumsDifferentiators.ENROLLMENT) {
			const coursesToUse = [...courseObjectForMapping.courses] as CourseEnrollmentObjectType[];
			if (!coursesToUse.length) {
				return <NoCourses />;
			}
			return coursesToUse.map((courseObject: CourseEnrollmentObjectType) => {
				return (
					<CardInformation
						key={courseObject.id}
						course={courseObject.course}
						isUserSpecificContent={true}
						disableHoverInteraction={true}
						user={user}
						userCourses={userCourses}
						cardHeight={CardHeight.AUTO}
						showBackdropShadow={false}
						handleDownloadPDF={(setLoading) =>
							downloadCertificateForCourse({
								courseId: courseObject.course.id,
								onCompletedCallback: () => setLoading(false),
								onErrorCallback: () => setLoading(false),
							})
						}
						areQuizzesCompleted={courseObject.allow_certificate_download}
						allowDownloadCertificate={
							courseObject.allow_certificate_download &&
							courseObject.course.hasCertificate &&
							courseObject.status === CourseEnrollmentStatusEnum.COMPLETED
						}
						hasCardOffset
						onSubmitRatings={async ({ courseId, starCount, review }) => {
							addReviewForCourse({
								review,
								starCount,
								courseId,
								onErrorCallback: failedReview,
								onCompletedCallback: successfulReview,
							});
							await getUserCourses();
						}}
					/>
				);
			});
		} else {
			const coursesToUse = [...courseObjectForMapping.courses] as Course[];
			if (!coursesToUse.length) {
				return <NoCourses />;
			}
			return coursesToUse.map((course: Course) => {
				return (
					<CardInformation
						key={course.id}
						course={course}
						isUserSpecificContent={false}
						disableHoverInteraction={false}
						user={user}
						userCourses={userCourses}
						cardHeight={CardHeight.LARGE}
						showBackdropShadow={false}
					/>
				);
			});
		}
	}, [courseObjectForMapping]);

	return (
		<div
			className={`bg-backgroundGlobal container ${
				isUserSpecificContent ? 'p-sm-1 px-0 py-1' : ''
			} `}>
			<ButtonGroup className="mb-2">
				{tabRadios.map((radio, idx) => (
					<ToggleButton
						key={idx}
						id={`radio-${idx}`}
						type="radio"
						name="radio"
						value={radio.value}
						checked={radioValue === radio.value}
						onChange={(e) => setRadioValue(e.currentTarget.value as SelectedTabType)}
						className={`rounded-pill box-shadow-none border-style-black mx-lg-2 me-1 mx-sm-2 px-4 py-2 outline-none ${
							radioValue === radio.value ? 'hover-white' : 'hover-black'
						}`}>
						{radio.name}
					</ToggleButton>
				))}
			</ButtonGroup>

			<div className="row mt-4">{renderTabContent()}</div>
		</div>
	);
};
export default CommonTabView;
