import React from 'react';
import './downloadapp.css';
import { toAbsoluteUrl } from 'assets/helpers/AssetHelpers';

const DownloadApp = () => {
	return (
		<>
			<section className="downloadapp-wraper mb-5">
				<div className="container">
					<div className="row">
						<div className="col-lg-11 col-md-10 col-11 layout-set downloadapp-layout">
							<div className="row">
								<div className="col-lg-8 col-md-12 col-12 px-lg-5 px-md-5 px-3">
									<div className="content-spacing sm:text-center">
										<h2 className="dapp-title ibm_sup">HoGrowth App(Coming Soon)</h2>
										<p className="dapp-subtitle p_reg py-3">
											Register and start Learning Today and Earn Profits
										</p>
										<div className="d-flex w-100 align-items-center justify-content-lg-start justify-content-md-center  flex-column flex-sm-row gap-sm-2 gap-4">
											<a rel="noopener noreferrer" style={{ cursor: 'default' }}> 
												<img
													src={toAbsoluteUrl('/media/downloadapp-images/playstore.png')}
													alt="PlayStore_Image"
												/>
											</a>
											<a rel="noopener noreferrer" style={{ cursor: 'default' }}>
												<img
													src={toAbsoluteUrl('/media/downloadapp-images/apple.png')}
													alt="Apple_Image"
												/>
											</a>
										</div>
									</div>
								</div>

								<div className="col-lg-4 col-md-12 col-12 mb-lg-0 mb-md-4 d-flex align-items-center justify-content-center mb-4 py-2">
									<img
										src={toAbsoluteUrl(`/media/downloadapp-images/hogrowth-small-screen.png`)}
										alt="Dash1"
										style={{ width: 'auto' }}
										height={400}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default DownloadApp;
