import { useEffect, useState } from "react";
import { Recommendation } from "../../Courses-Page/Detail-Page/Recommendation";
import { AiFillCheckCircle } from "react-icons/ai";
import { MdCancel } from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";
import { DOWNLOADINVOICE, VERIFY_ORDER_MUTATION } from "../core/requests";
import { useMutation, useQuery } from "@apollo/client";
import toast from "react-hot-toast";
import Loader from "../../common/Loader/Loader";
export const Payment = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [error, seterror] = useState("");
  const [loading, setLoading] = useState(true);
  const razorpayPaymentLinkId = queryParams.get("razorpay_payment_link_id");
  const [verifyorder, { data: verifyorderdata }] = useMutation(
    VERIFY_ORDER_MUTATION,
    {
      variables: {
        verifyOrderInputData: {
          transactionId: razorpayPaymentLinkId,
        },
      },
      onError: (err: any) => {
        seterror(err.message);
        toast.error(err.message);
        setLoading(false);
      },
      onCompleted: () => {
        setLoading(false);
      },
    },
  );
  const navigate = useNavigate();
  const { data: invoiceData, refetch } = useQuery(DOWNLOADINVOICE, {
    variables: {
      order_id: verifyorderdata?.verifyOrder?.id,
    },
    // onError: (err:any) => {
    //     toast.error(err.message)
    // }
  });
  const fetchorder = async () => {
    await verifyorder({
      variables: {
        verifyOrderInputData: {
          transactionId: razorpayPaymentLinkId,
        },
      },
    });
  };
  const downloadPDF = async (pdf: any) => {
    refetch();
    const linkSource = `data:application/pdf;base64,${pdf}`;
    const downloadLink = document.createElement("a");
    const orderID = verifyorderdata?.verifyOrder?.id;
    const utcTimestamp = new Date().toISOString().replace(/:/g, "-");
    const fileName = `${orderID}_${utcTimestamp}.pdf`;
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  useEffect(() => {
    fetchorder();
  }, []);
  const calculateTotalPrice = () => {
    const total = verifyorderdata?.verifyOrder?.orderDetails.reduce(
      (acc: any, item: any) => {
        return {
          totalPrice: acc.totalPrice + item.price,
          totalTaxAmount: acc.totalTaxAmount + item.taxAmount,
        };
      },
      { totalPrice: 0, totalTaxAmount: 0 },
    );

    return total;
  };
  const total = calculateTotalPrice();
  return (
    <section className="section px-xl-4 px-lg-3 px-2">
      <section className="section">
        <div className="container">
          {loading && (
            <>
              <Loader />
            </>
          )}
          {verifyorderdata?.verifyOrder?.transactionStatus === "COMPLETED" ? (
            <div className="payment card border-0">
              <div className="card-body text-center">
                <AiFillCheckCircle className="payment-icon" />
                <h3 className="font-title fw-bold mt-4">Payment Successful</h3>
                <p className="font-subtitle text-muted">
                  <strong>Congratulations!</strong> Your payment has been
                  successfully processed
                </p>
                <div>
                  {/* <button className='font-subtitle btn btn-primary'>Add to Calender</button> */}
                  <button
                    className="font-subtitle btn btn-outline-primary ms-3"
                    onClick={() => {
                      navigate(`/my-profile`, {
                        state: { page: "mylearning" },
                      });
                    }}
                  >
                    Go to my purchases
                  </button>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          {/* {error}
                      <div className="payment card border-0">
                        <div className="card-body text-center d-none">
                            <MdCancel className='payment-icon text-danger' />
                            <h3 className='font-title fw-bold mt-4'>Payment Failed !!</h3>
                            <p className='font-subtitle text-muted'>The Payment for <strong>Order Id : {verifyorderdata?.verifyOrder?.id}</strong>  Could not Proceed Due to <strong>XYZ Reason.</strong> </p>
                            <div>
                                <button className='font-subtitle btn btn-primary'>Try Again</button>
                                <button className='font-subtitle btn btn-outline-primary ms-3'>Help Center</button>
                            </div>
                        </div>
                    </div> */}
          {error === "Student has already enrolled for these courses!" ||
          verifyorderdata?.verifyOrder?.transactionStatus === "FAILED" ? (
            <div className="payment card border-0">
              <div className="card-body text-center">
                <MdCancel className="payment-icon text-danger" />
                <h3 className="font-title fw-bold mt-4">Payment Failed !!</h3>
                <p className="font-subtitle text-muted">
                  Could not Proceed Due to
                  <strong>{error} Reason.</strong>{" "}
                </p>
                <div>
                  <button
                    className="font-subtitle btn btn-primary"
                    onClick={() => {
                      navigate("/");
                    }}
                  >
                    Try Again
                  </button>
                  {/* <button className='font-subtitle btn btn-outline-primary ms-3'>Help Center</button> */}
                </div>
              </div>
            </div>
          ) : (
            ""
          )}

          {verifyorderdata && (
            <>
              {" "}
              <div className="card border-muted mt-4">
                <div className="card-body">
                  <div className="row my-3 align-items-center">
                    <div className="col-lg-8">
                      <h3 className="font-title head1">
                        Order ID: {verifyorderdata?.verifyOrder?.id}
                      </h3>
                    </div>
                    <div className="col-lg-4 text-end">
                      <a
                        className="color1 text-decoration-none fs-16 font-switzer fw-bold cursor-pointer"
                        onClick={() => {
                          refetch();
                          downloadPDF(invoiceData?.downloadInvoice);
                        }}
                      >
                        Download Invoice
                      </a>
                    </div>
                  </div>
                  <div className="border-line" />
                  <div className="row my-3 align-items-center">
                    <h6 className="font-subtitle text-muted">Items</h6>

                    {verifyorderdata?.verifyOrder?.orderDetails.map(
                      (items: any, index: any) => {
                        return (
                          <>
                            <div className="col-lg-8">
                              <div className="font-subtitle text-dark fs-6">
                                {items.course.name}
                              </div>
                            </div>
                            <div className="col-lg-4 text-end">
                              <div className="text-muted fw-bold ">
                                <i className="fa fa-indian-rupee mt-3 px-1"></i>
                                {items.price}
                              </div>
                            </div>
                          </>
                        );
                      },
                    )}
                  </div>
                  <div className="border-line" />
                  {/* <div className="row my-3 align-items-center">
                                <h6 className='font-subtitle text-muted'>Coupon Applied</h6>
                                <div className="col-lg-8">
                                    <div className='text-dark fs-6'><BsTicketPerforatedFill className='me-2 text-primary fs-5 mb-1' />EXX43FGD</div>
                                </div>
                                <div className="col-lg-4 text-end">
                                    <div className='text-muted'><i className='fa fa-indian-rupee px-1'></i>-30.00</div>
                                </div>
                            </div> */}
                  <div className="border-line" />
                  <div className="row my-3 align-items-center">
                    <div className="col-lg-8">
                      <div className="font-subtitle text-dark fs-6">
                        GST (18%)
                      </div>
                    </div>
                    <div className="col-lg-4 text-end">
                      <div className="text-muted">
                        <i className="fa fa-indian-rupee px-1"></i>
                        {(verifyorderdata?.verifyOrder?.subTotal *
                          verifyorderdata?.verifyOrder?.tax) /
                          100}
                        .00
                      </div>
                    </div>
                  </div>
                  <div className="border-line" />
                  <div className="row my-3 align-items-center">
                    <div className="col-lg-8">
                      <div className="font-subtitle fw-bold text-dark fs-5">
                        Total Amount
                      </div>
                    </div>
                    <div className="col-lg-4 text-end">
                      <div className="text-dark fs-6 fw-bold">
                        <i className="fa fa-indian-rupee px-1"></i>
                        {verifyorderdata?.verifyOrder?.total}.00
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </section>
      <Recommendation />
    </section>
  );
};
