import {
  ApolloClient,
  InMemoryCache,
  split,
  HttpLink,
  createHttpLink,
} from "@apollo/client";
import { WebSocketLink } from "@apollo/client/link/ws";
import { getMainDefinition } from "@apollo/client/utilities";
import { setContext } from "@apollo/client/link/context";
import { AUTH_LOCAL_STORAGE_KEY } from "./pages/auth/core/AuthHelpers";

const wsLink = new WebSocketLink({
	uri: process.env.REACT_APP_WS_LINK,
	options: {
		reconnect: true,
	},
});

const httpLink = createHttpLink({
	uri: process.env.REACT_APP_API_URL,
});

const authLink = setContext((_, { headers }) => {
  const lsValue = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY);
  let authToken = "";

  if (lsValue) {
    const user = JSON.parse(lsValue);
    authToken = user.access_token;
  }

  return {
    headers: {
      ...headers,
      authorization: authToken ? `Bearer ${authToken}` : "",
    },
  };
});

const authHttpLink = authLink.concat(httpLink);

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  authHttpLink
);

// Create the Apollo Client
const client = new ApolloClient({
  link: splitLink,
  cache: new InMemoryCache(),
});

export default client;
