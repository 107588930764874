import { UserType } from 'types/globalTypes';
import { QuizObjectType } from '../ViewCourseData/LessonTypesComponents/Quiz/type';

export interface Course {
	courseLevel: any;
	id: string;
	name: string;
	slug: string;
	shortDescription: string;
	description: string;
	sortOrder: number;
	status: CourseStatusEnum;
	images: { original: string }[];
	courseType: { name: string } | null;
	amount: number;
	sellPrice: number;
	startDatetime: string;
	endDatetime: string;
	totalSeat: number;
	costType: CourseCostType;
	address: string;
	meetingLink: string;
	currentChapter?: LessonType;
	nextChapter?: LessonType;
	meetingNumber: string;
	meetingPassword: string;
	hasCertificate: boolean;
	courseSettings: CourseSettings;
	hasQuiz: boolean;
	quizId: string;
	requirements: string;
	totalUserRatings?: number;
	averageStarCount?: number;
	myCourseRatings?: UserCourseRating;
	userCourseRatings?: UserCourseRating[];
	courseCategoryId: string;
	courseSubCategoryId: string;
	courseLevelId: number;
	courseMode: {
		id: string;
		name: string;
	};
	courseLanguages: {
		id: string;
		name: string;
	}[];
	courseRatings: CourseRatingsObjectType;
	courseModeId: string;
	courseTypeId: string;
	highlights: CourseHighlights[];
	mentors: { mentor: Mentor }[];
	objectives: { objective: string }[];
	courseSkills: CourseSkills[];
	completed: boolean;
	wishlist: boolean;
	enrollmentStatus: string;
	previewVideo: string;
	courseEnrollmentCount: number;
	ratingCounts: number;
	inCart: boolean;
	progressPercentage: number;
	sections: CourseSections[];
	relatedCoursesUId: string[];
}

export enum RatingStatusEnum {
	PENDING = 'PENDING',
	APPROVED = 'APPROVED',
	REJECTED = 'REJECTED',
}

export enum CourseStatusEnum {
	DRAFT = 'DRAFT',
	READY_FOR_REVIEW = 'READY_FOR_REVIEW',
	IN_REVIEW = 'IN_REVIEW',
	READY_FOR_PUBLISH = 'READY_FOR_PUBLISH',
	PUBLISHED = 'PUBLISHED',
	ARCHIVED = 'ARCHIVED',
}

export interface UserCourseRating {
	review: string;
	starCount: number;
	status: RatingStatusEnum;
	user: {
		fullName: string;
		id: string;
	};
}

export interface CourseRatingsObjectType {
	courseId: string;
	createdAt: Date;
	createdById: string;
	id: string;
	ratings: number;
	starCount: number;
	updatedAt: Date;
	updatedById: string;
}

export interface CourseSections {
	completed: boolean;
	id: string;
	name: string;
	introduction: string;
	lessons: LessonType[];
	sortNumber: number;
}
export interface LessonType {
	completed: boolean;
	completedAt?: Date;
	contentUrl?: string;
	description: string;
	duration?: number;
	id: string;
	meetingUrl?: string;
	sectionId: string;
	introduction: string;
	isLock: boolean;
	name: string;
	quiz?: QuizObjectType;
	quizId?: string;
	sortNumber?: number;
	startDate?: Date;
	status: LessonStatusEnum;
	type: LessonTypeEnum;
	disabledSelection?: boolean;
}

export interface Mentor {
	id: string;
	firstName: string;
	lastName: string;
	email: string;
	userProfile?: {
		id: string;
		imageUrl: string;
		description: string;
		gender: UsersGendersEnum;
		qualification: string;
	}[];
}

export interface CourseData {
	getPublicCourses: {
		records: Course[];
		pagination: {
			totalRecords: number;
			currentPage: number;
		};
	};
}

export interface StudentCoursesResponse {
	getStudentCourses: {
		records: CourseEnrollmentObjectType[];
		pagination: {
			totalRecords: number;
			currentPage: number;
		};
	};
}

export enum CourseCostType {
	FREE = 'FREE',
	PAID = 'PAID',
}

export enum SkillStatus {
	ACTIVE = 'ACTIVE',
	INACTIVE = 'INACTIVE',
}

export enum LessonTypeEnum {
	VIDEO = 'VIDEO',
	DOCS = 'DOCS',
	QUIZ = 'QUIZ',
	MEETING = 'MEETING',
}

export enum LessonStatusEnum {
	COMPLETED = 'COMPLETED',
	IN_PROGRESS = 'IN_PROGRESS',
	NOT_STARTED = 'NOT_STARTED',
}

export enum UsersGendersEnum {
	MALE = 'MALE',
	FEMALE = 'FEMALE',
	OTHER = 'OTHER',
}

export interface CourseHighlights {
	id: string;
	courseId: string;
	highlightIcon: string;
	highlightText: string;
}

export interface CourseSettings {
	id: string;
	courseId: string;
	dateVisible: boolean;
	durationVisible: boolean;
	timeVisible: boolean;
	languageVisible: boolean;
	levelVisible: boolean;
	courseTypeVisible: boolean;
	createdAt: Date;
	updatedAt: Date;
}

export interface CourseSkills {
	id: string;
	name: string;
	slug: string;
	status: SkillStatus;
}

export const LessonTypeToText = {
	[LessonTypeEnum.DOCS]: 'Docs',
	[LessonTypeEnum.MEETING]: 'Meeting',
	[LessonTypeEnum.QUIZ]: 'Quiz',
	[LessonTypeEnum.VIDEO]: 'Video',
};

export enum CourseEnrollmentStatusEnum {
	ENROLLED = 'ENROLLED',
	IN_PROGRESS = 'IN_PROGRESS',
	COMPLETED = 'COMPLETED',
}

export interface CourseEnrollmentObjectType {
	completedDatetime: Date;
	course: Course;
	courseId: string;
	createdAt: Date;
	deletedAt?: Date; // Assuming this can be null or undefined
	endDatetime: Date;
	id: string;
	allow_certificate_download: boolean;
	orderId: string;
	startDatetime: Date;
	status: CourseEnrollmentStatusEnum;
	updatedAt: Date;
	user: Pick<UserType, 'email' | 'firstName' | 'id' | 'lastName' | 'userProfile'> & {
		courses: Course[];
	};
	userId: string;
}

export const CourseApiGraphQL = (includeCurrentChapter = false) => `id
        name
        slug
        shortDescription
        description
        objectives {
          objective
        }
        sortOrder
        status
        images {
          original
        }
        courseType {
          name
        }
        courseSkills {
          id
          name
          slug
          status
        }
        sections {
          completed
          name
          id
          introduction
          sortNumber
          lessons {
            completed
            completedAt
            contentUrl
            description
            duration
            id
            introduction
            isLock
            name
            sectionId
            quizId
            meetingUrl
            quiz {
              createdAt
              createdById
              duration
              eligibleMarksForCertificate
              hasTimeLimit
              id
              questions {
                answers
                createdAt
                createdById
                duration
                hasMultipleAnswers
                id
                mark
                options {
                  optionImage
                  optionText
                }
                question
                questionImages
                questionOrder
                quizId
                updatedAt
                updatedById
              }
              status
              title
              totalMarks
              type
              updatedAt
              updatedById
            }
            sortNumber
            startDate
            status
            type
          }
          name
          sortNumber   
        }
        amount
        sellPrice
        ${includeCurrentChapter ? UserCurrentChapter : ''}
        ${includeCurrentChapter ? UserNextChapter : ''}
        startDatetime
        endDatetime
        totalSeat
        costType
        address
        meetingLink
        meetingNumber
        meetingPassword
        hasCertificate
        highlights {
          highlightIcon
          highlightText
          id
        }
        requirements
        courseCategoryId
        courseSubCategoryId
        courseLevelId
        courseMode {
          name
          id
        }
        courseRatings{
          ratings
          starCount
        }
        courseLanguages {
          id
          name
        }
        courseSettings {
          id
          courseId
          dateVisible
          durationVisible
          timeVisible
          languageVisible
          levelVisible
          courseTypeVisible
          createdAt
          updatedAt
        }
        courseModeId
        courseTypeId
        courseLevel {
          name
        }
        mentors {
          mentor {
            firstName
            lastName
            id
            userProfile {
              imageUrl
              id
              description
              gender
              qualification
            }
          }
        }
        completed
        wishlist
        enrollmentStatus
        previewVideo
        courseEnrollmentCount
        courseRatings {
          ratings
          starCount
        }
        totalUserRatings
        averageStarCount
        myCourseRatings{
					review
					starCount
					status
					user{
							fullName
							id
					}
				}
        userCourseRatings{
					review
					starCount
					status
					user{
							fullName
							id
					}
				}
        ratingCounts
        InCart
        progressPercentage
        relatedCoursesUId`;

const chapterDetails = `{
          completed
          completedAt
          contentUrl
          createdAt
          createdById
          description
          duration
          id
          introduction
          isLock
          name
          quizId
          section {
            completed
            id
            name
            introduction
            lessons {
              completed
              contentUrl
              description
              duration
              id
              introduction
              isLock
              name
              quizId
              sortNumber
              startDate
              status
              type             
            }
          }
          sectionId
          sortNumber
          startDate
          status
          type
          updatedAt
          updatedById
        }`;

export const UserCurrentChapter = `currentChapter ${chapterDetails}`;

export const UserNextChapter = `nextChapter ${chapterDetails}`;
