export enum CardHeight {
	SMALL = 'small-card-height',
	MEDIUM = 'medium-card-height',
	LARGE = 'large-card-height',
	AUTO = 'auto-card-height',
}

export enum SelectedTabType {
	'ALL' = 'All',
	'OFFLINE' = 'Offline',
	'LIVE' = 'Online',
	'ONGOING' = 'Ongoing',
	'COMPLETED' = 'Completed',
}
export enum ButtonType {
	PRIMARY_BUTTON = 'primary-btn',
	SECONDARY_BUTTON = 'secondary-btn',
	WHITE_BUTTON = 'white-btn',
	GREY_BUTTON = 'grey-btn',
}
export enum ButtonBorderType {
	BLACK_BORDER = 'border-style-black',
	PRIMARY_BORDER = 'border-primaryColor',
	GREY_BORDER = 'border-grey-color',
	NO_BORDER = 'border-0',
}

export enum SliderComponentType {
	COURSES,
	REVIEWS,
}
