import Slider from "react-slick";
import { toAbsoluteUrl } from "../../../../assets/helpers/AssetHelpers";
import { slider_four } from "../../../../assets/helpers/Slider";
import { GETMENTOR } from "../../core/requests";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { useEffect } from "react";

function Recommendation() {
  const navigate = useNavigate();
  const { data: mentor } = useQuery(GETMENTOR, {
    variables: {
      paginate: { per_page: 5, page: 0 },
    },
  });
  const mentorRecord: any = mentor?.getPublicInstructors?.records ?? [];
  const handleOpen = (id: string) => {
    navigate(`/mentor-view/${id}`);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <section className="section d-lg-block d-md-block d-none">
        <div className="container px-lg-3">
          <div className="heading mb-2 mx-1">
            <div className="row">
              <div className="col-xl-9 col-lg-9 col-md-6 col-12">
                <div className="font-title title pb-lg-3 head1">
                  Learn from industry leading mentors
                  <img
                    src="../media/logo/learn.png"
                    alt="learn"
                    className="mx-2 home-title-icon ms-2"
                    style={{ height: "35px", width: "35px" }}
                  />
                </div>
                <p className="font-subtitle sub-title text-muted-dark">
                  Top mentors for top knowledge
                </p>
              </div>

              <div className="col-xl-3 col-lg-3 col-md-6 col-12 d-grid gap-2 d-md-flex justify-content-md-end mt-4 ">
                <div className="d-lg-block d-md-block d-none">
                  <button
                    id="btn"
                    className="btn btn-outline-primary mb-0"
                    onClick={() => {
                      navigate("/mentors-list");
                    }}
                  >
                    Explore all
                    <i className="fa fa-arrow-right ms-1"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="row slickarrows">
            <Slider {...slider_four}>
              {mentorRecord.map((value: any) => (
                <div className="col-xxl-3 col-xl-3 col-lg-3 col-6 col-md-3 mt-2">
                  <div
                    className="card mentor-card mentor-card overflow-hidden border-muted cursor-pointer mx-xl-2 m-2"
                    onClick={() => {
                      handleOpen(value.id);
                      window.scrollTo(0, 0); // Scroll to the top when a card is clicked
                    }}
                  >
                    {/* <div className="card mentor-card mentor-card overflow-hidden border-muted cursor-pointer mx-xl-2 m-2"   onClick={() => handleOpen(value.slug); window.scrollTo(0, 0)} > */}
                    <div className="card-body rounded-md bg-white p-4">
                      <div className="mentor-image position-relative mentor">
                        {Array.from(value.userProfile).map((value: any) => {
                          return (
                            <>
                              {value?.imageUrl?.length > 0 ? (
                                <>
                                  <img
                                    alt=""
                                    src={value.imageUrl}
                                    className="card-img-top image img-fluid rounded bg-light"
                                  />
                                </>
                              ) : (
                                <img
                                  alt=""
                                  src={toAbsoluteUrl("/media/mentor/1.png")}
                                  className=" card-img-top  image img-fluid rounded bg-light"
                                />
                              )}
                            </>
                          );
                        })}
                        <div
                          className="mt-2"
                          onClick={() => handleOpen(value.slug)}
                        >
                          <div
                            className="name fs-4 mb-0"
                            onClick={() => handleOpen(value.slug)}
                          >
                            {value.firstName} {value.lastName}
                          </div>
                          <div className="fw-normal text-muted">
                            {Array.from(value.userProfile).map((value: any) => {
                              return <>{value.qualification}</>;
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>

            <div className="d-flex text-start mx-2 mt-4">
              <div className="d-lg-none d-md-none d-block">
                <button id="btn" className="btn btn-outline-primary mb-0">
                  Explore all
                  <i className="fa fa-arrow-right ms-1"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*
-------------------------------
 Responsive Mobile View Code 
 ---------------------------
 */}

      <section className="section d-lg-none d-md-none d-block">
        <div className="container px-lg-3">
          <div className="heading mb-2 mx-1">
            <div className="row">
              <div className="col-xl-9 col-lg-9 col-md-6 col-12">
                <div
                  className="font-title title pb-lg-3 head1"
                  style={{ fontSize: "18px" }}
                >
                  Learn from industry leading mentors
                  <img
                    src="../media/logo/learn.png"
                    alt="learn"
                    className="mx-2 home-title-icon ms-2"
                    style={{ height: "35px", width: "35px" }}
                  />
                </div>
                <p className="font-subtitle sub-title text-muted-dark">
                  Top mentors for top knowledge
                </p>
              </div>

              <div className="col-xl-3 col-lg-3 col-md-6 col-12 d-grid gap-2 d-md-flex justify-content-md-end ">
                <div className="d-lg-block d-md-block d-none">
                  <button
                    id="btn"
                    className="btn btn-outline-primary mb-0"
                    onClick={() => {
                      navigate("/mentors-list");
                    }}
                  >
                    Explore all
                    <i className="fa fa-arrow-right ms-1"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="row slickarrows">
            <Slider {...slider_four}>
              {mentorRecord.map((value: any) => (
                <div className="col-xxl-3 col-xl-3 col-lg-3 col-6 col-md-3 mt-2">
                  <div
                    className="card mentor-card mentor-card overflow-hidden border-muted cursor-pointer mx-xl-2 m-2"
                    onClick={() => {
                      handleOpen(value.slug);
                      window.scrollTo(0, 0); // Scroll to the top when a card is clicked
                    }}
                  >
                    {/* <div className="card mentor-card mentor-card overflow-hidden border-muted cursor-pointer mx-xl-2 m-2"   onClick={() => handleOpen(value.slug); window.scrollTo(0, 0)} > */}
                    <div className="card-body rounded-md bg-white p-4">
                      <div className="mentor-image position-relative mentor">
                        {Array.from(value.userProfile).map((value: any) => {
                          return (
                            <>
                              {value?.imageUrl?.length > 0 ? (
                                <>
                                  <img
                                    alt=""
                                    src={value.imageUrl}
                                    className="card-img-top image img-fluid rounded bg-light"
                                  />
                                </>
                              ) : (
                                <img
                                  alt=""
                                  src={toAbsoluteUrl("/media/mentor/1.png")}
                                  className=" card-img-top  image img-fluid rounded bg-light"
                                />
                              )}
                            </>
                          );
                        })}
                        <div
                          className="mt-2"
                          onClick={() => handleOpen(value.slug)}
                        >
                          <div
                            className="name fs-4 mb-0"
                            onClick={() => handleOpen(value.slug)}
                          >
                            {value.firstName} {value.lastName}
                          </div>
                          <div className="fw-normal text-muted">
                            {Array.from(value.userProfile).map((value: any) => {
                              return <>{value.qualification}</>;
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>

            <div className="d-flex text-start mx-2 mt-4">
              <div className="d-lg-none d-md-none d-block">
                <button id="btn" className="btn btn-outline-primary mb-0">
                  Explore all
                  <i className="fa fa-arrow-right ms-1"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*
-------------------------------
 Responsive Mobile View Code 
 ---------------------------
 */}

      <section className="section d-lg-none d-md-none d-block">
        <div className="container px-lg-3">
          <div className="heading mb-2 mx-1">
            <div className="row">
              <div className="col-xl-9 col-lg-9 col-md-6 col-12">
                <div
                  className="font-title title pb-lg-3 head1"
                  style={{ fontSize: "18px" }}
                >
                  Learn from industry leading mentors
                  <img
                    src="../media/logo/learn.png"
                    alt="learn"
                    className="mx-2 home-title-icon ms-2"
                    style={{ height: "35px", width: "35px" }}
                  />
                </div>
                <p className="font-subtitle sub-title text-muted-dark">
                  Top mentors for top knowledge
                </p>
              </div>

              <div className="col-xl-3 col-lg-3 col-md-6 col-12 d-grid gap-2 d-md-flex justify-content-md-end ">
                <div className="d-lg-block d-md-block d-none">
                  <button
                    id="btn"
                    className="btn btn-outline-primary mb-0"
                    onClick={() => {
                      navigate("/mentors-list");
                    }}
                  >
                    Explore all
                    <i className="fa fa-arrow-right ms-1"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="row slickarrows">
            <Slider {...slider_four}>
              {mentorRecord.map((value: any) => (
                <div className="col-xxl-3 col-xl-3 col-lg-3 col-6 col-md-3 mt-2">
                  <div
                    className="card mentor-card mentor-card overflow-hidden border-muted cursor-pointer mx-xl-2 m-2"
                    onClick={() => {
                      handleOpen(value.slug);
                      window.scrollTo(0, 0); // Scroll to the top when a card is clicked
                    }}
                  >
                    {/* <div className="card mentor-card mentor-card overflow-hidden border-muted cursor-pointer mx-xl-2 m-2"   onClick={() => handleOpen(value.slug); window.scrollTo(0, 0)} > */}
                    <div className="card-body rounded-md bg-white p-4">
                      <div className="mentor-image position-relative mentor">
                        {Array.from(value.userProfile).map((value: any) => {
                          return (
                            <>
                              {value?.imageUrl?.length > 0 ? (
                                <>
                                  <img
                                    alt=""
                                    src={value.imageUrl}
                                    className="card-img-top image img-fluid rounded bg-light"
                                  />
                                </>
                              ) : (
                                <img
                                  alt=""
                                  src={toAbsoluteUrl("/media/mentor/1.png")}
                                  className=" card-img-top  image img-fluid rounded bg-light"
                                />
                              )}
                            </>
                          );
                        })}
                        <div
                          className="mt-2"
                          onClick={() => handleOpen(value.slug)}
                        >
                          <div
                            className="name fs-4 mb-0"
                            onClick={() => handleOpen(value.slug)}
                          >
                            {value.firstName} {value.lastName}
                          </div>
                          <div className="fw-normal text-muted">
                            {Array.from(value.userProfile).map((value: any) => {
                              return <>{value.qualification}</>;
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>

            <div className="d-flex text-start mx-2 mt-4">
              <div className="d-lg-none d-md-none d-block">
                <button id="btn" className="btn btn-outline-primary mb-0">
                  Explore all
                  <i className="fa fa-arrow-right ms-1"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Recommendation;
