import { toAbsoluteUrl } from "assets/helpers/AssetHelpers";
import React from "react";

const QuizTimeUp = ({ onSubmit }: { onSubmit: () => void }) => {
  return (
    <div className="quiz-timeup-layout text-center">
      <img
        src={toAbsoluteUrl("/media/course-detail-images/quiz-timeup.svg")}
        alt="Quiz_TimeUP"
      />
      <h4 className="timneup-title lora">Time Up</h4>
      <p className="quiz-time-label g_reg">Your Quiz Time has Ended</p>
      <button className="btn quiz-submit-btn g_mid" onClick={() => onSubmit()}>
        Get Your Result
      </button>
      {/* <ButtonRenderer
      textClass="g_mid quiz-submit-btn w-100"
      text="Submit"
      typeOfButton={ButtonType.PRIMARY}
      handleClick={() => console.log("test")}
    /> */}
    </div>
  );
};

export default QuizTimeUp;
