import { Link } from 'react-router-dom';
import './Breadcrumbs.css';
import { BreadcrumbDataProps } from 'types/globalTypes';
import { toAbsoluteUrl } from 'assets/helpers/AssetHelpers';

const Breadcrumbs = ({ breadcrumbData }: { breadcrumbData: BreadcrumbDataProps[] }) => {
	return (
		<div className="d-flex flex-row  gap-sm-3 ps-3 ps-sm-0 mb-4 flex-nowrap">
			{breadcrumbData.map((breadcrumb, index) => {
				return (
					<div key={index} className="d-flex g_reg gap-1 align-items-center justify-content-center">
						{breadcrumb.href ? (
							<Link
								to={breadcrumb.href}
								className={index + 1 != breadcrumbData.length ? 'link-breadcrumb' : 'black'}>
								{breadcrumb.name}
							</Link>
						) : (
							<span className="breadcrumbs-title">{breadcrumb.name}</span>
						)}
						{index + 1 != breadcrumbData.length ? (
							<img
								alt="Logo"
								src={toAbsoluteUrl('/media/breadCrumbs/arrow-left.svg')}
								style={{ width: '25px' }}
								className="mx-1"
							/>
						) : (
							''
						)}
					</div>
				);
			})}
		</div>
	);
};

export default Breadcrumbs;
