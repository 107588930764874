import React from "react";
import { Accordion } from "react-bootstrap";
import { PiNumberCircleThreeFill } from "react-icons/pi";

export const Gst = (props: any) => {
  const { handleFormDataChangeforGst, formData, gst } = props;
  return (
    <>
      <Accordion className="checkout p-0 mb-3" id="gstDetails">
        <Accordion.Item eventKey="">
          <Accordion.Header
            // className="font-subtitle bg-light fw-bolder"
            className={`font-subtitle bg-light fw-bolder ${
              gst ? "active" : ""
            }`}
          >
            {/* <AiFillCheckCircle className="text-success me-2 fs-5" /> */}
            <PiNumberCircleThreeFill className="checkout-number fs-5 me-2 mb-1" />
            GST Details (Optional)
            <span className="text-end float-end">
              <div className="text-primary">Skip</div>
            </span>
          </Accordion.Header>
          <Accordion.Body
            // className="text-muted font-italic  fs-6"
            className={`text-muted font-italic fs-6 ${gst ? "show" : ""}`}
          >
            <form action="">
              <div className="row">
                <div className="col-6">
                  <label className="font-subtitle text-muted fs-6 mb-1">
                    GST Number
                  </label>
                  <input
                    type="text"
                    className="form-control border-muted"
                    placeholder="Enter GST Number"
                    name="gstNo"
                    defaultValue={formData.gstNo}
                    onChange={handleFormDataChangeforGst}
                  />
                </div>
                <div className="col-6">
                  <label className="font-subtitletext-muted fs-6 mb-1">
                    Company Name
                  </label>
                  <input
                    type="text"
                    className="form-control border-muted"
                    placeholder="Enter Company Name"
                    name="companyName"
                    defaultValue={formData.companyName}
                    onChange={handleFormDataChangeforGst}
                  />
                </div>
                <div className="col-12 mt-3">
                  <label className="font-subtitle text-muted fs-6 mb-1">
                    Registered Address
                  </label>
                  <input
                    type="text"
                    className="form-control border-muted"
                    placeholder="Enter Registered Address"
                    name="registerAddress"
                    defaultValue={formData.registerAddress}
                    onChange={handleFormDataChangeforGst}
                  />
                </div>

                <div className="col-12 mt-3">
                  <button type="button" className="btn btn-primary">
                    Continue
                  </button>
                </div>
              </div>
            </form>
            {/* <table className="w-100 mt-3">
              <tr className="font-subtitle border-bottom fw-light fs-6 fst-normal">
                <th>Seat</th>
                <th>Full Name</th>
                <th>Email</th>
                <th>Phone Number</th>
              </tr>
              <tr>
                <td>1</td>
                <td>John Smith</td>
                <td>johnsmith@gmail.com</td>
                <td>98765 43210</td>
              </tr>
              <tr>
                <td>2</td>
                <td>John Smith</td>
                <td>johnsmith@gmail.com</td>
                <td>98765 43210</td>
              </tr>
            </table> */}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  );
};
